import { takeLatest, put, race, take } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  fetchContracts,
  upsertPlan,
  cloneContract,
  popupAlertDialog,
  upsertUser
} from '../actions';
import { cloneContract as cloneContractApi } from '../api/graphql';

export function* cloneContractSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      contractId,
      history,
      cloneContractMessage,
      generalAlertText
    } = payload || {};

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');

    yield put(popupAlertDialog({
      title: (generalAlertText || {}).title,
      message: (generalAlertText || {}).message
    }));
    const [cancelled] = yield race([
      take(popupAlertDialog.type.ERROR),
      take(popupAlertDialog.type.SUCCESS)
    ]);

    if (cancelled) {
      yield put(upsertUser.error());
      return;
    }

    const res =
      yield cloneContractApi({ token, contractId });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: cloneContractMessage.success,
        variant: 'success'
      }));
      yield put(cloneContract.success());
      yield put(fetchContracts({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || cloneContractMessage.error,
        variant: 'error'
      }));
    }
    history.push('/admin/contracts');
  } catch (err) {
    upsertPlan.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(cloneContract.type.PENDING, cloneContractSaga);
}
