
const renderDiagramData = renderData => {
  const list = renderData;
  const map = {};
  let node;
  const roots = [];
  let index;
  // eslint-disable-next-line no-restricted-syntax
  for (index = 0;index < list.length;index += 1) {
    map[list[index].key] = index; // initialize the map
    list[index].children = []; // initialize the children
  }

  // eslint-disable-next-line no-restricted-syntax
  for (index = 0;index < list.length;index += 1) {
    node = list[index];
    if (node.parentKey !== '') {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentKey]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getTreeData = (data, plainData = false, showOnlyQuantity = false) => {
  const reorganizeData = (data || []).reduce((accum, item) => {
    const { key, deleted, title, dispatchCompleteNumber,
      planQuantity, dispatchAssignNumber, deliveryNumber } = item || {};
    const showQuantity = planQuantity ?
      // eslint-disable-next-line max-len
      `(${dispatchCompleteNumber ? dispatchCompleteNumber : 0}/${planQuantity ? planQuantity : 0}/${dispatchAssignNumber ? dispatchAssignNumber : 0}/${deliveryNumber ? deliveryNumber : 0})` : '';
    // eslint-disable-next-line no-nested-ternary
    const displayTitle = `${title} ${showOnlyQuantity ? - (planQuantity ? planQuantity : 0) : showQuantity}`;
    const layers = (key || '').split('-') || [];
    const level = layers.length;
    const lastLayer = level - 1;
    const parentKey = layers.filter((___, idx2) => idx2 < lastLayer).join('-');

    const isParentKeyExist = data.find(eachDate => eachDate.key === parentKey);

    const result = { ...item, level, parentKey: isParentKeyExist ? parentKey : '',
      id: key, name: title, title: displayTitle };
    if (!deleted) {
      return [...accum, result];
    }
    return accum;
  }, []);

  if (plainData) {
    return reorganizeData;
  }

  const reorganizeDataWithSortKey = reorganizeData.map(item => {
    const { key } = item || {};
    const keyList = key.split('-');
    const firstPart = parseInt(keyList[0]) < 10 ? `0${keyList[0]}` : keyList[0];
    const finalFirst = keyList[0] ? firstPart : '00';
    const secondPart = parseInt(keyList[1]) < 10 ? `0${keyList[1]}` : keyList[1];
    const finalSecond = keyList[1] ? secondPart : '00';
    const thirdPart = parseInt(keyList[2]) < 10 ? `0${keyList[2]}` : keyList[2];
    const finalThird = keyList[2] ? thirdPart : '00';
    const sortKey = `${finalFirst}${finalSecond}${finalThird}`;
    return { ...item, sortKey };
  });

  const filterReorganizeData = reorganizeDataWithSortKey.sort((alpha, bate) => alpha.sortKey - bate.sortKey);

  const displayData = renderDiagramData(filterReorganizeData);

  return displayData;
};
