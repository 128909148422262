/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import regularFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import WidgetHeader from '../../../components/ui/WidgetHeader';
import DispatchWorkAddEdit from '../DispatchWorkAddEdit';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import moment from 'moment-timezone';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { TableContainer } from '@mui/material';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';

const DispatchWork = props => {
  const {
    modeType,
    history,
    onClose,
    upsertDispatchWork,
    workers,
    dispatchId,
    fetchDispatchWorks,
    timezone,
    totalDispatchNumber,
    selectedDispatch,
    equipments,
    planItemsTreeData,
    planItemsPlainData,
    dispatchWorkMainRows,
    isContractAssign
  } = props || {};

  // const [useExpandNotes, setUseExpandNotes] = useState(false);
  // const [expandNodes, setExpandNodes] = useState([]);
  //
  // const onExpand = expandedKeys => {
  //   setUseExpandNotes(true);
  //   setExpandNodes(expandedKeys);
  // };

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      }
    }
  );

  const useStyles = makeStyles(styles);

  const [editDispatchWork, setEditDispatchWork] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (dispatchId) {
      fetchDispatchWorks({
        history,
        dispatchId
      });
    }
  }, []);

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'dispatchId',
      label: 'DispatchId',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'createDate',
      label: 'CreateDate',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'startDate',
      label: translate('dispatchDetailForm&Table.startDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'endDate',
      label: translate('dispatchDetailForm&Table.endDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'worker',
      label: translate('dispatchDetailForm&Table.workers'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: workerId =>
          (workers.find(worker => worker.id === workerId) || {}).label
      }
    },

    ...isContractAssign ? [] :
      [{
        name: 'workTime',
        label: translate('dispatchDetailForm&Table.workTime'),
        options: {
          filter: true,
          sort: true
        }
      },

      {
        name: 'workType',
        label: translate('dispatchDetailForm&Table.workType'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: workType => translate(`workType.${workType}`)
        }
      },

      {
        name: 'equipment',
        label: translate('dispatchDetailForm&Table.equipment'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: equipment => {
            const equipmentObj = (equipments || []).find(item => item.equipmentId === equipment) || {};
            return (equipmentObj || {}).name;
          }
        }
      }],
    {
      name: 'level',
      label: translate('dispatchDetailForm&Table.level'),
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'assignItem',
      label: translate('dispatchDetailForm&Table.assignItem'),
      options: {
        filter: true,
        sort: true
      }
    },

    {
      name: 'dispatchNumber',
      label: translate('dispatchDetailForm&Table.dispatchNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'completeNumber',
      label: translate('dispatchDetailForm&Table.completeNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: translate('dispatchDetailForm&Table.note'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'createDate',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: createDate => (
          <GridContainer wrap={'nowrap'}>
            {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
            <GridItem>
              <Tooltip title={translate('dispatchHint.editDispatchWork')}>
                <IconButton
                  onClick={() => setEditDispatchWork(createDate)}
                  color={'primary'}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            {/*}*/}

            {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
            <GridItem>
              <Tooltip title={translate('dispatchHint.delete')}>
                <IconButton
                  onClick={() => {
                    upsertDispatchWork({
                      isDelete: true,
                      formValues: { dispatchId, createDate },
                      history,
                      upsertDispatchMessage:
                        { success: translate('upsertDispatch.success'),
                          error: translate('upsertDispatch.error') },
                      generalAlertText: {
                        title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message')
                      }
                    });
                  }}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            {/*}*/}

          </GridContainer>
        )
      }
    }
  ];

  const {
    contract,
    assignPlan,
    level1,
    level2,
    level3,
    planNumber,
    startDate: sDate,
    endDate: eDate
  } = selectedDispatch || {};

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'xl'}
          isOpen={true}
          onClose={onClose}
          id="capitalAddEdit-dialog"
          title={translate(modeType)}
        >
          <Grid container spacing={24}>

            <Grid item xs={6} className={classes.leftBody}>
              <Paper elevation={3}>
                <WidgetHeader
                  title={translate('dispatchDetail')} />
                <div className={classes.widget}>
                  <Grid container spacing={16}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow key={1}>
                            <TableCell align="left">{translate('assignItemDetail.contract')}:</TableCell>
                            <TableCell align="left">{contract}</TableCell>
                          </TableRow>
                          <TableRow key={2}>
                            <TableCell align="left">{translate('assignItemDetail.plan')}:</TableCell>
                            <TableCell align="left">{assignPlan}</TableCell>
                          </TableRow>
                          <TableRow key={3}>
                            <TableCell align="left">{translate('assignItemDetail.assignItem')}:</TableCell>
                            <TableCell align="left">{level1} - {level2} - {level3}</TableCell>
                          </TableRow>
                          <TableRow key={4}>
                            <TableCell align="left">{translate('assignItemDetail.date')}:</TableCell>
                            <TableCell align="left">
                              {moment.unix(sDate).tz(timezone)
                                .format('MM/DD/YYYY')} ~ {moment.unix(eDate).tz(timezone)
                                .format('MM/DD/YYYY')}
                            </TableCell>
                          </TableRow>
                          <TableRow key={5}>
                            <TableCell align="left">{translate('assignItemDetail.dispatchNumber')}:</TableCell>
                            <TableCell align="left">{planNumber}</TableCell>
                          </TableRow>

                          <TableRow key={6}>
                            <TableCell align="left">{translate('assignItemDetail.dispatchWorkType')}:</TableCell>
                            <TableCell align="left">{isContractAssign ? '包工' : '派工'}</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={6} className={classes.leftBody}>
              <Paper elevation={3}>
                <WidgetHeader
                  title={'分配详情'} />
                <div className={classes.widget}>
                  <Grid container
                    spacing={16} wrap={'nowrap'}>
                    <Grid item xs={12}>
                      <Tree
                        switcherIcon={<DownOutlined />}
                        // expandedKeys={
                        //   useExpandNotes ? expandNodes :
                        //     (planItemsPlainData || []).map(item => item.key)}
                        // onExpand={onExpand}
                        treeData={planItemsTreeData || []}
                        showLine={{ showLeafIcon: false }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={editDispatchWork ? 8 : 12} className={classes.leftBody}>
              <Paper elevation={3}>
                <WidgetHeader
                  isAdd
                  onEdit={() => setEditDispatchWork('new')}
                  title={translate('dispatchWork')} />
                <div className={classes.widget}>
                  <MUIDataTable
                    title={(
                      <Grid container justifyContent="flex-start">
                      </Grid>
                    )}
                    data={dispatchWorkMainRows || []}
                    columns={renderColumns()}
                    options={{
                      textLabels: MUI_DATA_TABLE_LABELS,
                      responsive: 'stacked',
                      elevation: 0,
                      selectableRows: 'none',
                      rowsPerPage: 50,
                      rowsPerPageOptions: [25, 50, 75, 100],
                      download: false,
                      print: false
                    }}
                  />
                </div>
              </Paper>
            </Grid>

            {editDispatchWork &&
                <Grid item xs={12} sm={4} className={classes.leftBody}>
                  <Paper elevation={3}>
                    <DispatchWorkAddEdit
                      planItemsPlainData={planItemsPlainData}
                      planItemsTreeData={planItemsTreeData}
                      totalDispatchNumber={totalDispatchNumber}
                      planNumber={planNumber}
                      selectedDispatch={selectedDispatch}
                      dispatchWorkMainRows={dispatchWorkMainRows}
                      dispatchId={dispatchId}
                      workers={workers}
                      editDispatchWork={editDispatchWork}
                      setEditDispatchWork={setEditDispatchWork}
                    />
                  </Paper>
                </Grid>
            }
          </Grid>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

DispatchWork.propTypes = {
  history: PropTypes.any
};

export default DispatchWork;
