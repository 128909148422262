import gql from 'graphql-tag';

export default gql`
    mutation upsertAttendee(
        $token: String!
        $request: AttendeeInput
        $correlationId: String!
        $isDelete: Boolean
    ){
        upsertAttendee(
            token: $token
            correlationId: $correlationId
            request: $request
            isDelete: $isDelete
        ) {
            error
            message
        }
    }
`;
