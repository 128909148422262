import React, { memo } from 'react';
import Divider from '@material-ui/core/Divider';
import { withTheme } from '@material-ui/core/styles';

// eslint-disable-next-line react/display-name
const DividerWithSpacing = memo(({ theme, spacing = 1 }) => (
  <Divider
    component="div"
    style={{
      marginTop: theme.spacing.unit * spacing,
      marginBottom: theme.spacing.unit * spacing
    }}
  />
));

export default withTheme(DividerWithSpacing);
