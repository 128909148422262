import moment from 'moment-timezone';

const getDispatchWorkDisplayData = ({ dispatchWorkDisplay, workers, equipmentTypes, equipmentList }) => {
  const dispatchWorkDisplayData = dispatchWorkDisplay.map(item => {
    const { worker, equipment, equipmentType, level1, level2, level3, contract, assignPlan, workType, startDate }
      = item || {};
    const workerName = ((workers || []).find(workerItem => workerItem.id === worker) || {}).name;
    const equipmentTypeName = ((equipmentTypes || [])
      .find(eTypeItem => eTypeItem.setupId === equipmentType) || {}).name;
    const equipmentName = ((equipmentList || [])
      .find(equip => equip.equipmentId === equipment) || {}).name;

    let workTypeSort = 0;
    if (workType === 'day') {
      workTypeSort = 1;
    }
    if (workType === 'bigDay') {
      workTypeSort = 2;
    }
    if (workType === 'night') {
      workTypeSort = 3;
    }
    if (workType === 'bigNight') {
      workTypeSort = 4;
    }
    if (workType === 'overTime') {
      workTypeSort = 5;
    }
    if (workType === 'noon') {
      workTypeSort = 6;
    }

    const date = moment.unix(startDate).format('YYYY-MM-DD');
    const sortKey = `${date}:${workTypeSort}:${workerName}:`;

    return {
      ...item,
      workerName,
      equipmentTypeName,
      equipmentName,
      level1Name: level1.name,
      level2Name: level2.name,
      level3Name: level3.name,
      contractName: contract.name,
      assignPlanName: assignPlan.name,
      sortKey
    };
  });

  const data = (dispatchWorkDisplayData || [])
    .sort(
      (
        { sortKey: sortKeyA },
        { sortKey: sortKeyB }
      ) => (`${sortKeyA}` < `${sortKeyB}` ? -1 : 1));

  return data;
};

export default getDispatchWorkDisplayData;
