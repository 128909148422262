import { takeLatest, put } from 'redux-saga/effects';
import { fetchLoginUserInfo } from '../actions';
import { getUserInfo as getUserInfoApi } from '../api/graphql';
import { SUPER_ADMIN } from '../conf';

export function* loginSaga({ payload }) {
  try {
    // eslint-disable-next-line no-undef
    const { history } = payload || {};
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const result = yield getUserInfoApi({ token });
    if (!result) {
      history.push('/auth');
    }
    const email = (result || {}).email;
    const permissions = (result || {}).permissions;
    const userInfo = SUPER_ADMIN.includes(email) ?
      { ...result, isSuperAdmin: true } : result;
    if (permissions) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('permissions', JSON.stringify(permissions));
    }
    yield put(fetchLoginUserInfo.success(userInfo));
  } catch (err) {
    fetchLoginUserInfo.error(err);
  }
}

export default function*() {
  yield takeLatest(fetchLoginUserInfo.type.PENDING, loginSaga);
}
