import { takeLatest, put, race, take, select } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertDispatch,
  fetchDispatches
} from '../actions';
import moment from 'moment-timezone';

import { upsertDispatch as upsertDispatchApi } from '../api/graphql';
import { getLoginUserClientConfigTimezone } from '../selectors';

export function* upsertDispatchSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertDispatchMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    const timezone = yield select(getLoginUserClientConfigTimezone);

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertDispatch.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { startDate: start, endDate: end, contract, assignPlan, assignPlanItem, level1,
      level2, level3, note, planNumber, dispatchId, dispatchWorkType, contractAmount,
      contractCompleteDate: completeDate, contractCompleteWorker, additionalNotes } = formValues;

    const startDate = (Number.isInteger(start)) ? start : (moment(start).tz(timezone)
      .startOf('day')
      .unix());

    const endDate = (Number.isInteger(end)) ? end : (moment(end).tz(timezone)
      .endOf('day')
      .unix());

    const contractCompleteDate = (Number.isInteger(completeDate)) ? end : (moment(completeDate).tz(timezone)
      .startOf('day')
      .unix());

    const request = {
      dispatchId,
      startDate,
      endDate,
      level1,
      level2,
      level3,
      note,
      planNumber,
      contract,
      assignPlan,
      assignPlanItem,
      dispatchWorkType,
      contractAmount,
      contractCompleteDate,
      contractCompleteWorker,
      additionalNotes
    };
    // const res = {};
    const res = yield upsertDispatchApi({ token, request, isDelete });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertDispatchMessage.success,
        variant: 'success'
      }));
      yield put(upsertDispatch.success());
      yield put(fetchDispatches({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertDispatchMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertDispatch.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertDispatch.type.PENDING, upsertDispatchSaga);
}
