import { fromJS } from 'immutable';
import {
  setCommoditiesImages
} from '../selectors';
import {
  UPLOAD_IMAGE_ASYNC
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const setters = {
  uploadImageCommodity: (state, payload) => setCommoditiesImages(state, payload)
};

const createSetter = uploadName => setters[uploadName];

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [UPLOAD_IMAGE_ASYNC.SUCCESS]: () => createSetter(payload.uploadName)(state, payload)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
