import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchContracts } from '../actions';
import { getContracts as getContractsApi } from '../api/graphql';

export function* fetchContractsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const {
      history,
      level2Number
    } = payload || {};
    const { contracts, contractOptions, message, error } =
      yield getContractsApi({
        token,
        level2Number
      });
    const companyOptions = (contractOptions || []).filter(item => item.type === 'company');
    const entrustCompanyOptions = (contractOptions || []).filter(item => item.type === 'entrustCompany');
    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchContracts.success({
      companyOptions,
      entrustCompanyOptions,
      contracts,
      ...level2Number && { level2Number }
    }));
    // yield put(setAppAlert({
    //   message: fetchEquipmentsMessage.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchContracts.type.PENDING, fetchContractsSaga);
}
