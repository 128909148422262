/* eslint-disable max-lines */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import DatePicker from '../../../components/redux-form/DatePicker';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../components/Grid/GridItem';
import moment from 'moment-timezone';
import AutoSuggest from '../../../components/redux-form/AutoSuggest';

const styles = () => ({
  root: {
    marginTop: 10,
    marginRight: 50
  },
  searchButton: {
    marginTop: 17
  },
  marginTop: {
    marginTop: 10
  }
});
const useStyles = makeStyles(styles);

export default function Capitals(props) {
  const {
    fetchDispatches,
    history,
    formStartDate,
    formEndDate,
    onStartBiggerThanEnd,
    contractsOptions,
    fetchSearchPlans,
    onLevel1Clear,
    onLevel2Clear,
    onLevel3Clear,
    planHierarchy,
    level1Options,
    level2Options,
    level3Options,
    fetchAssignPlans,
    assignPlanOptions,
    saveDispatchesSearchData,
    entrustCompanyOptions,
    formValues
  } = props;

  const onContractChange = cId => {
    if (cId) {
      fetchSearchPlans(
        {
          history,
          contractId: cId
        });
      fetchAssignPlans({
        history,
        contractId: cId
      });
    }
  };

  const onSubmit = evt => {
    evt.preventDefault();
    fetchDispatches({
      history
    });
  };

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <Form>
      <Grid container justify={'center'} alignItems="center" spacing={24}>
        <div className={classes.root}>
          <Grid container>
            <GridItem xs={3}>
              <Field
                required
                fullWidth
                onChange={evt => {
                  const changeStartDate = moment(evt).unix();
                  const endDate = Number.isInteger(formEndDate) ? formEndDate : moment(formEndDate).unix();
                  if (changeStartDate > endDate) {
                    onStartBiggerThanEnd(evt);
                  }
                  saveDispatchesSearchData({
                    ...formValues,
                    startDate: evt,
                    ...changeStartDate > endDate && { endDate: evt }
                  });
                }}
                label={translate('startDate')}
                component={DatePicker}
                name="startDate"
                validate={[required]}
              />
            </GridItem>

            <GridItem xs={3}>
              <Field
                required
                fullWidth
                minDate={formStartDate}
                onChange={evt => {
                  saveDispatchesSearchData({
                    ...formValues,
                    endDate: evt
                  });
                }}
                label={translate('endDate')}
                component={DatePicker}
                name="endDate"
                validate={[required]}
              />
            </GridItem>

            <GridItem xs={3} className={classes.marginTop}>
              <Field
                fullWidth
                component={AutoSuggest}
                name="dispatchWorkType"
                itemsToUse={[{ value: 'assign', label: '派工' },
                  { value: 'contract', label: '包工' }]}
                validate={[required]}
                onChange={evt => {
                  saveDispatchesSearchData({
                    ...formValues,
                    dispatchWorkType: evt
                  });
                }}
                label={translate('dispatchWorkType')}
              />
            </GridItem>
          </Grid>

          <Grid container>

            <GridItem xs={3} className={classes.marginTop}>
              <Field
                fullWidth
                component={AutoSuggest}
                name="contractEntrustCompany"
                itemsToUse={entrustCompanyOptions}
                validate={[required]}
                onChange={evt => {
                  saveDispatchesSearchData({
                    ...formValues,
                    contractEntrustCompany: evt
                  });
                }}
                label={'委托公司'}
              />
            </GridItem>

            <GridItem xs={3} className={classes.marginTop}>
              <Field
                fullWidth
                component={AutoSuggest}
                name="contractStatus"
                itemsToUse={
                  [
                    {
                      label: '未完成',
                      value: 'active'
                    },
                    {
                      label: '已完成',
                      value: 'inactive'
                    }]
                }
                validate={[required]}
                label={'合同状态'}
                onChange={evt => {
                  saveDispatchesSearchData({
                    ...formValues,
                    contractStatus: evt
                  });
                }}
              />
            </GridItem>

            <GridItem xs={3} className={classes.marginTop}>
              <Field
                onChange={evt => {
                  if (!evt) {
                    onLevel1Clear();
                    onLevel2Clear();
                    onLevel3Clear();
                  }
                  saveDispatchesSearchData({
                    ...formValues,
                    contract: evt
                  });
                  return onContractChange(evt);
                } }
                fullWidth
                component={AutoSuggest}
                name="contract"
                itemsToUse={contractsOptions}
                validate={[required]}
                label={translate('capitalTable&Form.contract')}
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={2} className={classes.marginTop}>
              <Field
                fullWidth
                component={AutoSuggest}
                name="assignPlan"
                itemsToUse={assignPlanOptions}
                onChange={evt => {
                  saveDispatchesSearchData({
                    ...formValues,
                    assignPlan: evt
                  });
                }}
                label={translate('dispatchDetailForm&Table.plan')}
              />
            </GridItem>

            <GridItem xs={2} className={classes.marginTop}>
              <Field
                onChange={evt => {
                  if (!evt) {
                    onLevel2Clear();
                    onLevel3Clear();
                  }
                  saveDispatchesSearchData({
                    ...formValues,
                    level1: evt
                  });
                } }
                fullWidth
                component={AutoSuggest}
                name="level1"
                itemsToUse={level1Options}
                validate={[required]}
                label={((planHierarchy || []).find(item => item.level === 1) || {}).name}
              />
            </GridItem>

            <GridItem xs={2} className={classes.marginTop}>
              <Field
                onChange={evt => {
                  if (!evt) {
                    onLevel3Clear();
                  }
                  saveDispatchesSearchData({
                    ...formValues,
                    level2: evt
                  });
                } }
                fullWidth
                component={AutoSuggest}
                name="level2"
                itemsToUse={level2Options}
                validate={[required]}
                label={((planHierarchy || []).find(item => item.level === 2) || {}).name}
              />
            </GridItem>

            <GridItem xs={2} className={classes.marginTop}>
              <Field
                fullWidth
                component={AutoSuggest}
                name="level3"
                itemsToUse={level3Options}
                onChange={evt => {
                  saveDispatchesSearchData({
                    ...formValues,
                    level3: evt
                  });
                }}
                validate={[required]}
                label={((planHierarchy || []).find(item => item.level === 3) || {}).name}
              />
            </GridItem>
          </Grid>

          <Grid container justify={'center'} alignItems="center">
            <div className={classes.root}>
              <Button
                onClick={evt => onSubmit(evt)}
                className={classes.searchButton}
                type="button"
                color="info"
              >
                {translate('submit')}
              </Button>
            </div>
          </Grid>

        </div>

      </Grid>
    </Form>

  );
}

Capitals.propTypes = {
  history: PropTypes.any
};
