import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const AUTO_HIDE_DURATION = 5000;

const AppAlert = ({ alert: { message, variant, isVisible }, closeAppAlert, classes }) => {
  const Icon = variantIcon[variant];
  const handleClose = (event, reason) => reason !== 'clickaway' && closeAppAlert();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isVisible}
      onClose={handleClose}
      autoHideDuration={AUTO_HIDE_DURATION}>
      <SnackbarContent
        className={classNames(classes[variant], classes.contentRoot)}
        aria-describedby="app-alert-message"
        message={
          <span id="app-alert-message" className={classes.message}>
            { variant && <Icon className={classNames(classes.icon, classes.iconVariant)} /> }
            { message ? message : '' }
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

AppAlert.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string,
    variant: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
    isVisible: PropTypes.bool
  }),
  closeAppAlert: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

AppAlert.defaultProps = {
  alert: {
    message: null,
    variant: 'info',
    isVisible: false
  }
};

export default withStyles(styles)(AppAlert);
