import { createSelector } from 'reselect';
import { fromJS, List, Map, isMap } from 'immutable';

export const setContracts = (contractsState, payload) => contractsState.setIn(['contractsList'], fromJS(payload));
export const getContracts = createSelector(
  state => state.getIn(['contracts', 'contractsList'], List()),
  contractsList => (contractsList || List()).toJS()
);

export const getActiveContracts = createSelector(
  state => state.getIn(['contracts', 'contractsList'], List()),
  contractsList => (contractsList || List()).toJS()
    .filter(contract => (contract.contractStatus === 'active'
      || !contract.contractStatus))
);

export const setContractCompanyOptions = (contractsState, payload) =>
  contractsState.setIn(['companyOptions'], fromJS(payload));
export const getContractCompanyOptions = createSelector(
  state => state.getIn(['contracts', 'companyOptions'], List()),
  companyOptions => (companyOptions || List()).toJS().sort((alpha, bate) => bate - alpha)
);

export const setContractsForWarehouses = (warehousesState, payload) =>
  warehousesState.setIn(['contractsForWarehouses'], fromJS(payload));

export const getContractsForWarehouses = createSelector(
  state => state.getIn(['contracts', 'contractsForWarehouses'], List()),
  contractsForWarehouses => (contractsForWarehouses || List()).toJS() || []
);

export const setContractEntrustCompanyOptions = (contractsState, payload) =>
  contractsState.setIn(['entrustCompanyOptions'], fromJS(payload));
export const getContractEntrustCompanyOptions = createSelector(
  state => state.getIn(['contracts', 'entrustCompanyOptions'], List()),
  entrustCompanyOptions => (entrustCompanyOptions || List()).toJS().sort((alpha, bate) => bate - alpha)
);

export const setEditPlans = (contractsState, payload) => contractsState.setIn(['editPlans'], fromJS(payload));
export const getEditPlans = createSelector(
  state => state.getIn(['contracts', 'editPlans'], List()),
  editPlans => (editPlans || List()).toJS()
);

export const setSearchPlans = (contractsState, payload) => contractsState.setIn(['searchPlans'], fromJS(payload));
export const getSearchPlans = createSelector(
  state => state.getIn(['contracts', 'searchPlans'], List()),
  searchPlans => (searchPlans || List()).toJS()
);

export const setContractReceipt = (contractsState, payload) => contractsState.setIn(['contractReceipts'],
  fromJS(payload));
export const getContractReceipt = createSelector(
  state => state.getIn(['contracts', 'contractReceipts'], List()),
  contractReceipts => (contractReceipts || List()).toJS()
    .sort(
      (
        { date: dateA, createDate: createDateA },
        { date: dateB, createDate: createDateB }
      ) => (`${dateA}:${createDateA}` < `${dateB}:${createDateB}` ? 1 : -1))
);

export const setContractReturn = (contractsState, payload) => contractsState.setIn(['contractReturns'],
  fromJS(payload));
export const getContractReturn = createSelector(
  state => state.getIn(['contracts', 'contractReturns'], List()),
  contractReturns => (contractReturns || List()).toJS()
    .sort(
      (
        { date: dateA, createDate: createDateA },
        { date: dateB, createDate: createDateB }
      ) => (`${dateA}:${createDateA}` < `${dateB}:${createDateB}` ? 1 : -1))
);

export const setAssignPlans = (contractsState, payload) => contractsState.setIn(['assignPlans'], fromJS(payload));
export const getAssignPlans = createSelector(
  state => state.getIn(['contracts', 'assignPlans'], List()),
  assignPlans => (assignPlans || List()).toJS()
    .sort(
      (
        { createTime: createTimeA },
        { createTime: createTimeB }
      ) => (`${createTimeA}` < `${createTimeB}` ? 1 : -1))
);

export const setAssignPlanItems = (contractsState, payload) =>
  contractsState.setIn(['assignPlanItems'], fromJS(payload));
export const getAssignPlanItems = createSelector(
  state => state.getIn(['contracts', 'assignPlanItems'], List()),
  assignPlanItems => (assignPlanItems || List()).toJS()
);

export const setContractFilter = (contractsState, payload) => contractsState.setIn(['contractFilter'], fromJS(payload));
export const getContractFilter = createSelector(
  state => state.getIn(['contracts', 'contractFilter'], Map()),
  contractFilter => (isMap(contractFilter) ? (contractFilter || Map()).toJS() : contractFilter)
);

export const setContractTab = (contractsState, payload) => contractsState.setIn(['contractTab'], payload);
export const getContractTab = createSelector(
  state => state.getIn(['contracts', 'contractTab'], 0),
  contractTab => contractTab
);

export const setCheckin = (contractsState, payload) =>
  contractsState.setIn(['checkin'], fromJS(payload));
export const getCheckin = createSelector(
  state => state.getIn(['contracts', 'checkin'], List()),
  checkin => (checkin || List()).toJS()
);
