import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertDispatch } from '../../../actions/index';
import DispatchLists from './DispatchLists';
import {
  getDispatches,
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone,
  getUsers
} from '../../../selectors';

const stateToProps = state => {
  const dispatchListsInit = getDispatches(state);
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];
  const timezone = getLoginUserClientConfigTimezone(state) || [];
  const usersInit = getUsers(state) || [];

  const users = (usersInit || []).reduce((accum, item) => {
    const { id, realName } = item || {};
    return {
      ...accum,
      [id]: realName
    };
  }, {});
  const dispatchLists = (dispatchListsInit || []).map(item => {
    const { contractCompleteWorker } = item || {};
    return {
      ...item,
      contractCompleteWorkerName: users[contractCompleteWorker]
    };
  });
  return ({
    timezone,
    planHierarchy,
    dispatchLists,
    users
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertDispatch
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(DispatchLists);
