import { useTranslation } from 'react-i18next';
import { enUS, zhCN } from '@material-ui/core/locale';
const LANGS = [
  {
    value: 'cn',
    label: '中文',
    systemValue: zhCN
  },
  {
    value: 'en',
    label: 'English',
    systemValue: enUS
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  // eslint-disable-next-line no-undef
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find(_lang => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = newlang => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
    currentValue: (currentLang || {}).value
  };
}
