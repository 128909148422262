/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AttendeesSearch from './AttendeesSearch';
import AttendeesLists from './AttendeesLists';
import Button from '../../components/CustomButtons/Button';
import AttendeeAddEdit from './AttendeeAddEdit';

const styles = () => ({
  searchArea: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  accountArea: {
    marginTop: '25px',
    marginLeft: '20px'
  },
  listArea: {
    marginTop: '25px'
  },
  root: {
    width: 150,
    marginRight: 20,
    marginTop: 20,
    backgroundColor: '#f3f3f4'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 17
  },
  headerArea: {
    marginLeft: '20px'
  }
});

const useStyles = makeStyles(styles);

export default function Dispatches(props) {
  const {
    fetchSetups,
    attendees,
    history,
    fetchUsers
  } = props;

  useEffect(() => {
    fetchSetups(
      {
        history
      });
    fetchUsers(
      {
        history
      });
  }, []);

  const classes = useStyles();
  const [modeOpen, setModeOpen] = useState(null);

  return (

    <Fragment>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              onClick={() => setModeOpen('attendeeAddEdit')}
              color="facebook"
            >
              考勤
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <AttendeesSearch/>
        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          {attendees && attendees.length > 0 &&
            <AttendeesLists/>
          }
        </Grid>
      </Grid>

      {modeOpen === 'attendeeAddEdit' &&
      <AttendeeAddEdit
        onClose={() => {
          setModeOpen(null);
          // setDispatchId(null);
          // clearPlans();
        }}
      />
      }

    </Fragment>
  );
}

Dispatches.propTypes = {
  history: PropTypes.any
};
