import gql from 'graphql-tag';

export default gql`
    query getAssignPlanItems(
        $contractId: String!
        $assignPlanId: String
        $assignPlanItemId: String
        $correlationId: String!
        $token: String!
        $returnType: String
    ){
        getAssignPlanItems(
            contractId: $contractId
            assignPlanId: $assignPlanId
            assignPlanItemId: $assignPlanItemId
            correlationId: $correlationId
            token: $token
            returnType: $returnType
        ){
            error
            message
            assignPlanItems {
                planItemDispatchNumber
                key
                level1
                level2
                level3
                title
                clientId
                assignPlanId
                assignPlanItemId
                isMain
            }
        }
    }`;
