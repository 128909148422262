import gql from 'graphql-tag';

export default gql`
    query getAssignPlans(
        $contractId: String!
        $assignPlanId: String
        $correlationId: String!
        $token: String!
    ){
        getAssignPlans(
            contractId: $contractId
            assignPlanId: $assignPlanId
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            assignPlans {
                contractId
                assignPlanId
                clientId
                endDate
                startDate
                note
                assignPlanName
                createTime
            }
        }
    }`;
