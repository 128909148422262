import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
// import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

const LoginPage = props => {
  const { login, history } = props;
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');

  const onSubmit = () => {
    login({ userName, password, history });
  };

  React.useEffect(() => {
    const id = setTimeout(() => {
      setCardAnimation('');
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>易思创科</h4>
                <h4 className={classes.cardTitle}>机械管理系统</h4>
                <div className={classes.socialLine}>
                  {/*{[*/}
                  {/*  'fab fa-facebook-square',*/}
                  {/*  'fab fa-twitter',*/}
                  {/*  'fab fa-google-plus'*/}
                  {/*].map((prop, key) => (*/}
                  {/*  <Button*/}
                  {/*    color="transparent"*/}
                  {/*    justIcon*/}
                  {/*    key={key}*/}
                  {/*    className={classes.customButtonClass}*/}
                  {/*  >*/}
                  {/*    <i className={prop} />*/}
                  {/*  </Button>*/}
                  {/*))}*/}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="用户名.."
                  id="userName"
                  value={userName}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: evt => setUserName(evt.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="密码"
                  id="password"
                  value={password}
                  formControlProps={{
                    fullWidth: true
                  }}
                  onChange={evt => setPassword(evt.target.value)}
                  inputProps={{
                    onChange: evt => setPassword(evt.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off'
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => onSubmit()}
                >
                  登录
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired
};

export default LoginPage;
