/* eslint-disable max-lines */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

export default function ContractCharts(props) {
  const {
    resultData,
    level3
  } = props;

  useEffect(() => {

  }, []);

  return (

    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          {level3 ? '工序无法展示，请选择其他选项' :
            <ResponsiveContainer width={'99%'} height={300}>
              <BarChart
                data={resultData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="完成数量" fill="#8884d8" />
                <Bar dataKey="计划完成数量" fill="#82ca9d" />
                <Bar dataKey="已分配数量" fill="#ffc658" />
                <Bar dataKey="已交付数量" fill="#51b1c5" />
              </BarChart>

            </ResponsiveContainer>

          }

        </Grid>
      </Grid>

    </Fragment>

  );
}

ContractCharts.propTypes = {
  history: PropTypes.any
};
