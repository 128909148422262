import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSetups, fetchContracts, fetchCapitals } from '../../actions';
import {
  getContracts,
  getLoginUserPermissions,
  getSetups,
  getCapitals,
  getAllAccountAmount,
  getSelectedAccountAmount, getLoginUserClientConfigTimezone
} from '../../selectors';
import Capitals from './Capitals';
import moment from 'moment-timezone';
import { getFormValues } from 'redux-form/immutable';
import { SEARCH_CAPITALS } from '../../lib/formNames';
import { Map } from 'immutable';

const stateToProps = state => {
  const permissions = getLoginUserPermissions(state);
  const contracts = (getContracts(state) || [])
    .map(item => ({ ...item, id: item.contractId, value: item.contractId, label: item.name }));
  const setups = (getSetups(state) || [])
    .map(item => ({ ...item, id: item.setupId, value: item.setupId, label: item.name }));
  const capitalSubjects = setups.filter(item => item.type === 'capitalSubject');
  const capitalAccounts = setups.filter(item => item.type === 'capitalAccount');
  const capitalUnits = setups.filter(item => item.type === 'capitalUnit');
  const capitals = getCapitals(state);
  const timezone = getLoginUserClientConfigTimezone(state);

  const statusMapping = {
    bank: '银行转账',
    noReceipt: '无发票',
    issued: '已开发票',
    unissued: '未开发票',
    accepted: '已承兑',
    unaccepted: '未承兑',
    normal: '正常'
  };

  const formValues = (getFormValues(SEARCH_CAPITALS)(state) || Map()).toJS();

  const { startDate: start, endDate: end } = formValues || {};

  const startDate = moment.unix(start).tz(timezone)
    .format('MM/DD/YYYY');
  const endDate = moment.unix(end).tz(timezone)
    .format('MM/DD/YYYY');
  const capitalLists = (capitals || []).map(capital => {
    const { account, contract, subject, unit, date, expired, status } = capital || {};
    const accountDisplay = (capitalAccounts.find(item => item.id === account) || {}).name;
    const contractDisplay = (contracts.find(item => item.id === contract) || {}).name;
    const subjectDisplay = (capitalSubjects.find(item => item.id === subject) || {}).name;
    const unitDisplay = (capitalUnits.find(item => item.id === unit) || {}).name;
    const statusDisplay = statusMapping[status];
    const dateDisplay = moment.unix(date).tz(timezone)
      .format('MM/DD/YYYY');
    return { ...capital, accountDisplay, contractDisplay,
      subjectDisplay, unitDisplay, dateDisplay, expired, statusDisplay };
  });

  const allAccountAmount = getAllAccountAmount(state);
  const selectedAccountAmount = getSelectedAccountAmount(state);

  const addAmountToAccount = capitalAccounts.map(account => {
    const { setupId } = account || {};
    const all = (allAccountAmount.find(item => item.id === setupId) || {}).value;
    const selected = (selectedAccountAmount.find(item => item.id === setupId) || {}).value;
    return { ...account, all: all ? all : 0, selected: selected ? selected : 0 };
  });

  return ({
    startDate,
    endDate,
    capitalLists,
    capitals,
    contracts: contracts.filter(item => item.contractStatus !== 'archived'),
    permissions,
    capitalSubjects,
    capitalAccounts: addAmountToAccount,
    capitalUnits
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSetups,
  fetchContracts,
  fetchCapitals
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Capitals);
