import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import useLocales from '../../lib/i18n/useLocales';
import EquipmentsIcon from '@material-ui/icons/DevicesOther';
import EquipmentsList from './EquipmentsList';
import EquipmentAddEdit from './EquipmentAddEdit';
import History from './History';

export default function Equipments(props) {
  const useStyles = makeStyles(() => ({
    ...styles,
    root: {
      flexGrow: 1,
      backgroundColor: '#f3f3f4'
    }
  }));
  const classes = useStyles();

  const { translate } = useLocales();

  const { fetchEquipments, history } = props;
  const [modeOpen, setModeOpen] = useState(null);
  const [editEquipmentId, setEditEquipmentId] = useState(null);

  useEffect(() => {
    fetchEquipments(
      {
        history,
        fetchEquipmentsMessage: { success: translate('fetchEquipments.success'),
          error: translate('fetchEquipments.error') }
      });
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              setEditEquipmentId(null);
              setModeOpen('equipment');
            }}
            color="facebook">
            {translate('equipmentAdd')}
          </Button>
        </div>

      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <EquipmentsIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('equipments')}</h4>
          </CardHeader>
          <CardBody>
            <div className={classes.root}>
            </div>
            <EquipmentsList
              onEdit={ value => {
                setModeOpen('equipment');
                setEditEquipmentId(value);
              }}
              onViewHistory={ value => {
                setModeOpen('history');
                setEditEquipmentId(value);
              }}
              history={history}
            />
            {modeOpen === 'equipment' &&
              <EquipmentAddEdit
                equipmentId={editEquipmentId}
                onClose={() => setModeOpen(null)}
              />
            }

            {modeOpen === 'history' &&
            <History
              equipmentId={editEquipmentId}
              onClose={() => setModeOpen(null)}
            />
            }

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Equipments.propTypes = {
  history: PropTypes.any
};
