import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchDispatchesByContract } from '../actions';
import { getDispatches as getDispatchesApi } from '../api/graphql';

export function* fetchDispatchesByContractSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, contractId } = payload || {};

    const request = {
      contract: contractId
    };

    const { dispatches, message, error } = yield getDispatchesApi({ token, request });

    if (error && message === 'auth') {
      history.push('/auth');
    }
    const dispatchesWithCompleteAndAssign = dispatches.reduce((accum, item) => {
      const { dispatchWithCompleteAndAssign = [], planNumber = 0 } = item || {};
      const finalDispatchWithCompleteAndAssign = (dispatchWithCompleteAndAssign || []).map(eachRow => ({
        ...eachRow,
        planNumber
      }));
      return accum.concat(finalDispatchWithCompleteAndAssign);
    }, []);

    yield put(fetchDispatchesByContract.success(dispatchesWithCompleteAndAssign));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchDispatchesByContract.type.PENDING, fetchDispatchesByContractSaga);
}
