import gql from 'graphql-tag';

export default gql`
    query getContractReceiptReturn(
        $contractId: String!
        $token: String!
        $correlationId: String!
        $createTime: Int
        $type: String
    ){
        getContractReceiptReturn(
            contractId: $contractId
            token: $token
            correlationId: $correlationId
            createTime: $createTime
            type: $type
        ){
            error
            message
            receiptReturn {
                contractId
                amount
                date
                type
                createTime
                note
                capitalId
            }
        }
    }`;
