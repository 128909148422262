import gql from 'graphql-tag';

export default gql`
    query getUserInfo(
        $token: String!
    ){
        getUserInfo(
            token: $token
        ){
            id
            email
            name
            password
            idNumber
            phoneNumber
            userRole
            realName
            permissions {
                id
                value
            }
            clientId
        }
    }`;
