import { takeLatest, put, race, take } from 'redux-saga/effects';
import {
  upsertUser,
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  fetchEquipments,
  upsertEquipmentType
} from '../actions';
import { upsertEquipmentType as upsertEquipmentTypeApi } from '../api/graphql';

export function* upsertEquipmentTypeSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertEquipmentTypeMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertUser.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { name, equipmentId } = formValues;

    const request = {
      name,
      equipmentId
    };

    const res =
      yield upsertEquipmentTypeApi({ token, request, isDelete });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertEquipmentTypeMessage.success,
        variant: 'success'
      }));
      yield put(upsertEquipmentType.success());
      yield put(fetchEquipments({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertEquipmentTypeMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertEquipmentType.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertEquipmentType.type.PENDING, upsertEquipmentTypeSaga);
}
