import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchSetups,
  fetchWarehouses,
  clearWarehousesExcel,
  clearContractsForWarehouse
} from '../../actions';
import Warehouses from './Warehouses';
import { getWarehouses } from '../../selectors';

const stateToProps = state => {
  const warehouses = getWarehouses(state);
  return {
    warehouses
  };
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSetups,
  fetchWarehouses,
  clearWarehousesExcel,
  clearContractsForWarehouse
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Warehouses);
