import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/named
import {
  upsertAssignPlan,
  fetchPlans,
  fetchDispatchesByContract
} from '../../../../actions';
import AssignPlansAddEdit from './AssignPlansAddEdit';
import { reduxForm, getFormValues, change } from 'redux-form/immutable';
import { Map } from 'immutable';
import { ASSIGN_PLAN_ADD_EDIT as form } from '../../../../lib/formNames';
import moment from 'moment-timezone';
import {
  getDispatchWorkByContract,
  getEditPlans,
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone
} from '../../../../selectors';
import { getFinalContractTreeData } from '../../../../lib/getFinalContractTreeData';

const stateToProps = (state, ownProps) => {
  const assignPlanId = ownProps.assignPlanId;
  const assignPlans = ownProps.assignPlans;
  const contractId = ownProps.contractId;

  const selectedAssignPlan = (assignPlans || []).find(item => item.assignPlanId === assignPlanId) || {};

  const {
    startDate: sDate,
    endDate: eDate,
    note,
    assignPlanName
  } = selectedAssignPlan || {};

  const timezone = getLoginUserClientConfigTimezone(state);
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const plans = getEditPlans(state) || [];

  const startDate = moment().tz(timezone)
    .startOf('day')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('day')
    .unix();
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];
  const initialValues = {
    contractId,
    assignPlanId,
    note,
    assignPlanName,
    startDate: sDate ? sDate : startDate,
    endDate: eDate ? eDate : endDate
  };
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;

  const dispatchWorksByContract = getDispatchWorkByContract(state);
  const {
    contractPlanItemsPlainData,
    contractPlanItemsTreeData
  } = getFinalContractTreeData({ dispatchWorksByContract, contractId, plans });

  return ({
    timezone,
    formValues,
    initialValues,
    plans,
    contractId,
    planHierarchy,
    formStartDate,
    formEndDate,
    contractPlanItemsPlainData,
    contractPlanItemsTreeData
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertAssignPlan,
  fetchPlans,
  fetchDispatchesByContract,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(AssignPlansAddEdit));
