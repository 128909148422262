import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux-immutablejs';
import { Map } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import { reducer as formReducer } from 'redux-form/immutable';
import Sagas from '../sagas';
import reducer from '../reducers';

// getInitialState()
const initialState = {};

const reducers = combineReducers(
  Map({
    ...reducer,
    form: formReducer
  })
);

const sagaMiddleWare = createSagaMiddleware();
const middleware = [sagaMiddleWare];

const store = createStore(
  reducers,
  Map(initialState),
  composeWithDevTools(
    // maybe need routerMiddleware in future
    applyMiddleware(...middleware)
  )
);

sagaMiddleWare.run(Sagas);

export default store;
