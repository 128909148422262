import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchCheckIn } from '../actions';
import { getCheckin as getCheckinApi } from '../api/graphql';

export function* fetchCheckInSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, contractId } = payload || {};
    const { checkin, message, error } = yield getCheckinApi({ token, contractId });
    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchCheckIn.success(checkin));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchCheckIn.type.PENDING, fetchCheckInSaga);
}
