import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCommodities, upsertUser, fetchUsers } from '../../actions';
import Users from './Users';
import { getUsers } from '../../selectors';

const stateToProps = state => ({
  users: getUsers(state)
});

const dispatchToProps = dispatch => bindActionCreators({
  fetchUsers,
  fetchCommodities,
  upsertUser
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Users);
