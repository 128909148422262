import auth from './auth';
import upload from './upload';
import app from './app';
import equipments from './equipments';
import contracts from './contracts';
import setups from './setups';
import capitals from './capitals';
import dispatches from './dispatches';
import attendees from './attendees';
import salaries from './salaries';
import warehouses from './warehouses';

export default {
  auth,
  upload,
  app,
  equipments,
  contracts,
  setups,
  capitals,
  dispatches,
  attendees,
  salaries,
  warehouses
};
