import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchLoginUserInfo } from '../../actions';
import Admin from './Admin';
import { getLoginUser } from '../../selectors';

const stateToProps = state => {
  const loginUser = getLoginUser(state);
  // eslint-disable-next-line no-undef
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  // const permissions = getLoginUserPermissions(state);
  const realName = loginUser && loginUser.realName;
  return ({
    realName,
    permissions
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchLoginUserInfo
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Admin);
