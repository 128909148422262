import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const Dropdown = ({
  dataTid,
  errorMessage,
  label,
  name,
  onBlur,
  onChange,
  options,
  required,
  value,
  disabled,
  showDefaultNullOption,
  showInfoLabel,
  helperText,
  margin = 'normal',
  variant
}) => (
  <div data-bdd={dataTid}>
    <TextField
      select
      fullWidth
      SelectProps={{
        native: true
      }}
      variant={variant}
      error={!!errorMessage}
      margin={margin}
      disabled={disabled}
      aria-describedby={`${name}_error`}
      aria-required={required}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      label={`${label}${required ? '*' : ''}`}
      helperText={errorMessage ? <span>{errorMessage}</span> : helperText}
      inputProps={{
        name,
        id: name,
        style: { userSelect: 'all' } // fix issue on Windows Chrome (options are blank)
      }}
      InputProps={showInfoLabel ? {
        endAdornment: <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <InfoIcon style={{ marginRight: 8, marginLeft: 8 }}/>{'Select One'}
        </div>
      } : {}}
    >
      {showDefaultNullOption && <option value=""></option> }
      {options.map((option, index) => (
        <option key={index} value={option.id || option.name || option.label}>
          {option.name || option.label}
        </option>
      ))}
    </TextField>
  </div>
);

Dropdown.propTypes = {
  dataTid: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  showDefaultNullOption: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string
};

Dropdown.defaultProps = {
  dataTid: undefined,
  errorMessage: undefined,
  required: false,
  status: 'default',
  value: ''
};

export default Dropdown;
