/* eslint-disable max-lines */
import React from 'react';
// @material-ui/core components
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import company1 from 'assets/img/company1.jpeg';
import company2 from 'assets/img/company2.jpeg';
import company3 from 'assets/img/company3.jpeg';
import company4 from 'assets/img/company4.jpeg';

const items = [
  {
    src: company1
  },
  {
    src: company2
  },
  {
    src: company3
  },
  {
    src: company4
  }
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  root: {
    maxWidth: '100%',
    height: '100%',
    flexGrow: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: '100%',
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%'
  }
});

// const useStyles = makeStyles(styles);

// function Item({ key, item }) {
//   const classes = useStyles();
//   return (
//     <Paper key={key}>
//       <img className={classes.img} src={item.src} alt="..." />
//     </Paper>
//   );
// }

class Dashboard extends React.Component {
  state = {
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = items.length;
    return (
      <div className={classes.root}>
        {/*<Paper square elevation={0} className={classes.header}>*/}
        {/*  <Typography variant={'h5'}>*/}
        {/*    <b>*/}
        {/*      {items[activeStep].label}*/}
        {/*    </b>*/}
        {/*  </Typography>*/}
        {/*</Paper>*/}
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          interval={6000}
        >
          {items.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img
                  className={classes.img}
                  src={step.src}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              下一张
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              上一张
            </Button>
          }
        />
      </div>
    );
  }
}

// export default function Dashboard() {
//
//
//
//
//   return (
//     <div>
//       <GridContainer>
//         <GridItem xs={12}>
//           <GridContainer justify="space-between">
//             <GridItem xs={12}>
//               <Carousel>
//                 {
//                   // eslint-disable-next-line id-length
//                   items.map((item, i) => <Item key={i} item={item} />)
//                 }
//               </Carousel>
//
//               {/*<Carousel*/}
//               {/*  IndicatorIcon={anArrayOfNumbers}*/}
//               {/*>*/}
//               {/*</Carousel>*/}
//
//             </GridItem>
//           </GridContainer>
//         </GridItem>
//       </GridContainer>
//     </div>
//   );
// }

Dashboard.propTypes = {
  uploadImage: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(
  Dashboard
);
