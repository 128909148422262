import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';

const TextFieldContainer = ({
  input,
  meta: { error, invalid, touched },
  ...rest
}) => {
  let status = 'success';
  if (!touched) {
    status = 'default';
  } else if (invalid) {
    status = 'error';
  }

  return (
    <TextField
      errorMessage={touched && error ? error : undefined}
      status={status}
      {...input}
      {...rest}
    />
  );
};

TextFieldContainer.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default TextFieldContainer;
