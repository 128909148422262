import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSetups, upsertSetups } from '../../actions';
import { getSetups } from '../../selectors';
import Setups from './Setups';
import { SET_UP as form } from '../../lib/formNames';
import { reduxForm, getFormValues, change, arrayPush } from 'redux-form/immutable';
import { fromJS, Map } from 'immutable';

const stateToProps = state => {
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const setups = getSetups(state);
  const initialValues = setups.reduce((accum, item) => {
    const { type, name, setupId } = item || {};
    if (accum[type]) {
      accum[type].push({ name, setupId });
    } else {
      // eslint-disable-next-line no-param-reassign
      accum[type] = [{ name, setupId }];
    }
    return accum;
  }, {});

  return ({
    formValues,
    initialValues,
    equipments: []
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSetups,
  upsertSetups,
  onSetupAdd: id => arrayPush(form, id, fromJS({ name: '' })),
  onSetupRemove: (id, idx) => change(form, `${id}.${idx}.deleted`, true),
  onSetupRollback: (id, idx) => change(form, `${id}.${idx}.deleted`, false)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(Setups));
