import gql from 'graphql-tag';

export default gql`
    query getSetups(
        $type: String
        $correlationId: String!
        $token: String!
    ){
        getSetups(
            type: $type
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            setups {
               setupId
               name 
               type 
            }
        }
    }`;
