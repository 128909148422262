import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertCapital } from '../../../actions/index';
import CapitalLists from './CapitalLists';
import { getLoginUserClientConfigTimezone } from '../../../selectors';
import moment from 'moment-timezone';

const stateToProps = (state, ownProps) => {
  const timezone = getLoginUserClientConfigTimezone(state);

  const capitalSubjects = ownProps.capitalSubjects || [];
  const capitalAccounts = ownProps.capitalAccounts || [];
  const capitalUnits = ownProps.capitalUnits || [];
  const contracts = ownProps.contracts || [];
  const capitals = ownProps.capitals || [];

  const capitalLists = (capitals || []).map(capital => {
    const { account, contract, subject, unit, date, expired } = capital || {};
    const accountDisplay = (capitalAccounts.find(item => item.id === account) || {}).name;
    const contractDisplay = (contracts.find(item => item.id === contract) || {}).name;
    const subjectDisplay = (capitalSubjects.find(item => item.id === subject) || {}).name;
    const unitDisplay = (capitalUnits.find(item => item.id === unit) || {}).name;
    const dateDisplay = moment.unix(date).tz(timezone)
      .format('MM/DD/YYYY');
    return { ...capital, accountDisplay, contractDisplay, subjectDisplay, unitDisplay, dateDisplay, expired };
  });
  return ({
    capitalLists,
    timezone
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertCapital
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(CapitalLists);
