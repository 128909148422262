import { takeLatest, put } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  checkIn,
  fetchCheckIn
} from '../actions';
import { checkin as checkApi } from '../api/graphql';

export function* checkinSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      checkinNumber,
      key,
      contractId,
      history,
      checkinMessage
    } = payload || {};

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');

    const res =
      yield checkApi({ token, checkinNumber, key, contractId });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: checkinMessage.success,
        variant: 'success'
      }));
      yield put(checkIn.success());
      yield put(fetchCheckIn({ history, contractId }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || checkinMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    checkIn.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(checkIn.type.PENDING, checkinSaga);
}
