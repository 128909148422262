/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../components/redux-form/TextField';
import Dropdown from '../../../components/redux-form/Dropdown';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from '../../../components/redux-form/DatePicker';
import AutoSuggest from '../../../components/redux-form/AutoSuggest';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import { CAPITAL_STATUS } from '../../../conf/index';

const CapitalAddEdit = props => {
  const {
    modeType,
    history,
    formValues,
    onClose,
    pristine,
    invalid,
    capitalSubjects,
    capitalAccounts,
    contracts,
    upsertCapital
    // expired
  } = props || {};

  const styles = () => ({
    modalAction: {
      marginRight: '12px'
    },
    marginTop: {
      marginTop: 10
    }
  });

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    // fetchEquipments(
    //   {
    //     history,
    //     fetchEquipmentsMessage:
    //       { success: translate('fetchEquipments.success'), error: translate('fetchEquipments.error') }
    //   });
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertCapital({
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertCapitalMessage:
        { success: translate('upsertCapital.success'), error: translate('upsertCapital.error') }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={pristine || invalid}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  const capitalStatues = CAPITAL_STATUS.map(item => ({
    id: item.id,
    name: translate(`capitalStatus.${item.id}`)
  }));

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="capitalAddEdit-dialog"
          title= {translate(modeType)}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  // disabled={expired}
                  required
                  fullWidth
                  component={DatePicker}
                  name="date"
                  label={translate('capitalTable&Form.date')}
                  validate={[required]}
                />
              </GridItem>
              <GridItem xs={12} className={classes.marginTop}>
                <Field
                  // disabled={expired}
                  required
                  fullWidth
                  component={AutoSuggest}
                  name="subject"
                  itemsToUse={capitalSubjects}
                  label={translate('capitalTable&Form.subject')}
                  validate={[required]}
                />
              </GridItem>
              <GridItem xs={12} className={classes.marginTop}>
                <Field
                  // disabled={expired}
                  fullWidth
                  component={TextField}
                  name="unit"
                  label={translate('capitalTable&Form.unit')}
                  validate={[required]}
                  required
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  // disabled={expired}
                  required
                  fullWidth
                  isCurrency
                  component={TextField}
                  name="amount"
                  normalize={normalizeToFloat}
                  label={translate('capitalTable&Form.amount')}
                  validate={[required]}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  // disabled={expired}
                  required
                  fullWidth
                  component={AutoSuggest}
                  name="account"
                  itemsToUse={capitalAccounts}
                  label={translate('capitalTable&Form.account')}
                  validate={[required]}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  fullWidth
                  showDefaultNullOption
                  name="status"
                  options={capitalStatues}
                  component={Dropdown}
                  label={translate('capitalTable&Form.status')}
                  validate={[required]}
                  required
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  // disabled={expired}
                  fullWidth
                  component={AutoSuggest}
                  name="contract"
                  itemsToUse={contracts}
                  label={translate('capitalTable&Form.contract')}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  name="note"
                  label={translate('capitalTable&Form.note')}
                />
              </GridItem>
            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

CapitalAddEdit.propTypes = {
  history: PropTypes.any
};

export default CapitalAddEdit;
