import { takeLatest, put } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  upsertContract,
  fetchContracts,
  upsertPlan
} from '../actions';
import { upsertPlan as upsertPlanApi } from '../api/graphql';

export function* upsertPlanSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertPlanMessage,
      contractId
    } = payload || {};

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const planData = (formValues || {}).planData;
    const request = planData.map(item => {
      // eslint-disable-next-line no-unused-vars
      const { __typename, assignPlanNumber, ...rest } = item || {};
      return rest;
    });

    const res =
      yield upsertPlanApi({ token, request, contractId });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertPlanMessage.success,
        variant: 'success'
      }));
      yield put(upsertContract.success());
      yield put(fetchContracts({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertPlanMessage.error,
        variant: 'error'
      }));
    }
    history.push('/admin/contracts');
  } catch (err) {
    upsertPlan.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertPlan.type.PENDING, upsertPlanSaga);
}
