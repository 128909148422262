import { takeLatest } from 'redux-saga/effects';
import { login } from '../actions';
// import { login as loginApi } from '../api/rest/auth';
import { login as loginApi } from '../api/graphql';

export function* loginSaga({ payload }) {
  try {
    const { userName, password, history } = payload || {};
    const { token, user } = yield loginApi({ userName, password });
    const { clientId } = user || {};
    if (token) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('token', token);
      // eslint-disable-next-line no-undef
      localStorage.setItem('clientId', clientId);
      history.push('/admin/dashboard');
    } else {
      // eslint-disable-next-line no-undef
      alert('Login fail ! ! !');
    }
  } catch (err) {
    login.error(err);
  }
}

export default function*() {
  yield takeLatest(login.type.PENDING, loginSaga);
}
