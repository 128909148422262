/* eslint-disable max-lines */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import ContractLists from './ContractLists';

export default function Contracts(props) {
  const {
    history,
    fetchContracts
  } = props;

  useEffect(() => {
    fetchContracts(
      {
        history
      });
  }, []);

  return (
    <div>
      <ContractLists history={history} props={props}/>
    </div>
  );
}

Contracts.propTypes = {
  history: PropTypes.any
};
