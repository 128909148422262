import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CapitalsSearch from './CapitalsSearch';
import { change, getFormValues, reduxForm } from 'redux-form/immutable';
import { SEARCH_CAPITALS as form } from '../../../lib/formNames';
import { fetchContracts, fetchCapitals } from '../../../actions';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Map } from 'immutable';
import { getLoginUserClientConfigTimezone } from '../../../selectors';

const stateToProps = state => {
  const timezone = getLoginUserClientConfigTimezone(state);
  const startDate = moment().tz(timezone)
    .startOf('month')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('month')
    .unix();

  const initialValues = {
    startDate,
    endDate
  };
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const formEndDate = formValues.endDate;
  const formStartDate = formValues.startDate;

  return {
    formValues,
    formEndDate,
    formStartDate,
    initialValues
  };
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  fetchCapitals,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(CapitalsSearch));
