import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchWarehouses } from '../actions';
import { getWarehouses as getWarehousesApi } from '../api/graphql';

export function* fetchWarehousesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history } = payload || {};

    const { warehouses, message, error } =
      yield getWarehousesApi({ token });

    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchWarehouses.success(warehouses));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchWarehouses.type.PENDING, fetchWarehousesSaga);
}
