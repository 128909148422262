import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CapitalsSearch from './AttendeesSearch';
import { getFormValues, reduxForm, change } from 'redux-form/immutable';
import { ATTENDEE_SEARCH as form } from '../../../lib/formNames';
import {
  fetchSearchPlans,
  fetchAssignPlans,
  fetchDispatchWorkDisplay,
  fetchAttendees
} from '../../../actions';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Map } from 'immutable';
import {
  getAllEmployees,
  getLoginUserClientConfigTimezone
} from '../../../selectors';

const stateToProps = state => {
  const workers = getAllEmployees(state);
  const timezone = getLoginUserClientConfigTimezone(state);
  const startDate = moment().tz(timezone)
    .startOf('day')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('day')
    .unix();
  const initialValues = {
    startDate,
    endDate
  };
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;

  return {
    formStartDate,
    formEndDate,
    formValues,
    initialValues,
    timezone,
    workers
  };
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchAttendees,
  fetchSearchPlans,
  fetchAssignPlans,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate),
  fetchDispatchWorkDisplay
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(CapitalsSearch));
