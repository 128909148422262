import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, uploadImage } from '../../actions';
import Dashboard from './Dashboard';
// import { getResume, getResumeDrawerContent, getResumeUserInfo } from '../../../selectors';

// const stateToProps = state => ({
//   // resume: getResume(state),
//   // userInfo: getResumeUserInfo(state) || {},
//   // drawerContent: getResumeDrawerContent(state)
// });

const dispatchToProps = dispatch => bindActionCreators({
  login,
  uploadImage
}, dispatch);

export default connect(
  null,
  dispatchToProps
)(Dashboard);
