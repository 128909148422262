import gql from 'graphql-tag';

export default gql`
    mutation contractReceiptReturn(
        $token: String!
        $contractId: String!
        $correlationId: String!
        $type: String
        $amount: Float
        $isDelete: Boolean
        $date: String
        $createTime: Int
        $note: String
    ){
        contractReceiptReturn(
            token: $token
            contractId: $contractId
            correlationId: $correlationId
            type: $type
            amount: $amount
            isDelete: $isDelete
            date: $date
            createTime: $createTime
            note: $note
        ) {
            error
            message
        }
    }
`;
