import gql from 'graphql-tag';

export default gql`
    query getCapitals(
        $token: String!
        $request: CapitalInput
        $correlationId: String!
        $token: String!
    ){
        getCapitals(
            request: $request
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            allAccountAmount {
                id
                value
            }
            selectedAccountAmount {
                id
                value
            }
            capitals {
                capitalId
                year
                month
                day
                date
                type
                subject
                unit
                amount
                account
                status
                contract
                note
                createDate
                expired
            }
        }
    }`;
