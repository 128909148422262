import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';

import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import moment from 'moment-timezone';

const localeMap = {
  en: enLocale,
  cn: cnLocale
};

// eslint-disable-next-line no-undef
const localLang = localStorage.getItem('i18nextLng') || 'cn';

const DatePickerContainer = props => {
  const {
    dataTid,
    label,
    onChange,
    required,
    value,
    disabled,
    variant = 'standard',
    inputFormat = 'MM/dd/yyyy',
    views = ['day'],
    helperText,
    minDate,
    fullWidth
  } = props;

  const finalValue = (Number.isInteger(value) ? moment.unix(value) : value);
  const finalMin = (Number.isInteger(minDate) ? moment.unix(minDate)._d : minDate);

  return (
    <div data-bdd={dataTid}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[localLang]}>
        <DatePicker
          minDate={finalMin}
          views={views}
          inputFormat={inputFormat}
          disabled={disabled}
          label={`${label} ${required ? '*' : ''} `}
          value={finalValue}
          onChange={newValue => onChange(newValue)}
          renderInput={params => {
            const result = { ...params, error: false, variant, helperText };
            return <TextField fullWidth={fullWidth} {...result} />;
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

DatePickerContainer.propTypes = {
  dataTid: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  showDefaultNullOption: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string
};

DatePickerContainer.defaultProps = {
  dataTid: undefined,
  errorMessage: undefined,
  required: false,
  status: 'default',
  value: ''
};

export default DatePickerContainer;
