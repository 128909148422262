import React, { useEffect } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import moment from 'moment-timezone';
import HistoryIcon from '@material-ui/icons/History';

export default function Equipments(props) {
  const {
    equipmentTypeOptions,
    history,
    equipments,
    upsertEquipment,
    onEdit,
    fetchSetups,
    timezone,
    onViewHistory
  } = props;

  useEffect(() => {
    fetchSetups(
      {
        type: 'equipmentType',
        history,
        fetchSetupsMessage: { success: translate('fetchSetups.success'),
          error: translate('fetchSetups.error') }
      });
  }, []);

  const equipmentsDisplay = equipments.map(equipment => {
    const { type, purchaseDate: purchaseDateInit } = equipment || {};

    const purchaseDate = purchaseDateInit ? moment.unix(purchaseDateInit).tz(timezone)
      .format('MM/DD/yyyy') : null;

    const typeName = ((equipmentTypeOptions || [])
      .find(item => item.id === type) || {}).name;
    return { ...equipment, typeName, purchaseDate };
  });

  const renderColumns = () => [
    {
      name: 'equipmentId',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'name',
      label: translate('equipmentForm.name'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'typeName',
      label: translate('equipmentForm.type'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: translate('equipmentForm.status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => translate(value)
      }
    },

    {
      name: 'isDataControl',
      label: '是否数控',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value === 'yes' ? '是' : '否')
      }
    },
    {
      name: 'purchaseDate',
      label: '购买时间',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'equipmentId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <IconButton
                onClick={() => onEdit(value)}
                color={'primary'}>
                <EditIcon/>
              </IconButton>
            </GridItem>

            <GridItem>
              <Tooltip title={'查看维护保养记录'}>
                <IconButton
                  onClick={() => onViewHistory(value)}
                  color={'primary'}>
                  <HistoryIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

            <GridItem>
              <IconButton
                onClick={() => {
                  upsertEquipment({
                    isDelete: true,
                    formValues: { equipmentId: value },
                    history,
                    upsertEquipmentMessage: { success: translate('deleteEquipment.success'),
                      error: translate('deleteEquipment.error') },
                    generalAlertText: { title: translate('generalAlertText.title'),
                      message: translate('generalAlertText.message') }
                  });
                }}
                color={'info'}>
                <DeleteIcon/>
              </IconButton>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>
      </GridItem>
      <GridItem xs={12}>
        <MUIDataTable
          title={(
            <GridContainer>
            </GridContainer>
          )}
          data={equipmentsDisplay || []}
          columns={renderColumns()}
          options={{
            textLabels: MUI_DATA_TABLE_LABELS,
            responsive: 'stacked',
            elevation: 0,
            selectableRows: 'none',
            rowsPerPage: 50,
            rowsPerPageOptions: [25, 50, 75, 100],
            download: false,
            print: false
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

Equipments.propTypes = {
  history: PropTypes.any
};
