import { connect } from 'react-redux';
import AlertDialog from './AlertDialog';
import { getAlertDialogContent } from '../../../selectors';
import { popupAlertDialog } from '../../../actions';
import { bindActionCreators } from 'redux';

const stateToProps = state => ({
  content: getAlertDialogContent(state)
});

const dispatchToProps = dispatch => bindActionCreators({
  onConfirm: () => popupAlertDialog.success(),
  onCancel: () => popupAlertDialog.error()
}, dispatch);

export default connect(stateToProps, dispatchToProps)(AlertDialog);

