import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import AuthLayout from 'layouts/Auth.js';
import RtlLayout from 'layouts/RTL.js';
import AdminLayout from 'layouts/Admin/index';
import AlertDialog from 'components/ui/AlertDialog';
import Loading from 'components/ui/Loading';
import AppAlert from 'components/ui/AppAlert';

// eslint-disable-next-line import/no-unresolved
import 'assets/scss/material-dashboard-pro-react.scss?v=1.10.0';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

const App = ({ store }) => (
  <Provider store={store}>
    <AlertDialog/>
    <Loading/>
    <AppAlert/>
    <BrowserRouter>
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
