import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertPlan,
  fetchPlans,
  fetchContracts,
  fetchDispatchesByContract
} from '../../../actions';
import PlanAddEdit from './PlanAddEdit';
import {
  getUsers,
  getEditPlans,
  getContracts,
  getLoginUserClientConfigPlanHierarchy
} from '../../../selectors';
import { getFormValues, reduxForm, arrayPush, change } from 'redux-form/immutable';
import { PLAN_ADD_EDIT as form } from '../../../lib/formNames';
import { fromJS, Map } from 'immutable';
import NP from 'number-precision';
import moment from 'moment-timezone';

const stateToProps = (state, ownProps) => {
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const plans = getEditPlans(state);
  const contracts = getContracts(state);
  const contractId = ownProps.match.params.contractId;
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];
  const maxHierarchy = planHierarchy.length;

  const contractTotalPrice = plans.reduce((accum, item) => {
    const { planQuantity = 0, price = 0 } = item || {};
    return NP.plus(NP.times(planQuantity || 0, price || 0), accum);
  }, 0);
  const { name } = (contracts || []).find(item => item.contractId === contractId) || {};

  return ({
    contractTotalPrice,
    name,
    contractId,
    planId: '',
    users: getUsers(state),
    formValues,
    maxHierarchy,
    initialValues: {
      planData: plans
    }
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertPlan,
  fetchPlans,
  fetchContracts,
  fetchDispatchesByContract,
  onAddNode: (newNodeKey, planQuantity) => arrayPush(form, 'planData',
    fromJS({ key: `${newNodeKey}`, title: '---', planQuantity, createTime: moment().unix() })),
  onDeleteNode: planDataAfterDelete => change(form, 'planData', fromJS(planDataAfterDelete)),
  onChangeLevel1PlanQuantity: planData => change(form, 'planData', fromJS(planData))
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(PlanAddEdit));
