import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertAttendee } from '../../../actions/index';
import AttendeesLists from './AttendeesLists';
import {
  getAllEmployees,
  getAttendees,
  getLoginUserClientConfigTimezone
} from '../../../selectors';
import moment from 'moment-timezone';

const stateToProps = state => {
  const timezone = getLoginUserClientConfigTimezone(state) || [];
  const workers = getAllEmployees(state) || [];
  const attendees = getAttendees(state);

  const attendeesData = attendees.map(item => {
    const { worker, date, workType } = item || {};
    const workerName = ((workers || []).find(workerItem => workerItem.id === worker) || {}).name;
    const dateFormat = moment.unix(date).format('YYYY-MM-DD');
    const sk = `${date}:${workerName}:${workType}`;
    return {
      ...item,
      dateFormat,
      workerName,
      sk
    };
  });

  return ({
    timezone,
    attendeesLists: attendeesData.sort(
      (
        { sk: skA },
        { sk: skB }
      ) => (`${skA}` < `${skB}` ? 1 : -1))
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertAttendee
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(AttendeesLists);
