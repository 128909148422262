/* eslint-disable max-lines */
import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import { Accordion, AccordionActions, AccordionDetails, Tooltip } from '@material-ui/core';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import useLocales from '../../lib/i18n/useLocales';
import SettingsIcon from '@material-ui/icons/Settings';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { SET_UP } from '../../conf/index';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field } from 'redux-form/immutable';
import TextField from '../../components/redux-form/TextField';
import { required } from '../../lib/validation/validator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HistoryIcon from '@material-ui/icons/History';

export default function Setups(props) {
  const useStyles = makeStyles(theme => ({
    ...styles,
    root: {
      flexGrow: 1,
      backgroundColor: '#f3f3f4'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    fieldStyle: {
      width: 300
    },
    deleteButton: {
      cursor: 'pointer'
    },
    submitButton: {
      textAlign: 'center',
      marginTop: 15
    }
  }));
  const classes = useStyles();

  const { translate } = useLocales();

  const {
    fetchSetups,
    history,
    formValues,
    onSetupAdd,
    onSetupRemove,
    onSetupRollback,
    upsertSetups,
    pristine,
    invalid
  } = props;

  useEffect(() => {
    fetchSetups(
      {
        history,
        fetchSetupsMessage: { success: translate('fetchSetups.success'),
          error: translate('fetchSetups.error') }
      });
  }, []);

  const onSubmit = () => {
    upsertSetups({
      formValues,
      history,
      upsertSetupMessage: { success: translate('upsertSetup.success'), error: translate('upsertSetup.error') }
    });
  };

  const renderTableCell = ({ row, id, idx, deleted }) => {
    const { name, type } = row || {};
    if (type === 'textField') {
      return <TableCell align={'left'}>
        <Field
          disabled={deleted}
          className={classes.fieldStyle}
          fullWidth
          name={`${id}.${idx}.${name}`}
          component={TextField}
          label={''}
          validate={[required]}
        />
      </TableCell>;
    }
    return <TableCell align={'left'}>
      <Field
        disabled={deleted}
        className={classes.fieldStyle}
        fullWidth
        name={`${id}.${idx}.${name}`}
        component={TextField}
        label={''}
        validate={[required]}
      />
    </TableCell>;
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SettingsIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('setups')}</h4>
          </CardHeader>
          <CardBody>

          </CardBody>
        </Card>

        <div className={classes.root}>

          {SET_UP.map((setup, idx) => {
            const { id, filed } = setup || {};
            const entities = formValues[id] || [];
            return (
              <Accordion key={idx}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>{translate(`setupCategory.${id}`)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GridContainer>
                    {entities.length ?
                      <GridItem xs={12}>
                        <TableContainer>
                          <Table size={'small'} aria-label={'Set Up Table'}>
                            <TableHead>
                              <TableRow>
                                {filed.map(item => (
                                  <TableCell key={item.name}>
                                    {translate(`setupField.${item.name}`)}
                                  </TableCell>
                                ))}
                                <TableCell>{translate('setupField.action')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {entities.map((entity, idx2) => {
                                const { deleted } = entity || {};
                                return (
                                  <TableRow key={idx2}>
                                    <Fragment>
                                      {filed.map(item =>
                                        renderTableCell({ row: item, id, idx: idx2, deleted }))}
                                      <TableCell align={'left'}>
                                        {deleted ?
                                          <Tooltip title={translate('rollback')}>
                                            <HistoryIcon
                                              className={classes.deleteButton}
                                              onClick={() => onSetupRollback(id, idx2)}
                                            />
                                          </Tooltip>
                                          :
                                          <Tooltip title={translate('delete')}>
                                            <DeleteIcon
                                              className={classes.deleteButton}
                                              onClick={() => onSetupRemove(id, idx2)}
                                            />
                                          </Tooltip>
                                        }

                                      </TableCell>
                                    </Fragment>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </GridItem> :
                      <GridItem xs={12}>
                        <Typography variant={'subtitle1'}>
                          {translate('setupEmpty')} {translate(`setupCategory.${id}`)}
                        </Typography>
                      </GridItem>
                    }
                  </GridContainer>

                </AccordionDetails>
                <AccordionActions>
                  <Button
                    onClick={() => onSetupAdd(id)}
                    size="small"
                    color="facebook">
                    {translate('new')}
                  </Button>
                </AccordionActions>
              </Accordion>
            );
          })}

          <Divider/>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <div className={classes.submitButton}>
          <Button
            onClick={() => onSubmit()}
            disabled={pristine || invalid}
            size="small"
            color="facebook">
            {translate('submit')}
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
}

Setups.propTypes = {
  history: PropTypes.any
};
