import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

const styles = () => ({
  icon: {
    color: '#bdbdbd',
    fontSize: '100px'
  }
});

const useStyles = makeStyles(styles);

const EmptyBox = props => {
  const {
    title,
    message
  } = props || {};

  const classes = useStyles();
  return (
    <Fragment>
      <GridContainer spacing={16} jusify="center" alignItems="center" direction="column">
        <GridItem xs={12}>
          <MoveToInboxIcon
            className={classes.icon}
          />
        </GridItem>
      </GridContainer>
      <GridContainer spacing={16} jusify="center" alignItems="center" direction="column">
        <GridItem xs={12}>
          <Typography align="center" variant="h6" color="textSecondary">
            {title}
          </Typography>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={16} jusify="center" alignItems="center" direction="column">
        <GridItem xs={12}>
          <Typography align="center" variant="body2" color="textSecondary">
            {message}
          </Typography>
        </GridItem>
      </GridContainer>
    </Fragment>

  );
};

export default EmptyBox;
