
const renderDiagramData = renderData => {
  const list = renderData;
  const map = {};
  let node;
  const roots = [];
  let index;
  // eslint-disable-next-line no-restricted-syntax
  for (index = 0;index < list.length;index += 1) {
    map[list[index].key] = index; // initialize the map
    list[index].children = []; // initialize the children
  }

  // eslint-disable-next-line no-restricted-syntax
  for (index = 0;index < list.length;index += 1) {
    node = list[index];
    if (node.parentKey !== '') {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentKey]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getDispatchTreeData = ({ data, plainData = false, plans }) => {
  const reorganizeData = (data || []).reduce((accum, item) => {
    const { key, deleted, dispatchCompleteNumber, dispatchPlanNumber, dispatchAssignNumber } = item || {};

    const title = ((plans || []).find(plan => plan.key === key) || {}).title;
    const showQuantity = dispatchPlanNumber ?
      // eslint-disable-next-line max-len
      `(${dispatchCompleteNumber ? dispatchCompleteNumber : 0}/${dispatchPlanNumber ? dispatchPlanNumber : 0}/${dispatchAssignNumber ? dispatchAssignNumber : 0})` : '';
    const displayTitle = `${title} ${showQuantity}`;
    const layers = (key || '').split('-') || [];
    const level = layers.length;
    const lastLayer = level - 1;
    const parentKey = layers.filter((___, idx2) => idx2 < lastLayer).join('-');
    const isParentKeyExist = data.find(eachDate => eachDate.key === parentKey);
    const result = { ...item, level, parentKey: isParentKeyExist ? parentKey : '',
      id: key, name: title, title: displayTitle };
    if (!deleted) {
      return [...accum, result];
    }
    return accum;
  }, []);

  if (plainData) {
    return reorganizeData;
  }

  const displayData = renderDiagramData(reorganizeData);

  return displayData;
};
