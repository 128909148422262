/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DownloadingIcon from '@material-ui/icons/CloudDownloadOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import DescriptionIcon from '@material-ui/icons/Description';
import useLocales from '../../../lib/i18n/useLocales';
import PlansIcon from '@material-ui/icons/Assignment';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import { isTrue } from '../../../lib/booleanCheck';
import AssignPlanIcon from '@material-ui/icons/PostAdd';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { currency } from '../../../lib/getCurrencyAmount';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';

export default function Contracts(props) {
  const {
    cloneContract,
    upsertContract,
    history,
    contracts,
    permissions,
    editContractFilter,
    contractFilter,
    editContractTab,
    contractTab,
    fetchPlans,
    plans
  } = props;

  const CONTRACT_TYPE = {
    0: 'active',
    1: 'completed',
    2: 'archived'
  };

  const [editContractId, setEditContractId] = React.useState(null);
  const downLoadContract = (contracts || []).find(item => `${item.contractId}` === `${editContractId}`);
  const isAllowDownload = (plans || []).find(plan => `${plan.contractId}` === `${editContractId}`);
  const {
    companyName: downloadCompanyName,
    entrustCompanyName: downloadEntrustCompanyName,
    contractNo: downloadContractNo,
    name: downloadName,
    contractAmount: downloadContractAmount,
    receiptOverAmount: downloadReceiptOverAmount,
    returnOverAmount: downloadReturnOverAmount
  } = downLoadContract || {};
  const downloadDataInit = (plans || []).map(plan => {
    const { key, planQuantity, price } = plan || {};

    const keyArray = (key || '').split('-');

    const oneInit = keyArray[0];
    const twoInit = keyArray[1];
    const threeInit = keyArray[2];
    let type = '部件';
    if (keyArray.length === 2) {
      type = '零件';
    }
    if (keyArray.length === 3) {
      type = '工序';
    }

    const one = oneInit ? oneInit : '00';
    const two = twoInit ? twoInit : '00';
    const three = threeInit ? threeInit : '00';

    const finalOne = (one || '').split('').length > 1 ? one : `0${one}`;
    const finalTwo = (two || '').split('').length > 1 ? two : `0${two}`;
    const finalThree = (three || '').split('').length > 1 ? three : `0${three}`;

    // eslint-disable-next-line max-len
    const finalKey = `${finalOne ? finalOne : ''}${finalTwo ? finalTwo : ''}${finalThree ? finalThree : ''}${keyArray.length}`;

    return {
      ...plan,
      companyName: downloadCompanyName,
      entrustCompanyName: downloadEntrustCompanyName,
      contractNo: downloadContractNo,
      name: downloadName,
      contractAmount: downloadContractAmount,
      receiptOverAmount: downloadReceiptOverAmount,
      returnOverAmount: downloadReturnOverAmount,
      key: finalKey,
      type,
      total: (price || 0) * (planQuantity || 0)
    };
  });

  const downloadContractData = [downLoadContract];
  const downloadPlanData = downloadDataInit
    .filter(item => item.type === '部件')
    .sort((alpha, bate) => alpha.key - bate.key);
  const handleChange = (event, newValue) => {
    editContractTab(newValue);
  };

  let contractsFilter = (contracts || []).filter(contract => contract.contractStatus === CONTRACT_TYPE[contractTab]);
  if (contractTab === 0) {
    contractsFilter = (contracts || []).filter(contract => (contract.contractStatus === CONTRACT_TYPE[contractTab]
      || !contract.contractStatus));
  }

  const filterContractNumber = (contractFilter || {}).contractNumber;
  const filterName = (contractFilter || {}).name;
  const filterCompanyName = (contractFilter || {}).companyName;
  const filterEntrustCompanyName = (contractFilter || {}).entrustCompanyName;

  const summaryRow = (contractsFilter || []).reduce((accum, item) => {
    let check = true;
    if (filterContractNumber && (filterContractNumber !== item.contractNumber)) {
      check = false;
    }
    if (filterName && (filterName !== item.name)) {
      check = false;
    }
    if (filterCompanyName && (filterCompanyName !== item.companyName)) {
      check = false;
    }
    if (filterEntrustCompanyName && (filterEntrustCompanyName !== item.entrustCompanyName)) {
      check = false;
    }

    if (check) {
      const { contractAmount: cAmount, receiptOverAmount: recAmount, returnOverAmount: retAmount } = item || {};
      // eslint-disable-next-line no-param-reassign
      accum.contractAmount += cAmount || 0;
      // eslint-disable-next-line no-param-reassign
      accum.receiptOverAmount += recAmount || 0;
      // eslint-disable-next-line no-param-reassign
      accum.returnOverAmount += retAmount || 0;
    }

    return accum;
  }, {
    contractId: 'summary', contractNo: '总结', contractAmount: 0, receiptOverAmount: 0, returnOverAmount: 0
  });

  if (filterContractNumber) {
    summaryRow.contractNumber = filterContractNumber;
  }
  if (filterName) {
    summaryRow.name = filterName;
  }
  if (filterCompanyName) {
    summaryRow.companyName = filterCompanyName;
  }
  if (filterEntrustCompanyName) {
    summaryRow.entrustCompanyName = filterEntrustCompanyName;
  }

  const renderColumns = () => [
    {
      name: 'contractId',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'contractNo',
      label: translate('contractTable.contractNo'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (value === '总结' ?
          <div style={{ fontSize: 18 }}>
            <b>{value}</b>
          </div>
          : value)
      }
    },
    {
      name: 'contractNumber',
      label: translate('contractTable.contractNumber'),
      options: {
        filter: true,
        sort: true,
        ...(contractFilter || {}).contractNumber && { filterList: [(contractFilter || {}).contractNumber] }
      }
    },
    {
      name: 'name',
      label: translate('contractTable.name'),
      options: {
        filter: true,
        sort: true,
        ...(contractFilter || {}).name && { filterList: [(contractFilter || {}).name] }
      }
    },
    {
      name: 'companyName',
      label: translate('contractTable.company'),
      options: {
        filter: true,
        sort: true,
        ...(contractFilter || {}).companyName && { filterList: [(contractFilter || {}).companyName] }
      }
    },
    {
      name: 'entrustCompanyName',
      label: translate('contractTable.entrustCompany'),
      options: {
        filter: true,
        sort: true,
        ...(contractFilter || {}).entrustCompanyName && { filterList: [(contractFilter || {}).entrustCompanyName] }
      }
    },
    {
      name: 'workTime',
      label: translate('contractTable.workTime'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'contractAmount',
      label: '合同总额',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => currency(value)
      }
    },
    {
      name: 'receiptOverAmount',
      label: '发票余额',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => currency(value)
      }
    },
    {
      name: 'returnOverAmount',
      label: '回款余额',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => currency(value)
      }
    },
    {
      name: 'note',
      label: translate('contractTable.note'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'contractId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          value !== 'summary' &&
          <GridContainer wrap={'nowrap'}>
            {isTrue((permissions.find(permission => permission.id === 'manageContractView') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.overView')}>
                  <IconButton
                    onClick={() => {
                      history.push(`/admin/contracts/view/${value}`);
                    }}
                    color={'primary'}>
                    <ViewCompactIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
            }
            {isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.edit')}>
                  <IconButton
                    onClick={() => {
                      history.push(`/admin/contracts/edit/${value}`);
                    }}
                    color={'primary'}>
                    <EditIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
            }
            {isTrue((permissions.find(permission => permission.id === 'manageContractPlan') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.contractDetail')}>
                  <IconButton
                    onClick={() => {
                      history.push(`/admin/contracts/plans/${value}`);
                    }}
                    color={'primary'}>
                    <DescriptionIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
            }

            {isTrue((permissions.find(permission => permission.id === 'manageContractPlan') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.assignPlans')}>
                  <IconButton
                    onClick={() => {
                      history.push(`/admin/contracts/assignPlans/${value}`);
                    }}
                    color={'primary'}>
                    <AssignPlanIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
            }

            {isTrue((permissions.find(permission => permission.id === 'manageContractReceipt') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.receipt')}>
                  <IconButton
                    onClick={() => {
                      history.push(`/admin/contracts/receipt/${value}`);
                    }}
                    color={'primary'}>
                    <ReceiptIcon/>
                  </IconButton>
                </Tooltip>

              </GridItem>
            }
            {isTrue((permissions.find(permission => permission.id === 'manageContractReturn') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.return')}>
                  <IconButton
                    onClick={() => {
                      history.push(`/admin/contracts/return/${value}`);
                    }}
                    color={'primary'}>
                    <AttachMoneyIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
            }

            {isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&
            <GridItem>
              <Tooltip title={'复制合同'}>
                <IconButton
                  onClick={() => {
                    cloneContract({
                      contractId: value,
                      history,
                      cloneContractMessage: {
                        success: '复制合同成功！',
                        error: '复制合同失败'
                      },
                      generalAlertText: {
                        title: '复制合同',
                        message: '确定要复制该合同吗？'
                      }
                    });
                  }}
                >
                  <FileCopyIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            }

            {isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&
            <GridItem>
              <Tooltip title={'下载合同'}>
                {!(`${value}` === `${editContractId}`) ?
                  <IconButton
                    onClick={() => {
                      fetchPlans({ history, contractId: value });
                      setEditContractId(value);
                    }}
                  >
                    <DownloadingIcon/>
                  </IconButton> :
                  <ExcelFile
                    filename={`合同：${downloadName} 详情`}
                    element={<IconButton
                      disabled={!isAllowDownload}
                      onClick={() => {
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>}
                  >
                    <ExcelSheet data={downloadContractData} name="合同详情">
                      <ExcelColumn value="contractNo" label="合同企业编号"/>
                      <ExcelColumn value="contractNumber" label="合同号"/>
                      <ExcelColumn value="name" label="合同名称"/>
                      <ExcelColumn value="companyName" label="公司"/>
                      <ExcelColumn value="entrustCompanyName" label="委托公司"/>
                      <ExcelColumn value="contractAmount" label="合同总额"/>
                      <ExcelColumn value="receiptOverAmount" label="发票余额"/>
                      <ExcelColumn value="returnOverAmount" label="回款余额"/>
                    </ExcelSheet>
                    <ExcelSheet data={downloadPlanData} name="计划详情">
                      <ExcelColumn value="key" label="key"/>
                      <ExcelColumn value="type" label="类型"/>
                      <ExcelColumn value="title" label="名称"/>
                      <ExcelColumn value="unit" label="单位"/>
                      <ExcelColumn value="planQuantity" label="交付数量"/>
                      <ExcelColumn value="price" label="单价"/>
                      <ExcelColumn value="total" label="总价"/>
                      <ExcelColumn value="note" label="备注"/>
                    </ExcelSheet>
                  </ExcelFile>
                }
              </Tooltip>
            </GridItem>
            }

            {isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&
              <GridItem>
                <Tooltip title={translate('contractHint.delete')}>
                  <IconButton
                    onClick={() => {
                      upsertContract({
                        isDelete: true,
                        formValues: { contractId: value },
                        history,
                        upsertContractMessage: {
                          success: translate('deleteContract.success'),
                          error: translate('deleteContract.error')
                        },
                        generalAlertText: {
                          title: translate('generalAlertText.title'),
                          message: translate('generalAlertText.message')
                        }
                      });
                    }}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
            }

          </GridContainer>
        )
      }
    }
  ];

  useEffect(() => {
  }, [contracts]);

  const useStyles = makeStyles(() => ({
    ...styles,
    root: {
      flexGrow: 1,
      backgroundColor: '#f3f3f4'
    }
  }));

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <GridContainer>
      {isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&
      <GridItem xs={12}>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => history.push('/admin/contracts/add')}
            color="linkedin">
            {translate('add')}
          </Button>
        </div>
      </GridItem>
      }
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <PlansIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {translate('contracts')}
          </h4>
        </CardHeader>
        <CardBody>

          <div className={classes.root}>
            <Tabs
              value={contractTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="加工中" />
              <Tab label="已完成" />
              <Tab label="归档" />
            </Tabs>
          </div>

          <MUIDataTable
            title={(
              <GridContainer>
              </GridContainer>
            )}
            data={[summaryRow, ...(contractsFilter || [])] }
            columns={renderColumns()}
            options={{
              setTableProps: () => ({
                size: 'small'
              }),
              textLabels: MUI_DATA_TABLE_LABELS,
              responsive: 'stacked',
              elevation: 0,
              selectableRows: 'none',
              rowsPerPage: 50,
              rowsPerPageOptions: [25, 50, 75, 100],
              download: false,
              print: false,
              // eslint-disable-next-line id-length
              onFilterChange: (_, filter) => {
                // eslint-disable-next-line no-unused-vars
                const [[id], [contractNo], [contractNumber], [name], [companyName], [entrustCompanyName],
                  [workTime], [contractAmount], [receiptOverAmount], [returnOverAmount], [note]]
                    = filter;

                const entity = {
                  contractNo,
                  contractNumber,
                  name,
                  companyName,
                  entrustCompanyName,
                  id,
                  workTime,
                  contractAmount,
                  receiptOverAmount,
                  returnOverAmount,
                  note
                };
                // eslint-disable-next-line no-console
                console.log(entity);
                editContractFilter(entity);
              }
            }}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
}

Contracts.propTypes = {
  history: PropTypes.any
};
