import { fromJS } from 'immutable';
import {
  setDispatches,
  setDispatchWorks,
  setDispatchWorkByContract,
  setDispatchWorkDisplay,
  setDispatchesSearchData
} from '../selectors';
import {
  FETCH_DISPATCHES_ASYNC,
  FETCH_DISPATCH_WORKS_ASYNC,
  FETCH_DISPATCHES_BY_CONTRACT_ASYNC,
  FETCH_DISPATCH_WORK_DISPLAY_ASYNC,
  SAVE_DISPATCHES_SEARCH_DATA
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [FETCH_DISPATCHES_ASYNC.SUCCESS]: () => setDispatches(state, payload),
  [SAVE_DISPATCHES_SEARCH_DATA]: () => setDispatchesSearchData(state, payload),
  [FETCH_DISPATCH_WORKS_ASYNC.SUCCESS]: () => setDispatchWorks(state, payload),
  [FETCH_DISPATCHES_BY_CONTRACT_ASYNC.SUCCESS]: () => setDispatchWorkByContract(state, payload),
  [FETCH_DISPATCH_WORK_DISPLAY_ASYNC.SUCCESS]: () => setDispatchWorkDisplay(state, payload)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
