/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import TextField from '../../../components/redux-form/TextField';
import Dropdown from '../../../components/redux-form/Dropdown';
import ToggleSwitch from '../../../components/redux-form/ToggleSwitch';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';

const styles = () => ({
  label: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  button: {
    marginTop: '60px'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  submitButton: {
    textAlign: 'right'
  },
  title: {
    marginTop: '20px'
  },
  toggle: {
    marginTop: '20px'
  },
  bottomButton: {
    marginRight: '10px'
  },
  addContractGroup: {
    marginTop: '30px',
    textAlign: 'right'
  }
});
const useStyles = makeStyles(styles);

const ContractAddEdit = props => {
  const {
    history,
    fetchContracts,
    formValues,
    invalid,
    contractId,
    upsertContract,
    companyOptions,
    entrustCompanyOptions,
    pristine,
    onNewEntrustCompanyClick,
    onNewCompanyClick
  } = props || {};

  useEffect(() => {
    fetchContracts(
      {
        history
      });
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertContract({
      formValues,
      history,
      upsertContractMessage: { success: translate('upsertContract.success'), error: translate('upsertContract.error') }
    });
  };

  const classes = useStyles();

  const { translate } = useLocales();

  const { companyNew, entrustCompanyNew } = formValues || {};

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/admin/contracts')}
              color="info">
              <ArrowBackIosIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {contractId ? translate('contractEdit') : translate('contractAdd')}
            </h4>
          </CardHeader>

          <CardBody>
            <Form onSubmit={onSubmit}>
              <GridContainer>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="contractNo"
                    component={TextField}
                    label={translate('contractForm.contractNo')}
                    validate={[required]}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="contractNumber"
                    component={TextField}
                    label={translate('contractForm.contractNumber')}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="name"
                    component={TextField}
                    label={translate('contractForm.name')}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="workTime"
                    component={TextField}
                    label={translate('contractForm.workTime')}
                  />
                </GridItem>

                <GridItem xs={4}>
                  {companyNew ? <Field
                    fullWidth
                    name="company"
                    component={TextField}
                    label={translate('contractForm.company')}
                  /> :
                    <Field
                      component={Dropdown}
                      name={'company'}
                      dataTid="company-input"
                      options={companyOptions}
                      showDefaultNullOption
                      label={translate('contractForm.company')}
                    />
                  }
                </GridItem>

                <GridItem xs={2}>
                  <Field
                    onChange={() => onNewCompanyClick()}
                    fullWidth
                    name="companyNew"
                    component={ToggleSwitch}
                    label={translate('contractForm.companyNew')}
                  />
                </GridItem>

                <GridItem xs={4}>
                  {entrustCompanyNew ?
                    <Field
                      fullWidth
                      name="entrustCompany"
                      component={TextField}
                      label={translate('contractForm.entrustCompany')}
                    /> :
                    <Field
                      component={Dropdown}
                      name={'entrustCompany'}
                      dataTid="entrustCompany-input"
                      options={entrustCompanyOptions}
                      showDefaultNullOption
                      label={translate('contractForm.entrustCompany')}
                    />
                  }

                </GridItem>

                <GridItem xs={2}>
                  <Field
                    onChange={() => onNewEntrustCompanyClick()}
                    fullWidth
                    name="entrustCompanyNew"
                    component={ToggleSwitch}
                    label={translate('contractForm.entrustCompanyNew')}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    component={Dropdown}
                    name="contractStatus"
                    options={[
                      { id: 'active', name: '加工中' },
                      { id: 'completed', name: '已完成' },
                      { id: 'archived', name: '归档' }
                    ]}
                    label={'合同状态'}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="note"
                    component={TextField}
                    label={translate('contractForm.note')}
                  />
                </GridItem>

              </GridContainer>

              <GridItem xs={12}>
                <div
                  className={classes.addContractGroup}
                >
                  <Button
                    className={classes.bottomButton}
                    onClick={() => history.push('/admin/contracts')}
                  >
                    {translate('comeBack')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={pristine || invalid}
                    className={classes.bottomButton}
                    color="info"
                  >
                    {translate('submit')}
                  </Button>
                </div>

              </GridItem>

            </Form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

ContractAddEdit.propTypes = {
  history: PropTypes.any
};

export default ContractAddEdit;
