import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalariesSearch from './SalariesSearch';
import { getFormValues, reduxForm } from 'redux-form/immutable';
import {
  SALARIES_SEARCH as form,
  SALARIES_FIELD_CHANGE as salariesForm
} from '../../../lib/formNames';
import getSummarySalaries from '../getSummarySalaries';

import {
  fetchSalaries,
  clearSalaries
} from '../../../actions';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Map } from 'immutable';
import {
  getAllEmployees,
  getLoginUserClientConfigTimezone,
  getSalaries
} from '../../../selectors';

const stateToProps = state => {
  const salaries = getSalaries(state);
  const salariesFormValues = (getFormValues(salariesForm)(state) || Map()).toJS() || {};
  const summarySalaries = getSummarySalaries({ formValues: salariesFormValues, salaries });
  const workers = getAllEmployees(state);
  const timezone = getLoginUserClientConfigTimezone(state);
  const year = moment().tz(timezone)
    .format('YYYY');
  const month = moment().tz(timezone)
    .format('MM');

  const initialValues = {
    year,
    month
  };
  const formValues = (getFormValues(form)(state) || Map()).toJS();

  return {
    formValues,
    initialValues,
    timezone,
    workers,
    summarySalaries
  };
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSalaries,
  clearSalaries
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(SalariesSearch));
