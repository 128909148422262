/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../components/redux-form/TextField';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import AutoSuggest from '../../../components/redux-form/AutoSuggest';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import regularFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import WidgetHeader from '../../../components/ui/WidgetHeader';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Typography } from '@material-ui/core';
import DatePicker from '../../../components/redux-form/DatePicker';
import moment from 'moment-timezone';
import Dropdown from '../../../components/redux-form/Dropdown';
import { fromJS } from 'immutable';

const DispatchAddEdit = props => {
  const {
    timezone,
    modeType,
    history,
    formValues,
    onClose,
    pristine,
    invalid,
    contracts,
    fetchPlans,
    fetchAssignPlanItems,
    plans,
    level1,
    level2,
    startWithLevel2,
    startWithLevel3,
    planHierarchy,
    contractId,
    level2Options,
    level3Options,
    upsertDispatch,
    onLevel1Clear,
    onLevel2Clear,
    onLevel3Clear,
    onAssignPlanClear,
    onAssignPlanItemClear,
    onStartBiggerThanEnd,
    formStartDate,
    formEndDate,
    finalPlans,
    setTotalCurrentNumber,
    setTotalNumber,
    fetchAssignPlans,
    assignPlan,
    assignPlanItem,
    assignPlansOptions,
    assignPlanItemsOptions,
    planItemsTreeData,
    // planItemsPlainData,
    onChangeFiledValue,
    fetchDispatchesByContract,
    assignPlanSummary,
    // contractPlanItemsPlainData,
    contractPlanItemsTreeData,
    selectContractId,
    additionalNotes,
    onAddNote,
    formDispatchWorkType,
    formContractCompleteDate,
    workers
  } = props || {};

  const isAssign = formDispatchWorkType === 'assign';

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      }
    }
  );

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    // setTimeout(() => {
    if (selectContractId) {
      fetchDispatchesByContract({
        history,
        contractId: selectContractId
      });
    }
    // }, 100);
  }, []);

  const onContractChange = cId => {
    if (cId) {
      fetchDispatchesByContract({
        history,
        contractId: cId
      });
      fetchPlans(
        {
          history,
          contractId: cId
        });
      fetchAssignPlans(
        {
          history,
          contractId: cId
        });
      fetchAssignPlanItems(
        {
          history,
          contractId: cId
        });
    }
  };

  const onSubmit = evt => {
    evt.preventDefault();
    upsertDispatch({
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertDispatchMessage:
        { success: translate('upsertDispatch.success'), error: translate('upsertDispatch.error') }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={pristine || invalid}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  // const [usePlanItemExpandNotes, setUsePlanItemExpandNotes] = useState(false);
  // const [expandPlanItemNodes, setExpandPlanItemNodes] = useState([]);
  //
  // const onPlanItemExpand = expandedKeys => {
  //   setUsePlanItemExpandNotes(true);
  //   setExpandPlanItemNodes(expandedKeys);
  // };
  //
  // const [usePlanExpandNotes, setUsePlanExpandNotes] = useState(false);
  // const [expandPlanNodes, setExpandPlanNodes] = useState([]);
  //
  // const onPlanExpand = expandedKeys => {
  //   setUsePlanExpandNotes(true);
  //   setExpandPlanNodes(expandedKeys);
  // };
  //
  // const [useExpandNotes, setUseExpandNotes] = useState(false);
  // const [expandNodes, setExpandNodes] = useState([]);
  //
  // const onExpand = expandedKeys => {
  //   setUseExpandNotes(true);
  //   setExpandNodes(expandedKeys);
  // };

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'xl'}
          isOpen={true}
          onClose={onClose}
          id="capitalAddEdit-dialog"
          title={translate(modeType)}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={8} className={classes.leftBody}>
                <Paper elevation={3}>
                  <WidgetHeader title={translate('dispatchDetail')} />
                  <div className={classes.widget}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={12}>
                            <GridContainer>
                              <GridItem xs={6} className={classes.marginTop}>
                                <Field
                                  required
                                  fullWidth
                                  onChange={evt => {
                                    const changeStartDate = moment(evt).unix();
                                    const endDate = Number.isInteger(formEndDate)
                                      ? formEndDate : moment(formEndDate).unix();
                                    if (changeStartDate > endDate) {
                                      onStartBiggerThanEnd(evt);
                                    }
                                  }}
                                  label={translate('startDate')}
                                  component={DatePicker}
                                  name="startDate"
                                  validate={[required]}
                                />
                              </GridItem>

                              <GridItem xs={6} className={classes.marginTop}>
                                <Field
                                  required
                                  fullWidth
                                  minDate={formStartDate}
                                  label={translate('endDate')}
                                  component={DatePicker}
                                  name="endDate"
                                  validate={[required]}
                                />
                              </GridItem>

                              {assignPlanItem && <GridItem xs={12} className={classes.marginTop}>
                                <Field
                                  required
                                  fullWidth
                                  component={TextField}
                                  name="planNumber"
                                  normalize={normalizeToFloat}
                                  validate={[required]}
                                  label={translate('dispatchDetailForm&Table.planNumber')}
                                />
                              </GridItem>
                              }
                              <GridItem xs={12} className={classes.marginTop}>
                                <Field
                                  fullWidth
                                  name="dispatchWorkType"
                                  component={Dropdown}
                                  label={translate('dispatchWorkType')}
                                  options={
                                    [{ id: 'assign', name: '派工' },
                                      { id: 'contract', name: '包工' }]}
                                  validate={[required]}
                                />
                              </GridItem>

                              {formDispatchWorkType === 'contract' &&
                                <GridItem xs={12} className={classes.marginTop}>
                                  <Field
                                    required
                                    fullWidth
                                    component={TextField}
                                    name="contractAmount"
                                    normalize={normalizeToFloat}
                                    validate={[required]}
                                    label={'包工金额'}
                                  />
                                </GridItem>
                              }

                              {formDispatchWorkType === 'contract' &&
                                <GridItem xs={12}>
                                  <Field
                                    fullWidth
                                    minDate={moment().tz(timezone)
                                      .startOf('day')
                                      .unix()}
                                    label={'完成日期'}
                                    component={DatePicker}
                                    name="contractCompleteDate"
                                  />
                                </GridItem>
                              }

                              {formContractCompleteDate &&
                              <GridItem xs={12} className={classes.marginTop}>
                                <Field
                                  required={!isAssign}
                                  fullWidth
                                  name="contractCompleteWorker"
                                  component={Dropdown}
                                  showDefaultNullOption
                                  label={'完成员工'}
                                  options={workers}
                                  validate={isAssign ? [] : [required]}
                                />
                              </GridItem>
                              }

                              <GridItem xs={12} className={classes.marginTop}>
                                <Field
                                  fullWidth
                                  component={TextField}
                                  name="note"
                                  label={translate('dispatchDetailForm&Table.note')}
                                />
                              </GridItem>
                              <GridItem xs={12} className={classes.marginTop}>
                                <Button
                                  onClick={() => onAddNote(fromJS(additionalNotes))}
                                >
                                  新建备注
                                </Button>
                              </GridItem>

                              {(additionalNotes || []).map((note, idx) =>
                                <div key={idx}>
                                  <GridItem xs={12} className={classes.marginTop}>
                                    <Field
                                      fullWidth
                                      component={TextField}
                                      name={`additionalNotes.${idx}`}
                                      label={`附加备注 ${idx + 1}`}
                                    />
                                  </GridItem>
                                </div>
                              )}

                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={12} className={classes.marginTop}>
                            <Field
                              onChange={evt => {
                                if (!evt) {
                                  onAssignPlanClear();
                                  onAssignPlanItemClear();
                                  onLevel1Clear();
                                  onLevel2Clear();
                                  onLevel3Clear();
                                }
                                return onContractChange(evt);
                              } }
                              required
                              fullWidth
                              component={AutoSuggest}
                              name="contract"
                              itemsToUse={contracts}
                              validate={[required]}
                              label={translate('capitalTable&Form.contract')}
                            />
                          </GridItem>

                          {contractId &&
                            <GridItem xs={12} className={classes.marginTop}>
                              <Field
                                onChange={evt => {
                                  if (!evt) {
                                    onAssignPlanItemClear();
                                    onLevel1Clear();
                                    onLevel2Clear();
                                    onLevel3Clear();
                                  }
                                }}
                                required
                                fullWidth
                                component={AutoSuggest}
                                name="assignPlan"
                                itemsToUse={assignPlansOptions}
                                validate={[required]}
                                label={translate('assignPlan')}
                              />
                            </GridItem>
                          }

                          {assignPlan &&
                          <GridItem xs={12} className={classes.marginTop}>
                            <Field
                              onChange={evt => {
                                if (!evt) {
                                  onLevel1Clear();
                                  onLevel2Clear();
                                  onLevel3Clear();
                                }
                                const { key } = assignPlanItemsOptions.find(item =>
                                  item.assignPlanItemId === evt) || {};
                                const level = ((key || '').split('-') || []).length;
                                const fieldName = `level${level}`;
                                onChangeFiledValue(fieldName, key);
                              }}
                              required
                              fullWidth
                              component={AutoSuggest}
                              name="assignPlanItem"
                              itemsToUse={assignPlanItemsOptions}
                              validate={[required]}
                              label={translate('assignPlanItem')}
                            />
                          </GridItem>
                          }

                          {/*{assignPlanItem && startWithLevel1 &&*/}
                          {/*<GridItem xs={12} className={classes.marginTop}>*/}
                          {/*  <Field*/}
                          {/*    onChange={evt => {*/}
                          {/*      if (!evt) {*/}
                          {/*        onLevel2Clear();*/}
                          {/*        onLevel3Clear();*/}
                          {/*      }*/}
                          {/*    } }*/}
                          {/*    fullWidth*/}
                          {/*    component={AutoSuggest}*/}
                          {/*    name="level1"*/}
                          {/*    itemsToUse={level1Options}*/}
                          {/*    label={((planHierarchy || []).find(item => item.level === 1) || {}).name}*/}
                          {/*  />*/}
                          {/*</GridItem>*/}
                          {/*}*/}

                          {(level1 || startWithLevel2) &&
                          <GridItem xs={12} className={classes.marginTop}>
                            <Field
                              onChange={evt => {
                                if (!evt) {
                                  onLevel3Clear();
                                }
                              } }
                              fullWidth
                              component={AutoSuggest}
                              name="level2"
                              itemsToUse={level2Options}
                              label={((planHierarchy || []).find(item => item.level === 2) || {}).name}
                            />
                          </GridItem>
                          }

                          {(level2 || startWithLevel3) &&
                          <GridItem xs={12} className={classes.marginTop} >
                            <Field
                              onChange={evt => {
                                const totalCurrentNumber = ((finalPlans || [])
                                  .find(item => item.key === evt) || {}).dispatchNumber || 0;
                                const totalNumber = ((finalPlans || [])
                                  .find(item => item.key === evt) || {}).planQuantity || 0;
                                setTotalCurrentNumber(totalCurrentNumber);
                                setTotalNumber(totalNumber);
                              }}
                              fullWidth
                              component={AutoSuggest}
                              name="level3"
                              itemsToUse={level3Options}
                              label={((planHierarchy || []).find(item => item.level === 3) || {}).name}
                            />
                          </GridItem>
                          }
                        </GridContainer>

                      </GridItem>

                    </GridContainer>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={4} className={classes.leftBody}>

                {planItemsTreeData && planItemsTreeData.length > 0 &&
                  <Paper elevation={3}>
                    <WidgetHeader title={translate('assignPlanItemDetail')} />
                    <div className={classes.widget}>
                      <Tree
                        switcherIcon={<DownOutlined />}
                        // expandedKeys={
                        //   usePlanItemExpandNotes ? expandPlanItemNodes :
                        //     (planItemsPlainData || []).map(item => item.key)}
                        // onExpand={onPlanItemExpand}
                        treeData={planItemsTreeData || []}
                        showLine={{ showLeafIcon: false }}
                      />
                    </div>
                  </Paper>
                }

                {assignPlanSummary && assignPlanSummary.length > 0 &&
                  <Paper elevation={3}>
                    <WidgetHeader title={translate('assignPlanDetail')} />
                    <div className={classes.widget}>
                      {
                        // eslint-disable-next-line no-unused-vars
                        assignPlanSummary.map(({ planItemsPlainData: plainData, planItemsTreeData: treeData }, idx) =>
                          <div key={idx}>
                            <Tree
                              switcherIcon={<DownOutlined />}
                              // expandedKeys={
                              //   usePlanExpandNotes ? expandPlanNodes :
                              //     (plainData || []).map(item => item.key)}
                              // onExpand={onPlanExpand}
                              treeData={treeData || []}
                              showLine={{ showLeafIcon: false }}
                            />
                          </div>
                        )}
                    </div>
                  </Paper>
                }

                <Paper elevation={3}>
                  <WidgetHeader title={translate('planDetail')} />
                  <div className={classes.widget}>
                    {(plans && plans.length) ?
                      <Tree
                        switcherIcon={<DownOutlined />}
                        // expandedKeys={
                        //   useExpandNotes ? expandNodes :
                        //     (contractPlanItemsPlainData || []).map(item => item.key)}
                        // onExpand={onExpand}
                        treeData={contractPlanItemsTreeData || []}
                        showLine={{ showLeafIcon: false }}
                      /> :
                      <Typography align={'center'} variant="body1">
                        {translate('emptyPlanInDispatch')}
                      </Typography>
                    }
                  </div>
                </Paper>
              </Grid>
            </Grid>

          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

DispatchAddEdit.propTypes = {
  history: PropTypes.any
};

export default DispatchAddEdit;
