/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import moment from 'moment-timezone';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const History = props => {
  const {
    history,
    fetchWarehouseHistories,
    onClose,
    warehouseId,
    warehouseHistories,
    timezone,
    totalInAndOut,
    upsertWarehouseHistory
  } = props || {};

  useEffect(() => {
    fetchWarehouseHistories({ warehouseId, history });
  }, []);

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'createDate',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'createDate',
      label: '操作日期',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (moment.unix(value).tz(timezone)
          .format('YYYY-MM-DD HH:mm'))
      }
    },
    {
      name: 'type',
      label: '类型',
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value === 'in' ? '入库' : '出库')
      }
    },
    {
      name: 'number',
      label: '数量',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'inOutNote',
      label: '备注',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'createDate',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <Tooltip title={'删除'}>
                <IconButton
                  onClick={() => {
                    upsertWarehouseHistory({
                      isDelete: true,
                      formValues: { createDate: value, warehouseId },
                      history,
                      upsertWarehouseHistoryMessage:
                          { success: '删除仓库历史记录成功！', error: '删除仓库历史记录失败！' },
                      generalAlertText: { title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message') }
                    });
                  }}
                  color={'info'}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'lg'}
          isOpen={true}
          onClose={onClose}
          id="inAndOut-dialog"
          title= {'历史记录'}
          dialogActions={actionButtons()}
        >

          <GridContainer>

            <GridItem xs={12}>
              总共入库: {totalInAndOut.totalIn}
            </GridItem>
            <GridItem xs={12}>
              总共出库: {totalInAndOut.totalOut}
            </GridItem>

            {warehouseHistories && warehouseHistories.length > 0 &&
              <GridItem xs={12}>
                <MUIDataTable
                  title={(
                    <GridContainer>
                    </GridContainer>
                  )}
                  data={warehouseHistories || []}
                  columns={renderColumns()}
                  options={{
                    setTableProps: () => ({
                      size: 'small'
                    }),
                    textLabels: MUI_DATA_TABLE_LABELS,
                    responsive: 'stacked',
                    elevation: 0,
                    selectableRows: 'none',
                    rowsPerPage: 50,
                    rowsPerPageOptions: [25, 50, 75, 100],
                    download: false,
                    print: false
                  }}
                />
              </GridItem>
            }

            {/*<GridItem xs={12}>*/}
            {/*  {warehouseHistories && warehouseHistories.length > 0 &&*/}
            {/*  <Timeline align="left">*/}
            {/*    {warehouseHistories.map(item => {*/}
            {/*      const { createDate, number, type, inOutNote } = item || {};*/}
            {/*      const date = moment.unix(createDate).tz(timezone)*/}
            {/*        .format('YYYY-MM-DD HH:mm');*/}
            {/*      const description = `在 ${date} ${(type === 'in') ? '入库' : '出库'} ${number}件`;*/}
            {/*      return (*/}
            {/*        <TimelineItem key={createDate}>*/}
            {/*          <TimelineOppositeContent>*/}
            {/*            <Typography color="textSecondary">{description}</Typography>*/}
            {/*          </TimelineOppositeContent>*/}
            {/*          <TimelineSeparator>*/}
            {/*            <TimelineDot color={(type === 'in') ? 'primary' : 'secondary'} />*/}
            {/*            <TimelineConnector />*/}
            {/*          </TimelineSeparator>*/}
            {/*          <TimelineContent>*/}
            {/*            <Typography>{inOutNote}</Typography>*/}
            {/*          </TimelineContent>*/}
            {/*        </TimelineItem>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </Timeline>*/}
            {/*  }*/}

            {/*</GridItem>*/}

          </GridContainer>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

History.propTypes = {
  history: PropTypes.any
};

export default History;
