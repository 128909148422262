import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertContract,
  fetchContracts,
  fetchPlans,
  fetchAssignPlans,
  upsertAssignPlan
} from '../../../actions';
import {
  getLoginUserPermissions,
  getAssignPlans,
  getLoginUserClientConfigTimezone,
  getContracts
} from '../../../selectors';
import AssignPlans from './AssignPlans';

const stateToProps = (state, ownProps) => {
  const contractId = ownProps.match.params.contractId;
  const assignPlans = getAssignPlans(state);
  const contracts = getContracts(state);
  const contractName = ((contracts || []).find(contract =>
    contract.contractId === contractId) || {}).name;
  const timezone = getLoginUserClientConfigTimezone(state);
  const permissions = getLoginUserPermissions(state);
  return ({
    assignPlans,
    permissions,
    contractId,
    timezone,
    contractName
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  upsertContract,
  fetchPlans,
  fetchAssignPlans,
  upsertAssignPlan
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(AssignPlans);
