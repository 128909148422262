import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, uploadImage } from '../../actions';
import Sidebar from './Sidebar';
import { getLoginUserPermissions } from '../../selectors';

const stateToProps = state => ({
  permissions: getLoginUserPermissions(state)
});

const dispatchToProps = dispatch => bindActionCreators({
  login,
  uploadImage
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Sidebar);
