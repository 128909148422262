import gql from 'graphql-tag';

export default gql`
    query getDispatchDisplay(
        $request: DispatchWorkInput
        $correlationId: String!
        $token: String!
    ){
        getDispatchDisplay(
            request: $request
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            dispatchWorks {
                dispatchId
                startDate
                endDate
                worker
                createDate
                dispatchNumber
                completeNumber
                note
                workTime
                workType
                equipmentType
                equipment
                level1Id
                level2Id
                level3Id
                isMain
                key
                dispatchWorkType
                quantityTest
                level1 {
                  id
                  name
                  modelName
                }
                level2 {
                  id
                  name
                  modelName
                }
                level3 {
                  id
                  name
                  modelName
                }
                contract {
                  id
                  name
                }
                assignPlan {
                  id
                  name
                }
            }
        }
    }`;
