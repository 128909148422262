/* eslint-disable max-lines */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import GridItem from '../../../components/Grid/GridItem';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AttendeeAddEdit from '../AttendeeAddEdit';
import DeleteIcon from '@material-ui/icons/Delete';

export default function AttendeesLists(props) {
  const {
    attendeesLists,
    upsertAttendee,
    history
  } = props;

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevAttendeeLists = usePrevious(attendeesLists);

  const [summaryRow, setSummaryRow] = useState(
    { attendeeId: 'summary', dateFormat: '总结', workTime: 0 });

  const [editAttendeeId, setEditAttendeeId] = useState(null);

  useEffect(() => {
    if ((prevAttendeeLists || []).length !== (attendeesLists || []).length) {
      const initialWorkTime = attendeesLists.reduce((accum, item) => {
        const { workTime: itemWorkTime } = item || {};
        return accum + itemWorkTime || 0;
      }, 0);
      setSummaryRow({ attendeeId: 'summary', dateFormat: '总结', workTime: initialWorkTime });
    }
  }, [attendeesLists]);

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'attendeeId',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'dateFormat',
      label: '日期',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (value === '总结' ?
          <div style={{ fontSize: 18 }}>
            <b>{value}</b>
          </div>
          : value)
      }
    },
    {
      name: 'workerName',
      label: '员工',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'workType',
      label: '班次',
      options: {
        filter: true,
        sort: true,
        customBodyRender: workType => (workType ? translate(`workType.${workType}`) : '')
      }
    },
    {
      name: 'workTime',
      label: '工作时间',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'attendeeId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: attendeeId => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <Tooltip title={'编辑派工'}>
                <IconButton
                  onClick={() => setEditAttendeeId(attendeeId)}
                  color={'primary'}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            <GridItem>
              <Tooltip title={'删除派工'}>
                <IconButton
                  onClick={() => {
                    upsertAttendee({
                      isDelete: true,
                      formValues: { attendeeId },
                      history,
                      upsertAttendeeMessage: {
                        success: translate('删除派工成功！'),
                        error: translate('删除派工失败')
                      },
                      generalAlertText: {
                        title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message')
                      }
                    });
                  }}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

          </GridContainer>
        )
      }
    }

  ];

  return (
    <Fragment>
      <MUIDataTable
        title={(
          <GridContainer>
          </GridContainer>
        )}
        data={[summaryRow, ...(attendeesLists || [])] }
        columns={renderColumns()}
        options={{
          setTableProps: () => ({
            size: 'small'
          }),
          textLabels: MUI_DATA_TABLE_LABELS,
          responsive: 'stacked',
          elevation: 0,
          selectableRows: 'none',
          rowsPerPage: 50,
          rowsPerPageOptions: [25, 50, 75, 100],
          download: false,
          print: false,
          // eslint-disable-next-line id-length
          onFilterChange: (_, filter) => {
            // eslint-disable-next-line no-unused-vars
            const [[id], [dateFormat], [workerName], [workType], [workTime]]
              = filter;

            // eslint-disable-next-line no-console
            console.log({
              id,
              dateFormat,
              workerName,
              workType,
              workTime
            });

            let transferWorkType = '';

            if (workType === '白班') {
              transferWorkType = 'day';
            }
            if (workType === '大白班') {
              transferWorkType = 'bigDay';
            }
            if (workType === '夜班') {
              transferWorkType = 'night';
            }
            if (workType === '大夜班') {
              transferWorkType = 'bigNight';
            }
            if (workType === '加班') {
              transferWorkType = 'overTime';
            }
            if (workType === '中班') {
              transferWorkType = 'noon';
            }

            const summaryLists = attendeesLists
              .filter(item => !workerName || (item.workerName === workerName))
              .filter(item => !workType || (item.workType === transferWorkType));

            const initialWorkTime = summaryLists.reduce((accum, item) => {
              const { workTime: itemWorkTime } = item || {};
              return accum + itemWorkTime || 0;
            }, 0);
            setSummaryRow({ attendeeId: 'summary', dateFormat: '总结', workTime: initialWorkTime,
              workerName, workType: transferWorkType
            });
          }
        }}
      />

      {editAttendeeId &&
       <AttendeeAddEdit
         onClose={() => setEditAttendeeId(null)}
         attendeesLists={attendeesLists}
         editAttendeeId={editAttendeeId}
       />
      }
    </Fragment>

  );
}

AttendeesLists.propTypes = {
  history: PropTypes.any
};
