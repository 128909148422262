import gql from 'graphql-tag';

export default gql`
    query getWarehouses(
        $token: String!
        $correlationId: String!
        $request: WarehouseInput
    ){
        getWarehouses(
            correlationId: $correlationId
            token: $token
            request: $request
        ){
            error
            message
            warehouses {
                warehouseId
                company
                subject
                level1
                level1Number
                level2
                level2Number
                unit
                note
                position
                type
                name
                standard
                material
                model
                warehouseType
                number
                warningQuantity
                price
                total
            }
        }
    }`;
