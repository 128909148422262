import { takeLatest, put, select } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchSalaries } from '../actions';
import { getSalaries as getSalariesApi } from '../api/graphql';
import { getFormValues } from 'redux-form/lib/immutable';
import { SALARIES_SEARCH as form } from '../lib/formNames';
import { Map } from 'immutable';
import { getLoginUserClientConfigTimezone } from '../selectors';
import moment from 'moment-timezone';

export function* fetchSalariesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history } = payload || {};
    const timezone = yield select(getLoginUserClientConfigTimezone);
    const formValues = (yield select(getFormValues(form))) || Map();

    const initialYear = moment().tz(timezone)
      .format('YYYY');
    const initialMonth = moment().tz(timezone)
      .format('MM');

    const year = formValues.get('year') || initialYear;
    const month = formValues.get('month') || initialMonth;
    const employee = formValues.get('employee');

    const startDate = moment(`${year}-${month}-01`).tz(timezone)
      .startOf('month')
      .unix();

    const endDate = moment(`${year}-${month}-01`).tz(timezone)
      .endOf('month')
      .unix();

    const { salaries, error, message } =
      yield getSalariesApi({ token, year, month, startDate, endDate, employee });

    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchSalaries.success(salaries));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchSalaries.type.PENDING, fetchSalariesSaga);
}
