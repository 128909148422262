/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../../../components/redux-form/TextField';
import { Field } from 'redux-form/immutable';
import { required } from '../../../../../lib/validation/validator';
import useLocales from '../../../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import regularFormsStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import AutoSuggest from '../../../../../components/redux-form/AutoSuggest';
import { normalizeToFloat } from '../../../../../lib/validation/formatting';

const PlanItemAddEdit = props => {
  const {
    setEditAssignPlanItem,
    history,
    formValues,
    pristine,
    invalid,
    planHierarchy,
    level1,
    level2,
    level1Options,
    level2Options,
    level3Options,
    onLevel2Clear,
    onLevel3Clear,
    upsertAssignPlanItem
  } = props || {};

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      }
    }
  );

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertAssignPlanItem({
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertAssignPlanItemMessage:
        { success: translate('upsertAssignItemPlan.success'),
          error: translate('upsertAssignItemPlan.error') }
    });
    setEditAssignPlanItem(null);
  };

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12} className={classes.marginTop}>
        <Field
          onChange={evt => {
            if (!evt) {
              onLevel2Clear();
              onLevel3Clear();
            }
          } }
          required
          fullWidth
          component={AutoSuggest}
          name="level1"
          itemsToUse={level1Options}
          validate={[required]}
          label={((planHierarchy || []).find(item => item.level === 1) || {}).name}
        />
      </GridItem>

      {level1 &&
          <GridItem xs={12} className={classes.marginTop}>
            <Field
              onChange={evt => {
                if (!evt) {
                  onLevel3Clear();
                }
              } }
              fullWidth
              component={AutoSuggest}
              name="level2"
              itemsToUse={level2Options}
              label={((planHierarchy || []).find(item => item.level === 2) || {}).name}
            />
          </GridItem>
      }

      {level2 &&
          <GridItem xs={12} className={classes.marginTop} >
            <Field
              fullWidth
              component={AutoSuggest}
              name="level3"
              itemsToUse={level3Options}
              label={((planHierarchy || []).find(item => item.level === 3) || {}).name}
            />
          </GridItem>
      }

      <GridItem xs={12} className={classes.marginTop} >
        <Field
          required
          fullWidth
          component={TextField}
          name="planItemDispatchNumber"
          validate={[required]}
          normalize={normalizeToFloat}
          label={translate('planItemDispatchNumber')}
        />
      </GridItem>

      <GridItem xs={12} className={classes.marginTop}>
        <div style={{ textAlign: 'center' }}>
          <Button
            className={classes.modalAction}
            variant="contained"
            type="button"
            onClick={() => setEditAssignPlanItem(null)}
          >
            {translate('cancel')}
          </Button>
          <Button
            className={classes.modalAction}
            disabled={pristine || invalid}
            variant="contained"
            color="linkedin"
            type="button"
            onClick={evt => onSubmit(evt)}

          >
            {translate('confirm')}
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  );
};

PlanItemAddEdit.propTypes = {
  history: PropTypes.any
};

export default PlanItemAddEdit;
