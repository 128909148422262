import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../components/redux-form/TextField';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import Dropdown from '../../../components/redux-form/Dropdown';

const InAndOut = props => {
  const {
    history,
    formValues,
    type,
    changeWarehouseNumber,
    onClose,
    fetchContracts,
    warehouseType,
    contractsForWarehouses,
    level2Number,
    pristine,
    invalid
  } = props || {};

  const finalContractForWare = warehouseType === 'product' && type !== 'in' && contractsForWarehouses;

  const showContract = warehouseType === 'product' && type !== 'in';

  const styles = () => ({
    modalAction: {
      marginRight: '12px'
    }
  });

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    if (level2Number && warehouseType === 'product') {
      fetchContracts(
        {
          history,
          level2Number
        });
    }
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    changeWarehouseNumber({
      formValues,
      history,
      changeWarehouseNumberMessage:
        { success: '更改数量成功！', error: translate('更改数量失败') }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={pristine || invalid}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="inAndOut-dialog"
          title= {type === 'in' ? '入库' : '出库'}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="number"
                  component={TextField}
                  label={'数量'}
                  validate={[required]}
                  required
                  normalize={normalizeToFloat}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="inOutNote"
                  component={TextField}
                  label={'备注'}
                />
              </GridItem>

              {showContract &&
                <GridItem xs={12}>
                  <Field
                    fullWidth
                    name="contractId"
                    component={Dropdown}
                    showDefaultNullOption
                    label={'出库零件所属合同'}
                    options={(finalContractForWare || []).map(item => ({
                      ...item,
                      id: item.contractId
                    }))}
                    validate={[required]}
                  />
                </GridItem>
              }

            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

InAndOut.propTypes = {
  history: PropTypes.any
};

export default InAndOut;
