import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchAssignPlanItems } from '../actions';
import { getAssignPlanItems as getAssignPlansItemApi } from '../api/graphql';

export function* fetchAssignPlanItemsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, contractId, assignPlanId, assignPlanItemId, returnType } = payload || {};

    const { assignPlanItems, error, message } =
      yield getAssignPlansItemApi({ token, contractId, assignPlanId, assignPlanItemId, returnType });

    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchAssignPlanItems.success(assignPlanItems));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchAssignPlanItems.type.PENDING, fetchAssignPlanItemsSaga);
}
