const getSummarySalaries = ({ formValues, salaries }) => {
  const summarySalaries = salaries.map(salary => {
    const {
      userId,
      employeeSalary = 0,
      dailySalary = 0,
      monthlyContractAmount = 0,
      monthlyFoodAllowance = 0,
      monthlySkillSalary = 0,
      monthlyWorkFee = 0,
      transportAllowance = 0,
      insuranceAllowance = 0,
      livingAllowance = 0
    } = salary || {};

    let summary = employeeSalary + dailySalary + monthlyContractAmount + monthlyFoodAllowance
      + monthlySkillSalary + monthlyWorkFee + transportAllowance + insuranceAllowance + livingAllowance;

    // eslint-disable-next-line array-callback-return
    (Object.keys(formValues) || []).map(id => {
      const value = formValues[id] ? parseInt(formValues[id]) : 0;
      const [uId, field] = (id || '').split(':');
      if (field === 'award' && uId === userId) {
        summary = summary + value;
      }
      if (field === 'punishment' && uId === userId) {
        summary = summary - value;
      }
      if (field === 'allowance' && uId === userId) {
        summary = summary + value;
      }
    });

    return {
      ...salary,
      summary: summary.toFixed(2)
    };
  });

  return summarySalaries;
};

export default getSummarySalaries;
