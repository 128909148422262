import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchContracts,
  fetchPlans,
  fetchContractReturn,
  fetchContractReceipt,
  fetchDispatchesByContract,
  fetchCheckIn
} from '../../../actions';
import ContractCheckin from './ContractCheckin';
import {
  getContracts,
  getContractReturn,
  getEditPlans,
  getContractReceipt,
  getContractCompanyOptions,
  getContractEntrustCompanyOptions,
  getDispatchWorkByContract,
  getLoginUserClientConfigTimezone,
  getCheckin
} from '../../../selectors';
import NP from 'number-precision';
import { getFinalContractTreeData } from '../../../lib/getFinalContractTreeData';

const stateToProps = (state, ownProps) => {
  const contractId = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.contractId;
  const contracts = getContracts(state);
  const returns = getContractReturn(state);
  const companies = getContractCompanyOptions(state);
  const entrustCompanies = getContractEntrustCompanyOptions(state);
  const receipts = getContractReceipt(state);
  const checkin = getCheckin(state);
  const timezone = getLoginUserClientConfigTimezone(state) || [];
  const checkinMapping = (checkin || []).reduce((accum, item) => {
    const { key, quantity } = item || {};
    // eslint-disable-next-line no-param-reassign
    accum[key] = quantity;
    return accum;
  }, {});

  const {
    contractNo,
    contractNumber,
    name,
    entrustCompany,
    company,
    workTime,
    note
  } = contracts.find(item => item.contractId === contractId) || {};

  const contract = {
    contractNo,
    contractNumber,
    name,
    entrustCompany: (entrustCompanies.find(item => item.id === entrustCompany) || {}).name,
    company: (companies.find(item => item.id === company) || {}).name,
    workTime,
    note
  };

  const plans = getEditPlans(state);
  const contractTotalPrice = plans.reduce((accum, item) => {
    const { planQuantity = 0, price = 0 } = item || {};
    return NP.plus(NP.times(planQuantity || 0, price || 0), accum);
  }, 0);
  const totalReturnAmount = returns.reduce((accum, item) => {
    const { amount } = item || {};
    return NP.plus(amount || 0, accum);
  }, 0);
  const totalReceiptAmount = receipts.reduce((accum, item) => {
    const { amount } = item || {};
    return NP.plus(amount || 0, accum);
  }, 0);

  const remainReturnAmount = contractTotalPrice - totalReturnAmount;
  const remainReceiptAmount = contractTotalPrice - totalReceiptAmount;

  const dispatchWorksByContract = getDispatchWorkByContract(state);
  const {
    contractPlanItemsPlainData,
    contractPlanItemsTreeData
  } = getFinalContractTreeData({ dispatchWorksByContract, contractId, plans });

  return ({
    contractId,
    returns,
    receipts,
    contract,
    contractTotalPrice,
    totalReturnAmount,
    checkin,
    checkinMapping,
    totalReceiptAmount,
    plans,
    name,
    contractPlanItemsPlainData,
    contractPlanItemsTreeData,
    remainReturnAmount,
    remainReceiptAmount,
    timezone
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  fetchPlans,
  fetchContractReturn,
  fetchContractReceipt,
  fetchDispatchesByContract,
  fetchCheckIn
}, dispatch);

export default connect(stateToProps, dispatchToProps)(ContractCheckin);
