import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchAssignPlans } from '../actions';
import { getAssignPlans as getAssignPlansApi } from '../api/graphql';

export function* fetchAssignPlansSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, contractId, assignPlanId } = payload || {};

    const { assignPlans, error, message } =
      yield getAssignPlansApi({ token, contractId, assignPlanId });

    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchAssignPlans.success(assignPlans));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchAssignPlans.type.PENDING, fetchAssignPlansSaga);
}
