import { getContractAssignAndCompleteData } from './getContractAssignAndCompleteData';
import { getTreeData } from './getTreeData';

export const getFinalContractTreeData = ({ dispatchWorksByContract, contractId, plans }) => {
  const assignContractPlanItemSummary =
    getContractAssignAndCompleteData(dispatchWorksByContract, contractId, 'contract');
  const contractPlanItems = ((plans || []).map(item => {
    const { key: itemKey, deliveryNumber } = item || {};
    const {
      dispatchCompleteNumber = 0,
      dispatchAssignNumber = 0,
      planNumber: planNumberSummary
    } =
      (assignContractPlanItemSummary.find(sDispatch => sDispatch.key === itemKey) || {});
    const itemLevel = (itemKey || '').split('-').length;
    return {
      ...item,
      dispatchCompleteNumber,
      dispatchAssignNumber,
      planNumber: planNumberSummary,
      itemLevel,
      ...deliveryNumber && { deliveryNumber }
    };
  }) || []).sort((alpha, bate) => bate.itemLevel - alpha.itemLevel);

  const finalContractPlanItems = contractPlanItems.map(item => {
    const { key: itemKey } = item || {};
    const isLevel1 = (itemKey || '').split('-').length === 1;
    const checkList = contractPlanItems.filter(planItem => {
      const { key: planItemKey } = planItem || {};
      return (planItemKey || '').startsWith(`${itemKey}-`) && planItemKey !== itemKey;
    });
    if (checkList && checkList.length) {
      const { finalDispatchAssignNumber, finalDispatchCompleteNumber } =
        checkList.reduce((accum, each) => {
          const { dispatchAssignNumber = 0, dispatchCompleteNumber = 0 } = each || {};
          if (accum.finalDispatchAssignNumber > dispatchAssignNumber) {
            // eslint-disable-next-line no-param-reassign
            accum.finalDispatchAssignNumber = dispatchAssignNumber;
          }
          if (accum.finalDispatchCompleteNumber > dispatchCompleteNumber) {
            // eslint-disable-next-line no-param-reassign
            accum.finalDispatchCompleteNumber = dispatchCompleteNumber;
          }
          return accum;
        }, {
          finalDispatchAssignNumber: 999999,
          finalDispatchCompleteNumber: 999999
        });

      const needToChange = contractPlanItems.find(each => each.key === itemKey) || {};
      needToChange.dispatchCompleteNumber = finalDispatchCompleteNumber;
      needToChange.dispatchAssignNumber = finalDispatchAssignNumber;
      if (isLevel1) {
        const firstLevelCheckList =
            (checkList || []).filter(cc => (cc.key || '').split('-').length === 2);
        if (firstLevelCheckList && firstLevelCheckList.length) {
          const { finalDeliveryNumber } =
              firstLevelCheckList.reduce((accum, each) => {
                const { deliveryNumber = 0 } = each || {};
                if (accum.finalDeliveryNumber > deliveryNumber) {
                  // eslint-disable-next-line no-param-reassign
                  accum.finalDeliveryNumber = deliveryNumber;
                }
                return accum;
              }, {
                finalDeliveryNumber: 999999
              });
          needToChange.deliveryNumber = finalDeliveryNumber;
        }
      }

      return {
        ...item,
        dispatchCompleteNumber: finalDispatchCompleteNumber,
        dispatchAssignNumber: finalDispatchAssignNumber
        // ...isLevel1 && { deliveryNumber: finalDeliveryNumber }
      };
    }
    return item;
  });

  const contractPlanItemsPlainData = getTreeData(finalContractPlanItems, true) || [];
  const contractPlanItemsTreeData = getTreeData(finalContractPlanItems, false) || [];

  return {
    contractPlanItemsPlainData,
    contractPlanItemsTreeData
  };
};
