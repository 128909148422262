/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import ContractCharts from 'views/ContractCharts/index';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import WidgetHeader from '../../../components/ui/WidgetHeader';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Typography from '@material-ui/core/Typography';
import { currency } from '../../../lib/getCurrencyAmount';
import moment from 'moment-timezone';

const styles = ({ spacing, palette, shape }) => (
  {
    ...regularFormsStyle,
    root: {
      padding: spacing.unit * 5,
      overflowY: 'scroll',
      height: '100%'
    },
    title: {
      fontFamily: '"Roboto Slab", Roboto'
    },
    grow: {
      flexGrow: 1
    },
    pageHeader: {
      padding: spacing.unit * 2,
      marginBottom: spacing.unit * 2,
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      borderRadius: shape.borderRadius,
      position: 'relative'
    },
    link: {
      cursor: 'pointer'
    },
    widget: {
      padding: spacing.unit * 2,
      marginBottom: spacing.unit * 3
    },
    widgetNoPad: {
      padding: 0
    },
    widgetNoOverflow: {
      overflow: 'hidden'
    },
    infoHeader: {
      backgroundColor: palette.info.light,
      color: palette.info.contrastText,
      paddingLeft: spacing.unit * 2
    },
    leftIcon: {
      marginRight: spacing.unit / 2
    },
    leftBody: {
      paddingRight: spacing.unit * 4
    },
    rightBody: {
      paddingLeft: spacing.unit
    },
    drawerContent: {
      position: 'relative',
      height: `calc(100vh - ${spacing.unit * 8}px)`,
      overflow: 'hidden',
      webkitOverflowScrolling: 'touch',
      width: 600,
      maxWidth: '100%'
    },
    isComplete: {
      color: 'green'
    },
    notComplete: {
      color: 'red'
    },
    tableRoot: {
      '& > *': {
        borderBottom: 'unset'
      }
    }
  }
);

const useStyles = makeStyles(styles);

export default function ContractView(props) {
  const {
    fetchContractReturn,
    fetchContractReceipt,
    history,
    fetchContracts,
    fetchPlans,
    contractId,
    returns,
    receipts,
    contract,
    contractTotalPrice,
    // totalReturnAmount,
    // totalReceiptAmount,
    fetchDispatchesByContract,
    // contractPlanItemsPlainData,
    contractPlanItemsTreeData,
    remainReturnAmount,
    remainReceiptAmount,
    timezone
  } = props;

  const {
    company,
    contractNo,
    contractNumber,
    entrustCompany,
    name,
    note,
    workTime
  } = contract || {};

  const [selectNode, setSelectNode] = useState(null);

  const onSelect = selectedKeys => {
    const key = (selectedKeys || [])[0];
    setSelectNode(key);
  };

  useEffect(() => {
    fetchDispatchesByContract({
      history,
      contractId
    });

    fetchContractReturn(
      {
        history,
        contractId,
        type: 'return'
      }
    );
    fetchContractReceipt(
      {
        history,
        contractId,
        type: 'receipt'
      }
    );
    fetchPlans(
      {
        history,
        contractId
      });
    fetchContracts(
      {
        history
      });
  }, []);

  const classes = useStyles();
  const { translate } = useLocales();

  // const [useExpandNotes, setUseExpandNotes] = useState(false);
  // const [expandNodes, setExpandNodes] = useState([]);

  // const onExpand = expandedKeys => {
  //   setUseExpandNotes(true);
  //   setExpandNodes(expandedKeys);
  // };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/admin/contracts')}
              color="info">
              <ArrowBackIosIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {translate('contractView')}
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={24}>

              {contractPlanItemsTreeData && contractPlanItemsTreeData.length > 0 &&
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <WidgetHeader title={'展示图'} />
                    <div className={classes.widget}>
                      <ContractCharts
                        selectNode={selectNode}
                        contractData={contractPlanItemsTreeData || []}
                      />
                    </div>
                  </Paper>
                </Grid>
              }

              <Grid item xs={12} sm={6} className={classes.leftBody}>
                <Paper elevation={3}>
                  <WidgetHeader title={translate('contractDetail')} />
                  <div className={classes.widget}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow key={1}>
                            <TableCell align="left">{translate('contractForm.name')}:</TableCell>
                            <TableCell align="left">{name}</TableCell>
                          </TableRow>
                          <TableRow key={2}>
                            <TableCell align="left">{translate('contractForm.contractNo')}:</TableCell>
                            <TableCell align="left">{contractNo}</TableCell>
                          </TableRow>
                          <TableRow key={3}>
                            <TableCell align="left">{translate('contractForm.contractNumber')}:</TableCell>
                            <TableCell align="left">{contractNumber}</TableCell>
                          </TableRow>
                          <TableRow key={4}>
                            <TableCell align="left">{translate('contractForm.entrustCompany')}:</TableCell>
                            <TableCell align="left">{entrustCompany}</TableCell>
                          </TableRow>
                          <TableRow key={5}>
                            <TableCell align="left">{translate('contractForm.company')}:</TableCell>
                            <TableCell align="left">{company}</TableCell>
                          </TableRow>
                          <TableRow key={6}>
                            <TableCell align="left">{translate('contractForm.workTime')}:</TableCell>
                            <TableCell align="left">{workTime}</TableCell>
                          </TableRow>
                          <TableRow key={7}>
                            <TableCell align="left">{translate('contractForm.note')}:</TableCell>
                            <TableCell align="left">{note}</TableCell>
                          </TableRow>
                          <TableRow key={8}>
                            <TableCell align="left">{translate('contractTotal')}:</TableCell>
                            <TableCell align="left">{currency(contractTotalPrice)}</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.leftBody}>
                <Paper elevation={3}>
                  <WidgetHeader title={translate('planDetail')} />
                  <div className={classes.widget}>
                    <Tree
                      switcherIcon={<DownOutlined />}
                      onSelect={onSelect}
                      // expandedKeys={
                      //   useExpandNotes ? expandNodes :
                      //     (contractPlanItemsPlainData || []).map(item => item.key)}
                      // onExpand={onExpand}
                      treeData={contractPlanItemsTreeData || []}
                      showLine={{ showLeafIcon: false }}
                    />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.leftBody}>
                <Paper elevation={3}>
                  <WidgetHeader title={translate('contractReceipt')} />
                  <div className={classes.widget}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography variant={'h6'} color={'primary'}>
                                {currency(remainReceiptAmount)}
                              </Typography>
                            </TableCell>
                            <TableCell>{translate('date')}</TableCell>
                            <TableCell>{translate('receiptAmount')}</TableCell>
                            <TableCell>{translate('note')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(receipts || []).map(({ date: rDate, amount: rAmount, note: rNote }, idx) =>
                            <TableRow key={idx}>
                              <TableCell/>
                              <TableCell align="left">{rDate}</TableCell>
                              <TableCell align="left">{currency(rAmount)}</TableCell>
                              <TableCell align="left">{rNote}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.leftBody}>
                <Paper elevation={3}>
                  <WidgetHeader title={translate('contractReturn')} />
                  <div className={classes.widget}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography variant={'h6'} color={'primary'}>
                                {currency(remainReturnAmount)}
                              </Typography>
                            </TableCell>
                            <TableCell>{translate('date')}</TableCell>
                            <TableCell>{translate('returnAmount')}</TableCell>
                            <TableCell>{translate('note')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(returns || []).map(({ date: rDate, amount: rAmount, note: rNote }, idx) =>
                            <TableRow key={idx}>
                              <TableCell/>
                              <TableCell align="left">{moment.unix(rDate).tz(timezone)
                                .format('MM/DD/YYYY')}</TableCell>
                              <TableCell align="left">{currency(rAmount)}</TableCell>
                              <TableCell align="left">{rNote}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Paper>
              </Grid>
            </Grid>

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ContractView.propTypes = {
  history: PropTypes.any
};
