/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import ContractReceiptAddEdit from './ContractReceiptAddEdit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment-timezone/moment-timezone-utils';
import { currency } from '../../../lib/getCurrencyAmount';

const useStyles = makeStyles(styles);

export default function ContractReceipt(props) {
  const {
    history,
    fetchContracts,
    fetchPlans,
    contractId,
    fetchContractReceipt,
    receipts,
    upsertContractReceiptReturn,
    name,
    contractTotalPrice,
    totalReceiptAmount
  } = props;

  const [modeOpen, setModeOpen] = useState(null);
  const [createTime, setCreateTime] = useState(null);

  const renderColumns = () => [
    {
      name: 'createTime',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'date',
      label: translate('date'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => moment(value).format('MM/DD/YYYY')
      }
    },
    {
      name: 'amount',
      label: translate('receiptAmount'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => currency(value)
      }
    },
    {
      name: 'note',
      label: translate('note'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'createTime',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <IconButton
                onClick={() => {
                  setModeOpen('addContractReceipt');
                  setCreateTime(value);
                }}
                color={'primary'}>
                <EditIcon/>
              </IconButton>
            </GridItem>

            <GridItem>
              <IconButton
                onClick={() => {
                  upsertContractReceiptReturn({
                    isDelete: true,
                    formValues: { contractId, createTime: value, type: 'receipt' },
                    history,
                    upsertReceiptReturnMessage:
                      { success: translate('upsertContractReceiptReturn.success'),
                        error: translate('upsertContractReceiptReturn.error') },
                    generalAlertText: { title: translate('generalAlertText.title'),
                      message: translate('generalAlertText.message') }
                  });
                }}
              >
                <DeleteIcon/>
              </IconButton>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  useEffect(() => {
    fetchPlans(
      {
        history,
        contractId
      });
    fetchContracts(
      {
        history
      });
    fetchContractReceipt(
      {
        history,
        contractId,
        type: 'receipt'
      }
    );
  }, []);

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              setModeOpen('addContractReceipt');
            }}
            color="linkedin">
            {translate('addReceipt')}
          </Button>
        </div>

      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/admin/contracts')}
              color="info">
              <ArrowBackIosIcon />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}>
              {translate('contractReceipt')} : {name}
            </h4>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              title={(
                <GridContainer>
                  <GridItem>
                    <Typography variant="subtitle1" color="inherit">
                      {translate('contractTotal')}
                    </Typography>
                    <Typography variant="body1" color="inherit">
                      {currency(contractTotalPrice)}
                    </Typography>
                  </GridItem>
                  <GridItem>
                    <Typography variant="subtitle1" color="inherit">
                      {translate('receiptTotal')}
                    </Typography>
                    <Typography variant="body1" color="inherit">
                      {currency(totalReceiptAmount)}
                    </Typography>
                  </GridItem>
                </GridContainer>
              )}
              data={receipts || []}
              columns={renderColumns()}
              options={{
                textLabels: MUI_DATA_TABLE_LABELS,
                responsive: 'stacked',
                elevation: 0,
                selectableRows: 'none',
                rowsPerPage: 50,
                rowsPerPageOptions: [25, 50, 75, 100],
                download: false,
                print: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>

      {modeOpen === 'addContractReceipt' &&
        <ContractReceiptAddEdit
          createTime={createTime}
          receipts={receipts}
          contractId={contractId}
          onClose={() => {
            setModeOpen(null);
            setCreateTime(null);
          }}
        />
      }

    </GridContainer>
  );
}

ContractReceipt.propTypes = {
  history: PropTypes.any
};
