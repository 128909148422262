import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/named
import {
  upsertAssignPlan,
  fetchPlans,
  upsertAssignPlanItem,
  fetchAssignPlanItems,
  fetchDispatchesByContract
} from '../../../../actions';
import AssignPlanItems from './AssignPlanItems';
import {
  getEditPlans,
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone,
  getAssignPlanItems,
  getDispatchWorkByContract
} from '../../../../selectors';
import { getFinalPlanTreeData } from '../../../../lib/getFinalPlanTreeData';

const stateToProps = (state, ownProps) => {
  const assignPlanId = ownProps.assignPlanId;
  const assignPlans = ownProps.assignPlans;

  const selectedAssignPlan = (assignPlans || []).find(item => item.assignPlanId === assignPlanId) || {};

  const {
    contractId
  } = selectedAssignPlan || {};

  const timezone = getLoginUserClientConfigTimezone(state);
  const plans = getEditPlans(state) || [];
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];
  const initialAssignPlanItems = getAssignPlanItems(state);
  const assignPlanItems = ((initialAssignPlanItems || []).map(item => {
    const { key } = item || {};
    const level = (key || '').split('-').length;
    const levelName = ((planHierarchy || []).find(hierarchy => hierarchy.level === level) || {}).name;
    return { ...item, levelName };
  }) || []).filter(item => item.isMain);
  const dispatchWorksByContract = getDispatchWorkByContract(state);

  const assignPlanSummary = getFinalPlanTreeData({
    dispatchWorksByContract, assignPlanItems: initialAssignPlanItems, assignPlan: assignPlanId, plans });

  return ({
    timezone,
    plans,
    contractId,
    assignPlanId,
    planHierarchy,
    selectedAssignPlan,
    assignPlanItems,
    assignPlanSummary
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertAssignPlan,
  fetchPlans,
  upsertAssignPlanItem,
  fetchAssignPlanItems,
  fetchDispatchesByContract
}, dispatch);

export default connect(stateToProps, dispatchToProps)(AssignPlanItems);
