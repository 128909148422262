import { fromJS } from 'immutable';
import {
  setEquipmentMaintainHistories,
  setEquipmentsList,
  setEquipmentTypesList
} from '../selectors';
import {
  FETCH_EQUIPMENT_MAINTAIN_HISTORIES_ASYNC,
  FETCH_EQUIPMENTS_ASYNC
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [FETCH_EQUIPMENTS_ASYNC.SUCCESS]: () => setEquipmentsList(
    setEquipmentTypesList(state, payload.equipmentTypes),
    payload.equipments),
  [FETCH_EQUIPMENT_MAINTAIN_HISTORIES_ASYNC.SUCCESS]: () =>
    setEquipmentMaintainHistories(state, payload)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
