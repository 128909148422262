import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContractCharts from './ContractCharts';

const stateToProps = (state, ownProps) => {
  const contractData = ownProps.contractData;
  const selectNode = ownProps.selectNode || '';
  let resultData = [];
  const [level1, level2, level3] = (selectNode || '').split('-');
  if (!selectNode) {
    resultData = contractData;
  }
  if (level1 && level2) {
    const level1Entity = contractData.find(item => `${item.key}` === `${level1}`) || {};
    const { children: level1Children } = level1Entity || {};
    const level2Entity = level1Children.find(item => `${item.key}` === `${level1}-${level2}`) || {};
    const { children: level2Children } = level2Entity || {};
    resultData = level2Children;
  }
  if (level1 && !level2) {
    const level1Entity = contractData.find(item => `${item.key}` === `${level1}`) || {};
    const { children: level1Children } = level1Entity || {};
    resultData = level1Children;
  }

  const finalResultData = resultData.map(item => ({
    ...item,
    完成数量: item.dispatchCompleteNumber,
    计划完成数量: item.planQuantity,
    已分配数量: item.dispatchAssignNumber,
    已交付数量: item.deliveryNumber
  }));

  return {
    level1,
    level2,
    level3,
    resultData: finalResultData
  };
};

const dispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(ContractCharts);

