// eslint-disable-next-line no-undef
const langStorage = localStorage.getItem('i18nextLng');

// eslint-disable-next-line no-nested-ternary
export const required = value => (value ? undefined : ((langStorage === 'cn') ? '必须填写' : 'Field Required'));

export const noMoreThanMaxValue = (value, formValue) => {
  const formExistNumber = formValue.get('formExistNumber');
  const totalNumber = formValue.get('totalNumber');
  const totalCurrentNumber = formValue.get('totalCurrentNumber');
  if (!value) {
    return ((langStorage === 'cn') ? '必须填写' : 'Field Required');
  }
  const finalValue = Number.parseFloat(value);
  if ((finalValue + (totalCurrentNumber || 0) - (formExistNumber || 0)) <= totalNumber || 0) {
    return undefined;
  }
  return ((langStorage === 'cn') ? '超出最大数量' : 'Pass Max Value');
};
