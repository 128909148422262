/* eslint-disable max-lines */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../components/Grid/GridItem';
import Dropdown from '../../../components/redux-form/Dropdown';
import TextField from '../../../components/redux-form/TextField';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = () => ({
  root: {
    marginTop: 10,
    marginRight: 50
  },
  searchButton: {
    marginTop: 17
  },
  marginTop: {
    marginTop: 10
  }
});
const useStyles = makeStyles(styles);

export default function SalariesSearch(props) {
  const {
    history,
    fetchSalaries,
    clearSalaries,
    workers,
    summarySalaries,
    formValues
  } = props;

  const { year, month } = formValues || {};

  const onSubmit = evt => {
    evt.preventDefault();
    fetchSalaries({
      history
    });
  };

  const classes = useStyles();

  const MONTHS = [
    { id: '01', name: '01' },
    { id: '02', name: '02' },
    { id: '03', name: '03' },
    { id: '04', name: '04' },
    { id: '05', name: '05' },
    { id: '06', name: '06' },
    { id: '07', name: '07' },
    { id: '08', name: '08' },
    { id: '09', name: '09' },
    { id: '10', name: '10' },
    { id: '11', name: '11' },
    { id: '12', name: '12' }
  ];

  return (
    <Form>
      <Grid container justify={'center'} alignItems="center" spacing={24}>
        <Grid container justify={'center'} alignItems="center" spacing={24}>
          <GridItem xs={3}>
            <Field
              fullWidth
              name="year"
              component={TextField}
              label={'年'}
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              fullWidth
              name="month"
              component={Dropdown}
              showDefaultNullOption
              label={'月'}
              options={MONTHS}
              onChange={() => clearSalaries()}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              fullWidth
              name="employee"
              component={Dropdown}
              showDefaultNullOption
              label={'员工'}
              options={workers}
            />
          </GridItem>

          <GridItem xs={1}>
            <Button
              onClick={evt => onSubmit(evt)}
              className={classes.searchButton}
              type="button"
              color="info"
            >
              搜索
            </Button>
          </GridItem>

          <GridItem xs={1}>
            {!(summarySalaries && summarySalaries.length) ?
              <Button
                disabled={!(summarySalaries && summarySalaries.length)}
                className={classes.searchButton}
                type="button"
                color="warning"
              >
                导出EXCEL
              </Button> :
              <ExcelFile
                filename={`工资单 ${year}-${month}`}
                element={<Button
                  disabled={!(summarySalaries && summarySalaries.length)}
                  className={classes.searchButton}
                  type="button"
                  color="warning"
                >
                  导出EXCEL
                </Button>}>
                <ExcelSheet data={summarySalaries || []} name="工资">
                  <ExcelColumn label="员工" value="userName"/>
                  <ExcelColumn label="基本工资" value="employeeSalary"/>
                  <ExcelColumn label="派工工资" value="dailySalary"/>
                  <ExcelColumn label="包工工资" value="monthlyContractAmount"/>
                  <ExcelColumn label="餐补" value="monthlyFoodAllowance"/>
                  <ExcelColumn label="技能工资" value="monthlySkillSalary"/>
                  <ExcelColumn label="夜班费" value="monthlyWorkFee"/>
                  <ExcelColumn label="房补" value="livingAllowance"/>
                  <ExcelColumn label="交通补" value="transportAllowance"/>
                  <ExcelColumn label="社保" value="insuranceAllowance"/>
                  <ExcelColumn label="奖励" value="award"/>
                  <ExcelColumn label="扣款" value="punishment"/>
                  <ExcelColumn label="补贴" value="allowance"/>
                  <ExcelColumn label="总计" value="summary"/>
                </ExcelSheet>
              </ExcelFile>
            }
          </GridItem>

        </Grid>
      </Grid>
    </Form>

  );
}

SalariesSearch.propTypes = {
  history: PropTypes.any
};
