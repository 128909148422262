/* eslint-disable max-lines */
import React, { Fragment, useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { Field } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import regularFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import WidgetHeader from '../../../components/ui/WidgetHeader';
import DatePicker from '../../../components/redux-form/DatePicker';
import AutoSuggest from '../../../components/redux-form/AutoSuggest';
import TextField from '../../../components/redux-form/TextField';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import Button from '../../../components/CustomButtons/Button';
import moment from 'moment-timezone';
import Dropdown from '../../../components/redux-form/Dropdown';
import { WORK_TYPE } from '../../../conf/index';

const DispatchWorkAddEdit = props => {
  const { translate } = useLocales();

  const {
    history,
    formValues,
    upsertDispatchWork,
    editDispatchWork,
    setEditDispatchWork,
    workers,
    pristine,
    invalid,
    formStartDate,
    formEndDate,
    onStartBiggerThanEnd,
    formDispatchNumber,
    equipmentTypeOptions,
    formEquipmentType,
    equipmentOptions,
    startWithLevel1,
    startWithLevel2,
    startWithLevel3,
    formLevel1,
    formLevel2,
    level1Options,
    level2Options,
    level3Options,
    planHierarchy,
    onLevel2Clear,
    onLevel3Clear,
    dispatchWorkType
  } = props || {};

  const isContractAssign = dispatchWorkType === 'contract';

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      }
    }
  );

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertDispatchWork({
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertDispatchMessage:
        { success: translate('upsertDispatch.success'), error: translate('upsertDispatch.error') }
    });
    setEditDispatchWork(null);
  };

  return (
    <Fragment>
      <WidgetHeader
        isClose
        onEdit={() => setEditDispatchWork(null)}
        title={translate(editDispatchWork === 'new' ? 'createDispatchWork' : 'editDispatchWork')} />
      <div className={classes.widget}>
        <GridContainer>
          <GridItem xs={6}>
            <Field
              required
              fullWidth
              onChange={evt => {
                const changeStartDate = moment(evt).unix();
                const endDate = Number.isInteger(formEndDate) ?
                  formEndDate : moment(formEndDate).unix();
                if (changeStartDate > endDate) {
                  onStartBiggerThanEnd(evt);
                }
              }}
              label={translate('startDate')}
              component={DatePicker}
              name="startDate"
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={6}>
            <Field
              required
              fullWidth
              minDate={formStartDate}
              label={translate('endDate')}
              component={DatePicker}
              name="endDate"
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={12} className={classes.marginTop}>
            <Field
              required
              fullWidth
              component={AutoSuggest}
              name="worker"
              itemsToUse={workers}
              validate={[required]}
              label={translate('dispatchDetailForm&Table.workers')}
            />
          </GridItem>
          {!isContractAssign &&
            <GridItem xs={12} className={classes.marginTop}>
              <Field
                fullWidth
                component={TextField}
                name="workTime"
                normalize={normalizeToFloat}
                label={translate('dispatchDetailForm&Table.workTime')}
                validate={[required]}
              />
            </GridItem>
          }
          {!isContractAssign &&
            <GridItem xs={12} className={classes.marginTop}>
              <Field
                fullWidth
                name="workType"
                component={Dropdown}
                label={translate('dispatchDetailForm&Table.workType')}
                options={WORK_TYPE.map(item => ({
                  id: item.id,
                  name: translate(`workType.${item.id}`)
                }))}
                showDefaultNullOption
                validate={[required]}
              />
            </GridItem>
          }
          {!isContractAssign &&
            <GridItem xs={formEquipmentType ? 6 : 12} className={classes.marginTop}>
              <Field
                required
                fullWidth
                component={AutoSuggest}
                name="equipmentType"
                itemsToUse={equipmentTypeOptions}
                validate={[required]}
                label={translate('dispatchDetailForm&Table.equipmentType')}
              />
            </GridItem>
          }
          {formEquipmentType &&
          <GridItem xs={6} className={classes.marginTop}>
            <Field
              required
              fullWidth
              component={AutoSuggest}
              name="equipment"
              itemsToUse={equipmentOptions}
              validate={[required]}
              label={translate('dispatchDetailForm&Table.equipment')}
            />
          </GridItem>
          }

          {startWithLevel1 && (level1Options || []).length > 0 &&
          <GridItem xs={12} className={classes.marginTop}>
            <Field
              onChange={evt => {
                if (!evt) {
                  onLevel2Clear();
                  onLevel3Clear();
                }
              } }
              fullWidth
              component={AutoSuggest}
              name="level1Id"
              itemsToUse={level1Options}
              label={((planHierarchy || []).find(item => item.level === 1) || {}).name}
            />
          </GridItem>
          }
          {(formLevel1 || startWithLevel2) && (level2Options || []).length > 0 &&
          <GridItem xs={12} className={classes.marginTop}>
            <Field
              onChange={evt => {
                if (!evt) {
                  onLevel3Clear();
                }
              } }
              fullWidth
              component={AutoSuggest}
              name="level2Id"
              itemsToUse={level2Options}
              label={((planHierarchy || []).find(item => item.level === 2) || {}).name}
            />
          </GridItem>
          }
          {(formLevel2 || startWithLevel3) && (level3Options || []).length > 0 &&
          <GridItem xs={12} className={classes.marginTop}>
            <Field
              fullWidth
              component={AutoSuggest}
              name="level3Id"
              itemsToUse={level3Options}
              label={((planHierarchy || []).find(item => item.level === 3) || {}).name}
            />
          </GridItem>
          }

          <GridItem xs={formDispatchNumber ? 6 : 12} className={classes.marginTop}>
            <Field
              required
              fullWidth
              component={TextField}
              name="dispatchNumber"
              normalize={normalizeToFloat}
              validate={[required]}
              label={translate('dispatchDetailForm&Table.dispatchNumber')}
            />
          </GridItem>

          {formDispatchNumber &&
            <GridItem xs={6} className={classes.marginTop}>
              <Field
                fullWidth
                component={TextField}
                name="completeNumber"
                normalize={normalizeToFloat}
                label={translate('dispatchDetailForm&Table.completeNumber')}
              />
            </GridItem>
          }

          <GridItem xs={12} className={classes.marginTop}>
            <Field
              fullWidth
              component={TextField}
              name="note"
              label={translate('dispatchDetailForm&Table.note')}
            />
          </GridItem>

          <GridItem xs={12} className={classes.marginTop}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className={classes.modalAction}
                variant="contained"
                type="button"
                onClick={() => setEditDispatchWork(null)}
              >
                {translate('cancel')}
              </Button>
              <Button
                className={classes.modalAction}
                disabled={pristine || invalid}
                variant="contained"
                color="linkedin"
                type="button"
                onClick={evt => onSubmit(evt)}

              >
                {translate('confirm')}
              </Button>
            </div>

          </GridItem>

        </GridContainer>
      </div>

    </Fragment>

  );
};

DispatchWorkAddEdit.propTypes = {
  history: PropTypes.any
};

export default DispatchWorkAddEdit;
