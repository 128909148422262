import gql from 'graphql-tag';

export default gql`
    mutation changeWarehouseNumber(
        $token: String!
        $request: WarehouseNumberInput
        $correlationId: String!
    ){
        changeWarehouseNumber(
            token: $token
            correlationId: $correlationId
            request: $request
        ) {
            error
            message
        }
    }
`;
