import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchWarehouseHistories,
  upsertWarehouseHistory,
  upsertEquipmentMaintainHistory,
  fetchEquipmentMaintainHistories
} from '../../../actions';
import History from './History';
import {
  getLoginUserClientConfigTimezone,
  getEquipmentMaintainHistories
} from '../../../selectors';

const stateToProps = state => {
  const equipmentMaintainHistories = getEquipmentMaintainHistories(state);
  const timezone = getLoginUserClientConfigTimezone(state);

  return ({
    equipmentMaintainHistories:
      equipmentMaintainHistories.sort((alpha, bate) => bate.maintainDate - alpha.maintainDate),
    timezone
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchWarehouseHistories,
  upsertWarehouseHistory,
  upsertEquipmentMaintainHistory,
  fetchEquipmentMaintainHistories
}, dispatch);

export default connect(stateToProps, dispatchToProps)(History);
