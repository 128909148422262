/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import useLocales from '../../../lib/i18n/useLocales';
import PlansIcon from '@material-ui/icons/Assignment';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';

export default function Contracts(props) {
  const {
    history,
    contracts
  } = props;

  const contractsFilter = (contracts || []).filter(contract => contract.contractStatus === 'active');

  const renderColumns = () => [
    {
      name: 'contractId',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'contractNo',
      label: translate('contractTable.contractNo'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'contractNumber',
      label: translate('contractTable.contractNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: translate('contractTable.name'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'companyName',
      label: translate('contractTable.company'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'entrustCompanyName',
      label: translate('contractTable.entrustCompany'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'workTime',
      label: translate('contractTable.workTime'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'note',
      label: translate('contractTable.note'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'contractId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          value !== 'summary' &&
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <Tooltip title={translate('contractHint.overView')}>
                <IconButton
                  onClick={() => {
                    history.push(`/admin/checkin/edit/${value}`);
                  }}
                  color={'primary'}>
                  零件入库
                </IconButton>
              </Tooltip>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  useEffect(() => {
  }, [contracts]);

  const useStyles = makeStyles(() => ({
    ...styles,
    root: {
      flexGrow: 1,
      backgroundColor: '#f3f3f4'
    }
  }));

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <GridContainer>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <PlansIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {translate('checkinWarehouse')}
          </h4>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <GridContainer>
              </GridContainer>
            )}
            data={contractsFilter}
            columns={renderColumns()}
            options={{
              setTableProps: () => ({
                size: 'small'
              }),
              textLabels: MUI_DATA_TABLE_LABELS,
              responsive: 'stacked',
              elevation: 0,
              selectableRows: 'none',
              rowsPerPage: 50,
              rowsPerPageOptions: [25, 50, 75, 100],
              download: false,
              print: false
            }}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
}

Contracts.propTypes = {
  history: PropTypes.any
};
