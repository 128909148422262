import { getContractAssignAndCompleteData } from './getContractAssignAndCompleteData';
import { getAssignPlanItemTreeData } from './getAssignPlanItemTreeData';

export const getFinalPlanItemTreeData = ({ dispatchWorksByContract, selectedAssignPlanItems,
  assignPlanItem, plans }) => {
  const assignPlanItemSummary =
    getContractAssignAndCompleteData(dispatchWorksByContract, assignPlanItem, 'assignPlanItem');

  const summaryAssignPlanItems = (selectedAssignPlanItems || []).map(item => {
    const { key: itemKey } = item || {};
    const { dispatchCompleteNumber = 0, dispatchAssignNumber = 0, planNumber: planNumberSummary } =
      (assignPlanItemSummary.find(sDispatch => sDispatch.key === itemKey) || {});
    const sortKey = ((itemKey || '').split('-') || []).length;
    return {
      ...item,
      dispatchCompleteNumber,
      dispatchAssignNumber,
      planNumber: planNumberSummary,
      sortKey
    };
  });

  const sortSummaryAssignPlanItems = summaryAssignPlanItems
    .sort((alpha, bate) => bate.sortKey - alpha.sortKey);

  const finalAssignPlanItems = sortSummaryAssignPlanItems.map(reachRow => {
    const { key: itemKey } = reachRow || {};
    const checkList = summaryAssignPlanItems.filter(planItem => {
      const { key: planItemKey } = planItem || {};
      return (planItemKey || '').startsWith(`${itemKey}-`) && planItemKey !== itemKey;
    });

    if (checkList && checkList.length) {
      const { finalDispatchAssignNumber, finalDispatchCompleteNumber } =
        checkList.reduce((accum, item) => {
          const { dispatchAssignNumber = 0, dispatchCompleteNumber = 0 } = item || {};
          if (accum.finalDispatchAssignNumber > dispatchAssignNumber) {
            // eslint-disable-next-line no-param-reassign
            accum.finalDispatchAssignNumber = dispatchAssignNumber;
          }
          if (accum.finalDispatchCompleteNumber > dispatchCompleteNumber) {
            // eslint-disable-next-line no-param-reassign
            accum.finalDispatchCompleteNumber = dispatchCompleteNumber;
          }
          return accum;
        }, {
          finalDispatchAssignNumber: 999999,
          finalDispatchCompleteNumber: 999999
        });

      return { ...reachRow, dispatchCompleteNumber: finalDispatchCompleteNumber,
        dispatchAssignNumber: finalDispatchAssignNumber };
    }
    return reachRow;
  });

  const planItemsPlainData = getAssignPlanItemTreeData(
    { data: finalAssignPlanItems, plainData: true, plans });
  const planItemsTreeData = getAssignPlanItemTreeData(
    { data: finalAssignPlanItems, plainData: false, plans }) || [];

  return {
    planItemsPlainData,
    planItemsTreeData
  };
};
