import { takeLatest, put, select } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchDispatches } from '../actions';
import { getDispatches as getDispatchesApi } from '../api/graphql';
import { getFormValues } from 'redux-form/lib/immutable';
import { SEARCH_DISPATCHES as form } from '../lib/formNames';
import { Map } from 'immutable';
import moment from 'moment-timezone';
import { getLoginUserClientConfigTimezone } from '../selectors';

export function* fetchDispatchesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const timezone = yield select(getLoginUserClientConfigTimezone);
    const { history } = payload || {};
    const formValues = (yield select(getFormValues(form))) || Map();
    const start = formValues.get('startDate');
    const end = formValues.get('endDate');
    const contract = formValues.get('contract');
    const assignPlan = formValues.get('assignPlan');
    const level1 = formValues.get('level1');
    const level2 = formValues.get('level2');
    const level3 = formValues.get('level3');
    const dispatchWorkType = formValues.get('dispatchWorkType');

    const startDate = (Number.isInteger(start)) ? start : (moment(start).tz(timezone)
      .startOf('day')
      .unix());

    const endDate = (Number.isInteger(end)) ? end : (moment(end).tz(timezone)
      .endOf('day')
      .unix());

    const request = {
      startDate,
      endDate,
      contract,
      level1,
      level2,
      level3,
      assignPlan,
      dispatchWorkType
    };

    const { dispatches, message, error } = yield getDispatchesApi({ token, request });

    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchDispatches.success(dispatches));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchDispatches.type.PENDING, fetchDispatchesSaga);
}
