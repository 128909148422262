import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { warehouseExportExcel, fetchEquipments } from '../../../actions';
import ExportExcel from './ExportExcel';
import {
  getDownloadWarehouseHistories,
  getWarehouses,
  getLoginUserClientConfigTimezone,
  getSetups
} from '../../../selectors';
import { reduxForm, getFormValues, change } from 'redux-form/immutable';
import { Map } from 'immutable';
import { WAREHOUSE_EXPORT_EXCEL as form } from '../../../lib/formNames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
const WAREHOUSE_TYPE = {
  product: '成品/半成品',
  material: '材料',
  standardParts: '标准件',
  accessories: '辅料/工具',
  cutter: '刃具',
  lowValue: '低值易耗/办公用品'
};

const stateToProps = state => {
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;
  const timezone = getLoginUserClientConfigTimezone(state);
  const downloadWarehouseHistoriesInit = getDownloadWarehouseHistories(state);
  const warehouses = getWarehouses(state);
  const initialSetups = getSetups(state);
  const setupsInit = initialSetups.map(item => ({ ...item, id: item.setupId }));

  const setups = setupsInit.reduce((accum, item) => {
    // eslint-disable-next-line no-param-reassign
    accum[item.id] = item.name;
    return accum;
  }, {});

  const downloadWarehouseHistories = downloadWarehouseHistoriesInit.map(item => {
    const { warehouseType, material, name, createDate, type, warehouseId, number } = item || {};

    const warehouse = (warehouses || []).find(each => each.warehouseId === warehouseId);

    const { price } = warehouse || {};

    const total = (price || 0) * (number || 0);

    const isComeFromWarehouseName =
      warehouseType === 'cutter' || warehouseType === 'material' || warehouseType === 'standardParts';
    return {
      ...item,
      date: moment.unix(createDate).tz(timezone)
        .format('MM/DD/yyyy HH:mm:ss'),
      warehouseType: WAREHOUSE_TYPE[warehouseType],
      material: setups[material],
      name: isComeFromWarehouseName ? setups[name] : name,
      type: type === 'in' ? '入库' : '出库',
      total,
      price
    };
  });

  const sortDownloadWarehouseHistories = downloadWarehouseHistories.sort((aaa, bbb) =>
    bbb.createDate - aaa.createDate);

  const startDate = moment().tz(timezone)
    .startOf('month')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('month')
    .unix();
  const initialValues = {
    startDate,
    endDate
  };
  return ({
    downloadWarehouseHistories: sortDownloadWarehouseHistories,
    formValues,
    initialValues,
    formStartDate,
    formEndDate
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  warehouseExportExcel,
  fetchEquipments,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(ExportExcel));
