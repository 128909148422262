import gql from 'graphql-tag';

export default gql`
    query getCheckin(
        $contractId: String!
        $correlationId: String!
        $token: String!
    ){
        getCheckin(
            contractId: $contractId
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            checkin {
                contractId
                key
                quantity
            }
        }
    }`;
