import { createSelector } from 'reselect';
import { fromJS, Map, List } from 'immutable';
import { isTrue } from '../lib/booleanCheck';
import { CLIENTS } from '../conf/index';

export const setLoginUser = (authState, payload) => authState.setIn(['loginUser'], fromJS(payload));
export const getLoginUser = createSelector(
  state => state.getIn(['auth', 'loginUser'], Map()),
  loginUser => (loginUser || Map()).toJS()
);

export const getLoginUserPermissions = createSelector(
  state => state.getIn(['auth', 'loginUser', 'permissions'], List()),
  permissions => (permissions || List()).toJS()
);

export const getChangePasswordPermission = createSelector(
  state => state.getIn(['auth', 'loginUser', 'permissions'], List()),
  permissions => isTrue((((permissions || List()).toJS() || [])
    .find(item => item.id === 'changePassword') || {}).value)
);

export const getLoginUserRole = createSelector(
  state => state.getIn(['auth', 'loginUser', 'userRole'], ''),
  userRole => userRole
);

export const getIsSuperAdmin = createSelector(
  state => state.getIn(['auth', 'loginUser', 'isSuperAdmin'], false),
  userRole => !!userRole
);

export const getLoginUserClientId = createSelector(
  state => state.getIn(['auth', 'loginUser', 'clientId'], ''),
  clientId => clientId
);

export const getLoginUserClientConfig = createSelector(
  state => state.getIn(['auth', 'loginUser', 'clientId'], ''),
  clientId => CLIENTS.find(item => item.id === clientId) || {}
);

export const getLoginUserClientConfigTimezone = createSelector(
  getLoginUserClientConfig,
  clientConfig => (clientConfig || {}).timezone || 'America/Los_Angeles'
);

export const getLoginUserClientConfigPlanHierarchy = createSelector(
  getLoginUserClientConfig,
  clientConfig => (clientConfig || {}).planHierarchy || []
);

export const setUsers = (authState, payload) => authState.setIn(['users'], fromJS(payload));
export const getUsers = createSelector(
  state => state.getIn(['auth', 'users'], List()),
  users => (users || List()).toJS()
);

export const getAllEmployees = createSelector(
  getUsers,
  users => (users || []).map(user => ({
    ...user,
    label: user.realName,
    value: user.id,
    name: user.realName
  }))
);

export const getWorkers = createSelector(
  getUsers,
  users => (users || []).filter(user => user.userRole === 'worker').map(user => ({
    ...user,
    label: user.realName,
    value: user.id,
    name: user.realName
  }))
);
