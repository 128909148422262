import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertWarehouse, fetchEquipments } from '../../../actions';
import WarehouseAddEdit from './WarehouseAddEdit';
import { getSetups, getWarehouses } from '../../../selectors';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
import { WAREHOUSE_ADD_EDIT as form } from '../../../lib/formNames';

const stateToProps = (state, ownProps) => {
  const initialSetups = getSetups(state);
  const warehouses = getWarehouses(state);
  const setups = initialSetups.map(item => ({ ...item, id: item.setupId }));
  const warehouseMaterialTypeOptions = setups.filter(setup => setup.type === 'warehouseMaterialType');
  const warehouseMaterialNameOptions = setups.filter(setup => setup.type === 'warehouseMaterialName');
  const warehouseMaterialMaterialOptions = setups.filter(setup => setup.type === 'warehouseMaterialMaterial');
  const warehouseStandardPartsNameOptions = setups.filter(setup => setup.type === 'warehouseStandardPartsName');
  const warehouseStandardPartsMaterialOptions = setups.filter(setup => setup.type === 'warehouseStandardPartsMaterial');
  const warehouseCuttingToolTypeOptions = setups.filter(setup => setup.type === 'warehouseCuttingToolType');
  const warehouseCuttingToolNameOptions = setups.filter(setup => setup.type === 'warehouseCuttingToolName');
  const warehouseCuttingToolMaterialOptions = setups.filter(setup => setup.type === 'warehouseCuttingToolMaterial');

  const warehouseId = ownProps.warehouseId;
  const warehouseType = ownProps.warehouseType;
  const warehouse = (warehouses || []).find(item => item.warehouseId === warehouseId) || {};
  const {
    company,
    subject,
    level1,
    level1Number,
    level2,
    level2Number,
    unit,
    note,
    position,
    type,
    name,
    standard,
    material,
    model,
    warehouseType: passInWarehouseType,
    warningQuantity,
    total
  } = warehouse || {};
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const initialValues = {
    warehouseId,
    warehouseType: passInWarehouseType ? passInWarehouseType : warehouseType,
    company,
    subject,
    level1,
    level1Number,
    level2,
    level2Number,
    unit,
    note,
    position,
    type,
    name,
    standard,
    material,
    model,
    warningQuantity,
    price: total
  };
  return ({
    warehouseId,
    formValues,
    initialValues,
    warehouseMaterialTypeOptions,
    warehouseMaterialNameOptions,
    warehouseMaterialMaterialOptions,
    warehouseStandardPartsNameOptions,
    warehouseStandardPartsMaterialOptions,
    warehouseCuttingToolTypeOptions,
    warehouseCuttingToolNameOptions,
    warehouseCuttingToolMaterialOptions
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertWarehouse,
  fetchEquipments
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(WarehouseAddEdit));
