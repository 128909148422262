import { takeLatest, put, race, take } from 'redux-saga/effects';
import {
  upsertUser,
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertWarehouse,
  fetchWarehouses
} from '../actions';
import { upsertWarehouse as upsertWarehouseApi } from '../api/graphql';

export function* upsertWarehouseSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertWarehouseMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertUser.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const {
      warehouseId,
      company,
      subject,
      level1,
      level1Number,
      level2,
      level2Number,
      unit,
      note,
      position,
      type,
      name,
      standard,
      material,
      model,
      warehouseType,
      warningQuantity,
      price

    } = formValues;

    const request = {
      warehouseId,
      company,
      subject,
      level1,
      level1Number,
      level2,
      level2Number,
      unit,
      note,
      position,
      type,
      name,
      standard,
      material,
      model,
      warehouseType,
      warningQuantity,
      price
    };

    const { error, message } =
      yield upsertWarehouseApi({ token, request, isDelete });
    if (!error) {
      yield put(setAppAlert({
        message: upsertWarehouseMessage.success,
        variant: 'success'
      }));
      yield put(upsertWarehouse.success());
      yield put(fetchWarehouses({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertWarehouseMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertWarehouse.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertWarehouse.type.PENDING, upsertWarehouseSaga);
}
