import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchContracts, upsertContract } from '../../../actions';
import ContractAddEdit from './ContractAddEdit';
import { getContracts, getContractEntrustCompanyOptions, getContractCompanyOptions } from '../../../selectors';
import { reduxForm, getFormValues, change } from 'redux-form/immutable';
import { Map } from 'immutable';
import { CONTRACT_ADD_EDIT as form } from '../../../lib/formNames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

const stateToProps = (state, ownProps) => {
  const contracts = getContracts(state);
  const entrustCompanyOptions = getContractEntrustCompanyOptions(state);
  const companyOptions = getContractCompanyOptions(state);
  const contractId = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.contractId;
  const contract = contracts.find(item => item.contractId === contractId);
  const {
    company,
    contractNo,
    contractNumber,
    createTime,
    entrustCompany,
    name,
    note,
    workTime,
    contractStatus
  } = contract || {};

  const date = moment().format('YY-MM-DD');
  const showDate = date.split('-').join('');

  const allShowDateContracts = contracts.filter(item => {
    const ccNo = item && item.contractNo;
    return ccNo.split('-')[0] === showDate;
  }) || [];

  const contractCompanyNumber =
    allShowDateContracts.length > 0 ? `${showDate}-${allShowDateContracts.length + 1}` : showDate;

  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const initialValues = {
    contractId,
    company,
    contractNo: contractNo ? contractNo : contractCompanyNumber,
    contractNumber,
    createTime,
    entrustCompany,
    name,
    note,
    workTime,
    contractStatus: contractStatus ? contractStatus : 'active'
  };
  return ({
    contractId,
    formValues,
    initialValues,
    companyOptions,
    entrustCompanyOptions
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  upsertContract,
  onNewCompanyClick: () => change(form, 'company', null),
  onNewEntrustCompanyClick: () => change(form, 'entrustCompany', null)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(ContractAddEdit));
