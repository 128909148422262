/* eslint-disable max-lines */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import DatePicker from '../../../components/redux-form/DatePicker';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../components/Grid/GridItem';
import moment from 'moment-timezone';

const styles = () => ({
  root: {
    marginRight: 50
  },
  searchButton: {
    marginTop: 17
  }
});
const useStyles = makeStyles(styles);

export default function Capitals(props) {
  const {
    formEndDate,
    formStartDate,
    fetchCapitals,
    history,
    onStartBiggerThanEnd
  } = props;

  const onSubmit = evt => {
    evt.preventDefault();
    fetchCapitals({
      history
    });
  };

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <Form>
      <Grid container justify={'center'} alignItems="center" spacing={24}>
        <div className={classes.root}>
          <Grid container>
            <GridItem xs={6}>
              <Field
                required
                fullWidth
                onChange={evt => {
                  const changeStartDate = moment(evt).unix();
                  const endDate = Number.isInteger(formEndDate) ? formEndDate : moment(formEndDate).unix();
                  if (changeStartDate > endDate) {
                    onStartBiggerThanEnd(evt);
                  }
                }}
                label={translate('startDate')}
                component={DatePicker}
                name="startDate"
                validate={[required]}
              />
            </GridItem>

            <GridItem xs={6}>
              <Field
                required
                fullWidth
                minDate={formStartDate}
                label={translate('endDate')}
                component={DatePicker}
                name="endDate"
                validate={[required]}
              />
            </GridItem>
          </Grid>
        </div>

        <div className={classes.root}>
          <Button
            onClick={evt => onSubmit(evt)}
            className={classes.searchButton}
            type="button"
            color="info"
          >
            {translate('submit')}
          </Button>
        </div>

      </Grid>
    </Form>

  );
}

Capitals.propTypes = {
  history: PropTypes.any
};
