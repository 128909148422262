import { fromJS, Map } from 'immutable';
import { createSelector } from 'reselect';

export const setAlertDialogContent =
  (appState, payload) => appState.setIn(['alertDialogContent'], fromJS(payload));
export const getAlertDialogContent = createSelector(
  state => state.getIn(['app', 'alertDialogContent'], Map()),
  alertDialogContent => alertDialogContent.toJS()
);

export const setAppAlert = (appState, payload) => appState.setIn(['alert'], fromJS({ ...payload, isVisible: true }));
export const closeAppAlert = appState => appState.setIn(['alert', 'isVisible'], false);
export const getAppAlert = createSelector(
  state => state.getIn(['app', 'alert'], Map()),
  alert => alert.toJS()
);

export const setIsLoading = (appState, payload) => appState.setIn(['loading'], payload);
export const isLoading = createSelector(
  state => state.getIn(['app', 'loading']),
  loading => !!loading
);
