import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchContracts,
  fetchPlans,
  fetchContractReturn,
  fetchContractReceipt,
  fetchDispatchesByContract
} from '../../../actions';
import ContractView from './ContractView';
import {
  getContracts,
  getContractReturn,
  getEditPlans,
  getContractReceipt,
  getContractCompanyOptions,
  getContractEntrustCompanyOptions, getDispatchWorkByContract, getLoginUserClientConfigTimezone
} from '../../../selectors';
import NP from 'number-precision';
import { getFinalContractTreeData } from '../../../lib/getFinalContractTreeData';

const stateToProps = (state, ownProps) => {
  const contractId = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.contractId;
  const contracts = getContracts(state);
  const returns = getContractReturn(state);
  const companies = getContractCompanyOptions(state);
  const entrustCompanies = getContractEntrustCompanyOptions(state);
  const receipts = getContractReceipt(state);
  const timezone = getLoginUserClientConfigTimezone(state) || [];

  const {
    contractNo,
    contractNumber,
    name,
    entrustCompany,
    company,
    workTime,
    note
  } = contracts.find(item => item.contractId === contractId) || {};

  const contract = {
    contractNo,
    contractNumber,
    name,
    entrustCompany: (entrustCompanies.find(item => item.id === entrustCompany) || {}).name,
    company: (companies.find(item => item.id === company) || {}).name,
    workTime,
    note
  };

  const plans = getEditPlans(state);
  const contractTotalPrice = plans.reduce((accum, item) => {
    const { planQuantity = 0, price = 0 } = item || {};
    return NP.plus(NP.times(planQuantity || 0, price || 0), accum);
  }, 0);
  const totalReturnAmount = returns.reduce((accum, item) => {
    const { amount } = item || {};
    return NP.plus(amount || 0, accum);
  }, 0);
  const totalReceiptAmount = receipts.reduce((accum, item) => {
    const { amount } = item || {};
    return NP.plus(amount || 0, accum);
  }, 0);

  const remainReturnAmount = contractTotalPrice - totalReturnAmount;
  const remainReceiptAmount = contractTotalPrice - totalReceiptAmount;

  const dispatchWorksByContract = getDispatchWorkByContract(state);
  const {
    contractPlanItemsPlainData,
    contractPlanItemsTreeData
  } = getFinalContractTreeData({ dispatchWorksByContract, contractId, plans });

  return ({
    contractId,
    returns,
    receipts,
    contract,
    contractTotalPrice,
    totalReturnAmount,
    totalReceiptAmount,
    plans,
    contractPlanItemsPlainData,
    contractPlanItemsTreeData,
    remainReturnAmount,
    remainReceiptAmount,
    timezone
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  fetchPlans,
  fetchContractReturn,
  fetchContractReceipt,
  fetchDispatchesByContract
}, dispatch);

export default connect(stateToProps, dispatchToProps)(ContractView);
