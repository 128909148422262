/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import useLocales from '../../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import regularFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import WidgetHeader from '../../../../components/ui/WidgetHeader';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MUIDataTable from 'mui-datatables';
import { MUI_DATA_TABLE_LABELS } from '../../../../conf';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlanItemAddEdit from './PlanItemAddEdit';

const AssignPlanItems = props => {
  const {
    modeType,
    history,
    onClose,
    contractId,
    assignPlanId,
    selectedAssignPlan = {},
    timezone,
    upsertAssignPlanItem,
    fetchAssignPlanItems,
    assignPlanItems,
    fetchDispatchesByContract,
    assignPlanSummary
  } = props || {};

  // const [useExpandNotes, setUseExpandNotes] = useState(false);
  // const [expandNodes, setExpandNodes] = useState([]);
  //
  // const onExpand = expandedKeys => {
  //   setUseExpandNotes(true);
  //   setExpandNodes(expandedKeys);
  // };

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      }
    }
  );

  const renderColumns = () => [
    {
      name: 'assignPlanItemId',
      label: 'Assign Plan Item Id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'levelName',
      label: translate('levelName'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'title',
      label: translate('title'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'planItemDispatchNumber',
      label: translate('planItemDispatchNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'assignPlanItemId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>

            <GridItem>
              <IconButton
                onClick={() => {
                  setEditAssignPlanItem(value);
                }}
                color={'primary'}>
                <EditIcon/>
              </IconButton>
            </GridItem>

            <GridItem>
              <IconButton
                onClick={() => {
                  upsertAssignPlanItem({
                    isDelete: true,
                    formValues: { contractId, assignPlanId, assignPlanItemId: value },
                    history,
                    upsertAssignPlanItemMessage: { success: translate('upsertAssignItemPlan.success'),
                      error: translate('upsertAssignItemPlan.error') },
                    generalAlertText: { title: translate('generalAlertText.title'),
                      message: translate('generalAlertText.message') }
                  });
                }}
                color={'info'}>
                <DeleteIcon/>
              </IconButton>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  const [editAssignPlanItem, setEditAssignPlanItem] = useState(null);

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    fetchDispatchesByContract({
      history,
      contractId
    });
    fetchAssignPlanItems({
      history,
      contractId,
      assignPlanId
    });
  }, []);

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'xl'}
          isOpen={true}
          onClose={onClose}
          id="capitalAddEdit-dialog"
          title={translate(modeType)}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} sm={8}>

              <Grid container spacing={24}>
                <Grid item xs={editAssignPlanItem ? 6 : 12} className={classes.leftBody}>
                  <Paper elevation={3}>
                    <WidgetHeader title={translate('planDetail')} />
                    <div className={classes.widget}>
                      <GridContainer>
                        <Table aria-label="simple table">
                          <TableBody>
                            <TableRow key={1}>
                              <TableCell align="left">{translate('assignPlanTable&Form.assignPlanName')}:</TableCell>
                              <TableCell align="left">{selectedAssignPlan.assignPlanName}</TableCell>
                            </TableRow>
                            <TableRow key={2}>
                              <TableCell align="left">{translate('assignPlanTable&Form.startDate')}:</TableCell>
                              <TableCell align="left">
                                {moment.unix(selectedAssignPlan.startDate).tz(timezone)
                                  .format('MM/DD/yyyy')}
                              </TableCell>
                            </TableRow>
                            <TableRow key={3}>
                              <TableCell align="left">{translate('assignPlanTable&Form.endDate')}:</TableCell>
                              <TableCell align="left">
                                {moment.unix(selectedAssignPlan.endDate).tz(timezone)
                                  .format('MM/DD/yyyy')}
                              </TableCell>
                            </TableRow>
                            <TableRow key={4}>
                              <TableCell align="left">{translate('assignPlanTable&Form.note')}:</TableCell>
                              <TableCell align="left">{selectedAssignPlan.note}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </GridContainer>
                    </div>
                  </Paper>
                </Grid>

                {editAssignPlanItem &&
                    <Grid item xs={6} className={classes.leftBody}>
                      <Paper elevation={3}>
                        <WidgetHeader
                          isClose
                          onEdit={() => setEditAssignPlanItem(null)}
                          title={editAssignPlanItem === 'add' ? translate('addAssignPlanItem') :
                            translate('editAssignPlanItem')} />
                        <div className={classes.widget}>
                          <PlanItemAddEdit
                            assignPlanId={assignPlanId}
                            contractId={contractId}
                            assignPlanItems={assignPlanItems}
                            editAssignPlanItemId={editAssignPlanItem}
                            setEditAssignPlanItem={setEditAssignPlanItem}
                            history={history}
                          />
                        </div>
                      </Paper>
                    </Grid>
                }
                <Grid item xs={12} className={classes.leftBody}>
                  <Paper elevation={3}>
                    <WidgetHeader
                      isAdd
                      onEdit={() => setEditAssignPlanItem('add')}
                      title={translate('assignPlanItem')}
                    />

                    <MUIDataTable
                      title={(
                        <GridContainer>
                        </GridContainer>
                      )}
                      data={assignPlanItems || []}
                      columns={renderColumns()}
                      options={{
                        textLabels: MUI_DATA_TABLE_LABELS,
                        responsive: 'stacked',
                        elevation: 0,
                        selectableRows: 'none',
                        rowsPerPage: 50,
                        rowsPerPageOptions: [25, 50, 75, 100],
                        download: false,
                        print: false
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              {assignPlanSummary && assignPlanSummary.length > 0 &&
              <Paper elevation={3}>
                <WidgetHeader title={translate('assignPlanDetail')} />
                <div className={classes.widget}>
                  {
                    // eslint-disable-next-line no-unused-vars
                    assignPlanSummary.map(({ planItemsPlainData: plainData, planItemsTreeData: treeData }, idx) =>
                      <div key={idx}>
                        <Tree
                          switcherIcon={<DownOutlined />}
                          // expandedKeys={
                          //   useExpandNotes ? expandNodes :
                          //     (plainData || []).map(item => item.key)}
                          // onExpand={onExpand}
                          treeData={treeData || []}
                          showLine={{ showLeafIcon: false }}
                        />
                      </div>
                    )}
                </div>
              </Paper>
              }
            </Grid>

          </Grid>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

AssignPlanItems.propTypes = {
  history: PropTypes.any
};

export default AssignPlanItems;
