import gql from 'graphql-tag';

export default gql`
    query getEquipments(
        $equipmentId: String
        $correlationId: String!
        $token: String!
    ){
        getEquipments(
            equipmentId: $equipmentId
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            equipments {
                equipmentId
                name
                type
                status
                isDataControl
                purchaseDate
            }
        }
    }`;
