import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadImage, fetchUsers, upsertUser } from '../../../actions';
import UserAddEdit from './UserAddEdit';
import { getLoginUserRole, getChangePasswordPermission,
  getUsers, getIsSuperAdmin, getLoginUserClientId } from '../../../selectors';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
import { USER_ADD_EDIT as form } from '../../../lib/formNames';
import { isTrue } from '../../../lib/booleanCheck';
import { PERMISSIONS } from '../../../conf/index';

const stateToProps = (state, ownProps) => {
  const users = getUsers(state);
  const changePasswordPermission = getChangePasswordPermission(state);
  const loginUserRole = getLoginUserRole(state);
  const isSuperAdmin = getIsSuperAdmin(state);
  const loginUserClientId = getLoginUserClientId(state);
  const userId = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.userId;
  const user = (users || []).find(item => item.id === userId) || {};
  const {
    email,
    name,
    password,
    permissions, idNumber,
    phoneNumber,
    realName,
    userRole,
    clientId,
    livingAllowance,
    transportAllowance,
    insuranceAllowance,
    employeeSalary,
    hourSalary,
    dataControlHourSalary,
    skillSalary
  } = user || {};
  const isUnSuperAdminButAdmin = ((loginUserRole === 'admin') && (!isSuperAdmin));

  const permissionsInitialValue = (permissions || []).reduce((accum, item) => {
    const { id, value } = item || {};
    if (isTrue(value)) {
      return { ...accum, [id]: true };
    }
    return accum;
  }, {});

  const formValues = (getFormValues(form)(state) || Map()).toJS();

  const formPermissionValue = (formValues || {}).permissions || {};

  const rootPermissionValue = PERMISSIONS.reduce((accum, permission) => {
    const { id } = permission || {};
    const value = formPermissionValue[id];
    return { ...accum, [id]: value };
  }, {});

  const initialValues = {
    id: userId,
    email,
    name,
    password,
    clientId: isUnSuperAdminButAdmin ? loginUserClientId : clientId,
    permissions: permissionsInitialValue,
    idNumber,
    phoneNumber,
    realName,
    userRole,
    livingAllowance,
    transportAllowance,
    insuranceAllowance,
    employeeSalary,
    hourSalary,
    dataControlHourSalary,
    skillSalary
  };
  return ({
    rootPermissionValue,
    changePasswordPermission,
    userRole: loginUserRole,
    userId,
    formValues,
    initialValues,
    isSuperAdmin
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchUsers,
  uploadImage,
  upsertUser
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(UserAddEdit));
