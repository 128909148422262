// module.exports = {
//   graphqlURL: 'https://erbec6irczfqvgrnc7w5aqu5li.appsync-api.ap-east-1.amazonaws.com/graphql',
//   graphqlApiKey: 'da2-3ghk7fpalrgbbfjgudyemawm3u'
// };
// module.exports = {
//   graphqlURL: 'https://2jng2q3iw5fhtivd2o656lbhkm.appsync-api.ap-east-1.amazonaws.com/graphql',
//   graphqlApiKey: 'da2-xfula5xwbfb2tewg6yt3nhj3hq'
// };
module.exports = {
  graphqlURL: 'https://rv6dhzuqffet5jbuooig24a3pa.appsync-api.ap-east-1.amazonaws.com/graphql',
  graphqlApiKey: 'da2-7gshhlz3fbamvnxbk2qz3lgjpm'
};
