import { takeLatest, put } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  upsertWarehouse,
  changeWarehouseNumber,
  fetchWarehouses
} from '../actions';
import { changeWarehouseNumber as changeWarehouseNumberApi } from '../api/graphql';

export function* changeWarehouseNumberSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      changeWarehouseNumberMessage
    } = payload || {};

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const {
      warehouseId,
      type,
      number,
      inOutNote,
      contractId
    } = formValues;

    const request = {
      warehouseId,
      type,
      number,
      inOutNote,
      contractId
    };

    const { error, message } =
      yield changeWarehouseNumberApi({ token, request });
    if (!error) {
      yield put(setAppAlert({
        message: changeWarehouseNumberMessage.success,
        variant: 'success'
      }));
      yield put(changeWarehouseNumber.success());
      yield put(fetchWarehouses({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || changeWarehouseNumberMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertWarehouse.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(changeWarehouseNumber.type.PENDING, changeWarehouseNumberSaga);
}
