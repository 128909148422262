import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertEquipment, fetchEquipments } from '../../../actions';
import EquipmentAddEdit from './EquipmentAddEdit';
import { getEquipmentsList, getSetups } from '../../../selectors';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
import { EQUIPMENT_ADD_EDIT as form } from '../../../lib/formNames';

const stateToProps = (state, ownProps) => {
  const equipmentTypes = getSetups(state);
  const equipmentTypeOptions = equipmentTypes.filter(item => item.type === 'equipmentType').map(item => ({
    id: item.setupId,
    name: item.name
  }));

  const equipments = getEquipmentsList(state);
  const equipmentId = ownProps.equipmentId;
  const equipment = (equipments || []).find(item => item.equipmentId === equipmentId) || {};
  const { name, type, status, isDataControl, purchaseDate } = equipment || {};
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const initialValues = {
    equipmentId,
    name,
    type,
    status: status ? status : 'use',
    isDataControl: isDataControl ? isDataControl : 'no',
    purchaseDate
  };
  return ({
    formValues,
    initialValues,
    equipmentTypeOptions
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertEquipment,
  fetchEquipments
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(EquipmentAddEdit));
