import { fromJS } from 'immutable';
import {
  setWarehouses,
  setWarehouseHistories,
  setDownloadWarehouseHistories
} from '../selectors';
import {
  FETCH_WAREHOUSES_ASYNC,
  FETCH_WAREHOUSE_HISTORIES_ASYNC,
  WAREHOUSE_EXPORT_EXCEL_ASYNC,
  CLEAR_WAREHOUSES_EXCEL
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [CLEAR_WAREHOUSES_EXCEL]: () => setDownloadWarehouseHistories(state, null),
  [WAREHOUSE_EXPORT_EXCEL_ASYNC.SUCCESS]: () => setDownloadWarehouseHistories(state, payload),
  [FETCH_WAREHOUSES_ASYNC.SUCCESS]: () => setWarehouses(state, payload),
  [FETCH_WAREHOUSE_HISTORIES_ASYNC.SUCCESS]: () => setWarehouseHistories(state, payload)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
