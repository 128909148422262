import { takeLatest, put } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  upsertSetups,
  fetchSetups
} from '../actions';
import { upsertSetups as upsertSetupsApi } from '../api/graphql';

export function* upsertSetupsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues = {},
      history,
      upsertSetupMessage
    } = payload || {};

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const request = (Object.keys(formValues) || []).reduce((accum, id) =>
      [...accum, { id, setups: formValues[id] }], []);

    const res = yield upsertSetupsApi({ token, request });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertSetupMessage.success,
        variant: 'success'
      }));
      yield put(upsertSetups.success());
      yield put(fetchSetups({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertSetupMessage.error,
        variant: 'error'
      }));
    }
    history.push('/admin/setups');
  } catch (err) {
    upsertSetups.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertSetups.type.PENDING, upsertSetupsSaga);
}
