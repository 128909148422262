import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const setWarehouses = (warehousesState, payload) => warehousesState.setIn(['warehousesList'], fromJS(payload));
export const getWarehouses = createSelector(
  state => state.getIn(['warehouses', 'warehousesList'], List()),
  warehousesList => (warehousesList || List()).toJS() || []
);

export const setWarehouseHistories = (warehousesState, payload) =>
  warehousesState.setIn(['warehouseHistoriesList'], fromJS(payload));
export const getWarehouseHistories = createSelector(
  state => state.getIn(['warehouses', 'warehouseHistoriesList'], List()),
  warehouseHistoriesList => (warehouseHistoriesList || List()).toJS() || []
);

export const setDownloadWarehouseHistories = (warehousesState, payload) =>
  warehousesState.setIn(['downloadWarehouseHistoriesList'], fromJS(payload));
export const getDownloadWarehouseHistories = createSelector(
  state => state.getIn(['warehouses', 'downloadWarehouseHistoriesList'], List()),
  warehouseHistoriesList => (warehouseHistoriesList || List()).toJS() || []
);
