import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchSetups,
  fetchUsers,
  fetchAttendees
} from '../../actions';
import {
  getAttendees,
  getSalaries
} from '../../selectors';
import Salaries from './Salaries';

const stateToProps = state => {
  const attendees = getAttendees(state);
  const salaries = getSalaries(state);

  return ({
    attendees,
    salaries
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSetups,
  fetchUsers,
  fetchAttendees
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Salaries);
