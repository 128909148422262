import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CapitalsSearch from './DispatchDisplaySearch';
import { getFormValues, reduxForm, change } from 'redux-form/immutable';
import { SEARCH_DISPATCH_DISPLAY as form } from '../../../lib/formNames';
import {
  fetchCapitals,
  fetchDispatches,
  fetchSearchPlans,
  fetchAssignPlans,
  fetchDispatchWorkDisplay
} from '../../../actions';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Map } from 'immutable';
import {
  getLoginUserClientConfigTimezone,
  getAssignPlans,
  getWorkers,
  getDispatchWorkDisplay,
  getEquipmentType,
  getEquipmentsList,
  getLoginUser,
  getLoginUserPermissions,
  getLoginUserRole
} from '../../../selectors';
import getDispatchWorkDisplayData from '../getDispatchWorkDisplayData';
import { isTrue } from '../../../lib/booleanCheck';

const stateToProps = state => {
  const permissions = getLoginUserPermissions(state);
  const manageShowCompleteFunction =
    isTrue((permissions.find(permission => permission.id === 'manageShowCompleteFunction') || {}).value);
  const { id: userId } = getLoginUser(state) || {};
  const isWorker = !manageShowCompleteFunction && getLoginUserRole(state) === 'worker';

  const workers = getWorkers(state);
  const timezone = getLoginUserClientConfigTimezone(state);
  const startDate = moment().tz(timezone)
    .startOf('day')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('day')
    .unix();

  const initialValues = {
    startDate,
    endDate,
    worker: isWorker ? userId : ''
  };
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const searchType = formValues.searchType;
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;
  const formContract = formValues.contract;

  const assignPlans = getAssignPlans(state);

  const assignPlanOptions = (assignPlans || []).map(item => ({
    ...item,
    label: item.assignPlanName,
    value: item.assignPlanId
  }));

  const dispatchWorkDisplay = getDispatchWorkDisplay(state) || [];
  const equipmentTypes = getEquipmentType(state) || [];
  const equipmentList = getEquipmentsList(state) || [];
  const data = getDispatchWorkDisplayData({ dispatchWorkDisplay, workers, equipmentTypes, equipmentList });
  const dispatchWorkDisplayData = (data || []).filter(item => !!item.createDate);

  return {
    isWorker,
    formStartDate,
    formEndDate,
    formValues,
    searchType,
    initialValues,
    timezone,
    formContract,
    assignPlanOptions,
    workers,
    dispatchWorkDisplayData
  };
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchCapitals,
  fetchDispatches,
  fetchSearchPlans,
  fetchAssignPlans,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate),
  fetchDispatchWorkDisplay
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(CapitalsSearch));
