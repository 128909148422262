import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10000
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

const Loading = ({ isLoading, classes }) => (
  <div className={classes.root}>
    <Fade
      unmountOnExit
      in={isLoading}
      style={{
        transitionDelay: isLoading ? '100ms' : '0ms'
      }}>
      <LinearProgress color="secondary" />
    </Fade>
  </div>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loading);
