import { fromJS } from 'immutable';
import {
  setAttendees
} from '../selectors';
import {
  FETCH_ATTENDEES_ASYNC
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [FETCH_ATTENDEES_ASYNC.SUCCESS]: () => setAttendees(state, payload)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
