import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeWarehouseNumber,
  fetchContracts
} from '../../../actions';
import InAndOut from './InAndOut';
import { getContractsForWarehouses, getWarehouses } from '../../../selectors';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
import { WAREHOUSE_NUMBER_ADD_REMOVE as form } from '../../../lib/formNames';

const stateToProps = (state, ownProps) => {
  const warehouses = getWarehouses(state);
  const contractsForWarehouses = getContractsForWarehouses(state);
  const warehouseId = ownProps.warehouseId;
  const type = ownProps.type;
  const warehouse = (warehouses || []).find(item => item.warehouseId === warehouseId) || {};

  // const { name, type, status, isDataControl } = warehouse || {};
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const initialValues = {
    warehouseId,
    type
  };
  return ({
    warehouse,
    warehouseId,
    type,
    formValues,
    contractsForWarehouses,
    initialValues
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  changeWarehouseNumber,
  fetchContracts
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(InAndOut));
