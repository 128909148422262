import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchSetups } from '../actions';
import { getSetups as getSetupsApi } from '../api/graphql';

export function* fetchSetupsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, type } = payload || {};
    const result = yield getSetupsApi({ token, type });
    const { setups, message, error } = result || {};

    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchSetups.success(setups));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchSetups.type.PENDING, fetchSetupsSaga);
}
