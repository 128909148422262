import { takeLatest, put, select } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  warehouseExportExcel
} from '../actions';
import { warehouseExportExcel as warehouseExportExcelApi } from '../api/graphql';
import moment from 'moment-timezone';
import { getLoginUserClientConfigTimezone } from '../selectors';

export function* warehouseExportExcelSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      exportExcelMessage
    } = payload || {};

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const timezone = yield select(getLoginUserClientConfigTimezone);

    const {
      startDate,
      endDate,
      warehouseType,
      type
    } = formValues;

    const searchStartDate = (Number.isInteger(startDate)) ? startDate : (moment(startDate).tz(timezone)
      .startOf('day')
      .unix());

    const searchEndDate = (Number.isInteger(endDate)) ? endDate : (moment(endDate).tz(timezone)
      .endOf('day')
      .unix());

    const { error, message, result } =
      yield warehouseExportExcelApi({
        token,
        startDate: searchStartDate,
        endDate: searchEndDate,
        warehouseType,
        type
      });

    const res = result.reduce((accum, item) => {
      const { histories, ...resItem } = item || {};
      histories.forEach(historyItem => {
        const entity = {
          ...resItem,
          ...historyItem
        };
        accum.push(entity);
      });
      return accum;
    }, []);

    yield put(warehouseExportExcel.success(res));

    if (!error) {
      yield put(setAppAlert({
        message: exportExcelMessage.success,
        variant: 'success'
      }));
      yield put(exportExcelMessage.success());
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || warehouseExportExcel.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    warehouseExportExcel.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(warehouseExportExcel.type.PENDING, warehouseExportExcelSaga);
}
