import gql from 'graphql-tag';

export default gql`
    query login(
        $userName: String!
        $password: String!
        $correlationId: String!
    ){
        login(
            userName: $userName
            password: $password
            correlationId: $correlationId
        ){
            token
            user {
                id
                email
                name
                password
                idNumber
                phoneNumber
                userRole
                realName
                permissions {
                    id
                    value
                }
                clientId
            }
        }
    }`;
