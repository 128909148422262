import { takeLatest, put, race, take, select } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertDispatchWork,
  fetchDispatchWorks, fetchDispatchWorkDisplay
} from '../actions';
import moment from 'moment-timezone';

import { upsertDispatchWork as upsertDispatchWorkApi } from '../api/graphql';
import { getLoginUserClientConfigTimezone } from '../selectors';

export function* upsertDispatchWorkSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertDispatchMessage,
      generalAlertText,
      isDelete,
      dispatchWorkComplete
    } = payload || {};

    const timezone = yield select(getLoginUserClientConfigTimezone);

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertDispatchWork.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { startDate: start, endDate: end, dispatchId, createDate, worker, dispatchNumber, completeNumber, note,
      contractId, level1Id, level2Id, level3Id, workTime, workType, equipmentType, equipment, assignPlanId,
      assignPlanItemId, quantityTest } = formValues;

    const startDate = (Number.isInteger(start)) ? start : (moment(start).tz(timezone)
      .startOf('day')
      .unix());

    const endDate = (Number.isInteger(end)) ? end : (moment(end).tz(timezone)
      .endOf('day')
      .unix());

    const startYearMonth = moment.unix(startDate).tz(timezone)
      .format('YYYY-MM');
    const endYearMonth = moment.unix(endDate).tz(timezone)
      .format('YYYY-MM');

    if (startYearMonth !== endYearMonth) {
      yield put(setAppAlert({
        message: '开始时间和结束时间必须在同一个月！',
        variant: 'error'
      }));
    } else {
      const request = {
        dispatchId,
        startDate,
        endDate,
        createDate,
        worker,
        note,
        contractId,
        level1Id,
        level2Id,
        level3Id,
        workTime,
        workType,
        equipmentType,
        equipment,
        assignPlanId,
        assignPlanItemId,
        quantityTest: quantityTest || 0,
        dispatchNumber: dispatchNumber || 0,
        completeNumber: completeNumber || 0
      };

      const { error, message } = yield upsertDispatchWorkApi({ token, request, isDelete });

      if (!error) {
        yield put(setAppAlert({
          message: upsertDispatchMessage.success,
          variant: 'success'
        }));
        yield put(upsertDispatchWork.success());

        if (dispatchWorkComplete) {
          yield put(fetchDispatchWorkDisplay({ history }));
        } else {
          yield put(fetchDispatchWorks({ history, dispatchId }));
        }
      }

      if (error && message === 'auth') {
        history.push('/auth');
      }

      if (error && message !== 'auth') {
        yield put(setAppAlert({
          message: message || upsertDispatchMessage.error,
          variant: 'error'
        }));
      }
    }
  } catch (err) {
    upsertDispatchWork.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertDispatchWork.type.PENDING, upsertDispatchWorkSaga);
}
