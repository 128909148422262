/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import DatePicker from '../../../components/redux-form/DatePicker';
import Dropdown from '../../../components/redux-form/Dropdown';

const WAREHOUSE_TYPE_OPTIONS = [
  { id: 'product', name: '成品/半成品' },
  { id: 'material', name: '材料' },
  { id: 'standardParts', name: '标准件' },
  { id: 'accessories', name: '辅料/工具' },
  { id: 'cutter', name: '刃具' },
  { id: 'lowValue', name: '低值易耗/办公用品' }
];

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = props => {
  const {
    history,
    warehouseExportExcel,
    formValues,
    onClose,
    invalid,
    formEndDate,
    formStartDate,
    onStartBiggerThanEnd,
    downloadWarehouseHistories
  } = props || {};

  const styles = () => ({
    modalAction: {
      marginRight: '12px'
    }
  });

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    warehouseExportExcel({
      formValues,
      history,
      exportExcelMessage:
        { success: '导出EXCEL成功！', error: '导出EXCEL失败！' },
      generalAlertText: {
        title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message')
      }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={invalid || downloadWarehouseHistories.length}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="export-excel-dialog"
          title= {'导出Excel'}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            {!downloadWarehouseHistories.length ?
              <GridContainer>
                <GridItem xs={6}>
                  <Field
                    required
                    fullWidth
                    onChange={evt => {
                      const changeStartDate = moment(evt).unix();
                      const endDate = Number.isInteger(formEndDate) ? formEndDate : moment(formEndDate).unix();
                      if (changeStartDate > endDate) {
                        onStartBiggerThanEnd(evt);
                      }
                    }}
                    label={translate('startDate')}
                    component={DatePicker}
                    name="startDate"
                    validate={[required]}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    required
                    fullWidth
                    minDate={formStartDate}
                    label={translate('endDate')}
                    component={DatePicker}
                    name="endDate"
                    validate={[required]}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <Field
                    fullWidth
                    name="warehouseType"
                    showDefaultNullOption
                    options={WAREHOUSE_TYPE_OPTIONS}
                    component={Dropdown}
                    label={'仓库类型'}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <Field
                    fullWidth
                    name="type"
                    showDefaultNullOption
                    options={[
                      { id: 'in', name: '入库' },
                      { id: 'out', name: '出库' }
                    ]}
                    component={Dropdown}
                    label={'类型'}
                  />
                </GridItem>
              </GridContainer> :
              <GridContainer>
                <GridItem xs={12}>
                  <ExcelFile
                    filename={'仓库出入表'}
                    element={<Button
                      disabled={!(downloadWarehouseHistories && downloadWarehouseHistories.length)}
                      className={classes.searchButton}
                      type="button"
                      color="warning"
                    >
                      导出EXCEL
                    </Button>}>
                    <ExcelSheet data={downloadWarehouseHistories || []} name="仓库出入表">
                      <ExcelColumn label="日期时间" value="date"/>
                      <ExcelColumn label="仓库类型" value="warehouseType"/>
                      <ExcelColumn label="公司" value="company"/>
                      <ExcelColumn label="部件" value="level1"/>
                      <ExcelColumn label="部件图号" value="level1Number"/>
                      <ExcelColumn label="零件" value="level2"/>
                      <ExcelColumn label="零件图号" value="level2Number"/>
                      <ExcelColumn label="材料" value="material"/>
                      <ExcelColumn label="型号" value="model"/>
                      <ExcelColumn label="名称" value="name"/>
                      <ExcelColumn label="位置" value="position"/>
                      <ExcelColumn label="标准件" value="standard"/>
                      <ExcelColumn label="科目" value="subject"/>
                      <ExcelColumn label="单位" value="unit"/>
                      <ExcelColumn label="入/出库" value="type"/>
                      <ExcelColumn label="数量" value="number"/>
                      <ExcelColumn label="单价" value="price"/>
                      <ExcelColumn label="总价" value="total"/>
                      <ExcelColumn label="备注" value="inOutNote"/>
                    </ExcelSheet>
                  </ExcelFile>

                </GridItem>
              </GridContainer>
            }

          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

ExportExcel.propTypes = {
  history: PropTypes.any
};

export default ExportExcel;
