/* eslint-disable max-lines */
import React from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { Field, Form } from 'redux-form/immutable';
import regularFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import TextField from '../../../components/redux-form/TextField';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import useLocales from '../../../lib/i18n/useLocales';
import { makeStyles } from '@material-ui/core/styles';

const Checkin = props => {
  const {
    onClose,
    history,
    checkInAction,
    formValues,
    pristine,
    invalid,
    editLevel2,
    contractId,
    checkinMapping
  } = props || {};

  const {
    level1Name,
    level2Name,
    model,
    dispatchCompleteNumber,
    key
  } = editLevel2 || {};

  const numberInWarehouse = (checkinMapping || {})[key] ? parseFloat((checkinMapping || {})[key]) : 0;

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      },
      submitButton: {
        alignItems: 'center'
      },
      bottomButton: {
        marginRight: '10px'
      }
    }
  );

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { translate } = useLocales();

  const onSubmit = evt => {
    evt.preventDefault();
    const checkinNumber = (formValues || {}).checkinNumber;
    if (!model) {
      // eslint-disable-next-line no-undef
      alert('没有图号！');
    } else if ((parseFloat(checkinNumber) + numberInWarehouse) > parseFloat(dispatchCompleteNumber)) {
      // eslint-disable-next-line no-undef
      alert('入库数量大于完成数量！');
    } else {
      checkInAction({
        checkinNumber,
        key,
        history,
        contractId,
        generalAlertText: { title: translate('generalAlertText.title'),
          message: translate('generalAlertText.message') },
        checkinMessage:
                { success: '零件入库成功', error: '零件入库失败' }
      });
      onClose();
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="checkin-dialog"
          title={`入库： ${level1Name} / ${level2Name}`}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <b>
                    已入库数量：{numberInWarehouse}
                </b>
              </GridItem>
              <GridItem xs={12}>
                <b>
                    已完成数量：{dispatchCompleteNumber}
                </b>
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="checkinNumber"
                  component={TextField}
                  normalize={normalizeToFloat}
                  label={'入库数量'}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Button
                  className={classes.bottomButton}
                  onClick={() => onClose()}
                >
                  {translate('comeBack')}
                </Button>
                <Button
                  type={'submit'}
                  disabled={pristine || invalid}
                  className={classes.bottomButton}
                  color="info"
                >
                  {translate('submit')}
                </Button>

              </GridItem>

            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

Checkin.propTypes = {
  history: PropTypes.any
};

export default Checkin;
