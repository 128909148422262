import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchSearchPlans } from '../actions';
import { getPlans as getPlansApi } from '../api/graphql';

export function* fetchSearchPlansSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, contractId } = payload || {};
    const result = yield getPlansApi({ token, contractId });
    const { plans, message, error } = result || {};

    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchSearchPlans.success(plans));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchSearchPlans.type.PENDING, fetchSearchPlansSaga);
}
