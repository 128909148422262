import { connect } from 'react-redux';
import AppAlert from './AppAlert';
import { getAppAlert } from '../../../selectors';
import { bindActionCreators } from 'redux';
import { closeAppAlert } from '../../../actions';

const stateToProps = state => ({
  alert: getAppAlert(state)
});

const dispatchToProps = dispatch => bindActionCreators({ closeAppAlert }, dispatch);

export default connect(stateToProps, dispatchToProps)(AppAlert);

