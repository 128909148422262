export const COMMODITY = 'commodity';
export const USER_ADD_EDIT = 'userAddEdit';
export const EQUIPMENT_ADD_EDIT = 'equipmentAddEdit';
export const EQUIPMENT_TYPE_ADD_EDIT = 'equipmentTypeAddEdit';
export const PLAN_ADD_EDIT = 'planAddEdit';
export const CONTRACT_ADD_EDIT = 'contractAddEdit';
export const CONTRACT_RECEIPT = 'contractReceipt';
export const CONTRACT_RETURN = 'contractReturn';
export const SET_UP = 'setUp';
export const SEARCH_CAPITALS = 'searchCapitals';
export const SEARCH_DISPATCHES = 'searchDispatches';
export const SEARCH_DISPATCH_DISPLAY = 'searchDispatchDisplay';
export const CAPITALS_ADD_EDIT = 'capitalsAddEdit';
export const DISPATCHES_ADD_EDIT = 'dispatchesAddEdit';
export const DISPATCHES_WORK_ADD_EDIT = 'dispatchesWorkAddEdit';
export const ASSIGN_PLAN_ADD_EDIT = 'assignPlanAddEdit';
export const ASSIGN_PLAN_ITEM_ADD_EDIT = 'assignPlanItemAddEdit';
export const ATTENDEE_SEARCH = 'attendeeSearch';
export const ATTENDEE_ADD_EDIT = 'attendeeAddEdit';
export const CHECK_IN = 'checkin';
export const SALARIES_SEARCH = 'salariesSearch';
export const SALARIES_FIELD_CHANGE = 'salariesFieldChange';
export const WAREHOUSE_ADD_EDIT = 'warehouseAddEdit';
export const WAREHOUSE_NUMBER_ADD_REMOVE = 'warehouseNumberAddRemove';
export const WAREHOUSE_EXPORT_EXCEL = 'warehouseExportExcel';

