import * as types from './types';
export const createAction = type => payload => ({ payload, type });

export const createAsyncAction = type => Object.assign(
  createAction(type.PENDING),
  {
    error: payload => ({ error: true, payload, type: type.ERROR }),
    success: payload => ({ payload, type: type.SUCCESS })
  },
  { type }
);

export const login = createAsyncAction(types.LOGIN_ASYNC);
export const fetchLoginUserInfo = createAsyncAction(types.FETCH_LOGIN_USER_INFO_ASYNC);
export const uploadImage = createAsyncAction(types.UPLOAD_IMAGE_ASYNC);
export const upsertCommodity = createAsyncAction(types.UPSERT_COMMODITY_ASYNC);
export const fetchCommodities = createAsyncAction(types.FETCH_COMMODITIES_ASYNC);
export const fetchUsers = createAsyncAction(types.FETCH_USERS_ASYNC);
export const fetchEquipments = createAsyncAction(types.FETCH_EQUIPMENTS_ASYNC);
export const fetchContracts = createAsyncAction(types.FETCH_CONTRACTS_ASYNC);
export const upsertUser = createAsyncAction(types.UPSERT_USER_ASYNC);
export const upsertEquipment = createAsyncAction(types.UPSERT_EQUIPMENT_ASYNC);
export const upsertEquipmentType = createAsyncAction(types.UPSERT_EQUIPMENT_TYPE_ASYNC);
export const popupAlertDialog = createAsyncAction(types.POPUP_ALERT_DIALOG);
export const upsertContract = createAsyncAction(types.UPSERT_CONTRACT_ASYNC);
export const upsertPlan = createAsyncAction(types.UPSERT_PLAN_ASYNC);
export const fetchPlans = createAsyncAction(types.FETCH_PLANS_ASYNC);
export const upsertContractReceiptReturn = createAsyncAction(types.UPSERT_CONTRACT_RECEIPT_RETURN_ASYNC);
export const fetchContractReceipt = createAsyncAction(types.FETCH_CONTRACT_RECEIPT_ASYNC);
export const fetchContractReturn = createAsyncAction(types.FETCH_CONTRACT_RETURN_ASYNC);
export const upsertSetups = createAsyncAction(types.UPSERT_SETUPS_ASYNC);
export const fetchSetups = createAsyncAction(types.FETCH_SETUPS_ASYNC);
export const upsertCapital = createAsyncAction(types.UPSERT_CAPITAL_ASYNC);
export const fetchCapitals = createAsyncAction(types.FETCH_CAPITALS_ASYNC);
export const upsertDispatch = createAsyncAction(types.UPSERT_DISPATCH_ASYNC);
export const fetchDispatches = createAsyncAction(types.FETCH_DISPATCHES_ASYNC);
export const upsertDispatchWork = createAsyncAction(types.UPSERT_DISPATCH_WORK_ASYNC);
export const fetchDispatchWorks = createAsyncAction(types.FETCH_DISPATCH_WORKS_ASYNC);
export const fetchSearchPlans = createAsyncAction(types.FETCH_SEARCH_PLANS_ASYNC);
export const upsertAssignPlan = createAsyncAction(types.UPSERT_ASSIGN_PLAN_ASYNC);
export const fetchAssignPlans = createAsyncAction(types.FETCH_ASSIGN_PLANS_ASYNC);
export const upsertAssignPlanItem = createAsyncAction(types.UPSERT_ASSIGN_PLAN_ITEM_ASYNC);
export const fetchAssignPlanItems = createAsyncAction(types.FETCH_ASSIGN_PLAN_ITEMS_ASYNC);
export const fetchDispatchesByContract = createAsyncAction(types.FETCH_DISPATCHES_BY_CONTRACT_ASYNC);
export const fetchDispatchWorkDisplay = createAsyncAction(types.FETCH_DISPATCH_WORK_DISPLAY_ASYNC);
export const upsertAttendee = createAsyncAction(types.UPSERT_ATTENDEE_ASYNC);
export const fetchAttendees = createAsyncAction(types.FETCH_ATTENDEES_ASYNC);
export const fetchSalaries = createAsyncAction(types.FETCH_SALARIES_ASYNC);
export const upsertSalariesField = createAsyncAction(types.UPSERT_SALARIES_FIELD_ASYNC);
export const upsertWarehouse = createAsyncAction(types.UPSERT_WAREHOUSE_ASYNC);
export const fetchWarehouses = createAsyncAction(types.FETCH_WAREHOUSES_ASYNC);
export const changeWarehouseNumber = createAsyncAction(types.CHANGE_WAREHOUSE_NUMBER_ASYNC);
export const fetchWarehouseHistories = createAsyncAction(types.FETCH_WAREHOUSE_HISTORIES_ASYNC);
export const cloneContract = createAsyncAction(types.CLONE_CONTRACT_ASYNC);
export const upsertWarehouseHistory = createAsyncAction(types.UPSERT_WAREHOUSE_HISTORY_ASYNC);
export const warehouseExportExcel = createAsyncAction(types.WAREHOUSE_EXPORT_EXCEL_ASYNC);
export const upsertEquipmentMaintainHistory = createAsyncAction(types.UPSERT_EQUIPMENT_MAINTAIN_HISTORY_ASYNC);
export const fetchEquipmentMaintainHistories = createAsyncAction(types.FETCH_EQUIPMENT_MAINTAIN_HISTORIES_ASYNC);
export const checkIn = createAsyncAction(types.CHECK_IN);
export const fetchCheckIn = createAsyncAction(types.FETCH_CHECK_IN);
export const setIsLoading = createAction(types.SET_IS_LOADING);
export const setAppAlert = createAction(types.SET_APP_ALERT);
export const closeAppAlert = createAction(types.CLOSE_APP_ALERT);
export const clearPlans = createAction(types.CLEAR_PLANS);
export const clearSalaries = createAction(types.CLEAR_SALARIES);
export const editContractFilter = createAction(types.EDIT_CONTRACT_FILTER);
export const editContractTab = createAction(types.EDIT_CONTRACT_TAB);
export const clearWarehousesExcel = createAction(types.CLEAR_WAREHOUSES_EXCEL);
export const saveDispatchesSearchData = createAction(types.SAVE_DISPATCHES_SEARCH_DATA);
export const clearContractsForWarehouse = createAction(types.CLEAR_CONTRACTS_FOR_WAREHOUSE);
