import { takeLatest, put, race, take, select } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertCapital,
  fetchCapitals
} from '../actions';
import { upsertCapital as upsertCapitalApi } from '../api/graphql';
import moment from 'moment-timezone';
import { getLoginUserClientConfigTimezone } from '../selectors';

export function* upsertCapitalSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const timezone = yield select(getLoginUserClientConfigTimezone);
    const {
      formValues,
      history,
      upsertCapitalMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertCapital.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const {
      capitalId,
      date,
      type,
      subject,
      unit,
      amount,
      account,
      status,
      contract,
      note
    } = formValues;

    const dateTime = (Number.isInteger(date)) ? date : (moment(date).tz(timezone)
      .startOf('day')
      .unix());

    const request = {
      capitalId,
      date: dateTime,
      type,
      subject,
      unit,
      amount,
      account,
      status,
      contract,
      note
    };

    const res =
      yield upsertCapitalApi({ token, request, isDelete });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertCapitalMessage.success,
        variant: 'success'
      }));
      yield put(upsertCapital.success());
      yield put(fetchCapitals({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertCapitalMessage.error,
        variant: 'error'
      }));
    }
    history.push('/admin/capitals');
  } catch (err) {
    upsertCapital.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertCapital.type.PENDING, upsertCapitalSaga);
}
