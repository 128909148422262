import gql from 'graphql-tag';

export default gql`
    mutation upsertPlan(
        $token: String!
        $contractId: String!
        $request: [PlanInput]
        $correlationId: String!
    ){
        upsertPlan(
            token: $token
            contractId: $contractId
            correlationId: $correlationId
            request: $request
        ) {
            error
            message
        }
    }
`;
