import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEquipments, upsertWarehouse, fetchSetups } from '../../../actions';
import EquipmentsList from './WarehousesList';
import { getWarehouses, getSetups } from '../../../selectors';

const stateToProps = state => {
  const warehouses = getWarehouses(state);
  const setups = getSetups(state);
  const warehousesResult = (warehouses || []).map(warehouse => {
    const { number, warningQuantity } = warehouse || {};

    let status = '正常';
    if (warningQuantity && (warningQuantity > number)) {
      status = '超出预警';
    }
    return {
      ...warehouse,
      status
    };
  });
  return ({
    warehouses: warehousesResult,
    setups
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchEquipments,
  upsertWarehouse,
  fetchSetups
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(EquipmentsList);
