import { fromJS } from 'immutable';
import {
  setAllAccountAmount,
  setCapitals,
  setSelectedAccountAmount
} from '../selectors';
import {
  FETCH_CAPITALS_ASYNC
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [FETCH_CAPITALS_ASYNC.SUCCESS]: () => setCapitals(
    setAllAccountAmount(
      setSelectedAccountAmount(state, payload.selectedAccountAmount)
      , payload.allAccountAmount)
    , payload.capitals)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
