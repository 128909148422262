import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchEquipmentMaintainHistories } from '../actions';
import { getEquipmentMaintainHistories as getEquipmentMaintainHistoriesApi } from '../api/graphql';

export function* fetchEquipmentMaintainHistoriesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, equipmentId } = payload || {};

    const { equipmentMaintainHistories, message, error } =
      yield getEquipmentMaintainHistoriesApi({ token, equipmentId });

    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchEquipmentMaintainHistories.success(equipmentMaintainHistories));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchEquipmentMaintainHistories.type.PENDING, fetchEquipmentMaintainHistoriesSaga);
}
