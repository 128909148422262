/* eslint-disable max-lines */
import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import SalariesSearch from './SalariesSearch';
import SalariesLists from './SalariesLists';

const styles = () => ({
  searchArea: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  accountArea: {
    marginTop: '25px',
    marginLeft: '20px'
  },
  listArea: {
    marginTop: '25px'
  },
  root: {
    width: 150,
    marginRight: 20,
    marginTop: 20,
    backgroundColor: '#f3f3f4'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 17
  },
  headerArea: {
    marginLeft: '20px'
  }
});

const useStyles = makeStyles(styles);

export default function Dispatches(props) {
  const {
    fetchSetups,
    salaries,
    history,
    fetchUsers
  } = props;

  useEffect(() => {
    fetchSetups(
      {
        history
      });
    fetchUsers(
      {
        history
      });
  }, []);

  const classes = useStyles();

  return (

    <Fragment>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <SalariesSearch/>
        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          {salaries && salaries.length > 0 &&
            <SalariesLists/>
          }
        </Grid>
      </Grid>

    </Fragment>
  );
}

Dispatches.propTypes = {
  history: PropTypes.any
};
