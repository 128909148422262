import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const setEquipmentsList = (authState, payload) => authState.setIn(['equipmentsList'], fromJS(payload));
export const getEquipmentsList = createSelector(
  state => state.getIn(['equipments', 'equipmentsList'], List()),
  equipmentsList => (equipmentsList || List()).toJS()
);

export const setEquipmentTypesList = (authState, payload) => authState.setIn(['equipmentTypesList'], fromJS(payload));
export const getEquipmentTypesList = createSelector(
  state => state.getIn(['equipments', 'equipmentTypesList'], List()),
  equipmentTypesList => (equipmentTypesList || List()).toJS()
);

export const setEquipmentMaintainHistories = (authState, payload) =>
  authState.setIn(['equipmentMaintainHistories'], fromJS(payload));
export const getEquipmentMaintainHistories = createSelector(
  state => state.getIn(['equipments', 'equipmentMaintainHistories'], List()),
  equipmentMaintainHistories => (equipmentMaintainHistories || List()).toJS()
);
