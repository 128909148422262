/* eslint-disable max-lines */
import React from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import regularFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '../../../components/redux-form/TextField';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import { required } from '../../../lib/validation/validator';
import { Field } from 'redux-form/immutable';
import Button from '../../../components/CustomButtons/Button';
import useLocales from '../../../lib/i18n/useLocales';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import DatePicker from '../../../components/redux-form/DatePicker';
import AutoSuggest from '../../../components/redux-form/AutoSuggest';
import Dropdown from '../../../components/redux-form/Dropdown';
import { WORK_TYPE } from '../../../conf';

const DispatchWork = props => {
  const {
    dispatchWork,
    onClose,
    history,
    upsertDispatchWork,
    formValues,
    isWorker,
    onStartBiggerThanEnd,
    formStartDate,
    formEndDate,
    workers,
    equipmentTypeOptions,
    equipmentOptions
  } = props || {};

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      },
      submitButton: {
        alignItems: 'center'
      }
    }
  );

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { translate } = useLocales();

  const onSubmit = evt => {
    evt.preventDefault();
    upsertDispatchWork({
      dispatchWorkComplete: true,
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertDispatchMessage:
        { success: translate('upsertDispatch.success'), error: translate('upsertDispatch.error') }
    });
    onClose();
  };

  const {
    assignPlanName,
    contract: { name: contractName } = {},
    level1: { name: level1Name } = {},
    level2: { name: level2Name } = {},
    level3: { name: level3Name } = {}
  } = dispatchWork || {};

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="dispatchWorkAddEdit-dialog"
          title={'编辑派工完成数量'}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left">合同:</TableCell>
                  <TableCell align="left">{contractName}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left">计划:</TableCell>
                  <TableCell align="left">{assignPlanName}</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left">部件:</TableCell>
                  <TableCell align="left">{level1Name}</TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell align="left">零件:</TableCell>
                  <TableCell align="left">{level2Name}</TableCell>
                </TableRow>
                <TableRow key={5}>
                  <TableCell align="left">工序:</TableCell>
                  <TableCell align="left">{level3Name}</TableCell>
                </TableRow>

                <TableRow key={6}>
                  <TableCell align="left">开始时间:</TableCell>
                  <TableCell align="left">
                    <Field
                      disabled={isWorker}
                      fullWidth
                      onChange={evt => {
                        const changeStartDate = moment(evt).unix();
                        const endDate = Number.isInteger(formEndDate) ?
                          formEndDate : moment(formEndDate).unix();
                        if (changeStartDate > endDate) {
                          onStartBiggerThanEnd(evt);
                        }
                      }}
                      component={DatePicker}
                      name="startDate"
                      validate={[required]}
                      label={''}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={6}>
                  <TableCell align="left">结束时间:</TableCell>
                  <TableCell align="left">
                    <Field
                      disabled={isWorker}
                      fullWidth
                      minDate={formStartDate}
                      component={DatePicker}
                      name="endDate"
                      label={''}
                      validate={[required]}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={8}>
                  <TableCell align="left">工人:</TableCell>
                  <TableCell align="left">
                    <Field
                      disabled={isWorker}
                      fullWidth
                      component={AutoSuggest}
                      name="worker"
                      itemsToUse={workers}
                      validate={[required]}
                      label={''}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={9}>
                  <TableCell align="left">工作时间:</TableCell>
                  <TableCell align="left">
                    <Field
                      fullWidth
                      component={TextField}
                      name="workTime"
                      validate={[required]}
                      label={''}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={10}>
                  <TableCell align="left">班次:</TableCell>
                  <TableCell align="left">
                    <Field
                      disabled={isWorker}
                      fullWidth
                      name="workType"
                      component={Dropdown}
                      label={''}
                      options={WORK_TYPE.map(item => ({
                        id: item.id,
                        name: translate(`workType.${item.id}`)
                      }))}
                      showDefaultNullOption
                      validate={[required]}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={11}>
                  <TableCell align="left">设备类型:</TableCell>
                  <TableCell align="left">
                    <Field
                      disabled={isWorker}
                      label={''}
                      fullWidth
                      component={AutoSuggest}
                      name="equipmentType"
                      itemsToUse={equipmentTypeOptions}
                      validate={[required]}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={12}>
                  <TableCell align="left">设备:</TableCell>
                  <TableCell align="left">
                    <Field
                      disabled={isWorker}
                      label={''}
                      fullWidth
                      component={AutoSuggest}
                      name="equipment"
                      itemsToUse={equipmentOptions}
                      validate={[required]}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={13}>
                  <TableCell align="left">分配数量:</TableCell>
                  <TableCell align="left">
                    <Field
                      fullWidth
                      disabled={isWorker}
                      component={TextField}
                      name="dispatchNumber"
                      normalize={normalizeToFloat}
                      validate={[required]}
                      label={''}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={14}>
                  <TableCell align="left">完成数量:</TableCell>
                  <TableCell align="left">
                    <Field
                      fullWidth
                      component={TextField}
                      name="completeNumber"
                      normalize={normalizeToFloat}
                      validate={[required]}
                      label={''}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={14}>
                  <TableCell align="left">质检:</TableCell>
                  <TableCell align="left">
                    <Field
                      fullWidth
                      disabled={isWorker}
                      component={TextField}
                      name="quantityTest"
                      normalize={normalizeToFloat}
                      label={''}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={15}>
                  <TableCell align="left">备注:</TableCell>
                  <TableCell align="left">
                    <Field
                      fullWidth
                      component={TextField}
                      name="note"
                      label={''}
                    />
                  </TableCell>
                </TableRow>

                <TableRow key={15}>
                  <Button
                    onClick={evt => onSubmit(evt)}
                    className={classes.submitButton}
                    type="button"
                    color="info"
                  >
                    提交
                  </Button>
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

DispatchWork.propTypes = {
  history: PropTypes.any
};

export default DispatchWork;
