import gql from 'graphql-tag';

export default gql`
    mutation upsertSetups(
        $token: String!
        $request: [SetupsInput]
        $correlationId: String!
    ){
        upsertSetups(
            token: $token
            request: $request
            correlationId: $correlationId
        ) {
            error
            message
        }
    }
`;
