/* eslint-disable max-lines */
import React from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { Field, Form } from 'redux-form/immutable';
import regularFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import TextField from '../../../components/redux-form/TextField';
import { normalizeToFloat } from '../../../lib/validation/formatting';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import useLocales from '../../../lib/i18n/useLocales';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from '../../../components/redux-form/DatePicker';
import Dropdown from '../../../components/redux-form/Dropdown';
import { WORK_TYPE } from '../../../conf';

const AttendeeAddEdit = props => {
  const {
    attendeeId,
    onClose,
    history,
    upsertAttendee,
    formValues,
    pristine,
    invalid,
    workers
  } = props || {};

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      },
      submitButton: {
        alignItems: 'center'
      },
      bottomButton: {
        marginRight: '10px'
      }
    }
  );

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { translate } = useLocales();

  const onSubmit = evt => {
    evt.preventDefault();
    upsertAttendee({
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertAttendeeMessage:
        { success: '添加/修改考勤成功', error: '添加/修改考勤失败' }
    });
    onClose();
  };

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="attendeeWorkAddEdit-dialog"
          title={attendeeId ? '编辑考勤' : '创建考勤'}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  required
                  fullWidth
                  label={'日期'}
                  component={DatePicker}
                  name="date"
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="worker"
                  component={Dropdown}
                  showDefaultNullOption
                  label={translate('worker')}
                  options={workers}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="workType"
                  component={Dropdown}
                  label={'班次'}
                  options={WORK_TYPE.map(item => ({
                    id: item.id,
                    name: translate(`workType.${item.id}`)
                  }))}
                  showDefaultNullOption
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  name="workTime"
                  normalize={normalizeToFloat}
                  validate={[required]}
                  label={'工作时间'}
                />
              </GridItem>

              <GridItem xs={12}>
                <Button
                  className={classes.bottomButton}
                  onClick={() => onClose()}
                >
                  {translate('comeBack')}
                </Button>
                <Button
                  type={'submit'}
                  disabled={pristine || invalid}
                  className={classes.bottomButton}
                  color="info"
                >
                  {translate('submit')}
                </Button>

              </GridItem>

            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

AttendeeAddEdit.propTypes = {
  history: PropTypes.any
};

export default AttendeeAddEdit;
