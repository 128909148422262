import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertCapital,
  fetchPlans,
  upsertDispatchWork,
  fetchDispatchWorks
} from '../../../actions';
import DispatchWorkComplete from './DispatchWorkComplete';

import {
  getEquipmentsList,
  getLoginUserClientConfigTimezone,
  getSetups,
  getWorkers
} from '../../../selectors';
import { change, getFormValues, reduxForm } from 'redux-form/immutable';
import { DISPATCHES_WORK_ADD_EDIT as form } from '../../../lib/formNames';
import { Map } from 'immutable';
import moment from 'moment-timezone';

const stateToProps = (state, ownProps) => {
  const timezone = getLoginUserClientConfigTimezone(state);
  const formValues = (getFormValues(form)(state) || Map()).toJS();

  const dispatchWorkList = ownProps.dispatchWorkList;
  const editCreateDate = ownProps.editCreateDate;

  const dispatchWork = dispatchWorkList
    .find(item => item.createDate === editCreateDate) || {};

  const {
    contract: { id: contractId },
    assignPlan: { id: assignPlanId },
    level1: { id: level1Id },
    level2: { id: level2Id },
    level3: { id: level3Id },
    dispatchId,
    startDate: start,
    endDate: end,
    createDate,
    dispatchNumber,
    completeNumber,
    worker,
    note,
    workTime,
    workType,
    equipmentType,
    equipment,
    quantityTest
  } = dispatchWork || {};

  const startDate = start ? start : moment().tz(timezone)
    .startOf('day')
    .unix();
  const endDate = end ? end : moment().tz(timezone)
    .endOf('day')
    .unix();

  const initialValues = {
    contractId,
    assignPlanId,
    // assignPlanItemId,
    level1Id,
    level2Id,
    level3Id,
    dispatchId,
    startDate,
    endDate,
    createDate,
    worker,
    note,
    workTime,
    workType,
    equipmentType,
    equipment,
    quantityTest,
    dispatchNumber,
    completeNumber
  };

  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;
  const formEquipmentType = formValues.equipmentType;
  const workers = getWorkers(state);
  const setups = getSetups(state);
  const equipmentTypeOptions = setups.filter(item => item.type === 'equipmentType').map(item => ({
    value: item.setupId,
    label: item.name
  }));
  const equipments = getEquipmentsList(state);
  const equipmentOptions = (equipments || []).filter(item => item.type === formEquipmentType && item.status === 'use')
    .map(item => ({
      value: item.equipmentId,
      label: item.name
    }));

  return ({
    formValues,
    timezone,
    dispatchWork,
    initialValues,
    formStartDate,
    formEndDate,
    workers,
    equipmentTypeOptions,
    equipmentOptions
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertCapital,
  fetchPlans,
  upsertDispatchWork,
  fetchDispatchWorks,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(DispatchWorkComplete));
