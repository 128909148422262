import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertCapital } from '../../../actions';
import CapitalAddEdit from './CapitalAddEdit';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
import { CAPITALS_ADD_EDIT as form } from '../../../lib/formNames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

const stateToProps = (state, ownProps) => {
  const editCapital = ownProps.editCapital;
  const {
    account,
    amount,
    capitalId,
    contract,
    date,
    note,
    status,
    subject,
    type,
    unit,
    expired
  } = editCapital || {};
  const modeType = ownProps.modeType;
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const initialValues = {
    capitalId,
    date: date ? moment.unix(date).format('MM/DD/yyyy') : moment().format('MM/DD/yyyy'),
    type: type ? type : modeType,
    account,
    amount,
    contract,
    note,
    status: status ? status : '',
    subject,
    unit
  };
  return ({
    formValues,
    initialValues,
    expired
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertCapital
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(CapitalAddEdit));
