import gql from 'graphql-tag';

export default gql`
    query getPlans(
        $contractId: String!
        $correlationId: String!
        $token: String!
    ){
        getPlans(
            contractId: $contractId
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            plans {
                contractId
                key
                model
                note
                planQuantity
                deliveryNumber
                price
                quantity
                title
                unit
                deleted
                completeNumber
                dispatchNumber
                assignPlanNumber
                createTime
            }
        }
    }`;
