import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertDispatch,
  upsertDispatchWork
} from '../../../actions/index';
import DispatchDisplayLists from './DispatchDisplayLists';
import getDispatchWorkDisplayData from '../getDispatchWorkDisplayData';
import {
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone,
  getDispatchWorkDisplay,
  getWorkers,
  getEquipmentsList,
  getEquipmentType,
  getLoginUserPermissions,
  getLoginUserRole
} from '../../../selectors';
import { isTrue } from '../../../lib/booleanCheck';

const stateToProps = state => {
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];
  const timezone = getLoginUserClientConfigTimezone(state) || [];
  const dispatchWorkDisplay = getDispatchWorkDisplay(state) || [];
  const workers = getWorkers(state) || [];
  const equipmentTypes = getEquipmentType(state) || [];
  const equipmentList = getEquipmentsList(state) || [];
  const permissions = getLoginUserPermissions(state);
  const manageShowCompleteFunction =
    isTrue((permissions.find(permission => permission.id === 'manageShowCompleteFunction') || {}).value);
  const data = getDispatchWorkDisplayData({ dispatchWorkDisplay, workers, equipmentTypes, equipmentList });
  const isWorker = !manageShowCompleteFunction && getLoginUserRole(state) === 'worker';
  const dispatchWorkDisplayData = (data || []).filter(item => !!item.createDate);

  const dispatchWorkDisplayDataResult = (dispatchWorkDisplayData || []).map(item => {
    const { level1, level2, level3 } = item || {};
    const level1Name = (level1 || {}).name;
    const level2Name = (level2 || {}).name;
    const level3Name = (level3 || {}).name;

    let model = '';
    if (level1Name) {
      model = (level1 || {}).modelName;
    }
    if (level2Name) {
      model = (level2 || {}).modelName;
    }
    if (level3Name) {
      model = (level3 || {}).modelName;
    }
    return {
      ...item,
      model
    };
  });

  return ({
    isWorker,
    timezone,
    planHierarchy,
    manageShowCompleteFunction,
    dispatchWorkDisplayData: dispatchWorkDisplayDataResult
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertDispatch,
  upsertDispatchWork
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(DispatchDisplayLists);
