import { connect } from 'react-redux';
import Loading from './Loading';
import { isLoading } from '../../../selectors';

const stateToProps = state => ({
  isLoading: isLoading(state)
});

export default connect(stateToProps)(Loading);

