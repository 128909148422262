import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchContracts, fetchPlans, fetchContractReturn,
  upsertContractReceiptReturn } from '../../../actions';
import ContractReturn from './ContractReturn';
import { getContracts, getContractReturn, getEditPlans } from '../../../selectors';
import NP from 'number-precision';

const stateToProps = (state, ownProps) => {
  const contractId = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.contractId;
  const contracts = getContracts(state);
  const returns = getContractReturn(state);
  const { name } = contracts.find(item => item.contractId === contractId) || {};
  const plans = getEditPlans(state);
  const contractTotalPrice = plans.reduce((accum, item) => {
    const { planQuantity = 0, price = 0 } = item || {};
    return NP.plus(NP.times(planQuantity || 0, price || 0), accum);
  }, 0);
  const totalReturnAmount = returns.reduce((accum, item) => {
    const { amount } = item || {};
    return NP.plus(amount || 0, accum);
  }, 0);

  return ({
    contractId,
    returns,
    name,
    contractTotalPrice,
    totalReturnAmount
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  fetchPlans,
  fetchContractReturn,
  upsertContractReceiptReturn
}, dispatch);

export default connect(stateToProps, dispatchToProps)(ContractReturn);
