import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SortIcon from '@material-ui/icons/Sort';
import CloseIcon from '@material-ui/icons/Clear';
import DividerWithSpacing from '../ui/DividerWithSpacing';

const styles = ({ spacing }) => ({
  title: {
    fontFamily: '"Roboto Slab", Roboto',
    marginLeft: spacing.unit * 1,
    marginTop: spacing.unit * 1
  },
  root: {
    marginBottom: spacing.unit * 2,
    backgroundColor: 'rgba(207,209,213,0.27)'
  }
});

const WidgetHeader = ({ classes, onEdit = null, title, onSort = null, isAdd, isEdit, isClose }) => (
  <div className={classes.root}>
    <Grid
      container
      wrap={'nowrap'}
      justify={'space-between'}
      alignItems={'center'}
    >
      <Grid item>
        <Typography
          variant={'subtitle1'}
          className={classes.title}
          color={'primary'}
        >
          {' '}
          {title}{' '}
        </Typography>{' '}
      </Grid>{' '}
      <Grid item>
        <Grid container>
          {' '}
          {typeof onSort === 'function' && (
            <Grid item>
              <IconButton onClick={onSort} color={'secondary'}>
                <SortIcon />
              </IconButton>{' '}
            </Grid>
          )}{' '}
          {typeof onEdit === 'function' && (
            <Grid item>
              <IconButton onClick={onEdit} color={'default'}>
                {' '}
                {isAdd && <AddIcon />}{' '}
                {isEdit && <EditIcon />}{' '}
                {isClose && <CloseIcon />}{' '}
              </IconButton>{' '}
            </Grid>
          )}{' '}
        </Grid>{' '}
      </Grid>{' '}
    </Grid>{' '}
    <DividerWithSpacing />
  </div>
);

export default withStyles(styles)(WidgetHeader);
