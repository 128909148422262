import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const setAttendees = (capitalsState, payload) => capitalsState.setIn(['attendeesLists'], fromJS(payload));
export const getAttendees = createSelector(
  state => state.getIn(['attendees', 'attendeesLists'], List()),
  attendeesLists => ((attendeesLists || List()).toJS() || [])
    .sort(
      (
        { date: dateA, createDate: createDateA },
        { date: dateB, createDate: createDateB }
      ) => (`${dateA}:${createDateA}` < `${dateB}:${createDateB}` ? 1 : -1))
);
