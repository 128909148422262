/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import { TextField as MaterialTextField } from '@material-ui/core';
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AvatarMUI from '@material-ui/core/Avatar';
import CardContentMUI from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMUI from '@material-ui/core/Card';
import CardHeaderMUI from '@material-ui/core/CardHeader';
import TextField from '../../../components/redux-form/TextField';
import { required } from '../../../lib/validation/validator';
import { Field } from 'redux-form/immutable';
import EditIcon from '@material-ui/icons/Edit';
import EmptyBox from '../../../components/ui/EmptyBox';
import { getTreeData } from '../../../lib/getTreeData';
import NP from 'number-precision';
import { currency } from '../../../lib/getCurrencyAmount';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = () => ({
  label: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    fontWeight: 'bold'
  },
  submitButton: {
    textAlign: 'right'
  },
  title: {
    marginTop: '20px'
  },
  cardRoot: {
    width: '100%',
    backgroundColor: '#f3f3f4',
    marginRight: '20px'
  },
  buttonGroup: {
    textAlign: 'right',
    marginBottom: '20px'
  },
  button: {
    marginRight: '10px'
  },
  addPlanGroup: {
    marginTop: '30px',
    textAlign: 'right'
  },
  icon: {
    color: '#bdbdbd',
    fontSize: '100px'
  },
  emptyPlan: {
    marginTop: '100px'
  }
});

const useStyles = makeStyles(styles);

const PlanAddEdit = props => {
  // const [useExpandNotes, setUseExpandNotes] = useState(false);
  // const [expandNodes, setExpandNodes] = useState([]);
  const [selectNode, setSelectNode] = useState(null);
  const [search, setSearch] = useState(null);
  const selectLayer = ((selectNode || '').split('-') || []).length;

  const {
    history,
    formValues,
    onAddNode,
    onDeleteNode,
    pristine,
    invalid,
    upsertPlan,
    contractId,
    fetchPlans,
    contractTotalPrice,
    name,
    fetchContracts,
    maxHierarchy,
    onChangeLevel1PlanQuantity
  } = props || {};

  const currentHierarchy = ((selectNode || '').split('-') || []).length;

  const onSubmit = () => {
    upsertPlan({
      contractId,
      formValues,
      history,
      upsertPlanMessage: { success: translate('upsertPlan.success'), error: translate('upsertPlan.error') }
    });
  };

  let planData = (formValues || {}).planData || [];
  const originalPlanData = planData;

  if (search) {
    const searchPlanData = (planData || []).filter(item => {
      const { price, title, model } = item || {};
      const findIt =
          (`${price}` || '').includes(search)
          ||
          (`${title}` || '').includes(search)
          ||
          (`${model}` || '').includes(search);
      return findIt;
    });

    const relatedData = [];

    // eslint-disable-next-line array-callback-return
    searchPlanData.map(item => {
      const { key } = item || {};
      const level = (key || '').split('-');
      const [level1, level2] = level || [];
      const bjKey = level1;
      const ljKey = `${level1}-${level2}`;
      if (level.length === 1) {
        relatedData.push(item);
      } else if (level === 2) {
        const bj = planData.find(each => `${each.key}` === `${bjKey}`);
        relatedData.push(item);
        if (bj) {
          relatedData.push(bj);
        }
      } else {
        const bj = planData.find(each => `${each.key}` === `${bjKey}`);
        const lj = planData.find(each => `${each.key}` === `${ljKey}`);
        if (bj) {
          relatedData.push(bj);
        }
        if (lj) {
          relatedData.push(lj);
        }
        relatedData.push(item);
      }
    });

    const searchResult = (relatedData || []).reduce((accum, item) => {
      const { key } = item || {};
      if (!accum.find(acc => `${acc.key}` === `${key}`)) {
        accum.push(item);
      }
      return accum;
    }, []);

    planData = searchResult;
  }
  const selectObject = (planData || []).find(item => item.key === selectNode) || {};
  const { price = 0, planQuantity = 0, planQuantity: selectPlanQuantity } = selectObject || {};
  const totalPrice = NP.times(price || 0, planQuantity || 0);

  let selectNodeIndex = 0;
  // eslint-disable-next-line array-callback-return
  originalPlanData.map((item, idx) => {
    if (item.key === selectNode) {
      selectNodeIndex = idx;
    }
  });

  useEffect(() => {
    fetchPlans(
      {
        history,
        contractId
      });
    fetchContracts(
      {
        history
      });
  }, []);

  const classes = useStyles();

  const { translate } = useLocales();

  const displayData = getTreeData(planData, false, true);

  const onSelect = selectedKeys => {
    const key = (selectedKeys || [])[0];
    setSelectNode(key);
  };

  // const onExpand = expandedKeys => {
  //   setUseExpandNotes(true);
  //   setExpandNodes(expandedKeys);
  // };

  const onAdd = () => {
    const len = selectNode ? (selectNode || '').split('-').length : 0;
    const allChildren = (planData || []).filter(item => {
      const { key } = item || {};
      if (!selectNode) {
        return key.indexOf('-') === -1;
      }
      return key !== selectNode
        && key.startsWith(`${selectNode}-`)
        && ((key || '').split('-') || []).length === len + 1;
    });
    const start = selectNode ? selectNode : '';
    const biggestNode = allChildren.reduce((accum, childNode) => {
      const { key } = childNode || {};
      const keyArray = ((key || '').split('-') || []);
      const end = keyArray[keyArray.length - 1];
      if (end && !isNaN(end) && (parseInt(end) > accum)) {
        return parseInt(end);
      }
      return accum;
    }, 0);
    const newNodeKey = start ? `${start}-${biggestNode + 1}` : biggestNode + 1;
    // let allChildren;
    // if (!selectNode) {
    //   allChildren = (planData || []).filter(item =>
    //     ((item.key || '').split('-').length === 1));
    // } else {
    //   allChildren = (planData || []).filter(item =>
    //     ((item.key || '').indexOf(selectNode) !== -1)
    //     &&
    //     ((item.key || '').split('-').length === len + 1));
    // }
    // const newNodeKey = selectNode ?
    //   `${selectNode}-${(allChildren || []).length}`
    //   : (allChildren || []).length;
    const number = (selectLayer !== 0) ? selectPlanQuantity : null;
    onAddNode(newNodeKey, number);
  };

  const onDelete = () => {
    const planDataAfterDelete = planData.map(item => {
      const { key } = item || {};

      if (key.startsWith(`${selectNode}-`) || key === selectNode) {
        return { ...item, deleted: true };
      }
      return item;
    }, []);
    onDeleteNode(planDataAfterDelete);
    setSelectNode('');
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/admin/contracts')}
              color="info">
              <ArrowBackIosIcon/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {name}
              ({currency(contractTotalPrice)})
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify={'space-between'}>

              <GridItem xs={12}>
                <MaterialTextField
                  style={{
                    marginBottom: 20,
                    marginTop: 20
                  }}
                  onChange={evt => {
                    setSearch(evt.target.value);
                  }}
                  value={search}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">
                      {'🔍'}</InputAdornment>
                  }}
                />
              </GridItem>

              <GridItem xs={3}>

                {planData && planData.length === 0 &&
                <GridContainer spacing={16} jusify="center" alignItems="center" direction="column">
                  <GridItem>
                    <Typography className={classes.emptyPlan}>
                      {translate('emptyPlan')}
                    </Typography>
                  </GridItem>
                </GridContainer>
                }

                <Tree
                  switcherIcon={<DownOutlined />}
                  // expandedKeys={
                  //   useExpandNotes ? expandNodes :
                  //     (planData || []).map(item => item.key)}
                  // onExpand={onExpand}
                  onSelect={onSelect}
                  treeData={displayData || []}
                  showLine={{ showLeafIcon: false }}
                />
              </GridItem>
              <GridItem xs={6}>
                <GridContainer style={{ position: 'sticky', top: 35, zIndex: 1 }} justify={'space-between'}>
                  <CardMUI className={classes.cardRoot}>
                    {selectNode &&
                      <CardHeaderMUI
                        title={translate('planAddEdit')}
                        subheader={translate('planAddDescription')}
                        avatar={
                          <AvatarMUI aria-label="recipe" className={classes.avatar}>
                            <EditIcon/>
                          </AvatarMUI>
                        }
                      />
                    }
                    <CardContentMUI>
                      <GridContainer>

                        {!selectNode &&
                          <EmptyBox
                            title={translate('planEmptyTitle')}
                            message={translate('planEmptyMessage')}/>
                        }

                        {selectNode &&
                            <Fragment>
                              <GridItem xs={6}>
                                <Field
                                  fullWidth
                                  name={`planData.${selectNodeIndex}.title`}
                                  component={TextField}
                                  label={translate('planForm.title')}
                                  validate={[required]}
                                />
                              </GridItem>
                              <GridItem xs={6}>
                                <Field
                                  fullWidth
                                  name={`planData.${selectNodeIndex}.model`}
                                  component={TextField}
                                  label={translate('planForm.model')}
                                />
                              </GridItem>
                              <GridItem xs={6}>
                                <Field
                                  fullWidth
                                  name={`planData.${selectNodeIndex}.unit`}
                                  component={TextField}
                                  label={translate('planForm.unit')}
                                />
                              </GridItem>
                              {selectLayer === 1 &&
                                <GridItem xs={6}>
                                  <Field
                                    fullWidth
                                    name={`planData.${selectNodeIndex}.price`}
                                    component={TextField}
                                    label={translate('planForm.price')}
                                  />
                                </GridItem>
                              }
                              <GridItem xs={6}>
                                <Field
                                  onChange={evt => {
                                    const changedPlanData = (planData || []).map(item => {
                                      const { key } = item || {};
                                      if ((key || '').startsWith(selectNode)) {
                                        return { ...item, planQuantity: evt.target.value };
                                      }
                                      return item;
                                    });
                                    onChangeLevel1PlanQuantity(changedPlanData);
                                  }}
                                  disabled={selectLayer !== 1}
                                  fullWidth
                                  name={`planData.${selectNodeIndex}.planQuantity`}
                                  component={TextField}
                                  label={translate('planForm.planQuantity')}
                                />
                              </GridItem>
                              {selectLayer === 1 &&
                                <GridItem xs={6}>
                                  <TextField
                                    meta={{}}
                                    input={{
                                      disabled: true,
                                      label: translate('planForm.totalPrice'),
                                      value: totalPrice
                                    }}
                                  />
                                </GridItem>
                              }
                              <GridItem xs={6}>
                                <Field
                                  multiline={true}
                                  rows={5}
                                  fullWidth
                                  name={`planData.${selectNodeIndex}.note`}
                                  component={TextField}
                                  label={translate('planForm.note')}
                                />
                              </GridItem>
                            </Fragment>
                        }

                      </GridContainer>
                    </CardContentMUI>
                  </CardMUI>

                </GridContainer>

              </GridItem>
              <GridItem xs={3}>
                {!search &&
                  <GridItem xs={12} style={{ position: 'sticky', top: 35, zIndex: 1 }}>
                    <div className={classes.buttonGroup}>
                      {(maxHierarchy !== currentHierarchy) &&
                        <Button
                          className={classes.button}
                          color="success"
                          onClick={() => onAdd()}
                          round>
                          {translate('add')}
                        </Button>
                      }
                      {selectNode &&
                      <Button
                        className={classes.button}
                        color="danger"
                        onClick={() => onDelete()}
                        round>
                        {translate('delete')}
                      </Button>
                      }
                    </div>
                  </GridItem>
                }
              </GridItem>
              <GridItem xs={12}>
                <divhistory
                  className={classes.addPlanGroup}
                >
                  <Button
                    className={classes.button}
                    onClick={() => history.push('/admin/contracts')}
                  >
                    {translate('comeBack')}
                  </Button>
                  {!search &&
                    <Button
                      onClick={() => onSubmit()}
                      disabled={pristine || invalid}
                      className={classes.button}
                      color="info"
                    >
                      {translate('submit')}
                    </Button>
                  }
                </divhistory>

              </GridItem>

            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

PlanAddEdit.propTypes = {
  history: PropTypes.any
};

export default PlanAddEdit;
