import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
// eslint-disable-next-line no-undef
const langStorage = localStorage.getItem('i18nextLng');

const TextFieldInput = ({
  isCurrency,
  dataTid,
  errorMessage,
  helperText,
  label,
  name,
  required,
  fullWidth = true,
  variant = 'standard',
  margin = 'normal',
  value,
  ...rest
}) => (
  (
    <div data-bdd={dataTid}>
      <TextField
        value={value}
        fullWidth={fullWidth}
        variant={variant}
        margin={margin}
        name={name}
        label={`${label}${required ? ' *' : ''}`}
        error={!!errorMessage}
        helperText={errorMessage || helperText}
        InputProps={{
          startAdornment: <InputAdornment position="start">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isCurrency ? (langStorage === 'cn' ? '￥' : '$') : ''}</InputAdornment>
        }}
        {...rest}/>
    </div>
  ));

TextFieldInput.propTypes = {
  dataTid: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

TextFieldInput.defaultProps = {
  dataTid: undefined,
  errorMessage: undefined,
  required: false,
  status: 'default',
  type: 'text',
  value: ''
};

export default TextFieldInput;
