import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchContracts, fetchPlans, fetchContractReceipt, upsertContractReceiptReturn } from '../../../actions';
import ContractReceipt from './ContractReceipt';
import { getContracts, getContractReceipt, getEditPlans } from '../../../selectors';
import NP from 'number-precision';

const stateToProps = (state, ownProps) => {
  const contractId = ownProps && ownProps.match && ownProps.match.params && ownProps.match.params.contractId;
  const contracts = getContracts(state);
  const receipts = getContractReceipt(state);
  const { name } = contracts.find(item => item.contractId === contractId) || {};
  const plans = getEditPlans(state);
  const contractTotalPrice = plans.reduce((accum, item) => {
    const { planQuantity = 0, price = 0 } = item || {};
    return NP.plus(NP.times(planQuantity || 0, price || 0), accum);
  }, 0);
  const totalReceiptAmount = receipts.reduce((accum, item) => {
    const { amount } = item || {};
    return NP.plus(amount || 0, accum);
  }, 0);
  return ({
    contractId,
    receipts,
    name,
    contractTotalPrice,
    totalReceiptAmount
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  fetchPlans,
  fetchContractReceipt,
  upsertContractReceiptReturn
}, dispatch);

export default connect(stateToProps, dispatchToProps)(ContractReceipt);
