/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@mui/material';
import DatePicker from '../../../../src/components/redux-form/DatePicker/DatePicker';

const History = props => {
  const {
    history,
    fetchEquipmentMaintainHistories,
    onClose,
    equipmentId,
    equipmentMaintainHistories,
    upsertEquipmentMaintainHistory
  } = props || {};

  const [isAdd, setIsAdd] = React.useState(false);
  const [maintainDate, setMaintainDate] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const [note, setNote] = React.useState(null);

  useEffect(() => {
    fetchEquipmentMaintainHistories({ equipmentId, history });
  }, []);

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'maintainDate',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'maintainDate',
      label: '设备维护日期',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'price',
      label: '价格',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: '明细',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'maintainDate',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <Tooltip title={'删除'}>
                <IconButton
                  onClick={() => {
                    upsertEquipmentMaintainHistory({
                      equipmentId,
                      maintainDate: value,
                      isDelete: true,
                      upsertEquipmentMaintainHistoryMessage:
                        { success: '删除设备历史记录成功！', error: '删除设备历史记录失败！' },
                      generalAlertText: { title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message') }
                    });
                  }}
                  color={'info'}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'lg'}
          isOpen={true}
          onClose={onClose}
          id="maintain-dialog"
          title= {'维修记录'}
          dialogActions={actionButtons()}
        >

          <GridContainer>

            <GridItem xs={12}>
              <Button
                color={'facebook'}
                onClick={() => {
                  if (isAdd) {
                    setMaintainDate(null);
                    setNote(null);
                    setPrice(null);
                  }
                  setIsAdd(!isAdd);
                } }
              >
                添加
              </Button>
            </GridItem>

            {isAdd &&
              <Fragment style={{ marginTop: 10 }}>
                <GridItem xs={3}>
                  <DatePicker
                    label="维修日期"
                    value={maintainDate}
                    onChange={value => setMaintainDate(value)}
                  />

                </GridItem>
                <GridItem xs={3}>
                  <TextField
                    label="价格"
                    variant="standard"
                    value={price}
                    onChange={evt => setPrice(evt.target.value)}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <TextField
                    variant="standard"
                    label="明细"
                    value={note}
                    onChange={evt => setNote(evt.target.value)}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <Button
                    onClick={() => {
                      upsertEquipmentMaintainHistory({
                        maintainDate,
                        note,
                        price,
                        equipmentId,
                        history,
                        upsertEquipmentMaintainHistoryMessage:
                          { success: '创建设备历史记录成功！', error: '创建设备历史记录失败！' },
                        generalAlertText: { title: translate('generalAlertText.title'),
                          message: translate('generalAlertText.message') }
                      });
                      setIsAdd(false);
                      setMaintainDate(null);
                      setNote(null);
                      setPrice(null);
                    }}
                    disabled={!price || !maintainDate}
                    color={'twitter'}
                  >
                     提交
                  </Button>
                </GridItem>
              </Fragment>
            }

            {equipmentMaintainHistories && equipmentMaintainHistories.length > 0 &&
              <GridItem xs={12}>
                <MUIDataTable
                  title={(
                    <GridContainer>
                    </GridContainer>
                  )}
                  data={equipmentMaintainHistories || []}
                  columns={renderColumns()}
                  options={{
                    setTableProps: () => ({
                      size: 'small'
                    }),
                    textLabels: MUI_DATA_TABLE_LABELS,
                    responsive: 'stacked',
                    elevation: 0,
                    selectableRows: 'none',
                    rowsPerPage: 50,
                    rowsPerPageOptions: [25, 50, 75, 100],
                    download: false,
                    print: false
                  }}
                />
              </GridItem>
            }

            {/*<GridItem xs={12}>*/}
            {/*  {warehouseHistories && warehouseHistories.length > 0 &&*/}
            {/*  <Timeline align="left">*/}
            {/*    {warehouseHistories.map(item => {*/}
            {/*      const { createDate, number, type, inOutNote } = item || {};*/}
            {/*      const date = moment.unix(createDate).tz(timezone)*/}
            {/*        .format('YYYY-MM-DD HH:mm');*/}
            {/*      const description = `在 ${date} ${(type === 'in') ? '入库' : '出库'} ${number}件`;*/}
            {/*      return (*/}
            {/*        <TimelineItem key={createDate}>*/}
            {/*          <TimelineOppositeContent>*/}
            {/*            <Typography color="textSecondary">{description}</Typography>*/}
            {/*          </TimelineOppositeContent>*/}
            {/*          <TimelineSeparator>*/}
            {/*            <TimelineDot color={(type === 'in') ? 'primary' : 'secondary'} />*/}
            {/*            <TimelineConnector />*/}
            {/*          </TimelineSeparator>*/}
            {/*          <TimelineContent>*/}
            {/*            <Typography>{inOutNote}</Typography>*/}
            {/*          </TimelineContent>*/}
            {/*        </TimelineItem>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  </Timeline>*/}
            {/*  }*/}

            {/*</GridItem>*/}

          </GridContainer>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

History.propTypes = {
  history: PropTypes.any
};

export default History;
