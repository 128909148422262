export const getDispatchAssignAndCompleteData = (dispatch, assignPlanItems, dispatchWorkOtherRows) => {
  const { assignPlanItemId, level1Id, level2Id, level3Id, planNumber } = dispatch || {};

  const finalLevel = level3Id || level2Id || level1Id;

  const selectedAssignPlanItems = assignPlanItems
    .filter(item => item.assignPlanItemId === assignPlanItemId && !item.isMain);

  const assignSelectedAssignPlanItems = selectedAssignPlanItems.filter(item => {
    const { key } = item || {};
    return key.startsWith(`${finalLevel}-`) || key === finalLevel;
  });

  const lastLevelData = (assignSelectedAssignPlanItems || []).map(item => {
    const { key } = item || {};
    const dispatchCompleteNumber = dispatchWorkOtherRows.reduce((accum, row) => {
      if (row.key === key) {
        return accum + row.completeNumber || 0;
      }
      return accum;
    }, 0);

    const dispatchAssignNumber = dispatchWorkOtherRows.reduce((accum, row) => {
      if (row.key === key) {
        return accum + row.dispatchNumber || 0;
      }
      return accum;
    }, 0);
    const sortKey = ((key || '').split('-') || []).length;
    return ({
      ...item,
      dispatchPlanNumber: planNumber,
      dispatchCompleteNumber,
      dispatchAssignNumber,
      sortKey
    });
  });

  const sortLevelData = (lastLevelData || [])
    .sort((alpha, bate) => bate.sortKey - alpha.sortKey);

  // eslint-disable-next-line array-callback-return
  sortLevelData.map(item => {
    const { key, sortKey } = item || {};
    const filterData = sortLevelData.filter(eachData => {
      const { key: compareKey, sortKey: compareSortKey } = eachData;
      return compareKey !== key && compareKey.startsWith(key) && (compareSortKey === sortKey + 1);
    });

    if (filterData && filterData.length) {
      const initialDispatchAssignNumber = ((filterData || [])[0] || {}).dispatchAssignNumber || 0;
      const dispatchAssignNumber = filterData.reduce((accum, fData) => {
        if ((fData.dispatchAssignNumber || 0) < accum) {
          // eslint-disable-next-line no-param-reassign
          accum = fData.dispatchAssignNumber || 0;
        }
        return accum;
      }, initialDispatchAssignNumber);

      const initialCompleteNumber = ((filterData || [])[0] || {}).dispatchCompleteNumber || 0;
      const dispatchCompleteNumber = filterData.reduce((accum, fData) => {
        if ((fData.dispatchCompleteNumber || 0) < accum) {
          // eslint-disable-next-line no-param-reassign
          accum = fData.dispatchCompleteNumber || 0;
        }
        return accum;
      }, initialCompleteNumber);

      // eslint-disable-next-line no-param-reassign
      item.dispatchAssignNumber = dispatchAssignNumber;
      // eslint-disable-next-line no-param-reassign
      item.dispatchCompleteNumber = dispatchCompleteNumber;
    }
  });

  return sortLevelData;
};
