import gql from 'graphql-tag';

export default gql`
    query getDispatchWorks(
        $dispatchId: String
        $correlationId: String!
        $token: String!
    ){
        getDispatchWorks(
            dispatchId: $dispatchId
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            dispatchWorks {
                dispatchId
                startDate
                endDate
                note
                worker
                dispatchNumber
                completeNumber
                createDate
                workTime
                workType
                equipmentType
                equipment
                level1Id
                level2Id
                level3Id
                isMain
                key
                quantityTest
            }
        }
    }`;
