/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import { currency } from '../../../lib/getCurrencyAmount';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function CapitalLists(props) {
  const {
    history,
    onEditCapital,
    upsertCapital,
    capitalLists
  } = props;

  const [summaryRow, setSummaryRow] = useState(
    { capitalId: 'summary', dateDisplay: '总结', amount: 0 });

  const prevCapitalLists = usePrevious(capitalLists);

  useEffect(() => {
    if ((prevCapitalLists || []).length !== (capitalLists || []).length) {
      const initialAmount = capitalLists.reduce((accum, item) => {
        const { type: itemType, amount: itemAmount } = item || {};
        if (itemType === 'income') {
          return accum + itemAmount || 0;
        }
        return accum - itemAmount || 0;
      }, 0);
      setSummaryRow({ capitalId: 'summary', dateDisplay: '总结', amount: initialAmount });
    }
  }, [capitalLists]);

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'capitalId',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'dateDisplay',
      label: translate('capitalTable&Form.date'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (value === '总结' ?
          <div style={{ fontSize: 18 }}>
            <b>{value}</b>
          </div>
          : value)
      }
    },
    {
      name: 'type',
      label: translate('capitalTable&Form.type'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => translate(value)
      }
    },
    {
      name: 'subjectDisplay',
      label: translate('capitalTable&Form.subject'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'unit',
      label: translate('capitalTable&Form.unit'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'amount',
      label: translate('capitalTable&Form.amount'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => currency(value)
      }
    },
    {
      name: 'accountDisplay',
      label: translate('capitalTable&Form.account'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: translate('capitalTable&Form.status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => (value ? translate(`capitalStatus.${value}`) : '')
      }
    },
    {
      name: 'contractDisplay',
      label: translate('capitalTable&Form.contract'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: '备注',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'capitalId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          const capitalEntity = capitalLists.find(capital => capital.capitalId === value);
          return (
            value !== 'summary' &&
            <GridContainer wrap={'nowrap'}>
              {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
              <GridItem>
                <Tooltip title={translate('contractHint.edit')}>
                  <IconButton
                    onClick={() => {
                      onEditCapital(capitalEntity);
                    }}
                    color={'primary'}>
                    <EditIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
              {/*}*/}

              {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
              <GridItem>
                <Tooltip title={translate('contractHint.delete')}>
                  <IconButton
                    onClick={() => {
                      upsertCapital({
                        isDelete: true,
                        formValues: { capitalId: value },
                        history,
                        upsertCapitalMessage: {
                          success: translate('upsertCapital.success'),
                          error: translate('upsertCapital.error')
                        },
                        generalAlertText: {
                          title: translate('generalAlertText.title'),
                          message: translate('generalAlertText.message')
                        }
                      });
                    }}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </Tooltip>
              </GridItem>
              {/*}*/}

            </GridContainer>
          );
        }

      }
    }
  ];

  return (
    <MUIDataTable
      title={(
        <GridContainer>
        </GridContainer>
      )}
      data={[summaryRow, ...(capitalLists || [])] }
      columns={renderColumns()}
      options={{
        setTableProps: () => ({
          size: 'small'
        }),
        textLabels: MUI_DATA_TABLE_LABELS,
        responsive: 'stacked',
        elevation: 0,
        selectableRows: 'none',
        rowsPerPage: 50,
        rowsPerPageOptions: [25, 50, 75, 100],
        download: false,
        print: false,
        // eslint-disable-next-line id-length
        onFilterChange: (_, filter) => {
          // eslint-disable-next-line no-unused-vars
          const [[id], [date], [type], [subjectDisplay], [unit], [amount],
            [accountDisplay], [status], [contractDisplay], [note]]
            = filter;

          // eslint-disable-next-line no-nested-ternary
          const transferType = type ? ((type === '收入') ? 'income' : 'expense') : '';
          let transferStatus = '';
          if (status === '正常') {
            transferStatus = 'normal';
          }
          if (status === '无发票') {
            transferStatus = 'noReceipt';
          }
          if (status === '已开发票') {
            transferStatus = 'issued';
          }
          if (status === '未开发票') {
            transferStatus = 'unissued';
          }
          if (status === '已承兑') {
            transferStatus = 'accepted';
          }
          if (status === '未承兑') {
            transferStatus = 'unaccepted';
          }

          const summaryLists = capitalLists.filter(item => !date || (item.dateDisplay === date))
            .filter(item => !type || (item.type === transferType))
            .filter(item => !subjectDisplay || (item.subjectDisplay === subjectDisplay))
            .filter(item => !unit || (item.unit === unit))
            .filter(item => !accountDisplay || (item.accountDisplay === accountDisplay))
            .filter(item => !status || (item.status === transferStatus))
            .filter(item => !contractDisplay || (item.contractDisplay === contractDisplay))
            .filter(item => !note || (item.date === note));

          const summaryAmount = summaryLists.reduce((accum, item) => {
            const { type: itemType, amount: itemAmount } = item || {};
            if (itemType === 'income') {
              return accum + itemAmount || 0;
            }
            return accum - itemAmount || 0;
          }, 0);

          const newSummaryRow = {
            capitalId: 'summary',
            dateDisplay: '总结',
            type: transferType,
            subjectDisplay,
            unit,
            accountDisplay,
            status: transferStatus,
            contractDisplay,
            note,
            amount: summaryAmount
          };
          setSummaryRow(newSummaryRow);
        }
      }}
    />
  );
}

CapitalLists.propTypes = {
  history: PropTypes.any
};
