import gql from 'graphql-tag';

export default gql`
    query warehouseExportExcel(
        $startDate: Int!
        $endDate: Int!
        $warehouseType: String
        $type: String
        $correlationId: String!
        $token: String!
    ){
        warehouseExportExcel(
            startDate: $startDate
            endDate: $endDate
            warehouseType: $warehouseType
            type: $type
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            result {
                warehouseId
                company
                subject
                level1
                level1Number
                level2
                level2Number
                unit
                note
                position
                type
                name
                standard
                material
                model
                warehouseType
                number
                warningQuantity
                histories {
                    warehouseId
                    createDate
                    inOutNote
                    number
                    type
                }
            }
        }
    }`;
