import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../../components/redux-form/TextField';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../../lib/validation/validator';
import useLocales from '../../../../lib/i18n/useLocales';
import { normalizeFloatPositiveOnly } from '../../../../lib/validation/formatting';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';

const ContractReturnAddEdit = props => {
  const {
    history,
    formValues,
    returnR,
    upsertContractReceiptReturn,
    onClose,
    pristine,
    invalid
  } = props || {};

  const styles = () => ({
    modalAction: {
      marginRight: '12px'
    }
  });

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    // fetchEquipments(
    //   {
    //     history,
    //     fetchEquipmentsMessage:
    //       { success: translate('fetchEquipments.success'), error: translate('fetchEquipments.error') }
    //   });
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertContractReceiptReturn({
      formValues,
      history,
      upsertReceiptReturnMessage:
        { success: translate('upsertContractReceiptReturn.success'),
          error: translate('upsertContractReceiptReturn.error') }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        className={classes.modalAction}
        disabled={pristine || invalid}
        variant="contained"
        color="linkedin"
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="equipmentAddEdit-dialog"
          title= {returnR ? translate('editReturn') : translate('addReturn')}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>

              <GridItem xs={12}>
                <Field
                  keyboard
                  type={'date'}
                  component={TextField}
                  label={translate('date')}
                  name="date"
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="amount"
                  component={TextField}
                  label={translate('returnAmount')}
                  normalize={normalizeFloatPositiveOnly}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="note"
                  component={TextField}
                  label={translate('note')}
                />
              </GridItem>
            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

ContractReturnAddEdit.propTypes = {
  history: PropTypes.any
};

export default ContractReturnAddEdit;
