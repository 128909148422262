import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import ModalHeader from './ModalHeader';

const styles = ({ palette, spacing }) => ({
  content: {
    padding: spacing.unit * 3
  },
  contentDense: {
    padding: spacing.unit
  },
  contentNoPadding: {
    padding: 0
  },
  container: {
    alignItems: 'flex-start'
  },
  footer: {
    borderTop: `solid 1px ${palette.divider}`,
    paddingTop: spacing.unit * 2,
    paddingBottom: spacing.unit
  }
});

class Modal extends PureComponent {
  renderContentText = () => {
    const { contentText } = this.props;
    return contentText && (
      <DialogContentText>
        {contentText}
      </DialogContentText>
    );
  };

  renderDialogActions = () => {
    const { classes, dialogActions } = this.props;
    return dialogActions && (
      <DialogActions className={classes.footer}>
        {dialogActions}
      </DialogActions>
    );
  };

  render() {
    const {
      classes,
      children,
      id,
      isOpen,
      onClose,
      title,
      fullScreen,
      fixedTop,
      maxWidth = 'sm',
      contentNoPadding,
      contentDense,
      hideHeader
    } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={maxWidth}
        open={isOpen}
        onClose={onClose}
        aria-labelledby={id}
        classes={{
          container: fixedTop && classes.container
        }}
      >
        {!hideHeader &&
          <ModalHeader id={id} onClose={onClose}>{title}</ModalHeader>
        }
        <DialogContent className={classNames(classes.content, {
          [classes.contentDense]: contentDense,
          [classes.contentNoPadding]: contentNoPadding
        })}>
          {this.renderContentText()}
          {children}
        </DialogContent>
        {this.renderDialogActions()}
      </Dialog>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles)
)(Modal);
