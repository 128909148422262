import gql from 'graphql-tag';

export default gql`
    query getSalaries(
        $token: String!
        $correlationId: String!
        $year: String!
        $month: String!
        $startDate: Int!
        $endDate: Int!
        $employee: String
    ){
        getSalaries(
            correlationId: $correlationId
            token: $token
            year: $year
            month: $month
            startDate: $startDate
            endDate: $endDate
            employee: $employee
        ){
            error
            message
            salaries {
                salaryId
                userId
                userName
                realName
                livingAllowance
                transportAllowance
                insuranceAllowance
                employeeSalary
                hourSalary
                skillSalary
                dataControlHourSalary
                monthlyFoodAllowance
                monthlySkillSalary
                monthlyWorkFee
                dailySalary
                monthlyContractAmount
                award
                punishment
                allowance
            }
        }
    }`;
