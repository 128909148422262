import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchPlans,
  upsertAssignPlanItem,
  fetchAssignPlanItems
} from '../../../../../actions';
import PlanItemAddEdit from './PlanItemAddEdit';
import { reduxForm, getFormValues, change } from 'redux-form/immutable';
import { Map } from 'immutable';
import { ASSIGN_PLAN_ITEM_ADD_EDIT as form } from '../../../../../lib/formNames';
import {
  getEditPlans,
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone
} from '../../../../../selectors';

const renderOptions = (plans, key) => {
  const filterHierarchy = (key || '').split('-').length + 1;
  if (key === 'root') {
    return (plans || []).filter(plan =>
      ((plan.key || '').split('-').length === 1) && !plan.deleted)
      .map(item => ({
        value: item.key,
        label: item.title
      }));
  }
  return (plans || []).filter(plan =>
    ((plan.key || '').startsWith(key))
    && !plan.deleted && (plan.key !== key)
    && (plan.key || '').split('-').length === filterHierarchy)
    .map(item => ({
      value: item.key,
      label: item.title
    }));
};

const stateToProps = (state, ownProps) => {
  const contractId = ownProps.contractId;
  const assignPlanItems = ownProps.assignPlanItems;
  const editAssignPlanItemId = ownProps.editAssignPlanItemId;
  const assignPlanId = ownProps.assignPlanId;
  const selectedAssignPlanItem = (assignPlanItems || [])
    .find(item => item.assignPlanItemId === editAssignPlanItemId) || {};
  const {
    assignPlanItemId,
    level1: initialLevel1,
    level2: initialLevel2,
    level3: initialLevel3,
    planItemDispatchNumber
  } = selectedAssignPlanItem || {};

  const timezone = getLoginUserClientConfigTimezone(state);
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const plans = getEditPlans(state) || [];
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];

  const level1Options = renderOptions(plans, 'root');
  const level1 = formValues.level1;
  const level2Options = level1 ? renderOptions(plans, level1) : [];
  const level2 = formValues.level2;
  const level3Options = level2 ? renderOptions(plans, level2) : [];
  const level3 = formValues.level3;

  const initialValues = {
    contractId,
    assignPlanId,
    assignPlanItemId,
    level1: initialLevel1,
    level2: initialLevel2,
    level3: initialLevel3,
    planItemDispatchNumber
  };
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;

  return ({
    timezone,
    formValues,
    initialValues,
    plans,
    contractId,
    planHierarchy,
    formStartDate,
    formEndDate,
    level1,
    level2,
    level3,
    level1Options,
    level2Options,
    level3Options
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchPlans,
  onLevel1Clear: () => change(form, 'level1', null),
  onLevel2Clear: () => change(form, 'level2', null),
  onLevel3Clear: () => change(form, 'level3', null),
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate),
  upsertAssignPlanItem,
  fetchAssignPlanItems
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(PlanItemAddEdit));
