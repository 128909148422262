import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertEquipment, fetchPlans, upsertContractReceiptReturn } from '../../../../actions';
import ContractReturnAddEdit from './ContractReturnAddEdit';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';
import { CONTRACT_RETURN as form } from '../../../../lib/formNames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

const stateToProps = (state, ownProps) => {
  const createTime = ownProps.createTime;
  const contractId = ownProps.contractId;
  const returns = ownProps.returns;
  const returnObj = (returns || []).find(item => `${item.createTime}` === `${createTime}`);
  const { date, amount } = returnObj || {};

  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const initialValues = {
    date: date ? date : moment().format('YYYY-MM-DD'),
    type: 'return',
    contractId,
    amount,
    createTime
  };
  return ({
    formValues,
    initialValues
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertEquipment,
  fetchPlans,
  upsertContractReceiptReturn
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(ContractReturnAddEdit));
