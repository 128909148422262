/* eslint-disable max-lines */
import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/components/accordionStyle';
import TextField from '../../../components/redux-form/TextField';
import { normalizeFloatPositiveOnly } from '../../../lib/validation/formatting';
import { Field } from 'redux-form/immutable';
import Button from '../../../components/CustomButtons/Button';

export default function SalariesLists(props) {
  const {
    salaries,
    upsertSalariesField,
    history,
    pristine,
    formValues,
    salaryId
  } = props;

  const onSubmit = evt => {
    evt.preventDefault();
    upsertSalariesField({
      history,
      formValues,
      salaryId,
      upsertSalariesFieldMessage:
        { success: '更新工资信息成功', error: '更新工资信息失败' }
    });
  };

  useEffect(() => {

  }, []);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { translate } = useLocales();
  return (
    <Fragment>

      <Grid container justify={'right'} alignItems="right" spacing={24}>
        <Grid item xs={2}>
          <Button
            style={{ marginBottom: 20 }}
            disabled={pristine}
            onClick={evt => onSubmit(evt)}
            className={classes.searchButton}
            type="button"
            color="info"
          >
            {translate('submit')}
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>员工</TableCell>
              <TableCell align="right">基本工资</TableCell>
              <TableCell align="right">派工工资</TableCell>
              <TableCell align="right">包工工资</TableCell>
              <TableCell align="right">餐补</TableCell>
              <TableCell align="right">技能工资</TableCell>
              <TableCell align="right">夜班费</TableCell>
              <TableCell align="right">房补</TableCell>
              <TableCell align="right">交通补</TableCell>
              <TableCell align="right">社保</TableCell>
              <TableCell style={{ width: '7%' }} align="right">奖励</TableCell>
              <TableCell style={{ width: '7%' }} align="right">扣款</TableCell>
              <TableCell style={{ width: '7%' }} align="right">补贴</TableCell>
              <TableCell align="right">总计</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salaries.map(row => (
              <TableRow key={row.userId}>
                <TableCell component="th" scope="row">
                  {row.userName}
                </TableCell>
                <TableCell align="right">{row.employeeSalary}</TableCell>
                <TableCell align="right">{row.dailySalary}</TableCell>
                <TableCell align="right">{row.monthlyContractAmount}</TableCell>
                <TableCell align="right">{row.monthlyFoodAllowance}</TableCell>
                <TableCell align="right">{row.monthlySkillSalary}</TableCell>
                <TableCell align="right">{row.monthlyWorkFee}</TableCell>
                <TableCell align="right">{row.livingAllowance}</TableCell>
                <TableCell align="right">{row.transportAllowance}</TableCell>
                <TableCell align="right">{row.insuranceAllowance}</TableCell>
                <TableCell align="right" style={{ width: '7%' }}>
                  <Field
                    fullWidth
                    name={`${row.userId}:award`}
                    component={TextField}
                    label={''}
                    normalize={normalizeFloatPositiveOnly}
                  />
                </TableCell>
                <TableCell align="right" style={{ width: '7%' }}>
                  <Field
                    fullWidth
                    name={`${row.userId}:punishment`}
                    component={TextField}
                    label={''}
                    normalize={normalizeFloatPositiveOnly}
                  />
                </TableCell>
                <TableCell align="right" style={{ width: '7%' }}>
                  <Field
                    fullWidth
                    name={`${row.userId}:allowance`}
                    component={TextField}
                    label={''}
                    normalize={normalizeFloatPositiveOnly}
                  />
                </TableCell>
                <TableCell align="right">{row.summary}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container justify={'center'} alignItems="center" spacing={24}>
        <Grid item xs={2}>
          <Button
            style={{ marginTop: 20 }}
            disabled={pristine}
            onClick={evt => onSubmit(evt)}
            className={classes.searchButton}
            type="button"
            color="info"
          >
            {translate('submit')}
          </Button>
        </Grid>
      </Grid>

    </Fragment>

  );
}

SalariesLists.propTypes = {
  history: PropTypes.any
};
