import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEquipments, upsertEquipment } from '../../actions';
import Equipments from './Equipments';
import { getEquipmentsList } from '../../selectors';

const stateToProps = state => ({
  equipments: getEquipmentsList(state)
});

const dispatchToProps = dispatch => bindActionCreators({
  fetchEquipments,
  upsertEquipment
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Equipments);
