const kevin = require('./kevin');
const prod = require('./prod');

const env = process.env.REACT_APP_ENV;
// eslint-disable-next-line no-undef
const langStorage = localStorage.getItem('i18nextLng');

export const apiUrl = 'http://localhost:8000';
// export const apiUrl = 'http://198.11.175.110:8000';

export const config = (env === 'kevin') ? kevin : prod;

export const SUPER_ADMIN = [
  'xiaobabao0807@gmail.com'
];

const TEXT_LABELS = {
  body: {
    noMatch: '对不起! 没有对应的数据',
    toolTip: '种类',
    columnHeaderTooltip: column => `种类: ${column.label}`
  },
  pagination: {
    next: '下一页',
    previous: '上一页',
    rowsPerPage: '每页行数:',
    displayRows: '第'
  },
  toolbar: {
    search: '搜索',
    downloadCsv: '下载 CSV',
    print: '打印',
    viewColumns: '查看列',
    filterTable: '过滤',
    refresh: '刷新'
  },
  filter: {
    all: '全部',
    title: '过滤',
    reset: '重设'
  },
  viewColumns: {
    title: '显示列',
    titleAria: '展示/隐藏 表格列'
  },
  selectedRows: {
    text: '选中行',
    delete: '删除',
    deleteAria: '删除选中行'
  }
};

export const MUI_DATA_TABLE_LABELS = (langStorage === 'cn') ? TEXT_LABELS : '';

export const CLIENTS = [
  {
    id: 'prodTest',
    name: '线上测试环境',
    timezone: 'Asia/Hong_Kong',
    planHierarchy: [
      { level: 1, name: '部件' },
      { level: 2, name: '零件' },
      { level: 3, name: '工序' }
    ]
  },
  {
    id: 'ysck',
    name: '易思创科',
    timezone: 'Asia/Hong_Kong',
    planHierarchy: [
      { level: 1, name: '部件' },
      { level: 2, name: '零件' },
      { level: 3, name: '工序' }
    ]
  },
  {
    id: 'duiCloud',
    name: '杜伊云',
    timezone: 'America/Los_Angeles',
    planHierarchy: [
      { level: 1, name: '部件' },
      { level: 2, name: '零件' },
      { level: 3, name: '工序' }
    ]
  }
];

export const PERMISSIONS = [
  { id: 'manageUsers', subs: ['changePassword', 'changeUserRole'] },
  { id: 'manageEquipments', subs: [] },
  { id: 'manageContracts', subs: ['manageContractAddEdit', 'manageContractView', 'manageContractPlan',
    'manageContractReceipt', 'manageContractReturn' ] },
  { id: 'manageCapitals', subs: [] },
  { id: 'manageSetups', subs: [] },
  { id: 'manageDispatches', subs: [] },
  { id: 'manageDispatchesDisplay', subs: ['manageShowCompleteFunction'] },
  { id: 'manageAttendees', subs: [] },
  { id: 'manageSalaries', subs: [] },
  { id: 'manageCheckinWarehouse', subs: [] },
  { id: 'manageWarehouse', subs: [] }
];

export const USER_ROLE = [
  { id: 'employee', name: 'employee' },
  { id: 'admin', name: 'admin' },
  { id: 'worker', name: 'worker' }
];

export const SET_UP = [
  { id: 'capitalUnit', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'capitalSubject', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'capitalAccount', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'equipmentType', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseMaterialType', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseMaterialName', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseMaterialMaterial', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseStandardPartsName', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseStandardPartsMaterial', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseCuttingToolType', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseCuttingToolName', filed: [{ name: 'name', type: 'textField' }] },
  { id: 'warehouseCuttingToolMaterial', filed: [{ name: 'name', type: 'textField' }] }
];

export const SELECT_BY_DATE_TYPE = [
  { id: 'all' },
  { id: 'year' },
  { id: 'month' },
  { id: 'day' }
];

export const CAPITAL_STATUS = [
  { id: 'bank' },
  { id: 'noReceipt' },
  { id: 'issued' },
  { id: 'unissued' },
  { id: 'accepted' },
  { id: 'unaccepted' },
  { id: 'normal' }
];

export const WORK_TYPE = [
  { id: 'day' },
  { id: 'bigDay' },
  { id: 'night' },
  { id: 'bigNight' },
  { id: 'overTime' },
  { id: 'noon' }
];
