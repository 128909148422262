import { fork, all } from 'redux-saga/effects';
import login from './login';
import fetchLoginUserInfo from './fetchLoginUserInfo';
import uploadImage from './uploadImage';
import fetchUsers from './fetchUsers';
import upsertUser from './upsertUser';
import upsertEquipment from './upsertEquipment';
import upsertEquipmentType from './upsertEquipmentType';
import fetchEquipments from './fetchEquipments';
import upsertContract from './upsertContract';
import fetchContracts from './fetchContracts';
import upsertPlan from './upsertPlan';
import fetchPlans from './fetchPlans';
import upsertContractReceiptReturn from './upsertContractReceiptReturn';
import fetchContractReceipt from './fetchContractReceipt';
import fetchContractReturn from './fetchContractReturn';
import upsertSetups from './upsertSetups';
import fetchSetups from './fetchSetups';
import upsertCapital from './upsertCapital';
import fetchCapitals from './fetchCapitals';
import upsertDispatch from './upsertDispatch';
import fetchDispatches from './fetchDispatches';
import upsertDispatchWork from './upsertDispatchWork';
import fetchDispatchWorks from './fetchDispatchWorks';
import fetchSearchPlans from './fetchSearchPlans';
import upsertAssignPlan from './upsertAssignPlan';
import upsertAssignPlanItem from './upsertAssignPlanItem';
import fetchAssignPlans from './fetchAssignPlans';
import fetchAssignPlanItems from './fetchAssignPlanItems';
import fetchDispatchesByContract from './fetchDispatchesByContract';
import fetchDispatchWorkDispaly from './fetchDispatchWorkDispaly';
import upsertAttendee from './upsertAttendee';
import fetchAttendees from './fetchAttendees';
import fetchSalaries from './fetchSalaries';
import upsertSalariesField from './upsertSalariesField';
import upsertWarehouse from './upsertWarehouse';
import fetchWarehouses from './fetchWarehouses';
import changeWarehouseNumber from './changeWarehouseNumber';
import fetchWarehouseHistories from './fetchWarehouseHistories';
import cloneContract from './cloneContract';
import upsertWarehouseHistory from './upsertWarehouseHistory';
import warehouseExportExcel from './warehouseExportExcel';
import upsertEquipmentMaintainHistory from './upsertEquipmentMaintainHistory';
import fetchEquipmentMaintainHistories from './fetchEquipmentMaintainHistories';
import checkin from './checkin';
import fetchCheckin from './fetchCheckin';

// array of saga import functions
const sagas = [
  warehouseExportExcel,
  fetchContractReturn,
  fetchContractReceipt,
  upsertContractReceiptReturn,
  login,
  fetchLoginUserInfo,
  uploadImage,
  fetchUsers,
  upsertUser,
  upsertEquipment,
  fetchEquipments,
  upsertEquipmentType,
  upsertContract,
  fetchContracts,
  upsertPlan,
  fetchPlans,
  upsertSetups,
  fetchSetups,
  upsertCapital,
  fetchCapitals,
  upsertDispatch,
  fetchDispatches,
  upsertDispatchWork,
  fetchDispatchWorks,
  fetchSearchPlans,
  upsertAssignPlan,
  fetchAssignPlans,
  upsertAssignPlanItem,
  fetchAssignPlanItems,
  fetchDispatchesByContract,
  fetchDispatchWorkDispaly,
  upsertAttendee,
  fetchAttendees,
  fetchSalaries,
  upsertSalariesField,
  upsertWarehouse,
  fetchWarehouses,
  changeWarehouseNumber,
  fetchWarehouseHistories,
  cloneContract,
  upsertWarehouseHistory,
  upsertEquipmentMaintainHistory,
  fetchEquipmentMaintainHistories,
  checkin,
  fetchCheckin
];

export default function* root() {
  yield all(sagas.map(saga => fork(saga)));
}
