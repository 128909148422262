import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const ToggleSwitch = ({ input: { value, onChange }, label, className }) => {
  const isChecked = value === true || value === 'true';
  return (
    <FormControlLabel
      style={{ marginTop: 8 }}
      control={
        <Switch
          className={className}
          checked={isChecked}
          onChange={() => onChange(!isChecked)}
          value="true"
        />
      }
      label={label}
    />
  );
};

export default ToggleSwitch;
