/* eslint-disable max-lines */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertCapital,
  fetchPlans,
  upsertDispatch,
  fetchAssignPlanItems,
  fetchAssignPlans,
  fetchDispatchesByContract
} from '../../../actions';
import DispatchAddEdit from './DispatchAddEdit';
import { reduxForm, getFormValues, change } from 'redux-form/immutable';
import { Map, List, fromJS } from 'immutable';
import { DISPATCHES_ADD_EDIT as form } from '../../../lib/formNames';
import moment from 'moment-timezone';
import {
  getEditPlans,
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone,
  getAssignPlanItems,
  getAssignPlans,
  getDispatchWorkByContract, getWorkers
} from '../../../selectors';
import { getFinalContractTreeData } from '../../../lib/getFinalContractTreeData';
import { getFinalPlanTreeData } from '../../../lib/getFinalPlanTreeData';
import { getFinalPlanItemTreeData } from '../../../lib/getFinalPlanItemTreeData';

const setDefaultValues = values => (values);

const renderOptions = (plans, key) => {
  const filterHierarchy = (key || '').split('-').length + 1;
  if (key === 'root') {
    return (plans || []).filter(plan =>
      ((plan.key || '').split('-').length === 1) && !plan.deleted)
      .map(item => ({
        value: item.key,
        label: item.title
      }));
  }
  return (plans || []).filter(plan =>
    ((plan.key || '').startsWith(key))
    && !plan.deleted && (plan.key !== key)
    && (plan.key || '').split('-').length === filterHierarchy)
    .map(item => ({
      value: item.key,
      label: item.title
    }));
};

const stateToProps = (state, ownProps) => {
  const dispatchId = ownProps.dispatchId;
  const dispatches = ownProps.dispatches;
  const workers = getWorkers(state);
  const assignPlanItems = getAssignPlanItems(state);
  const dispatchWorksByContract = getDispatchWorkByContract(state);
  const assignPlans = getAssignPlans(state);
  const selectedDispatch = (dispatches || []).find(item => item.dispatchId === dispatchId) || {};

  const {
    contractId: cId,
    level1Id,
    level2Id,
    level3Id,
    startDate: sDate,
    endDate: eDate,
    planNumber,
    note,
    assignPlanId,
    assignPlanItemId,
    dispatchWorkType,
    contractAmount,
    contractComplete,
    contractCompleteDate: completeDate,
    contractCompleteWorker,
    additionalNotes: additionalNotesInit
  } = selectedDispatch || {};

  const timezone = getLoginUserClientConfigTimezone(state);
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const contractId = formValues.contract;
  const formPlanNumber = formValues.planNumber;
  const formContractCompleteDate = formValues.contractCompleteDate;
  const initialPlans = formValues.initialPlans || [];
  const plans = contractId ? getEditPlans(state) : [];
  const finalPlans = plans.length ? plans : initialPlans;

  const level1Options = renderOptions(finalPlans, 'root');
  const level1 = formValues.level1;
  const level2 = formValues.level2;
  const level3 = formValues.level3;
  const assignPlansOptions = assignPlans.map(item =>
    ({ ...item, label: item.assignPlanName, value: item.assignPlanId }));
  const assignPlan = formValues.assignPlan;
  const assignPlanItemsOptions = assignPlanItems.filter(item => item.assignPlanId === assignPlan && item.isMain)
    .map(item => ({ ...item, label: item.title, value: item.assignPlanItemId }));
  const assignPlanItem = formValues.assignPlanItem;

  const selectedAssignPlanItems = assignPlanItems
    .filter(item => item.assignPlanItemId === assignPlanItem && !item.isMain);
  const [selectedAssignPlanItemMainRow] = assignPlanItems
    .filter(item => item.assignPlanItemId === assignPlanItem && item.isMain);

  const { key } = selectedAssignPlanItemMainRow || {};
  const level = ((key || '').split('-') || []).length;
  const startWithLevel2 = key ? level === 1 : false;
  const startWithLevel3 = key ? level === 2 : false;

  // 合同数据
  const {
    contractPlanItemsPlainData,
    contractPlanItemsTreeData
  } = getFinalContractTreeData({ dispatchWorksByContract, contractId, plans });

  // 计划数据
  const assignPlanSummary = getFinalPlanTreeData({ dispatchWorksByContract, assignPlanItems, assignPlan, plans });

  // 计划项数据
  const { planItemsPlainData, planItemsTreeData } = getFinalPlanItemTreeData(
    { dispatchWorksByContract, selectedAssignPlanItems, assignPlanItem, plans });

  const level2Options = planItemsPlainData.filter(item => item.parentKey === key)
    .map(item => ({
      ...item,
      label: item.title,
      value: item.key
    }));
  const level3Options = planItemsPlainData.filter(item => item.parentKey === level2).map(item => ({
    ...item,
    label: item.title,
    value: item.key
  }));

  const startDate = moment().tz(timezone)
    .startOf('day')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('day')
    .unix();
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];

  const initialValues = {
    dispatchId,
    startDate: sDate ? sDate : startDate,
    endDate: eDate ? eDate : endDate,
    contractCompleteDate: completeDate,
    contract: cId,
    level1: level1Id,
    level2: level2Id,
    level3: level3Id,
    assignPlan: assignPlanId,
    assignPlanItem: assignPlanItemId,
    planNumber,
    note,
    dispatchWorkType: dispatchWorkType ? dispatchWorkType : 'assign',
    contractComplete: contractComplete ? contractComplete : 'no',
    contractAmount,
    contractCompleteWorker,
    additionalNotes: additionalNotesInit
    // formExistNumber: planNumber,
    // totalCurrentNumber: initTotalCurrentNumber,
    // totalNumber: initTotalNumber
  };
  const formStartDate = formValues.startDate;
  const additionalNotes = formValues.additionalNotes;
  const formEndDate = formValues.endDate;
  const formDispatchWorkType = formValues.dispatchWorkType;

  return ({
    timezone,
    formValues,
    initialValues,
    plans,
    level1,
    level2,
    level3,
    level2Options,
    level3Options,
    startWithLevel2,
    startWithLevel3,
    contractId,
    planHierarchy,
    level1Options,
    formStartDate,
    formEndDate,
    formPlanNumber,
    finalPlans,
    assignPlan,
    assignPlanItem,
    assignPlansOptions,
    assignPlanItemsOptions,
    planItemsTreeData,
    planItemsPlainData,
    assignPlanSummary,
    contractPlanItemsPlainData,
    contractPlanItemsTreeData,
    selectContractId: cId,
    formDispatchWorkType,
    formContractCompleteDate,
    workers,
    additionalNotes
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertCapital,
  fetchPlans,
  upsertDispatch,
  fetchAssignPlanItems,
  fetchAssignPlans,
  fetchDispatchesByContract,
  onChangeFiledValue: (filedName, value) => change(form, filedName, value),
  onLevel1Clear: () => change(form, 'level1', null),
  onLevel2Clear: () => change(form, 'level2', null),
  onLevel3Clear: () => change(form, 'level3', null),
  onAssignPlanClear: () => change(form, 'assignPlan', null),
  onAssignPlanItemClear: () => change(form, 'assignPlanItem', null),
  setTotalCurrentNumber: value => change(form, 'totalCurrentNumber', value),
  onAddNote: additionalNotes => change(form, 'additionalNotes',
    (additionalNotes || List()).push(fromJS(setDefaultValues('')))),
  setTotalNumber: value => change(form, 'totalNumber', value),
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(DispatchAddEdit));
