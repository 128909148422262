import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertCapital, fetchPlans, upsertDispatchWork } from '../../../actions';
import DispatchWorkAddEdit from './DispatchWorkAddEdit';
import { reduxForm, getFormValues, change } from 'redux-form/immutable';
import { Map } from 'immutable';
import { DISPATCHES_WORK_ADD_EDIT as form } from '../../../lib/formNames';
import moment from 'moment-timezone';
import {
  getEquipmentsList,
  getLoginUserClientConfigPlanHierarchy,
  getLoginUserClientConfigTimezone,
  getSetups
} from '../../../selectors';

const stateToProps = (state, ownProps) => {
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const timezone = getLoginUserClientConfigTimezone(state);
  const dispatchId = ownProps.dispatchId;
  const editDispatchWork = ownProps.editDispatchWork;
  const selectedDispatch = ownProps.selectedDispatch;
  const dispatchWorkMainRows = ownProps.dispatchWorkMainRows;
  const totalDispatchNumber = ownProps.totalDispatchNumber;
  const planItemsPlainData = ownProps.planItemsPlainData;
  const dispatchWork = dispatchWorkMainRows.find(item => item.createDate === editDispatchWork) || {};

  const {
    createDate,
    dispatchNumber,
    completeNumber,
    worker,
    note,
    startDate: start,
    endDate: end,
    workTime,
    workType,
    equipmentType,
    equipment,
    level1Id,
    level2Id,
    level3Id
  } = dispatchWork || {};

  const {
    contractId,
    planNumber,
    level1Id: dispatchLevel1,
    level2Id: dispatchLevel2,
    level3Id: dispatchLevel3,
    assignPlanId,
    assignPlanItemId,
    dispatchWorkType
  } = selectedDispatch || {};

  const startDate = start ? start : moment().tz(timezone)
    .startOf('day')
    .unix();
  const endDate = end ? end : moment().tz(timezone)
    .endOf('day')
    .unix();
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;
  const formDispatchNumber = formValues.dispatchNumber;
  const formEquipmentType = formValues.equipmentType;
  const formLevel1 = formValues.level1Id;
  const formLevel2 = formValues.level2Id;
  const formLevel3 = formValues.level3Id;

  const setups = getSetups(state);
  const equipmentTypeOptions = setups.filter(item => item.type === 'equipmentType').map(item => ({
    value: item.setupId,
    label: item.name
  }));
  const equipments = getEquipmentsList(state);

  const equipmentOptions = (equipments || []).filter(item => item.type === formEquipmentType && item.status === 'use')
    .map(item => ({
      value: item.equipmentId,
      label: item.name
    }));

  const key = dispatchLevel3 || dispatchLevel2 || dispatchLevel1;
  const level = ((key || '').split('-') || []).length;

  const startWithLevel1 = key ? level === 1 : false;
  const startWithLevel2 = key ? level === 2 : false;
  const startWithLevel3 = key ? level === 3 : false;

  const level1Options = !(startWithLevel2 || startWithLevel3) ? planItemsPlainData
    .filter(item => !item.parentKey)
    .map(item => ({
      ...item,
      label: item.title,
      value: item.key
    })) : [];

  const level2Options = !startWithLevel3 ? planItemsPlainData
    .filter(item => {
      if (startWithLevel2) {
        return !item.parentKey;
      }
      return item.parentKey === formLevel1;
    })
    .map(item => ({
      ...item,
      label: item.title,
      value: item.key
    })) : [];

  const level3Options = planItemsPlainData
    .filter(item => {
      if (startWithLevel3) {
        return !item.parentKey;
      }
      return item.parentKey === formLevel2;
    })
    .map(item => ({
      ...item,
      label: item.title,
      value: item.key
    }));

  const initialValues = {
    contractId,
    assignPlanId,
    assignPlanItemId,
    // eslint-disable-next-line no-nested-ternary
    level1Id: level1Id ? level1Id : (startWithLevel1 ? key : null),
    // eslint-disable-next-line no-nested-ternary
    level2Id: level2Id ? level2Id : (startWithLevel2 ? key : null),
    // eslint-disable-next-line no-nested-ternary
    level3Id: level3Id ? level3Id : (startWithLevel3 ? key : null),
    dispatchId,
    startDate,
    endDate,
    createDate,
    dispatchNumber,
    completeNumber,
    worker,
    note,
    formExistNumber: dispatchNumber,
    totalNumber: planNumber,
    totalCurrentNumber: totalDispatchNumber,
    workTime,
    workType,
    equipmentType,
    equipment
  };
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];

  return ({
    dispatchWorkType,
    formDispatchNumber,
    formValues,
    initialValues,
    formStartDate,
    formEndDate,
    formEquipmentType,
    formLevel1,
    formLevel2,
    formLevel3,
    equipmentTypeOptions,
    equipmentOptions,
    startWithLevel1,
    startWithLevel2,
    startWithLevel3,
    planHierarchy,
    level1Options,
    level2Options,
    level3Options
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertCapital,
  fetchPlans,
  upsertDispatchWork,
  onLevel1Clear: () => change(form, 'level1Id', null),
  onLevel2Clear: () => change(form, 'level2Id', null),
  onLevel3Clear: () => change(form, 'level3Id', null),
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(DispatchWorkAddEdit));
