import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../components/redux-form/TextField';
import Dropdown from '../../../components/redux-form/Dropdown';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from '../../../components/redux-form/DatePicker';

const EquipmentAddEdit = props => {
  const {
    history,
    fetchEquipments,
    formValues,
    equipmentTypeOptions,
    equipmentId,
    upsertEquipment,
    onClose,
    pristine,
    invalid
  } = props || {};

  const styles = () => ({
    modalAction: {
      marginRight: '12px'
    }
  });

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    fetchEquipments(
      {
        history,
        fetchEquipmentsMessage:
          { success: translate('fetchEquipments.success'), error: translate('fetchEquipments.error') }
      });
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertEquipment({
      formValues,
      history,
      upsertEquipmentMessage:
        { success: translate('upsertEquipment.success'), error: translate('upsertEquipment.error') }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={pristine || invalid}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  const EQUIPMENT_STATUS = [
    { id: 'use', name: translate('use') },
    { id: 'unuse', name: translate('unuse') }
  ];

  const IS_DATA_CONTROL = [
    { id: 'yes', name: '是' },
    { id: 'no', name: '否' }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'sm'}
          isOpen={true}
          onClose={onClose}
          id="equipmentAddEdit-dialog"
          title= {equipmentId ? translate('equipmentEdit') : translate('equipmentAdd')}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="name"
                  component={TextField}
                  label={translate('equipmentForm.name')}
                  validate={[required]}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="type"
                  options={equipmentTypeOptions}
                  component={Dropdown}
                  showDefaultNullOption
                  label={translate('equipmentForm.type')}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="status"
                  options={EQUIPMENT_STATUS}
                  component={Dropdown}
                  showDefaultNullOption
                  label={translate('equipmentForm.status')}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="isDataControl"
                  options={IS_DATA_CONTROL}
                  component={Dropdown}
                  showDefaultNullOption
                  label={'是否数控'}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  required
                  fullWidth
                  label={'购买时间'}
                  component={DatePicker}
                  name="purchaseDate"
                  validate={[required]}
                />
              </GridItem>

            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

EquipmentAddEdit.propTypes = {
  history: PropTypes.any
};

export default EquipmentAddEdit;
