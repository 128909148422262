export const asyncSuffixes = {
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS'
};

const createAsyncTypes = typeString => (
  Object.keys(asyncSuffixes).reduce((acc, suffixKey) => {
    const suffix = asyncSuffixes[suffixKey];

    return {
      [suffix]: `${typeString}_${suffix}`,
      ...acc
    };
  }, {})
);

export const LOGIN_ASYNC = createAsyncTypes('LOGIN_ASYNC');
export const FETCH_LOGIN_USER_INFO_ASYNC = createAsyncTypes('FETCH_LOGIN_USER_INFO_ASYNC');
export const UPLOAD_IMAGE_ASYNC = createAsyncTypes('UPLOAD_IMAGE_ASYNC');
export const UPSERT_COMMODITY_ASYNC = createAsyncTypes('UPSERT_COMMODITY_ASYNC');
export const FETCH_COMMODITIES_ASYNC = createAsyncTypes('FETCH_COMMODITIES_ASYNC');
export const FETCH_USERS_ASYNC = createAsyncTypes('FETCH_USERS_ASYNC');
export const POPUP_ALERT_DIALOG = createAsyncTypes('POPUP_ALERT_DIALOG');
export const UPSERT_USER_ASYNC = createAsyncTypes('UPSERT_USER_ASYNC');
export const UPSERT_EQUIPMENT_ASYNC = createAsyncTypes('UPSERT_EQUIPMENT_ASYNC');
export const UPSERT_EQUIPMENT_TYPE_ASYNC = createAsyncTypes('UPSERT_EQUIPMENT_TYPE_ASYNC');
export const FETCH_EQUIPMENTS_ASYNC = createAsyncTypes('FETCH_EQUIPMENTS_ASYNC');
export const FETCH_CONTRACTS_ASYNC = createAsyncTypes('FETCH_CONTRACTS_ASYNC');
export const UPSERT_CONTRACT_ASYNC = createAsyncTypes('UPSERT_CONTRACT_ASYNC');
export const UPSERT_PLAN_ASYNC = createAsyncTypes('UPSERT_PLAN_ASYNC');
export const FETCH_PLANS_ASYNC = createAsyncTypes('FETCH_PLANS_ASYNC');
export const UPSERT_CONTRACT_RECEIPT_RETURN_ASYNC = createAsyncTypes('UPSERT_CONTRACT_RECEIPT_RETURN_ASYNC');
export const FETCH_CONTRACT_RECEIPT_ASYNC = createAsyncTypes('FETCH_CONTRACT_RECEIPT_ASYNC');
export const FETCH_CONTRACT_RETURN_ASYNC = createAsyncTypes('FETCH_CONTRACT_RETURN_ASYNC');
export const UPSERT_SETUPS_ASYNC = createAsyncTypes('UPSERT_SETUPS_ASYNC');
export const FETCH_SETUPS_ASYNC = createAsyncTypes('FETCH_SETUPS_ASYNC');
export const UPSERT_CAPITAL_ASYNC = createAsyncTypes('UPSERT_CAPITAL_ASYNC');
export const FETCH_CAPITALS_ASYNC = createAsyncTypes('FETCH_CAPITALS_ASYNC');
export const UPSERT_DISPATCH_ASYNC = createAsyncTypes('UPSERT_DISPATCH_ASYNC');
export const FETCH_DISPATCHES_ASYNC = createAsyncTypes('FETCH_DISPATCHES_ASYNC');
export const UPSERT_DISPATCH_WORK_ASYNC = createAsyncTypes('UPSERT_DISPATCH_WORK_ASYNC');
export const FETCH_DISPATCH_WORKS_ASYNC = createAsyncTypes('FETCH_DISPATCH_WORKS_ASYNC');
export const FETCH_SEARCH_PLANS_ASYNC = createAsyncTypes('FETCH_SEARCH_PLANS_ASYNC');
export const UPSERT_ASSIGN_PLAN_ASYNC = createAsyncTypes('UPSERT_ASSIGN_PLAN_ASYNC');
export const FETCH_ASSIGN_PLANS_ASYNC = createAsyncTypes('FETCH_ASSIGN_PLANS_ASYNC');
export const UPSERT_ASSIGN_PLAN_ITEM_ASYNC = createAsyncTypes('UPSERT_ASSIGN_PLAN_ITEM_ASYNC');
export const FETCH_ASSIGN_PLAN_ITEMS_ASYNC = createAsyncTypes('FETCH_ASSIGN_PLAN_ITEMS_ASYNC');
export const FETCH_DISPATCHES_BY_CONTRACT_ASYNC = createAsyncTypes('FETCH_DISPATCHES_BY_CONTRACT_ASYNC');
export const FETCH_DISPATCH_WORK_DISPLAY_ASYNC = createAsyncTypes('FETCH_DISPATCH_WORK_DISPLAY_ASYNC');
export const UPSERT_ATTENDEE_ASYNC = createAsyncTypes('UPSERT_ATTENDEE_ASYNC');
export const FETCH_ATTENDEES_ASYNC = createAsyncTypes('FETCH_ATTENDEES_ASYNC');
export const FETCH_SALARIES_ASYNC = createAsyncTypes('FETCH_SALARIES_ASYNC');
export const UPSERT_SALARIES_FIELD_ASYNC = createAsyncTypes('FETCH_SALARIES_ASYNC');
export const UPSERT_WAREHOUSE_ASYNC = createAsyncTypes('UPSERT_WAREHOUSE_ASYNC');
export const FETCH_WAREHOUSES_ASYNC = createAsyncTypes('FETCH_WAREHOUSES_ASYNC');
export const CHANGE_WAREHOUSE_NUMBER_ASYNC = createAsyncTypes('CHANGE_WAREHOUSE_NUMBER_ASYNC');
export const FETCH_WAREHOUSE_HISTORIES_ASYNC = createAsyncTypes('FETCH_WAREHOUSE_HISTORIES_ASYNC');
export const CLONE_CONTRACT_ASYNC = createAsyncTypes('CLONE_CONTRACT_ASYNC');
export const UPSERT_WAREHOUSE_HISTORY_ASYNC = createAsyncTypes('UPSERT_WAREHOUSE_HISTORY_ASYNC');
export const UPSERT_EQUIPMENT_MAINTAIN_HISTORY_ASYNC = createAsyncTypes('UPSERT_EQUIPMENT_MAINTAIN_HISTORY_ASYNC');
export const WAREHOUSE_EXPORT_EXCEL_ASYNC = createAsyncTypes('WAREHOUSE_EXPORT_EXCEL_ASYNC');
export const FETCH_EQUIPMENT_MAINTAIN_HISTORIES_ASYNC = createAsyncTypes('FETCH_EQUIPMENT_MAINTAIN_HISTORIES_ASYNC');
export const CHECK_IN = createAsyncTypes('CHECK_IN');
export const FETCH_CHECK_IN = createAsyncTypes('FETCH_CHECK_IN');

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_APP_ALERT = 'SET_APP_ALERT';
export const CLOSE_APP_ALERT = 'CLOSE_APP_ALERT';
export const CLEAR_PLANS = 'CLEAR_PLANS';
export const CLEAR_SALARIES = 'CLEAR_SALARIES';
export const EDIT_CONTRACT_FILTER = 'EDIT_CONTRACT_FILTER';
export const EDIT_CONTRACT_TAB = 'EDIT_CONTRACT_TAB';
export const CLEAR_WAREHOUSES_EXCEL = 'CLEAR_WAREHOUSES_EXCEL';
export const SAVE_DISPATCHES_SEARCH_DATA = 'SAVE_DISPATCHES_SEARCH_DATA';
export const CLEAR_CONTRACTS_FOR_WAREHOUSE = 'CLEAR_CONTRACTS_FOR_WAREHOUSE';
