import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEquipments, upsertEquipment, fetchSetups } from '../../../actions';
import EquipmentsList from './EquipmentsList';
import { getEquipmentsList, getLoginUserClientConfigTimezone, getSetups } from '../../../selectors';

const stateToProps = state => {
  const equipmentTypes = getSetups(state);
  const timezone = getLoginUserClientConfigTimezone(state) || [];
  const equipmentTypeOptions = equipmentTypes.filter(item => item.type === 'equipmentType').map(item => ({
    id: item.setupId,
    name: item.name
  }));

  return ({
    equipments: getEquipmentsList(state),
    equipmentTypeOptions,
    timezone
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchEquipments,
  upsertEquipment,
  fetchSetups
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(EquipmentsList);
