import gql from 'graphql-tag';

export default gql`
    query getDispatches(
        $request: DispatchInput
        $correlationId: String!
        $token: String!
    ){
        getDispatches(
            request: $request
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            dispatches {
                dispatchId
                startDate
                endDate
                createDate
                level1 {
                    id
                    name
                    modelName
                }
                level2 {
                    id
                    name
                    modelName
                }
                level3 {
                    id
                    name
                    modelName
                }
                note
                planNumber
                completeNumber
                contract {
                    id
                    name
                }
                assignPlan {
                    id
                    name
                }
                assignPlanItem {
                    id
                    name
                }
                dispatchWorkType
                contractAmount
                contractComplete
                contractCompleteDate
                contractCompleteWorker
                additionalNotes
                dispatchWithCompleteAndAssign {
                    dispatchCompleteNumber
                    dispatchAssignNumber
                    assignPlanId
                    contractId
                    assignPlanItemId
                    key
                }
            }
        }
    }`;
