/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../../components/redux-form/TextField';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../../lib/validation/validator';
import useLocales from '../../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import regularFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import WidgetHeader from '../../../../components/ui/WidgetHeader';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import DatePicker from '../../../../components/redux-form/DatePicker';
import moment from 'moment-timezone';

const AssignPlansAddEdit = props => {
  const {
    modeType,
    history,
    formValues,
    onClose,
    pristine,
    invalid,
    onStartBiggerThanEnd,
    formStartDate,
    formEndDate,
    upsertAssignPlan,
    fetchDispatchesByContract,
    contractId,
    // contractPlanItemsPlainData,
    contractPlanItemsTreeData
  } = props || {};

  // const [useExpandNotes, setUseExpandNotes] = useState(false);
  // const [expandNodes, setExpandNodes] = useState([]);

  // const onExpand = expandedKeys => {
  //   setUseExpandNotes(true);
  //   setExpandNodes(expandedKeys);
  // };

  const styles = ({ spacing, palette, shape }) => (
    {
      modalAction: {
        marginRight: '12px'
      },
      marginTop: {
        marginTop: 10
      },
      ...regularFormsStyle,
      root: {
        padding: spacing.unit * 5,
        overflowY: 'scroll',
        height: '100%'
      },
      title: {
        fontFamily: '"Roboto Slab", Roboto'
      },
      grow: {
        flexGrow: 1
      },
      pageHeader: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: shape.borderRadius,
        position: 'relative'
      },
      link: {
        cursor: 'pointer'
      },
      widget: {
        padding: spacing.unit * 2,
        marginBottom: spacing.unit * 3
      },
      widgetNoPad: {
        padding: 0
      },
      widgetNoOverflow: {
        overflow: 'hidden'
      },
      infoHeader: {
        backgroundColor: palette.info.light,
        color: palette.info.contrastText,
        paddingLeft: spacing.unit * 2
      },
      leftIcon: {
        marginRight: spacing.unit / 2
      },
      leftBody: {
        paddingRight: spacing.unit * 4
      },
      rightBody: {
        paddingLeft: spacing.unit
      },
      drawerContent: {
        position: 'relative',
        height: `calc(100vh - ${spacing.unit * 8}px)`,
        overflow: 'hidden',
        webkitOverflowScrolling: 'touch',
        width: 600,
        maxWidth: '100%'
      },
      isComplete: {
        color: 'green'
      },
      notComplete: {
        color: 'red'
      },
      tableRoot: {
        '& > *': {
          borderBottom: 'unset'
        }
      }
    }
  );

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
    fetchDispatchesByContract({
      history,
      contractId
    });
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertAssignPlan({
      formValues,
      history,
      generalAlertText: { title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message') },
      upsertAssignPlanMessage:
        { success: translate('upsertAssignPlan.success'),
          error: translate('upsertAssignPlan.error') }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={pristine || invalid}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'xl'}
          isOpen={true}
          onClose={onClose}
          id="capitalAddEdit-dialog"
          title={translate(modeType)}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={8}>

                <Grid container spacing={24}>
                  <Grid item xs={12} className={classes.leftBody}>
                    <Paper elevation={3}>
                      <WidgetHeader title={translate('planDetail')} />
                      <div className={classes.widget}>
                        <GridContainer>
                          <GridItem xs={12}>
                            <Field
                              required
                              fullWidth
                              component={TextField}
                              name="assignPlanName"
                              validate={[required]}
                              label={translate('assignPlanName')}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <GridContainer>
                              <GridItem xs={12}>
                                <GridContainer>
                                  <GridItem xs={6} className={classes.marginTop}>
                                    <Field
                                      required
                                      fullWidth
                                      onChange={evt => {
                                        const changeStartDate = moment(evt).unix();
                                        const endDate = Number.isInteger(formEndDate)
                                          ? formEndDate : moment(formEndDate).unix();
                                        if (changeStartDate > endDate) {
                                          onStartBiggerThanEnd(evt);
                                        }
                                      }}
                                      label={translate('startDate')}
                                      component={DatePicker}
                                      name="startDate"
                                      validate={[required]}
                                    />
                                  </GridItem>

                                  <GridItem xs={6} className={classes.marginTop}>
                                    <Field
                                      required
                                      fullWidth
                                      minDate={formStartDate}
                                      label={translate('endDate')}
                                      component={DatePicker}
                                      name="endDate"
                                      validate={[required]}
                                    />
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12}>
                            <Field
                              fullWidth
                              component={TextField}
                              name="note"
                              label={translate('dispatchDetailForm&Table.note')}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} className={classes.leftBody}>
                <Paper elevation={3}>
                  <WidgetHeader title={translate('contractDetail')} />
                  <div className={classes.widget}>
                    <Tree
                      switcherIcon={<DownOutlined />}
                      // expandedKeys={
                      //   useExpandNotes ? expandNodes :
                      //     (contractPlanItemsPlainData || []).map(item => item.key)}
                      // onExpand={onExpand}
                      treeData={contractPlanItemsTreeData || []}
                      showLine={{ showLeafIcon: false }}
                    />
                  </div>
                </Paper>
              </Grid>

            </Grid>

          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

AssignPlansAddEdit.propTypes = {
  history: PropTypes.any
};

export default AssignPlansAddEdit;
