import gql from 'graphql-tag';

export default gql`
    query getAttendees(
        $token: String!
        $request: AttendeeInput
        $correlationId: String!
    ){
        getAttendees(
            request: $request
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            attendees {
                attendeeId
                workTime
                worker
                date
                createDate
                workType
            }
        }
    }`;
