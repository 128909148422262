export const getContractAssignAndCompleteData = (dispatchWorksByContract, id, type) => {
  let dispatchWorksFilter = [];

  if (type === 'assignPlanItem') {
    dispatchWorksFilter = dispatchWorksByContract.filter(item => item.assignPlanItemId === id);
  }

  if (type === 'contract') {
    dispatchWorksFilter = dispatchWorksByContract;
  }

  if (type === 'assignPlan') {
    dispatchWorksFilter = dispatchWorksByContract.filter(item => item.assignPlanId === id);
  }

  const result = (dispatchWorksFilter || []).reduce((accum, dispatch) => {
    const { dispatchCompleteNumber = 0, dispatchAssignNumber = 0, planNumber = 0, key } = dispatch || {};
    const summaryObj = accum.find(acc => acc.key === key);
    if (summaryObj) {
      summaryObj.dispatchCompleteNumber = summaryObj.dispatchCompleteNumber + dispatchCompleteNumber;
      summaryObj.dispatchAssignNumber = summaryObj.dispatchAssignNumber + dispatchAssignNumber;
      summaryObj.planNumber = summaryObj.planNumber + planNumber;
    } else {
      accum.push({ dispatchCompleteNumber, dispatchAssignNumber, planNumber, key });
    }
    return accum;
  }, []);

  return result;
};
