import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

const DropdownContainer = ({
  input,
  meta: { error, invalid, touched },
  ...rest
}) => {
  let status = 'success';
  if (!touched) {
    status = 'default';
  } else if (invalid) {
    status = 'error';
  }

  return (
    <Dropdown
      errorMessage={touched && error ? error : undefined}
      status={status}
      {...input}
      {...rest}
    />
  );
};

DropdownContainer.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default DropdownContainer;
