import { takeLatest, put, select } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchCapitals } from '../actions';
import { getCapitals as getCapitalsApi } from '../api/graphql';
import { getFormValues } from 'redux-form/lib/immutable';
import { SEARCH_CAPITALS as form } from '../lib/formNames';
import { Map } from 'immutable';
import { getLoginUserClientConfigTimezone } from '../selectors';
import moment from 'moment-timezone';

export function* fetchCapitalsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history } = payload || {};
    const timezone = yield select(getLoginUserClientConfigTimezone);
    const formValues = (yield select(getFormValues(form))) || Map();

    const initStartDate = moment().tz(timezone)
      .startOf('month')
      .unix();
    const initEndDate = moment().tz(timezone)
      .endOf('month')
      .unix();

    const formStartDate = formValues.get('startDate');
    const formEndDate = formValues.get('endDate');

    const searchStartDate = (Number.isInteger(formStartDate)) ? formStartDate : (moment(formStartDate).tz(timezone)
      .startOf('day')
      .unix());

    const searchEndDate = (Number.isInteger(formEndDate)) ? formEndDate : (moment(formEndDate).tz(timezone)
      .startOf('day')
      .unix());

    const startDate = searchStartDate ? searchStartDate : initStartDate;
    const endDate = searchEndDate ? searchEndDate : initEndDate;

    const request = {
      startDate,
      endDate
    };

    const { capitals, message, error, allAccountAmount, selectedAccountAmount } =
      yield getCapitalsApi({ token, request });

    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchCapitals.success({ capitals, allAccountAmount, selectedAccountAmount }));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchCapitals.type.PENDING, fetchCapitalsSaga);
}
