import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';

const AlertDialog = props => {
  const { translate } = useLocales();
  const { onConfirm, onCancel, content: { title, message } = {} } = props;
  return !!title && (
    <Dialog
      open={true}
      // onClose={this.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          { message }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default">
          {translate('cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {translate('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default AlertDialog;
