import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchDispatchWorks } from '../actions';
import { getDispatchWorks as getDispatchWorkApi } from '../api/graphql';

export function* fetchDispatchWorksSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, dispatchId } = payload || {};
    const { dispatchWorks, message, error } = yield getDispatchWorkApi({ token, dispatchId });
    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchDispatchWorks.success(dispatchWorks));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchDispatchWorks.type.PENDING, fetchDispatchWorksSaga);
}
