import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchCommodities,
  upsertContract,
  fetchContracts,
  cloneContract
} from '../../actions';
import {
  getContractEntrustCompanyOptions,
  getContracts,
  getContractCompanyOptions,
  getLoginUserPermissions
} from '../../selectors';
import Contracts from './Contracts';

const stateToProps = state => {
  const contracts = getContracts(state);
  const companyOptions = getContractCompanyOptions(state);
  const entrustCompanyOptions = getContractEntrustCompanyOptions(state);
  const permissions = getLoginUserPermissions(state);
  const displayContracts = (contracts || []).map(item => {
    const { company, entrustCompany } = item || {};
    const companyName = ((companyOptions || []).find(com => com.id === company) || {}).name;
    const entrustCompanyName = ((entrustCompanyOptions || []).find(enCom => enCom.id === entrustCompany) || {}).name;
    return { ...item, companyName, entrustCompanyName };
  });
  return ({
    contracts: displayContracts,
    permissions
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchContracts,
  fetchCommodities,
  upsertContract,
  cloneContract
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Contracts);
