import gql from 'graphql-tag';

export default gql`
    mutation upsertDispatchWork(
        $token: String!
        $request: DispatchWorkInput
        $isDelete: Boolean
        $correlationId: String!
    ){
        upsertDispatchWork(
            token: $token
            correlationId: $correlationId
            request: $request
            isDelete: $isDelete
        ) {
            error
            message
        }
    }
`;
