import React, { Component } from 'react';
import MuiDownshift from 'mui-downshift';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

class AutoSuggest extends Component {
  static defaultProps = {
    blurOnSelect: false
  };

  constructor(props) {
    super(props);
    this.state = {
      filteredItems: this.props.itemsToUse
    };
  }

  componentDidUpdate(prevProps) {
    const { itemsToUse } = this.props;
    if (!isEqual(prevProps.itemsToUse, itemsToUse)) {
      this.setState({ filteredItems: itemsToUse });
    }
  }

  handleStateChange = changes => {
    if (typeof changes.inputValue === 'string') {
      const filteredItems = this.props.itemsToUse
        .filter(item => item.label.toLowerCase().includes(changes.inputValue.toLowerCase()));
      this.setState({ filteredItems });
    }
    if (this.input && this.props.blurOnSelect) {
      this.input.blur();
    }
  };

  getDefaultSelectedItem = () => {
    const { itemsToUse, input: { value: inputVal } } = this.props;
    return ((itemsToUse || []).find(({ value }) => value === inputVal) || {}) || {};
  };

  render() {
    const {
      dataTid = 'mui-dadabdd',
      label,
      required,
      input: { value, onChange },
      disabled
    } = this.props;

    const { filteredItems } = this.state;
    return (
      <div data-bdd={dataTid}>
        <MuiDownshift
          defaultSelectedItem={this.getDefaultSelectedItem()}
          items={filteredItems}
          onStateChange={this.handleStateChange}
          onChange={item => onChange(item && item.value)}
          value={value}
          getInputProps={() => ({
            label: `${label}${required ? ' *' : ''}`,
            disabled
          })}
          {...this.props}
          inputRef={node => {
            this.input = node;
          }}
        />
      </div>
    );
  }
}

AutoSuggest.propTypes = {
  blurOnSelect: PropTypes.bool
};

export default AutoSuggest;
