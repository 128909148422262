import { AsYouType } from 'libphonenumber-js';

const selectedCountry = '';
let phoneFormatter = new AsYouType();

const getPhoneFormatter = country => {
  if (selectedCountry !== country) {
    phoneFormatter = new AsYouType(country);
  }

  return phoneFormatter;
};

const ALPHA_NUMERIC_ONLY = /[^a-z0-9,]/gi;
export const alphaNumericOnly = value => value.replace(ALPHA_NUMERIC_ONLY, '');

export const formatPhoneNumber = (number, country) => {
  if (!number) {
    return number;
  }
  return getPhoneFormatter(country).input(number);
};

export const numberOnly = (val, prevVal) => {
  if (val) {
    return (/^\d+$/.test(val)) ? val : prevVal;
  }
  return val;
};

export const positiveAndNegativeNumberOnly = (val, prevVal) => {
  if (val) {
    return (/^-?\d*\.?\d+$/.test(val)) ? val : prevVal;
  }
  return val;
};

export const menuTypeLimit = (val, prevVal) => {
  if (val) {
    return (/^.{0,14}$/.test(val)) ? val : prevVal;
  }
  return val;
};

export const normalizeFloatPositiveOnly = (value, previousValue) =>
  (!value || (!isNaN(parseFloat(value)) && parseFloat(value) >= 0) ? value : previousValue);

const FLOAT_ONLY = /[^.0-9,]/gi;
export const normalizeToFloat = (val, prevVal) => {
  const cleanValue = val.replace(FLOAT_ONLY, '');
  const dotCount = (cleanValue.match(/\./gi) || []).length;
  return dotCount <= 1 ? cleanValue : prevVal;
};

export const normalizeFloat = (value, previousValue) =>
  (!value || value === '-' || !isNaN(parseFloat(value)) ? value : previousValue);

export const maxNumberValue = max => (value, previousValue) => {
  const safeValue = numberOnly(value, previousValue);
  if (isNaN(parseInt(safeValue))) {
    return safeValue;
  }
  if (parseInt(safeValue) > max) {
    return max;
  }
  return safeValue;
};

export const normalizeRefundAmount = (number, maxAmount, refundTotal) => {
  if (isNaN(number)) {
    return null;
  }
  const floatNumber = parseFloat(number);
  const floatMaxAmount = parseFloat(maxAmount);
  if (floatNumber > floatMaxAmount) {
    return refundTotal < floatMaxAmount ? refundTotal.toString() : floatMaxAmount.toString();
  }
  return refundTotal < floatNumber ? refundTotal.toString() : number;
};

export const maxNumberLength = len => (value, previousValue) => {
  const safeValue = numberOnly(value, previousValue);
  if (isNaN(parseInt(safeValue))) {
    return safeValue;
  }
  if (`${safeValue}`.length > len) {
    return previousValue;
  }
  return safeValue;
};
