/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import AssignPlansAddEdit from './AssignPlansAddEdit';
import moment from 'moment-timezone';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import AssignPlanItems from './AssignPlanItems';

const useStyles = makeStyles(styles);

export default function AssignPlans(props) {
  const [modeOpen, setModeOpen] = useState(null);
  const [editAssignPlanId, setEditAssignPlanId] = useState(null);

  const {
    history,
    contracts,
    fetchContracts,
    fetchPlans,
    contractId,
    fetchAssignPlans,
    assignPlans,
    timezone,
    upsertAssignPlan,
    contractName
  } = props;

  const renderColumns = () => [
    {
      name: 'contractId',
      label: 'Contract Id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'assignPlanId',
      label: 'Assign Plan Id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'startDate',
      label: translate('assignPlanTable&Form.startDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'endDate',
      label: translate('assignPlanTable&Form.endDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: endDate => moment.unix(endDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'assignPlanName',
      label: translate('assignPlanTable&Form.assignPlanName'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: translate('assignPlanTable&Form.note'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'assignPlanId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <Tooltip title={translate('assignPlanHint.edit')}>
                <IconButton
                  onClick={() => {
                    setModeOpen('assignPlanAddEdit');
                    setEditAssignPlanId(value);
                  }}
                  color={'primary'}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>

              <Tooltip title={translate('assignPlanHint.assignPlanItem')}>
                <IconButton
                  onClick={() => {
                    setModeOpen('assignPlanItem');
                    setEditAssignPlanId(value);
                  }}
                  color={'primary'}>
                  <CallSplitIcon/>
                </IconButton>
              </Tooltip>

              <Tooltip title={translate('assignPlanHint.delete')}>
                <IconButton
                  onClick={() => {
                    upsertAssignPlan({
                      isDelete: true,
                      formValues: { contractId, assignPlanId: value },
                      history,
                      upsertAssignPlanMessage:
                        { success: translate('upsertAssignPlan.success'),
                          error: translate('upsertAssignPlan.error') },
                      generalAlertText: {
                        title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message')
                      }
                    });
                  }}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridContainer>
        )
      }
    }
  ];

  useEffect(() => {
    fetchContracts(
      {
        history
      });
    fetchPlans(
      {
        history,
        contractId
      });
    fetchAssignPlans({
      history,
      contractId
    });
  }, []);

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => setModeOpen('assignPlanAddEdit')}
            color="linkedin">
            {translate('addAssignPlans')}
          </Button>
        </div>
      </GridItem>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/admin/contracts')}
            color="info">
            <ArrowBackIosIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {translate('assignPlans')} - {contractName}
          </h4>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <GridContainer>
              </GridContainer>
            )}
            data={assignPlans || []}
            columns={renderColumns()}
            options={{
              setTableProps: () => ({
                size: 'small'
              }),
              textLabels: MUI_DATA_TABLE_LABELS,
              responsive: 'stacked',
              elevation: 0,
              selectableRows: 'none',
              rowsPerPage: 50,
              rowsPerPageOptions: [25, 50, 75, 100],
              download: false,
              print: false
            }}
          />

          {modeOpen === 'assignPlanAddEdit' &&
            <AssignPlansAddEdit
              assignPlanId={editAssignPlanId}
              contractId={contractId}
              contracts={contracts}
              modeType={modeOpen}
              assignPlans={assignPlans}
              onClose={() => {
                setModeOpen(null);
                setEditAssignPlanId(null);
              }}
            />
          }

          {modeOpen === 'assignPlanItem' &&
            <AssignPlanItems
              assignPlanId={editAssignPlanId}
              contractId={contractId}
              contracts={contracts}
              modeType={modeOpen}
              assignPlans={assignPlans}
              onClose={() => {
                setModeOpen(null);
                setEditAssignPlanId(null);
              }}
            />
          }

        </CardBody>
      </Card>
    </GridContainer>
  );
}

AssignPlans.propTypes = {
  history: PropTypes.any
};
