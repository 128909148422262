import gql from 'graphql-tag';

export default gql`
    query getUsers(
        $id: String
        $correlationId: String!
        $token: String!
    ){
        getUsers(
            id: $id
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            users {
                id
                email
                name
                password
                idNumber
                phoneNumber
                userRole
                realName
                permissions {
                    id
                    value
                }
                clientId
                livingAllowance
                transportAllowance
                insuranceAllowance
                employeeSalary
                hourSalary
                dataControlHourSalary
                skillSalary
            }
        }
    }`;
