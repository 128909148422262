import { takeLatest, put, race, take } from 'redux-saga/effects';
import {
  upsertUser,
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertWarehouseHistory,
  fetchWarehouseHistories
} from '../actions';
import { upsertWarehouseHistory as upsertWarehouseHistoryApi } from '../api/graphql';

export function* upsertWarehouseHistorySaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertWarehouseHistoryMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertUser.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');

    const {
      createDate,
      warehouseId
    } = formValues;

    const request = {
      createDate,
      warehouseId
    };

    const { error, message } =
      yield upsertWarehouseHistoryApi({ token, request, isDelete });
    if (!error) {
      yield put(setAppAlert({
        message: upsertWarehouseHistoryMessage.success,
        variant: 'success'
      }));
      yield put(upsertWarehouseHistory.success());
      yield put(fetchWarehouseHistories({ history, warehouseId }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertWarehouseHistoryMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertWarehouseHistory.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertWarehouseHistory.type.PENDING, upsertWarehouseHistorySaga);
}
