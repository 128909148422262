/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import MUIDataTable from 'mui-datatables';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import { IconButton } from '@material-ui/core';
import Checkin from '../Checkin';

const styles = ({ spacing, palette, shape }) => (
  {
    ...regularFormsStyle,
    root: {
      padding: spacing.unit * 5,
      overflowY: 'scroll',
      height: '100%'
    },
    title: {
      fontFamily: '"Roboto Slab", Roboto'
    },
    grow: {
      flexGrow: 1
    },
    pageHeader: {
      padding: spacing.unit * 2,
      marginBottom: spacing.unit * 2,
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      borderRadius: shape.borderRadius,
      position: 'relative'
    },
    link: {
      cursor: 'pointer'
    },
    widget: {
      padding: spacing.unit * 2,
      marginBottom: spacing.unit * 3
    },
    widgetNoPad: {
      padding: 0
    },
    widgetNoOverflow: {
      overflow: 'hidden'
    },
    infoHeader: {
      backgroundColor: palette.info.light,
      color: palette.info.contrastText,
      paddingLeft: spacing.unit * 2
    },
    leftIcon: {
      marginRight: spacing.unit / 2
    },
    leftBody: {
      paddingRight: spacing.unit * 4
    },
    rightBody: {
      paddingLeft: spacing.unit
    },
    drawerContent: {
      position: 'relative',
      height: `calc(100vh - ${spacing.unit * 8}px)`,
      overflow: 'hidden',
      webkitOverflowScrolling: 'touch',
      width: 600,
      maxWidth: '100%'
    },
    isComplete: {
      color: 'green'
    },
    notComplete: {
      color: 'red'
    },
    tableRoot: {
      '& > *': {
        borderBottom: 'unset'
      }
    }
  }
);

const useStyles = makeStyles(styles);

export default function ContractCheckin(props) {
  const {
    history,
    fetchContracts,
    fetchPlans,
    contractId,
    fetchDispatchesByContract,
    contractPlanItemsPlainData,
    fetchCheckIn,
    checkinMapping,
    name: contractName
  } = props;

  useEffect(() => {
    fetchDispatchesByContract({
      history,
      contractId
    });
    fetchCheckIn({
      history,
      contractId
    });
    fetchPlans(
      {
        history,
        contractId
      });
    fetchContracts(
      {
        history
      });
  }, []);

  const [open, setOpen] = React.useState(false);
  const [editLevel2, setEditLevel2] = React.useState(null);

  const classes = useStyles();
  const { translate } = useLocales();

  const level1NameMapping = (contractPlanItemsPlainData || []).reduce((accum, item) => {
    const { key, name: level1Name } = item || {};
    const isLevel1 = (key || '').split('-').length === 1;
    if (isLevel1) {
      // eslint-disable-next-line no-param-reassign
      accum[key] = level1Name;
    }
    return accum;
  }, {});

  const level2Data = (contractPlanItemsPlainData || []).reduce((accum, item) => {
    const { key, dispatchCompleteNumber, name: level2Name, title, model, parentKey, planQuantity } = item || {};
    const isLevel2 = (key || '').split('-').length === 2;
    if (isLevel2) {
      accum.push({
        level2Name,
        title,
        numberInWarehouse: checkinMapping[key],
        dispatchCompleteNumber,
        planQuantity,
        model,
        key,
        parentKey,
        level1Name: level1NameMapping[parentKey]
      });
    }
    return accum;
  }, []);

  const sortLevel2Data = level2Data.sort((alpha, beta) => alpha.parentKey - beta.parentKey);

  const renderColumns = () => [
    {
      name: 'key',
      label: 'Key',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'level2Name',
      label: '零件名称',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'model',
      label: '图号',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level1Name',
      label: '所属部件',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'numberInWarehouse',
      label: '已入库数量',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'dispatchCompleteNumber',
      label: '已完成数量',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'planQuantity',
      label: '计划完成数量',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'key',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          value !== 'summary' &&
                        <GridContainer wrap={'nowrap'}>
                          <GridItem>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setEditLevel2(
                                  (sortLevel2Data || []).find(item => `${item.key}` === `${value}`)
                                );
                              }}
                              color={'primary'}>
                                    入库
                            </IconButton>
                          </GridItem>

                        </GridContainer>
        )
      }
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/admin/checkin')}
              color="info">
              <ArrowBackIosIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {contractName} / 零件入库
            </h4>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              data={sortLevel2Data}
              columns={renderColumns()}
              options={{
                setTableProps: () => ({
                  size: 'small'
                }),
                textLabels: MUI_DATA_TABLE_LABELS,
                responsive: 'stacked',
                elevation: 0,
                selectableRows: 'none',
                rowsPerPage: 50,
                rowsPerPageOptions: [25, 50, 75, 100],
                download: false,
                print: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>

      {open &&
            <Checkin
              checkinMapping={checkinMapping}
              contractId={contractId}
              editLevel2={editLevel2}
              onClose={() => {
                setOpen(false);
                setEditLevel2(null);
              }}
            />
      }

    </GridContainer>
  );
}

ContractCheckin.propTypes = {
  history: PropTypes.any
};
