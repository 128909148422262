/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonAdd from '@material-ui/icons/PersonAdd';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import TextField from '../../../components/redux-form/TextField';
import Dropdown from '../../../components/redux-form/Dropdown';
import ToggleSwitch from '../../../components/redux-form/ToggleSwitch';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react';
import { CLIENTS, PERMISSIONS, USER_ROLE } from '../../../conf';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse, List } from '@material-ui/core';
import { normalizeToFloat } from '../../../lib/validation/formatting';

const styles = theme => ({
  label: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  button: {
    marginTop: '60px'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  submitButton: {
    textAlign: 'right'
  },
  title: {
    marginTop: '20px'
  },
  subs: {
    paddingLeft: theme.spacing(8)
  }
});
const useStyles = makeStyles(styles);

const UserAddEdit = props => {
  const {
    history,
    fetchUsers,
    formValues,
    invalid,
    userId,
    upsertUser,
    changePasswordPermission,
    userRole,
    isSuperAdmin,
    rootPermissionValue = {}
  } = props || {};

  const formUserRole = formValues.userRole;

  useEffect(() => {
    fetchUsers(
      {
        history,
        fetchUsersMessage: { success: translate('fetchUsers.success'), error: translate('fetchUsers.error') }
      });
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertUser({
      formValues,
      history,
      upsertUserMessage: { success: translate('upsertUser.success'), error: translate('upsertUser.error') }
    });
  };

  const classes = useStyles();

  const { translate } = useLocales();

  const userRoleOptions = USER_ROLE.map(item => ({ ...item, name: translate(`userRole.${item.name}`) }));

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PersonAdd />
            </CardIcon>
            <h4 className={classes.cardIconTitle}> {userId ? translate('userEdit') : translate('userAdd')} </h4>
          </CardHeader>

          <CardBody>
            <Form onSubmit={onSubmit}>
              <GridContainer>

                <GridItem xs={12}>
                  <Typography align={'left'} className={classes.title} variant="h6" color="primary">
                    {translate('userInfo')}
                  </Typography>
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="email"
                    component={TextField}
                    label={translate('userForm.email')}
                    validate={[required]}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="name"
                    component={TextField}
                    label={translate('userForm.name')}
                    validate={[required]}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    disabled={!changePasswordPermission}
                    name="password"
                    component={TextField}
                    label={translate('userForm.password')}
                    validate={[required]}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="realName"
                    component={TextField}
                    label={translate('userForm.realName')}
                    validate={[required]}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="idNumber"
                    component={TextField}
                    label={translate('userForm.idNumber')}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="phoneNumber"
                    component={TextField}
                    label={translate('userForm.phoneNumber')}
                  />
                </GridItem>
                {(userRole === 'admin' || isSuperAdmin) &&
                <GridItem xs={6}>
                  <Field
                    fullWidth
                    name="userRole"
                    component={Dropdown}
                    label={translate('userForm.userRole')}
                    options={userRoleOptions}
                    showDefaultNullOption
                    validate={[required]}
                  />
                </GridItem>
                }

                <Fragment>
                  <GridItem xs={12}>
                    <Typography align={'left'} className={classes.title} variant="h6" color="primary">
                      工资基础设置
                    </Typography>
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      normalize={normalizeToFloat}
                      name="livingAllowance"
                      component={TextField}
                      label={'房补'}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      normalize={normalizeToFloat}
                      name="transportAllowance"
                      component={TextField}
                      label={'交通补'}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      name="insuranceAllowance"
                      component={TextField}
                      label={'社保'}
                    />
                  </GridItem>
                  {(formUserRole === 'employee' || formUserRole === 'admin') &&
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      normalize={normalizeToFloat}
                      name="employeeSalary"
                      component={TextField}
                      label={'底薪'}
                    />
                  </GridItem>
                  }
                  {(formUserRole === 'worker' || formUserRole === 'admin') &&
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      normalize={normalizeToFloat}
                      name="hourSalary"
                      component={TextField}
                      label={'小时工资'}
                    />
                  </GridItem>
                  }
                  {(formUserRole === 'worker' || formUserRole === 'admin') &&
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      normalize={normalizeToFloat}
                      name="dataControlHourSalary"
                      component={TextField}
                      label={'数控小时工资'}
                    />
                  </GridItem>
                  }
                  {(formUserRole === 'worker' || formUserRole === 'admin') &&
                  <GridItem xs={6}>
                    <Field
                      fullWidth
                      normalize={normalizeToFloat}
                      name="skillSalary"
                      component={TextField}
                      label={'技能工资'}
                    />
                  </GridItem>
                  }

                </Fragment>

                {isSuperAdmin &&
                <Fragment>
                  <GridItem xs={12}>
                    <Typography align={'left'} className={classes.title} variant="h6" color="primary">
                      {translate('clientAccess')}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      fullWidth
                      name="clientId"
                      component={Dropdown}
                      label={translate('userForm.clientId')}
                      options={CLIENTS}
                      showDefaultNullOption
                      validate={[required]}
                    />
                    {/*<List>*/}
                    {/*  {CLIENTS.map(({ id, name }, idx) => (*/}
                    {/*    <ListItem button divider={idx !== CLIENTS.length - 1 } key={idx}>*/}
                    {/*      <ListItemText primary={name} />*/}
                    {/*      <ListItemSecondaryAction>*/}
                    {/*        <Field*/}
                    {/*          component={ToggleSwitch}*/}
                    {/*          name={`clients.${id}`}*/}
                    {/*          id={`clients.${id}`}*/}
                    {/*        />*/}
                    {/*      </ListItemSecondaryAction>*/}
                    {/*    </ListItem>*/}
                    {/*  ))}*/}
                    {/*</List>*/}
                  </GridItem>
                </Fragment>
                }

                <GridItem xs={12}>
                  <Typography align={'left'} className={classes.title} variant="h6" color="primary">
                    {translate('permissions')}
                  </Typography>
                </GridItem>

                <GridItem xs={12}>
                  <List>
                    {PERMISSIONS.map(({ id, subs }, idx) =>
                      <Fragment key={idx}>
                        <ListItem button divider={idx !== PERMISSIONS.length - 1 }>
                          <ListItemText primary={translate(`permission.${id}`)} />
                          <ListItemSecondaryAction>
                            <Field
                              component={ToggleSwitch}
                              name={`permissions.${id}`}
                              id={`permissions.${id}`}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        {!!subs.length &&
                        <Collapse in={rootPermissionValue[id]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {subs.map((sub, idx2) => (
                              <Fragment key={idx2}>
                                <ListItem button className={classes.subs}
                                  divider={idx2 !== subs.length }>
                                  <ListItemText primary={translate(`permission.${sub}`)} />
                                  <ListItemSecondaryAction>
                                    <Field
                                      component={ToggleSwitch}
                                      name={`permissions.${sub}`}
                                      id={`permissions.${sub}`}
                                    />
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </Fragment>
                            ))}

                          </List>
                        </Collapse>
                        }
                      </Fragment>

                    )}
                  </List>
                </GridItem>

              </GridContainer>

              <div className={classes.submitButton}>
                <Button
                  disabled={invalid}
                  className={classes.button}
                  type="submit"
                  color="primary">
                  提交
                </Button>
              </div>

            </Form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

UserAddEdit.propTypes = {
  history: PropTypes.any
};

export default UserAddEdit;
