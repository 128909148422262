import axios from 'axios';
import { apiUrl } from '../../conf';

export const uploadImage = async({ files }) => {
  // eslint-disable-next-line no-undef
  const data = new FormData();
  // eslint-disable-next-line id-length,no-restricted-syntax,no-plusplus
  for (let i = 0;i < files.length;i++) {
    data.append('file', files[i]);
  }
  const { data: returnDate } = await axios.post(`${apiUrl}/upload/image`, data);
  const { error, msg, files: uploadFiles } = returnDate;
  return { error, msg, uploadFiles };
};

export const getUserInfo = async token => {
  // eslint-disable-next-line no-undef
  const finalToken = token ? token : localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${finalToken}`
    }
  };
  const res = await axios.get(`${apiUrl}/users/userInfo`, config);
  const { data, status } = res;
  return { data, status };
};
