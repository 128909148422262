import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchContractReturn } from '../actions';
import { getContractReceiptReturn as getContractReceiptReturnApi } from '../api/graphql';

export function* fetchContractReturnSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, contractId, type, createTime } = payload || {};
    const { receiptReturn, message, error } =
      yield getContractReceiptReturnApi({ token, contractId, type, createTime });

    if (error && message === 'auth') {
      history.push('/auth');
    }
    yield put(fetchContractReturn.success(receiptReturn));
    // yield put(setAppAlert({
    //   message: fetchEquipmentsMessage.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchContractReturn.type.PENDING, fetchContractReturnSaga);
}
