import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchSetups,
  fetchUsers,
  fetchAttendees
} from '../../actions';
import {
  getAttendees
} from '../../selectors';
import Attendees from './Attendees';

const stateToProps = state => {
  const attendees = getAttendees(state);

  return ({
    attendees
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSetups,
  fetchUsers,
  fetchAttendees
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(Attendees);
