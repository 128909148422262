import { fromJS } from 'immutable';
import {
  setContracts,
  setContractCompanyOptions,
  setContractEntrustCompanyOptions,
  setEditPlans,
  setContractReceipt,
  setContractReturn,
  setSearchPlans,
  setAssignPlans,
  setAssignPlanItems,
  setContractFilter,
  setContractTab,
  setCheckin,
  setContractsForWarehouses
} from '../selectors';
import {
  FETCH_CONTRACTS_ASYNC,
  FETCH_PLANS_ASYNC,
  FETCH_CONTRACT_RECEIPT_ASYNC,
  FETCH_CONTRACT_RETURN_ASYNC,
  CLEAR_PLANS,
  FETCH_SEARCH_PLANS_ASYNC,
  FETCH_ASSIGN_PLANS_ASYNC,
  FETCH_ASSIGN_PLAN_ITEMS_ASYNC,
  EDIT_CONTRACT_FILTER,
  EDIT_CONTRACT_TAB,
  FETCH_CHECK_IN,
  CLEAR_CONTRACTS_FOR_WAREHOUSE
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [EDIT_CONTRACT_TAB]: () => setContractTab(state, payload),
  [EDIT_CONTRACT_FILTER]: () => setContractFilter(state, payload),
  [FETCH_ASSIGN_PLAN_ITEMS_ASYNC.SUCCESS]: () => setAssignPlanItems(state, payload),
  [FETCH_CHECK_IN.SUCCESS]: () => setCheckin(state, payload),
  [FETCH_ASSIGN_PLANS_ASYNC.SUCCESS]: () => setAssignPlans(state, payload),
  [FETCH_CONTRACT_RETURN_ASYNC.SUCCESS]: () => setContractReturn(state, payload),
  [FETCH_CONTRACT_RECEIPT_ASYNC.SUCCESS]: () => setContractReceipt(state, payload),
  [CLEAR_PLANS]: () => setEditPlans(state, null),
  [FETCH_PLANS_ASYNC.SUCCESS]: () => setEditPlans(state, payload),
  [FETCH_SEARCH_PLANS_ASYNC.SUCCESS]: () => setSearchPlans(state, payload),
  [CLEAR_CONTRACTS_FOR_WAREHOUSE]: () => setContractsForWarehouses(state, []),
  [FETCH_CONTRACTS_ASYNC.SUCCESS]: () => {
    if (payload.level2Number) {
      return setContractsForWarehouses(state, payload.contracts);
    }
    return setContracts(
      setContractCompanyOptions(
        setContractEntrustCompanyOptions(state, payload.entrustCompanyOptions)
        , payload.companyOptions)
      , payload.contracts);
  }
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
