import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Person from '@material-ui/icons/Person';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import useLocales from '../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../conf';

const useStyles = makeStyles(styles);

export default function Users(props) {
  const { fetchUsers, upsertUser, history, users } = props;

  const renderColumns = () => [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'email',
      label: translate('userTable.email'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: translate('userTable.name'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'realName',
      label: translate('userTable.realName'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'phoneNumber',
      label: translate('userTable.phoneNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'userRole',
      label: translate('userTable.userRole'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => translate(`userRole.${value}`)
      }
    },
    {
      name: 'id',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <IconButton
                onClick={() => {
                  history.push(`/admin/users/edit/${value}`);
                }}
                color={'primary'}>
                <EditIcon/>
              </IconButton>
            </GridItem>

            <GridItem>
              <IconButton
                onClick={() => {
                  upsertUser({
                    isDelete: true,
                    formValues: { id: value },
                    history,
                    upsertUserMessage: { success: translate('deleteUser.success'),
                      error: translate('deleteUser.error') },
                    generalAlertText: { title: translate('generalAlertText.title'),
                      message: translate('generalAlertText.message') }
                  });
                }}
                color={'info'}>
                <DeleteIcon/>
              </IconButton>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  useEffect(() => {
    fetchUsers(
      {
        history,
        fetchUsersMessage: { success: translate('fetchUsers.success'), error: translate('fetchUsers.error') }
      });
  }, []);

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => history.push('/admin/users/add')}
            color="linkedin">
            {translate('add')}
          </Button>
        </div>

      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{translate('users')}</h4>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              title={(
                <GridContainer>
                </GridContainer>
              )}
              data={users || []}
              columns={renderColumns()}
              options={{
                textLabels: MUI_DATA_TABLE_LABELS,
                responsive: 'stacked',
                elevation: 0,
                selectableRows: 'none',
                rowsPerPage: 50,
                rowsPerPageOptions: [25, 50, 75, 100],
                download: false,
                print: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Users.propTypes = {
  fetchCommodities: PropTypes.func,
  upsertCommodity: PropTypes.func,
  commodities: PropTypes.object,
  history: PropTypes.any
};
