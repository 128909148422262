/* eslint-disable max-lines */
import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DispatchDisplaySearch from './DispatchDisplaySearch';
import DispatchDisplayLists from './DispatchDisplayLists';

const styles = () => ({
  searchArea: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  accountArea: {
    marginTop: '25px',
    marginLeft: '20px'
  },
  listArea: {
    marginTop: '25px'
  },
  root: {
    width: 150,
    marginRight: 20,
    marginTop: 20,
    backgroundColor: '#f3f3f4'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 17
  },
  headerArea: {
    marginLeft: '20px'
  }
});

const useStyles = makeStyles(styles);

export default function Dispatches(props) {
  const {
    fetchSetups,
    fetchContracts,
    history,
    fetchUsers,
    fetchDispatches,
    fetchEquipments,
    dispatchWorkDisplay
  } = props;

  useEffect(() => {
    fetchEquipments(
      {
        history
      });
    fetchSetups(
      {
        history
      });
    fetchContracts(
      {
        history
      });
    fetchUsers(
      {
        history
      });
    fetchDispatches({
      history
    });
  }, []);

  const classes = useStyles();

  return (

    <Fragment>
      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <DispatchDisplaySearch/>
        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          {dispatchWorkDisplay && dispatchWorkDisplay.length > 0 &&
            <DispatchDisplayLists/>
          }
        </Grid>
      </Grid>
    </Fragment>
  );
}

Dispatches.propTypes = {
  history: PropTypes.any
};
