import { getContractAssignAndCompleteData } from './getContractAssignAndCompleteData';
import { getAssignPlanItemTreeData } from './getAssignPlanItemTreeData';

export const getFinalPlanTreeData = ({ dispatchWorksByContract, assignPlanItems, assignPlan, plans }) => {
  const selectedAssignPlans = assignPlanItems
    .filter(item => item.assignPlanId === assignPlan && !item.isMain);

  const sortSelectedAssignPlans = selectedAssignPlans.reduce((accum, item) => {
    const { assignPlanItemId: assignPIId } = item || {};
    const planItemObj = accum.find(acc => acc.assignPlanItemId === assignPIId);
    if (planItemObj) {
      planItemObj.planItems.push(item);
    } else {
      accum.push({ assignPlanItemId: assignPIId, planItems: [item] });
    }
    return accum;
  }, []);

  const assignPlanSummary = sortSelectedAssignPlans.map(item => {
    const { assignPlanItemId: assignPIID, planItems } = item || {};
    const assignPlanItemSummary =
      getContractAssignAndCompleteData(dispatchWorksByContract, assignPIID, 'assignPlanItem');
    const summaryAssignPlanItems = ((planItems || []).map(eachRow => {
      const { key: itemKey } = eachRow || {};
      const { dispatchCompleteNumber = 0, dispatchAssignNumber = 0, planNumber: planNumberSummary } =
        (assignPlanItemSummary.find(sDispatch => sDispatch.key === itemKey) || {});
      return {
        ...eachRow,
        dispatchCompleteNumber,
        dispatchAssignNumber,
        planNumber: planNumberSummary
      };
    }) || []).sort((alpha, bate) => bate.itemLevel - alpha.itemLevel);

    const finalAssignPlanItems = summaryAssignPlanItems.map(reachRow => {
      const { key: itemKey } = reachRow || {};
      const checkList = summaryAssignPlanItems.filter(planItem => {
        const { key: planItemKey } = planItem || {};
        return (planItemKey || '').startsWith(`${itemKey}-`) && planItemKey !== itemKey;
      });
      if (checkList && checkList.length) {
        const { finalDispatchAssignNumber, finalDispatchCompleteNumber } =
          checkList.reduce((accum, each) => {
            const { dispatchAssignNumber = 0, dispatchCompleteNumber = 0 } = each || {};
            if (accum.finalDispatchAssignNumber > dispatchAssignNumber) {
              // eslint-disable-next-line no-param-reassign
              accum.finalDispatchAssignNumber = dispatchAssignNumber;
            }
            if (accum.finalDispatchCompleteNumber > dispatchCompleteNumber) {
              // eslint-disable-next-line no-param-reassign
              accum.finalDispatchCompleteNumber = dispatchCompleteNumber;
            }
            return accum;
          }, {
            finalDispatchAssignNumber: 999999,
            finalDispatchCompleteNumber: 999999
          });
        return { ...reachRow, dispatchCompleteNumber: finalDispatchCompleteNumber,
          dispatchAssignNumber: finalDispatchAssignNumber };
      }
      return reachRow;
    });

    const planItemsPlainData = getAssignPlanItemTreeData(
      { data: finalAssignPlanItems, plainData: true, plans });
    const planItemsTreeData = getAssignPlanItemTreeData(
      { data: finalAssignPlanItems, plainData: false, plans }) || [];
    return {
      assignPlanItemId: assignPIID,
      planItems,
      finalAssignPlanItems,
      planItemsPlainData,
      planItemsTreeData
    };
  });

  return assignPlanSummary;
};
