import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const setCapitals = (capitalsState, payload) => capitalsState.setIn(['capitalLists'], fromJS(payload));
export const getCapitals = createSelector(
  state => state.getIn(['capitals', 'capitalLists'], List()),
  capitalLists => ((capitalLists || List()).toJS() || [])
    .sort(
      (
        { date: dateA, createDate: createDateA },
        { date: dateB, createDate: createDateB }
      ) => (`${dateA}:${createDateA}` < `${dateB}:${createDateB}` ? 1 : -1))
);

export const setAllAccountAmount = (capitalsState, payload) =>
  capitalsState.setIn(['allAccountAmount'], fromJS(payload));
export const getAllAccountAmount = createSelector(
  state => state.getIn(['capitals', 'allAccountAmount'], List()),
  allAccountAmount => ((allAccountAmount || List()).toJS() || [])
);

export const setSelectedAccountAmount = (capitalsState, payload) =>
  capitalsState.setIn(['selectedAccountAmount'], fromJS(payload));
export const getSelectedAccountAmount = createSelector(
  state => state.getIn(['capitals', 'selectedAccountAmount'], List()),
  selectedAccountAmount => ((selectedAccountAmount || List()).toJS() || [])
);
