import { takeLatest, put, race, take } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertContract,
  fetchContracts
} from '../actions';
import { upsertContract as upsertContractApi } from '../api/graphql';

export function* upsertContractSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertContractMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertContract.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const {
      contractId,
      contractNo,
      contractNumber,
      name,
      entrustCompany,
      company,
      workTime,
      note,
      contractStatus
    } = formValues;

    const request = {
      contractId,
      contractNo,
      contractNumber,
      name,
      entrustCompany,
      company,
      workTime,
      note,
      contractStatus
    };

    const res =
      yield upsertContractApi({ token, request, isDelete });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertContractMessage.success,
        variant: 'success'
      }));
      yield put(upsertContract.success());
      yield put(fetchContracts({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertContractMessage.error,
        variant: 'error'
      }));
    }
    history.push('/admin/contracts');
  } catch (err) {
    upsertContract.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertContract.type.PENDING, upsertContractSaga);
}
