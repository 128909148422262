import { takeLatest, put } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchWarehouseHistories } from '../actions';
import { getWarehouseHistories as getWarehouseHistoriesApi } from '../api/graphql';

export function* fetchWarehouseHistoriesSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history, warehouseId } = payload || {};

    const { warehouseHistories, message, error } =
      yield getWarehouseHistoriesApi({ token, warehouseId });

    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchWarehouseHistories.success(warehouseHistories));
    // yield put(setAppAlert({
    //   message: fetchCapitals.success,
    //   variant: 'success'
    // }));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchWarehouseHistories.type.PENDING, fetchWarehouseHistoriesSaga);
}
