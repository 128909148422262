/* eslint-disable max-lines */
import React, { useEffect, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';

const WAREHOUSE_TYPE = {
  0: 'product',
  1: 'material',
  2: 'standardParts',
  3: 'accessories',
  4: 'cutter',
  5: 'lowValue'
};

export default function Warehouses(props) {
  const {
    tabIdx,
    history,
    warehouses,
    upsertWarehouse,
    onEdit,
    onAdd,
    onRemove,
    onViewHistory,
    setups
  } = props;

  const prevTabIdx = useRef();

  const displayWarehouses = warehouses.filter(item => item.warehouseType === WAREHOUSE_TYPE[tabIdx]);

  useEffect(() => {
    prevTabIdx.current = tabIdx;
  }, [tabIdx]);

  const renderColumns = () => [
    {
      name: 'warehouseId',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    ...tabIdx === 0 ? [
      // {
      //   name: 'company',
      //   label: '公司',
      //   options: {
      //     filter: true,
      //     sort: true
      //   }
      // },
      // {
      //   name: 'subject',
      //   label: '项目',
      //   options: {
      //     filter: true,
      //     sort: true
      //   }
      // },
      // {
      //   name: 'level1',
      //   label: '部件',
      //   options: {
      //     filter: true,
      //     sort: true
      //   }
      // },
      // {
      //   name: 'level1Number',
      //   label: '部件图号',
      //   options: {
      //     filter: true,
      //     sort: true
      //   }
      // },
      {
        name: 'level2',
        label: '零件',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'level2Number',
        label: '零件图号',
        options: {
          filter: true,
          sort: true
        }
      }
    ] : [],

    ...tabIdx === 1 ? [
      {
        name: 'type',
        label: '类别',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'name',
        label: '名称',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'material',
        label: '材质',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'standard',
        label: '规格',
        options: {
          filter: true,
          sort: true
        }
      }
    ] : [],
    ...tabIdx === 2 ? [
      {
        name: 'name',
        label: '名称',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'material',
        label: '材质',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'model',
        label: '型号',
        options: {
          filter: true,
          sort: true
        }
      }
    ] : [],
    ...tabIdx === 3 ? [
      {
        name: 'name',
        label: '名称',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'standard',
        label: '规格',
        options: {
          filter: true,
          sort: true
        }
      }
    ] : [],
    ...tabIdx === 4 ? [
      {
        name: 'type',
        label: '类别',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'name',
        label: '名称',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'material',
        label: '材质',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => ((setups.find(item => item.setupId === value)) ?
            (setups.find(item => item.setupId === value) || {}).name : value)
        }
      },
      {
        name: 'standard',
        label: '规格',
        options: {
          filter: true,
          sort: true
        }
      }
    ] : [],
    ...tabIdx === 5 ? [
      {
        name: 'name',
        label: '名称',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'standard',
        label: '规格',
        options: {
          filter: true,
          sort: true
        }
      }
    ] : [],
    {
      name: 'unit',
      label: '单位',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'warehouseId',
      label: '数量',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          const warehouse = (warehouses || []).find(item => item.warehouseId === value) || {};
          const { number, status } = warehouse || {};
          if (status === '超出预警') {
            return <div style={{ color: 'red' }}>{number}</div>;
          }
          return number;
        }
      }
    },
    {
      name: 'price',
      label: '单价',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'total',
      label: '总价',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'warehouseId',
      label: '预警数量',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          const warehouse = (warehouses || []).find(item => item.warehouseId === value) || {};
          const { warningQuantity, status } = warehouse || {};
          if (status === '超出预警') {
            return <div style={{ color: 'red' }}>{warningQuantity}</div>;
          }
          return warningQuantity;
        }
      }
    },
    {
      name: 'status',
      label: '状态',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: '备注',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'position',
      label: '位置',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'warehouseId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            <GridItem>
              <Tooltip title={'编辑仓库件'}>
                <IconButton
                  onClick={() => onEdit(value)}
                  color={'primary'}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

            <GridItem>
              <Tooltip title={'入库'}>
                <IconButton
                  onClick={() => onAdd(value)}
                  color={'primary'}>
                  <AddIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

            <GridItem>
              <Tooltip title={'出库'}>
                <IconButton
                  onClick={() => onRemove(value)}
                  color={'primary'}>
                  <RemoveIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

            <GridItem>
              <Tooltip title={'查看出入库记录'}>
                <IconButton
                  onClick={() => onViewHistory(value)}
                  color={'primary'}>
                  <HistoryIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

            <GridItem>
              <Tooltip title={'删除'}>
                <IconButton
                  onClick={() => {
                    upsertWarehouse({
                      isDelete: true,
                      formValues: { warehouseId: value },
                      history,
                      upsertWarehouseMessage:
                            { success: '删除仓库件成功！', error: '删除仓库件失败！' },
                      generalAlertText: { title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message') }
                    });
                  }}
                  color={'info'}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>

          </GridContainer>
        )
      }
    }
  ];

  const { translate } = useLocales();

  // console.log('laoduxiangzhidao:prevTabIdx', prevTabIdx);

  // if (tableRef.current && prevTabIdx.current !== undefined && prevTabIdx.current !== tabIdx) {
  //   console.log('laoduxiangzhidao:kevin');
  //   tableRef.current.reset();
  // }

  return (
    <GridContainer>
      <GridItem xs={12}>
      </GridItem>
      <GridItem xs={12}>
        <MUIDataTable
          title={(
            <GridContainer>
            </GridContainer>
          )}
          data={displayWarehouses || []}
          columns={renderColumns()}
          options={{
            setTableProps: () => ({
              size: 'small'
            }),
            textLabels: MUI_DATA_TABLE_LABELS,
            responsive: 'stacked',
            elevation: 0,
            // onTableChange: (action, tableState) => {
            // if (action === 'propsUpdate') {
            //
            // }
            //
            // console.log('laoduxiangzhidao', {
            //   tableState,
            //   action
            // });

            // const { columns } = tableState || {};
            // // if (action === 'search') {
            // const filteredData = (tableState || []).data.filter(row => Object.values(row).some(value =>
            //   ((value || '').toString() || '').toLowerCase()
            //     .includes(((tableState || '').searchText || '').toLowerCase())
            // ));
            // const columnsMapping = (columns || []).reduce((accum, item, idx) => {
            //   // eslint-disable-next-line no-param-reassign
            //   accum[idx] = item.name;
            //   return accum;
            // }, {});
            // const result = (filteredData || []).map(item => {
            //   const res = {};
            //   const { data: rowData } = item || {};
            //   // eslint-disable-next-line id-length,no-plusplus,no-restricted-syntax
            //   for (let i = 0;i < rowData.length;i++) {
            //     const each = rowData[i];
            //     res[columnsMapping[i]] = each;
            //   }
            //   return res;
            // });

            // console.log('laodu:result', result);
            // }
            // },
            selectableRows: 'none',
            rowsPerPage: 50,
            rowsPerPageOptions: [25, 50, 75, 100],
            download: true,
            print: false
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

Warehouses.propTypes = {
  history: PropTypes.any
};
