/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useLocales from '../../lib/i18n/useLocales';
import Grid from '@material-ui/core/Grid';
import Button from '../../components/CustomButtons/Button';
import DispatchAddEdit from './DispatchAddEdit';
import DispatchWork from './DispatchWork';
import DispatchLists from './DispatchLists';
import DispatchesSearch from './DispatchesSearch';

const styles = () => ({
  searchArea: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  accountArea: {
    marginTop: '25px',
    marginLeft: '20px'
  },
  listArea: {
    marginTop: '25px'
  },
  root: {
    width: 150,
    marginRight: 20,
    marginTop: 20,
    backgroundColor: '#f3f3f4'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 17
  },
  headerArea: {
    marginLeft: '20px'
  }
});

const useStyles = makeStyles(styles);

export default function Dispatches(props) {
  const {
    fetchSetups,
    fetchContracts,
    capitalSubjects,
    capitalAccounts,
    capitalUnits,
    contracts,
    history,
    fetchUsers,
    capitals,
    fetchDispatches,
    dispatches,
    fetchPlans,
    plans,
    clearPlans,
    fetchEquipments,
    fetchAssignPlans,
    fetchAssignPlanItems
  } = props;

  useEffect(() => {
    fetchEquipments(
      {
        history
      });
    fetchSetups(
      {
        history
      });
    fetchContracts(
      {
        history
      });
    fetchUsers(
      {
        history
      });
    fetchDispatches({
      history
    });
  }, []);

  const classes = useStyles();
  const { translate } = useLocales();

  const [modeOpen, setModeOpen] = useState(null);
  const [dispatchId, setDispatchId] = useState(null);
  return (

    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              onClick={() => setModeOpen('dispatch')}
              color="facebook"
            >
              {translate('createDispatch')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <DispatchesSearch/>
        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <DispatchLists
            capitalSubjects={capitalSubjects}
            capitalAccounts={capitalAccounts}
            capitalUnits={capitalUnits}
            contracts={contracts}
            history={history}
            capitals={capitals}
            onDispatchWork={value => {
              setModeOpen('dispatchWork');
              setDispatchId(value);
              const { contractId } = dispatches.find(item => item.dispatchId === value) || {};
              fetchPlans(
                {
                  history,
                  contractId
                });
              fetchAssignPlans(
                {
                  history,
                  contractId
                });
              fetchAssignPlanItems(
                {
                  history,
                  contractId
                });
            }}
            onEditDispatch={value => {
              setModeOpen('editDispatch');
              setDispatchId(value);
              const { contractId } = dispatches.find(item => item.dispatchId === value) || {};
              fetchPlans(
                {
                  history,
                  contractId
                });
              fetchAssignPlans(
                {
                  history,
                  contractId
                });
              fetchAssignPlanItems(
                {
                  history,
                  contractId
                });
            }}
          />
        </Grid>
      </Grid>

      {modeOpen === 'dispatch' &&
        <DispatchAddEdit
          dispatches={dispatches}
          contracts={contracts}
          modeType={modeOpen}
          onClose={() => {
            setModeOpen(null);
            setDispatchId(null);
            clearPlans();
          }}
        />
      }

      {modeOpen === 'editDispatch' && (plans || []).length > 0 &&
        <DispatchAddEdit
          initialPlans={plans}
          dispatchId={dispatchId}
          dispatches={dispatches}
          contracts={contracts}
          modeType={modeOpen}
          onClose={() => {
            setModeOpen(null);
            setDispatchId(null);
            clearPlans();
          }}
        />
      }

      {modeOpen === 'dispatchWork' && (plans || []).length > 0 &&
        <DispatchWork
          plans={plans}
          history={history}
          dispatches={dispatches}
          dispatchId={dispatchId}
          contracts={contracts}
          modeType={modeOpen}
          onClose={() => {
            setModeOpen(null);
            setDispatchId(null);
            fetchDispatches({
              history
            });
          }}
        />
      }

    </Fragment>

  );
}

Dispatches.propTypes = {
  history: PropTypes.any
};
