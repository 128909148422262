/* eslint-disable max-lines */
import Calendar from 'views/Calendar/Calendar.js';
import UserAddEdit from 'views/Users/UserAddEdit/index';
import PlanAddEdit from 'views/Contracts/PlanAddEdit/index';
import ContractAddEdit from 'views/Contracts/ContractAddEdit/index';
import ContractReceipt from 'views/Contracts/ContractReceipt/index';
import ContractReturn from 'views/Contracts/ContractReturn/index';
import ContractView from 'views/Contracts/ContractView/index';
import Users from 'views/Users/index';
import Equipments from 'views/Equipments/index';
import AssignPlans from 'views/Contracts/AssignPlans/index';
import Capitals from 'views/Capitals/index';
import Dispatches from 'views/Dispatches/index';
import DispatchDisplay from 'views/DispatchDisplay/index';
import Attendees from 'views/Attendees/index';
import Salaies from 'views/Salaies/index';
import Warehouses from 'views/Warehouses/index';
import Contracts from 'views/Contracts/index';
import CheckInWarehouses from 'views/CheckInWarehouses/index';
import ContractCheckin from 'views/CheckInWarehouses/ContractCheckin/index';
import Setups from 'views/Setups/index';
import Charts from 'views/Charts/Charts.js';
import Dashboard from 'views/Dashboard/index';
import Widgets from 'views/Widgets/Widgets.js';
import LoginPage from 'views/Pages/LoginPage/index';
import AttendeeIcon from '@material-ui/icons/TouchApp';
import SalaryIcon from '@material-ui/icons/LocalAtm';
import WarehouseIcon from '@material-ui/icons/StoreMallDirectory';
// import ErrorPage from 'views/Pages/ErrorPage.js';
// import LockScreenPage from 'views/Pages/LockScreenPage.js';
// import PricingPage from 'views/Pages/PricingPage.js';
// import RTLSupport from 'views/Pages/RTLSupport.js';
// import RegisterPage from 'views/Pages/RegisterPage.js';
// import TimelinePage from 'views/Pages/Timeline.js';
// import UserProfile from 'views/Pages/UserProfile.js';
// import Buttons from 'views/Components/Buttons.js';
// import Wizard from 'views/Forms/Wizard.js';
// import ValidationForms from 'views/Forms/ValidationForms.js';
// import VectorMap from 'views/Maps/VectorMap.js';
// import Typography from 'views/Components/Typography.js';
// import RegularForms from 'views/Forms/RegularForms.js';
// import RegularTables from 'views/Tables/RegularTables.js';
// import SweetAlert from 'views/Components/SweetAlert.js';
// import ReactTables from 'views/Tables/ReactTables.js';
// import Notifications from 'views/Components/Notifications.js';
// import Panels from 'views/Components/Panels.js';
// import ExtendedForms from 'views/Forms/ExtendedForms.js';
// import ExtendedTables from 'views/Tables/ExtendedTables.js';
// import FullScreenMap from 'views/Maps/FullScreenMap.js';
// import GoogleMaps from 'views/Maps/GoogleMaps.js';
// import GridSystem from 'views/Components/GridSystem.js';
// import Icons from 'views/Components/Icons.js';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import DateRange from '@material-ui/icons/DateRange';
import Timeline from '@material-ui/icons/Timeline';
import WidgetsIcon from '@material-ui/icons/Widgets';
import CapitalIcon from '@material-ui/icons/AttachMoney';
import EquipmentsIcon from '@material-ui/icons/DevicesOther';
import ContractsIcon from '@material-ui/icons/Assignment';
import SetupsIcon from '@material-ui/icons/Settings';
import DispatchesIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
// import Apps from '@material-ui/icons/Apps';
// import Image from '@material-ui/icons/Image';
// import GridOn from '@material-ui/icons/GridOn';

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Dashboard,
    layout: '/admin'
  },
  // {
  //   path: '/login-page',
  //   name: 'Login Page',
  //   rtlName: 'هعذاتسجيل الدخول',
  //   mini: 'L',
  //   rtlMini: 'هعذا',
  //   hide: true,
  //   component: LoginPage,
  //   layout: '/auth'
  // },
  // {
  //   collapse: true,
  //   name: 'Pages',
  //   rtlName: 'صفحات',
  //   icon: Image,
  //   hide: true,
  //   state: 'pageCollapse',
  //   views: [
  //     {
  //       path: '/pricing-page',
  //       name: 'Pricing Page',
  //       rtlName: 'عالتسعير',
  //       mini: 'PP',
  //       rtlMini: 'ع',
  //       hide: true,
  //       component: PricingPage,
  //       layout: '/auth'
  //     },
  //     {
  //       path: '/rtl-support-page',
  //       name: 'RTL Support',
  //       rtlName: 'صودعم رتل',
  //       mini: 'RS',
  //       rtlMini: 'صو',
  //       hide: true,
  //       component: RTLSupport,
  //       layout: '/rtl'
  //     },
  //     {
  //       path: '/timeline-page',
  //       name: 'Timeline Page',
  //       rtlName: 'تيالجدول الزمني',
  //       mini: 'T',
  //       rtlMini: 'تي',
  //       hide: true,
  //       component: TimelinePage,
  //       layout: '/admin'
  //     },
  {
    path: '/login-page',
    name: '',
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    hide: true,
    component: LoginPage,
    layout: '/auth'
  },
  //     {
  //       path: '/register-page',
  //       name: 'Register Page',
  //       rtlName: 'تسجيل',
  //       mini: 'R',
  //       rtlMini: 'صع',
  //       hide: true,
  //       component: RegisterPage,
  //       layout: '/auth'
  //     },
  //     {
  //       path: '/lock-screen-page',
  //       name: 'Lock Screen Page',
  //       rtlName: 'اقفل الشاشة',
  //       mini: 'LS',
  //       rtlMini: 'هذاع',
  //       hide: true,
  //       component: LockScreenPage,
  //       layout: '/auth'
  //     },
  //     {
  //       path: '/user-page',
  //       name: 'User Profile',
  //       rtlName: 'ملف تعريفي للمستخدم',
  //       mini: 'UP',
  //       rtlMini: 'شع',
  //       hide: true,
  //       component: UserProfile,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/error-page',
  //       name: 'Error Page',
  //       rtlName: 'صفحة الخطأ',
  //       mini: 'E',
  //       rtlMini: 'البريد',
  //       hide: true,
  //       component: ErrorPage,
  //       layout: '/auth'
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: 'Components',
  //   rtlName: 'المكونات',
  //   icon: Apps,
  //   state: 'componentsCollapse',
  //   views: [
  //     {
  //       collapse: true,
  //       name: 'Multi Level Collapse',
  //       rtlName: 'انهيار متعدد المستويات',
  //       mini: 'MC',
  //       rtlMini: 'ر',
  //       state: 'multiCollapse',
  //       views: [
  //         {
  //           path: '#sample-path',
  //           name: 'Example',
  //           rtlName: 'مثال',
  //           mini: 'E',
  //           rtlMini: 'ه',
  //           component: () => {},
  //           layout: '#sample-layout'
  //         }
  //       ]
  //     },
  //     {
  //       path: '/buttons',
  //       name: 'Buttons',
  //       rtlName: 'وصفت',
  //       mini: 'B',
  //       rtlMini: 'ب',
  //       component: Buttons,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/grid-system',
  //       name: 'Grid System',
  //       rtlName: 'نظام الشبكة',
  //       mini: 'GS',
  //       rtlMini: 'زو',
  //       component: GridSystem,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/panels',
  //       name: 'Panels',
  //       rtlName: 'لوحات',
  //       mini: 'P',
  //       rtlMini: 'ع',
  //       component: Panels,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/sweet-alert',
  //       name: 'Sweet Alert',
  //       rtlName: 'الحلو تنبيه',
  //       mini: 'SA',
  //       rtlMini: 'ومن',
  //       component: SweetAlert,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/notifications',
  //       name: 'Notifications',
  //       rtlName: 'إخطارات',
  //       mini: 'N',
  //       rtlMini: 'ن',
  //       component: Notifications,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/icons',
  //       name: 'Icons',
  //       rtlName: 'الرموز',
  //       mini: 'I',
  //       rtlMini: 'و',
  //       component: Icons,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/typography',
  //       name: 'Typography',
  //       rtlName: 'طباعة',
  //       mini: 'T',
  //       rtlMini: 'ر',
  //       component: Typography,
  //       layout: '/admin'
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: 'Forms',
  //   rtlName: 'إستمارات',
  //   icon: 'content_paste',
  //   state: 'formsCollapse',
  //   views: [
  //     {
  //       path: '/regular-forms',
  //       name: 'Regular Forms',
  //       rtlName: 'أشكال عادية',
  //       mini: 'RF',
  //       rtlMini: 'صو',
  //       component: RegularForms,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/extended-forms',
  //       name: 'Extended Forms',
  //       rtlName: 'نماذج موسعة',
  //       mini: 'EF',
  //       rtlMini: 'هوو',
  //       component: ExtendedForms,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/validation-forms',
  //       name: 'Validation Forms',
  //       rtlName: 'نماذج التحقق من الصحة',
  //       mini: 'VF',
  //       rtlMini: 'تو',
  //       component: ValidationForms,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/wizard',
  //       name: 'Wizard',
  //       rtlName: 'ساحر',
  //       mini: 'W',
  //       rtlMini: 'ث',
  //       component: Wizard,
  //       layout: '/admin'
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: 'Tables',
  //   rtlName: 'الجداول',
  //   icon: GridOn,
  //   state: 'tablesCollapse',
  //   views: [
  //     {
  //       path: '/regular-tables',
  //       name: 'Regular Tables',
  //       rtlName: 'طاولات عادية',
  //       mini: 'RT',
  //       rtlMini: 'صر',
  //       component: RegularTables,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/extended-tables',
  //       name: 'Extended Tables',
  //       rtlName: 'جداول ممتدة',
  //       mini: 'ET',
  //       rtlMini: 'هور',
  //       component: ExtendedTables,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/react-tables',
  //       name: 'React Tables',
  //       rtlName: 'رد فعل الطاولة',
  //       mini: 'RT',
  //       rtlMini: 'در',
  //       component: ReactTables,
  //       layout: '/admin'
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: 'Maps',
  //   rtlName: 'خرائط',
  //   icon: Place,
  //   state: 'mapsCollapse',
  //   views: [
  //     {
  //       path: '/google-maps',
  //       name: 'Google Maps',
  //       rtlName: 'خرائط جوجل',
  //       mini: 'GM',
  //       rtlMini: 'زم',
  //       component: GoogleMaps,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/full-screen-maps',
  //       name: 'Full Screen Map',
  //       rtlName: 'خريطة كاملة الشاشة',
  //       mini: 'FSM',
  //       rtlMini: 'ووم',
  //       component: FullScreenMap,
  //       layout: '/admin'
  //     },
  //     {
  //       path: '/vector-maps',
  //       name: 'Vector Map',
  //       rtlName: 'خريطة المتجه',
  //       mini: 'VM',
  //       rtlMini: 'تم',
  //       component: VectorMap,
  //       layout: '/admin'
  //     }
  //   ]
  // },
  {
    path: '/widgets',
    name: 'Widgets',
    rtlName: 'الحاجيات',
    icon: WidgetsIcon,
    hide: true,
    component: Widgets,
    layout: '/admin'
  },
  {
    path: '/charts',
    name: 'Charts',
    rtlName: 'الرسوم البيانية',
    icon: Timeline,
    hide: true,
    component: Charts,
    layout: '/admin'
  },
  {
    path: '/calendar',
    name: 'Calendar',
    rtlName: 'التقويم',
    icon: DateRange,
    hide: true,
    component: Calendar,
    layout: '/admin'
  },
  {
    path: '/users/add',
    name: 'userAdd',
    rtlName: 'التقويم',
    icon: Person,
    hide: true,
    component: UserAddEdit,
    layout: '/admin',
    permission: { name: 'users', permission: 'manageUsers' }
  },
  {
    path: '/users/edit/:userId',
    name: 'userEdit',
    rtlName: 'التقويم',
    icon: Person,
    hide: true,
    component: UserAddEdit,
    layout: '/admin',
    permission: { name: 'users', permission: 'manageUsers' }
  },
  {
    path: '/users',
    name: 'users',
    rtlName: 'التقويم',
    icon: Person,
    component: Users,
    layout: '/admin',
    permission: { name: 'users', permission: 'manageUsers' }
  },
  {
    path: '/equipments',
    name: 'equipments',
    rtlName: 'التقويم',
    icon: EquipmentsIcon,
    component: Equipments,
    layout: '/admin',
    permission: { name: 'equipments', permission: 'manageEquipments' }
  },
  {
    path: '/contracts/assignPlans/:contractId',
    name: 'planEdit',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: AssignPlans,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractPlan' }
  },
  {
    path: '/contracts/plans/:contractId',
    name: 'planEdit',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: PlanAddEdit,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractPlan' }
  },
  {
    path: '/contracts/add',
    name: 'planEdit',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: ContractAddEdit,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractAddEdit' }
  },
  {
    path: '/contracts/edit/:contractId',
    name: 'planEdit',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: ContractAddEdit,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractAddEdit' }
  },
  {
    path: '/contracts/receipt/:contractId',
    name: 'receiptEdit',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: ContractReceipt,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractReceipt' }
  },
  {
    path: '/contracts/return/:contractId',
    name: 'returnEdit',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: ContractReturn,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractReturn' }
  },
  {
    path: '/contracts/view/:contractId',
    name: 'contractView',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    hide: true,
    component: ContractView,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContractView' }
  },
  {
    path: '/contracts',
    name: 'contracts',
    rtlName: 'التقويم',
    icon: ContractsIcon,
    component: Contracts,
    layout: '/admin',
    permission: { name: 'contracts', permission: 'manageContracts' }
  },

  {
    path: '/setups',
    name: 'setups',
    rtlName: 'التقويم',
    icon: SetupsIcon,
    component: Setups,
    layout: '/admin',
    permission: { name: 'setups', permission: 'manageSetups' }
  },

  {
    path: '/capitals',
    name: 'capitals',
    rtlName: 'خريطة كاملة الشاشة',
    icon: CapitalIcon,
    rtlMini: 'ووم',
    component: Capitals,
    layout: '/admin',
    permission: { name: 'capitals', permission: 'manageCapitals' }
  },

  {
    path: '/dispatches',
    name: 'dispatches',
    rtlName: 'خريطة كاملة الشاشة',
    icon: DispatchesIcon,
    rtlMini: 'ووم',
    component: Dispatches,
    layout: '/admin',
    permission: { name: 'dispatches', permission: 'manageDispatches' }
  },

  {
    path: '/dispatchDisplay',
    name: 'dispatchDisplay',
    rtlName: 'خريطة كاملة الشاشة',
    icon: WorkOutlineIcon,
    rtlMini: 'ووم',
    component: DispatchDisplay,
    layout: '/admin',
    permission: { name: 'dispatchesDisplay', permission: 'manageDispatchesDisplay' }
  },

  {
    path: '/attendees',
    name: 'attendees',
    rtlName: 'خريطة كاملة الشاشة',
    icon: AttendeeIcon,
    rtlMini: 'ووم',
    component: Attendees,
    layout: '/admin',
    permission: { name: 'attendees', permission: 'manageAttendees' }
  },

  {
    path: '/salaries',
    name: 'salaries',
    rtlName: 'خريطة كاملة الشاشة',
    icon: SalaryIcon,
    rtlMini: 'ووم',
    component: Salaies,
    layout: '/admin',
    permission: { name: 'salaries', permission: 'manageSalaries' }
  },

  // {
  //   path: '/contracts/view/:contractId',
  //   name: 'contractView',
  //   rtlName: 'التقويم',
  //   icon: ContractsIcon,
  //   hide: true,
  //   component: ContractView,
  //   layout: '/admin',
  //   permission: { name: 'contracts', permission: 'manageContractView' }
  // },
  // {
  //   path: '/contracts',
  //   name: 'contracts',
  //   rtlName: 'التقويم',
  //   icon: ContractsIcon,
  //   component: Contracts,
  //   layout: '/admin',
  //   permission: { name: 'contracts', permission: 'manageContracts' }
  // },

  {
    path: '/checkin/edit/:contractId',
    name: 'checkinWarehouseEdit',
    rtlName: 'خريطة كاملة الشاشة',
    icon: WarehouseIcon,
    hide: true,
    component: ContractCheckin,
    layout: '/admin',
    permission: { name: 'checkinWarehouse', permission: 'manageCheckinWarehouse' }
  },

  {
    path: '/checkin',
    name: 'checkinWarehouse',
    rtlName: 'خريطة كاملة الشاشة',
    icon: WarehouseIcon,
    rtlMini: 'ووم',
    component: CheckInWarehouses,
    layout: '/admin',
    permission: { name: 'checkinWarehouse', permission: 'manageCheckinWarehouse' }
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    rtlName: 'خريطة كاملة الشاشة',
    icon: WarehouseIcon,
    rtlMini: 'ووم',
    component: Warehouses,
    layout: '/admin',
    permission: { name: 'warehouses', permission: 'manageWarehouse' }
  }
  // {
  //   collapse: true,
  //   name: 'capitals',
  //   rtlName: 'خرائط',
  //   icon: Capital,
  //   state: 'mapsCollapse',
  //   permission: { name: 'capitals', permission: 'manageCapitals' },
  //   views: [
  //     {
  //       path: '/capitals/setting',
  //       name: 'capitalsSetting',
  //       rtlName: 'خرائط جوجل',
  //       mini: 'CS',
  //       rtlMini: 'زم',
  //       component: Warehouses,
  //       layout: '/admin',
  //       permission: { name: 'capitals', permission: 'manageCapitals' }
  //     },
  //     {
  //       path: '/capitals',
  //       name: 'capitalsManagement',
  //       rtlName: 'خريطة كاملة الشاشة',
  //       mini: 'C',
  //       rtlMini: 'ووم',
  //       component: Warehouses,
  //       layout: '/admin',
  //       permission: { name: 'capitals', permission: 'manageCapitals' }
  //     }
  //   ]
  // }
];
export default dashRoutes;
