import { takeLatest, put, select } from 'redux-saga/effects';
import { setIsLoading, setAppAlert, fetchDispatchWorkDisplay } from '../actions';
import { getDispatchWorkDisplay as getDispatchWorkDisplayApi } from '../api/graphql';
import { SEARCH_DISPATCH_DISPLAY as form } from '../lib/formNames';
import { getFormValues } from 'redux-form/lib/immutable';
import { Map } from 'immutable';
import moment from 'moment-timezone';
import { getLoginUserClientConfigTimezone } from '../selectors';

export function* fetchDispatchWorkDisplaySaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { history } = payload || {};
    const timezone = yield select(getLoginUserClientConfigTimezone);
    const formValues = (yield select(getFormValues(form))) || Map();
    const start = formValues.get('startDate');
    const end = formValues.get('endDate');
    const showCheck = formValues.get('showCheck');
    const startDate = (Number.isInteger(start)) ? start : (moment(start).tz(timezone)
      .startOf('day')
      .unix());
    const endDate = (Number.isInteger(end)) ? end : (moment(end).tz(timezone)
      .endOf('day')
      .unix());
    const worker = formValues.get('worker');
    const request = {
      startDate,
      endDate,
      worker,
      showCheck
    };
    const { dispatchWorks, message, error } = yield getDispatchWorkDisplayApi({ token, request });
    if (error && message === 'auth') {
      history.push('/auth');
    }

    yield put(fetchDispatchWorkDisplay.success(dispatchWorks));
  } catch (err) {
    yield put(setAppAlert({
      message: err.message,
      variant: 'error'
    }));
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(fetchDispatchWorkDisplay.type.PENDING, fetchDispatchWorkDisplaySaga);
}
