import gql from 'graphql-tag';

export default gql`
    mutation upsertContract(
        $token: String!
        $request: ContractInput
        $isDelete: Boolean
        $correlationId: String!
    ){
        upsertContract(
            token: $token
            correlationId: $correlationId
            request: $request
            isDelete: $isDelete
        ) {
            error
            message
        }
    }
`;
