import { takeLatest, put } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  upsertSalariesField
} from '../actions';

import { upsertSalariesField as upsertSalariesFieldApi } from '../api/graphql';

export function* upsertSalariesFieldSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertSalariesFieldMessage,
      salaryId
    } = payload || {};

    const request = Object.keys(formValues).map(key => ({
      id: key,
      value: formValues[key]
    }));

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');

    const res = yield upsertSalariesFieldApi({ token, request, salaryId });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertSalariesFieldMessage.success,
        variant: 'success'
      }));
      yield put(upsertSalariesField.success());
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertSalariesFieldMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertSalariesField.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertSalariesField.type.PENDING, upsertSalariesFieldSaga);
}
