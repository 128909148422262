/* eslint-disable max-lines */
import ApolloClient from 'apollo-boost';
import { config } from '../../conf';
import getUsersQuery from './queries/getUsers';
import loginQuery from './queries/login';
import getUserInfoQuery from './queries/getUserInfo';
import upsertUserMutation from './mutations/upsertUser';
import upsertEquipmentMutation from './mutations/upsertEquipment';
import upsertEquipmentMaintainHistoryMutation from './mutations/upsertEquipmentMaintainHistory';
import getEquipmentsQuery from './queries/getEquipments';
import upsertEquipmentTypeMutation from './mutations/upsertEquipmentType';
import upsertContractMutation from './mutations/upsertContract';
import upsertPlanMutation from './mutations/upsertPlan';
import getContractsQuery from './queries/getContracts';
import getPlansQuery from './queries/getPlans';
import contractReceiptReturnMutation from './mutations/contractReceiptReturn';
import getContractReceiptReturnQuery from './queries/getContractReceiptReturn';
import upsertSetupsMutation from './mutations/upsertSetups';
import getSetupsQuery from './queries/getSetups';
import upsertCapitalMutation from './mutations/upsertCapital';
import upsertDispatchMutation from './mutations/upsertDispatch';
import getCapitalsQuery from './queries/getCapitals';
import getDispatchesQuery from './queries/getDispatches';
import upsertDispatchWorkMutation from './mutations/upsertDispatchWork';
import upsertAssignPlanMutation from './mutations/upsertAssignPlan';
import getDispatchWorksQuery from './queries/getDispatchWorks';
import getAssignPlansQuery from './queries/getAssignPlans';
import upsertAssignPlanItemMutation from './mutations/upsertAssignPlanItem';
import getAssignPlanItemsQuery from './queries/getAssignPlanItems';
import getDispatchDisplayQuery from './queries/getDispatchDisplay';
import getAttendeesQuery from './queries/getAttendees';
import getSalariesQuery from './queries/getSalaries';
import upsertAttendeeMutation from './mutations/upsertAttendee';
import upsertSalariesFieldMutation from './mutations/upsertSalariesField';
import upsertWarehouseMutation from './mutations/upsertWarehouse';
import getWarehousesQuery from './queries/getWarehouses';
import getWarehouseHistoriesQuery from './queries/getWarehouseHistories';
import getEquipmentMaintainHistoriesQuery from './queries/getEquipmentMaintainHistories';
import warehouseExportExcelQuery from './queries/warehouseExportExcel';
import getCheckinQuery from './queries/getCheckin';
import changeWarehouseNumberMutation from './mutations/changeWarehouseNumber';
import cloneContractMutation from './mutations/cloneContract';
import upsertWarehouseHistoryMutation from './mutations/upsertWarehouseHistory';
import checkinMutation from './mutations/checkin';

const GraphQL = (() => {
  const client = new ApolloClient({
    uri: config.graphqlURL,
    headers: {
      'x-api-key': config.graphqlApiKey
    }
  });

  const gqlOperation = op => async params => {
    // const done = metrics.request({ name: params.opName });
    const result = await client[op](params);
    // done();
    // eslint-disable-next-line no-unused-vars
    const { data: { networkStatus, stale, ...data } } = result;
    return params.errorPolicy === 'all' ?
      { ...result, data }
      : data;
  };

  return {
    query: gqlOperation('query'),
    mutation: gqlOperation('mutate')
  };
})();

export const getUsers = async({ id, token }) => {
  const { getUsers: res } = await GraphQL.query({
    opName: 'getUsersQuery',
    query: getUsersQuery,
    variables: { id, correlationId: 'laodu-getUser', token },
    fetchPolicy: 'no-cache'
  });
  return res || [];
};

export const login = async({ userName, password }) => {
  const { login: res } = await GraphQL.query({
    opName: 'loginQuery',
    query: loginQuery,
    variables: { userName, password, correlationId: 'laodu-login' },
    fetchPolicy: 'no-cache'
  });

  return res || {};
};

export const getUserInfo = async({ token }) => {
  const { getUserInfo: res } = await GraphQL.query({
    opName: 'getUserInfoQuery',
    query: getUserInfoQuery,
    variables: { token },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertUser = async({ user, token, isDelete }) => {
  const { upsertUser: res } = await GraphQL.mutation({
    opName: 'upsertUserMutation',
    mutation: upsertUserMutation,
    variables: { correlationId: 'laodu:upsertUser', user, token, isDelete }
  });
  return res;
};

export const upsertEquipment = async({ request, token, isDelete }) => {
  const { upsertEquipment: res } = await GraphQL.mutation({
    opName: 'upsertEquipmentMutation',
    mutation: upsertEquipmentMutation,
    variables: { correlationId: 'laodu:upsertEquipment', request, token, isDelete }
  });
  return res;
};

export const getEquipments = async({ token, equipmentId }) => {
  const { getEquipments: res } = await GraphQL.query({
    opName: 'getEquipmentsQuery',
    query: getEquipmentsQuery,
    variables: { token, equipmentId, correlationId: 'laodu:getEquipments' },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertEquipmentType = async({ request, token, isDelete }) => {
  const { upsertEquipmentType: res } = await GraphQL.mutation({
    opName: 'upsertEquipmentTypeMutation',
    mutation: upsertEquipmentTypeMutation,
    variables: { correlationId: 'laodu:upsertEquipmentType', request, token, isDelete }
  });
  return res;
};

export const upsertContract = async({ request, token, isDelete }) => {
  const { upsertContract: res } = await GraphQL.mutation({
    opName: 'upsertContractMutation',
    mutation: upsertContractMutation,
    variables: { correlationId: 'laodu:upsertContract', request, token, isDelete }
  });
  return res;
};

export const getContracts = async({ token, contractId, type, level2Number }) => {
  const { getContracts: res } = await GraphQL.query({
    opName: 'getContractsQuery',
    query: getContractsQuery,
    variables: { token, contractId, correlationId: 'laodu:getContracts', type, level2Number },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertPlan = async({ request, token, contractId }) => {
  const { upsertPlan: res } = await GraphQL.mutation({
    opName: 'upsertPlanMutation',
    mutation: upsertPlanMutation,
    variables: { correlationId: 'laodu:upsertPlan', request, token, contractId }
  });
  return res;
};

export const getPlans = async({ token, contractId }) => {
  const { getPlans: res } = await GraphQL.query({
    opName: 'getPlansQuery',
    query: getPlansQuery,
    variables: { token, contractId, correlationId: 'laodu:getPlans' },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertContractReceiptReturn = async({ token, date, type, amount, contractId, isDelete, createTime,
  note }) => {
  const { contractReceiptReturn: res } = await GraphQL.mutation({
    opName: 'contractReceiptReturnMutation',
    mutation: contractReceiptReturnMutation,
    variables: { correlationId: 'laodu:upsertContractReceiptReturn',
      token, date, type, amount, contractId, isDelete, createTime, note }
  });
  return res;
};

export const getContractReceiptReturn = async({ token, contractId, createTime, type }) => {
  const { getContractReceiptReturn: res } = await GraphQL.query({
    opName: 'getContractReceiptReturnQuery',
    query: getContractReceiptReturnQuery,
    variables: { token, contractId, correlationId: 'laodu:getContractReceiptReturn', createTime, type },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertSetups = async({ request, token }) => {
  const { upsertSetups: res } = await GraphQL.mutation({
    opName: 'upsertSetupsMutation',
    mutation: upsertSetupsMutation,
    variables: { correlationId: 'laodu:upsertSetups', request, token }
  });
  return res;
};

export const getSetups = async({ token, type }) => {
  const { getSetups: res } = await GraphQL.query({
    opName: 'getSetupsQuery',
    query: getSetupsQuery,
    variables: { token, type, correlationId: 'laodu:getSetups' },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertCapital = async({ request, token, isDelete }) => {
  const { upsertCapital: res } = await GraphQL.mutation({
    opName: 'upsertCapitalMutation',
    mutation: upsertCapitalMutation,
    variables: { correlationId: 'laodu:upsertCapital', request, token, isDelete }
  });
  return res;
};

export const getCapitals = async({ token, request, dateType }) => {
  const { getCapitals: res } = await GraphQL.query({
    opName: 'getCapitalsQuery',
    query: getCapitalsQuery,
    variables: { token, dateType, correlationId: 'laodu:getCapitals', request },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertDispatch = async({ request, token, isDelete }) => {
  const { upsertDispatch: res } = await GraphQL.mutation({
    opName: 'upsertDispatchMutation',
    mutation: upsertDispatchMutation,
    variables: { correlationId: 'laodu:upsertDispatch', request, token, isDelete }
  });
  return res;
};

export const getDispatches = async({ token, request }) => {
  const { getDispatches: res } = await GraphQL.query({
    opName: 'getDispatchesQuery',
    query: getDispatchesQuery,
    variables: { token, correlationId: 'laodu:getDispatches', request },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertDispatchWork = async({ request, token, isDelete }) => {
  const { upsertDispatchWork: res } = await GraphQL.mutation({
    opName: 'upsertDispatchWorkMutation',
    mutation: upsertDispatchWorkMutation,
    variables: { correlationId: 'laodu:upsertDispatchWork', request, token, isDelete }
  });
  return res;
};

export const getDispatchWorks = async({ token, dispatchId }) => {
  const { getDispatchWorks: res } = await GraphQL.query({
    opName: 'getDispatchWorksQuery',
    query: getDispatchWorksQuery,
    variables: { token, correlationId: 'laodu:getDispatchWorks', dispatchId },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertAssignPlan = async({ request, token, isDelete }) => {
  const { upsertAssignPlan: res } = await GraphQL.mutation({
    opName: 'upsertAssignPlanMutation',
    mutation: upsertAssignPlanMutation,
    variables: { correlationId: 'laodu:upsertAssignPlan', request, token, isDelete }
  });
  return res;
};

export const getAssignPlans = async({ token, contractId, assignPlanId }) => {
  const { getAssignPlans: res } = await GraphQL.query({
    opName: 'getAssignPlansQuery',
    query: getAssignPlansQuery,
    variables: { token, correlationId: 'laodu:getAssignPlans', contractId, assignPlanId },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertAssignPlanItem = async({ request, token, isDelete }) => {
  const { upsertAssignPlanItem: res } = await GraphQL.mutation({
    opName: 'upsertAssignPlanItemMutation',
    mutation: upsertAssignPlanItemMutation,
    variables: { correlationId: 'laodu:upsertAssignPlanItem', request, token, isDelete }
  });
  return res;
};

export const getAssignPlanItems = async({ token, contractId, assignPlanId, assignPlanItemId, returnType }) => {
  const { getAssignPlanItems: res } = await GraphQL.query({
    opName: 'getAssignPlanItemsQuery',
    query: getAssignPlanItemsQuery,
    variables: { token, correlationId: 'laodu:getAssignPlanItems',
      contractId, assignPlanId, assignPlanItemId, returnType },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const getDispatchWorkDisplay = async({ token, request }) => {
  const { getDispatchDisplay: res } = await GraphQL.query({
    opName: 'getDispatchDisplayQuery',
    query: getDispatchDisplayQuery,
    variables: { token, correlationId: 'laodu:getDispatchDisplay', request },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertAttendee = async({ request, token, isDelete }) => {
  const { upsertAttendee: res } = await GraphQL.mutation({
    opName: 'upsertAttendeeMutation',
    mutation: upsertAttendeeMutation,
    variables: { correlationId: 'laodu:upsertAttendee', request, token, isDelete }
  });
  return res;
};

export const getAttendees = async({ token, request }) => {
  const { getAttendees: res } = await GraphQL.query({
    opName: 'getAttendeesQuery',
    query: getAttendeesQuery,
    variables: { token, correlationId: 'laodu:getAttendees', request },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const getSalaries = async({ token, year, month, startDate, endDate, employee }) => {
  const { getSalaries: res } = await GraphQL.query({
    opName: 'getSalariesQuery',
    query: getSalariesQuery,
    variables: { token, correlationId: 'laodu:getSalaries', year, month, startDate, endDate, employee },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const upsertSalariesField = async({ request, token, salaryId }) => {
  const { upsertSalariesField: res } = await GraphQL.mutation({
    opName: 'upsertSalariesFieldMutation',
    mutation: upsertSalariesFieldMutation,
    variables: { correlationId: 'laodu:upsertSalariesField', request, token, salaryId }
  });
  return res;
};

export const upsertWarehouse = async({ request, token, isDelete }) => {
  const { upsertWarehouse: res } = await GraphQL.mutation({
    opName: 'upsertWarehouseMutation',
    mutation: upsertWarehouseMutation,
    variables: { correlationId: 'laodu:upsertWarehouse', request, token, isDelete }
  });
  return res;
};

export const getWarehouses = async({ token, request }) => {
  const { getWarehouses: res } = await GraphQL.query({
    opName: 'getWarehousesQuery',
    query: getWarehousesQuery,
    variables: { token, correlationId: 'laodu:getWarehouses', request },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const changeWarehouseNumber = async({ request, token }) => {
  const { changeWarehouseNumber: res } = await GraphQL.mutation({
    opName: 'changeWarehouseNumberMutation',
    mutation: changeWarehouseNumberMutation,
    variables: { correlationId: 'laodu:changeWarehouseNumber', request, token }
  });
  return res;
};

export const getWarehouseHistories = async({ token, warehouseId }) => {
  const { getWarehouseHistories: res } = await GraphQL.query({
    opName: 'getWarehouseHistoriesQuery',
    query: getWarehouseHistoriesQuery,
    variables: { token, correlationId: 'laodu:getWarehouseHistories', warehouseId },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const getEquipmentMaintainHistories = async({ token, equipmentId }) => {
  const { getEquipmentMaintainHistories: res } = await GraphQL.query({
    opName: 'getEquipmentMaintainHistoriesQuery',
    query: getEquipmentMaintainHistoriesQuery,
    variables: { token, correlationId: 'laodu:getEquipmentMaintainHistories', equipmentId },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const cloneContract = async({ contractId, token }) => {
  const { cloneContract: res } = await GraphQL.mutation({
    opName: 'cloneContractMutation',
    mutation: cloneContractMutation,
    variables: { correlationId: 'laodu:cloneContract', token, contractId }
  });
  return res;
};

export const upsertWarehouseHistory = async({ request, token, isDelete }) => {
  const { upsertWarehouseHistory: res } = await GraphQL.mutation({
    opName: 'upsertWarehouseHistoryMutation',
    mutation: upsertWarehouseHistoryMutation,
    variables: { correlationId: 'laodu:upsertWarehouseHistory', request, token, isDelete }
  });
  return res;
};

export const upsertEquipmentMaintainHistory = async({ request, token, isDelete }) => {
  const { upsertEquipmentMaintainHistory: res } = await GraphQL.mutation({
    opName: 'upsertEquipmentMaintainHistoryMutation',
    mutation: upsertEquipmentMaintainHistoryMutation,
    variables: { correlationId: 'laodu:upsertEquipmentMaintainHistory', request, token, isDelete }
  });
  return res;
};

export const warehouseExportExcel = async({
  token,
  startDate,
  endDate,
  warehouseType,
  type
}) => {
  const { warehouseExportExcel: res } = await GraphQL.query({
    opName: 'warehouseExportExcelQuery',
    query: warehouseExportExcelQuery,
    variables: {
      token,
      correlationId: 'laodu:getWarehouses',
      startDate,
      endDate,
      warehouseType,
      type
    },
    fetchPolicy: 'no-cache'
  });
  return res;
};

export const checkin = async({ contractId, token, checkinNumber, key }) => {
  const { checkin: res } = await GraphQL.mutation({
    opName: 'checkinMutation',
    mutation: checkinMutation,
    variables: { correlationId: 'laodu:checkin', contractId, token, checkinNumber, key }
  });
  return res;
};

export const getCheckin = async({ token, contractId }) => {
  const { getCheckin: res } = await GraphQL.query({
    opName: 'getCheckinQuery',
    query: getCheckinQuery,
    variables: { token, correlationId: 'laodu:getCheckin', contractId },
    fetchPolicy: 'no-cache'
  });
  return res;
};
