/* eslint-disable max-lines */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import DatePicker from '../../../components/redux-form/DatePicker';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../components/Grid/GridItem';
import moment from 'moment-timezone';
import Dropdown from '../../../components/redux-form/Dropdown';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const styles = () => ({
  root: {
    marginTop: 10,
    marginRight: 50
  },
  searchButton: {
    marginTop: 17
  },
  marginTop: {
    marginTop: 10
  }
});
const useStyles = makeStyles(styles);

export default function Capitals(props) {
  const {
    history,
    formStartDate,
    formEndDate,
    onStartBiggerThanEnd,
    fetchDispatchWorkDisplay,
    workers,
    dispatchWorkDisplayData,
    timezone,
    isWorker
  } = props;

  const onSubmit = evt => {
    evt.preventDefault();
    fetchDispatchWorkDisplay({
      history
    });
  };

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <Form>
      <Grid container justify={'center'} alignItems="center" spacing={24}>
        <Grid container justify={'center'} alignItems="center" spacing={24}>
          <GridItem xs={3}>
            <Field
              required
              fullWidth
              onChange={evt => {
                const changeStartDate = moment(evt).unix();
                const endDate = Number.isInteger(formEndDate) ? formEndDate : moment(formEndDate).unix();
                if (changeStartDate > endDate) {
                  onStartBiggerThanEnd(evt);
                }
              }}
              label={translate('startDate')}
              component={DatePicker}
              name="startDate"
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              required
              fullWidth
              minDate={formStartDate}
              label={translate('endDate')}
              component={DatePicker}
              name="endDate"
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              disabled={isWorker}
              fullWidth
              name="worker"
              component={Dropdown}
              showDefaultNullOption
              label={translate('worker')}
              options={workers}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              disabled={isWorker}
              fullWidth
              name="showCheck"
              component={Dropdown}
              showDefaultNullOption
              label={translate('showCheck')}
              options={[
                {
                  label: '',
                  value: ''
                },
                {
                  label: '否',
                  value: '否'
                },
                {
                  label: '是',
                  value: '是'
                }]}
            />
          </GridItem>

        </Grid>

        <Grid container justify={'center'} alignItems="center">
          <div className={classes.root}>
            <Button
              onClick={evt => onSubmit(evt)}
              className={classes.searchButton}
              type="button"
              color="info"
            >
              {translate('submit')}
            </Button>
          </div>
          <div className={classes.root}>

            {!(dispatchWorkDisplayData && dispatchWorkDisplayData.length) ?
              <Button
                disabled={!(dispatchWorkDisplayData && dispatchWorkDisplayData.length)}
                className={classes.searchButton}
                type="button"
                color="warning"
              >
                导出EXCEL
              </Button> :
              <ExcelFile
                filename={'派工单'}
                element={<Button
                  disabled={!(dispatchWorkDisplayData && dispatchWorkDisplayData.length)}
                  className={classes.searchButton}
                  type="button"
                  color="warning"
                >
                  导出EXCEL
                </Button>}>
                <ExcelSheet data={dispatchWorkDisplayData || []} name="派工">
                  <ExcelColumn label="开始时间"
                    value={col => moment.unix(col.startDate).tz(timezone)
                      .format('MM/DD/yyyy')}
                  />
                  <ExcelColumn label="结束时间"
                    value={col => moment.unix(col.endDate).tz(timezone)
                      .format('MM/DD/yyyy')}
                  />
                  <ExcelColumn label="班次"
                    value={col => (col.workType ? translate(`workType.${col.workType}`) : '')}
                  />
                  <ExcelColumn label="工作分配类型"
                    value={col => (col.dispatchWorkType === 'contract' ? '包工' : '派工')}
                  />
                  <ExcelColumn label="分配人" value="workerName"/>
                  <ExcelColumn label="设备" value="equipmentName"/>
                  <ExcelColumn label="工作时间" value="workTime"/>
                  <ExcelColumn label="合同" value="contractName"/>
                  <ExcelColumn label="计划" value="assignPlanName"/>
                  <ExcelColumn label="部件" value="level1Name"/>
                  <ExcelColumn label="零件" value="level2Name"/>
                  <ExcelColumn label="工序" value="level3Name"/>
                  <ExcelColumn label="分配数量" value="dispatchNumber"/>
                  <ExcelColumn label="完成数量" value="completeNumber"/>
                  <ExcelColumn label="质检" value="quantityTest"/>
                  <ExcelColumn label="备注" value="note"/>
                </ExcelSheet>
              </ExcelFile>

            }

          </div>
        </Grid>

      </Grid>
    </Form>

  );
}

Capitals.propTypes = {
  history: PropTypes.any
};
