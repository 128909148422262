import { takeLatest, put } from 'redux-saga/effects';
import { uploadImage } from '../actions';
import { uploadImage as uploadImageApi } from '../api/rest/upload';

export function* uploadImageSaga({ payload }) {
  try {
    const { uploadName, files } = payload || {};

    const { error, msg, uploadFiles } = yield uploadImageApi({ files });
    if (error) {
      uploadImage.error(msg);
    } else {
      const filesUrl = uploadFiles.map(item => item.url);
      yield put(uploadImage.success({ filesUrl, uploadName }));
    }
  } catch (err) {
    uploadImage.error(err);
  }
}

export default function*() {
  yield takeLatest(uploadImage.type.PENDING, uploadImageSaga);
}
