import { createSelector } from 'reselect';
import { fromJS, List } from 'immutable';

export const setSetups = (setupsState, payload) => setupsState.setIn(['setupsList'], fromJS(payload));
export const getSetups = createSelector(
  state => state.getIn(['setups', 'setupsList'], List()),
  setupsList => ((setupsList || List()).toJS() || []).sort(
    (
      { name: prev },
      { name: next }
    ) => (prev < next ? -1 : 1))
);

export const getEquipmentType = createSelector(
  getSetups,
  setups => (setups || []).filter(setup => setup.type === 'equipmentType')
);

