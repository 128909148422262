import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { upsertSalariesField } from '../../../actions/index';
import SalariesLists from './SalariesLists';
import getSummarySalaries from '../getSummarySalaries';
import { SALARIES_FIELD_CHANGE as form } from '../../../lib/formNames';

import {
  getLoginUserClientConfigTimezone,
  getSalaries
} from '../../../selectors';
import { getFormValues, reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';

const stateToProps = state => {
  const timezone = getLoginUserClientConfigTimezone(state) || [];
  const salaries = getSalaries(state);
  const formValues = (getFormValues(form)(state) || Map()).toJS() || {};
  const salaryId = ((salaries || [])[0] || {}).salaryId;

  const initialValues = salaries.reduce((accum, salary) => {
    const { allowance, award, punishment, userId } = salary || {};
    if (allowance) {
      // eslint-disable-next-line no-param-reassign
      accum[`${userId}:allowance`] = allowance;
    }
    if (award) {
      // eslint-disable-next-line no-param-reassign
      accum[`${userId}:award`] = award;
    }
    if (punishment) {
      // eslint-disable-next-line no-param-reassign
      accum[`${userId}:punishment`] = punishment;
    }
    return accum;
  }, {});

  const summarySalaries = getSummarySalaries({ salaries, formValues });

  return ({
    initialValues,
    timezone,
    salaries: summarySalaries,
    formValues,
    salaryId
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertSalariesField
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(SalariesLists));
