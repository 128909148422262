import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertCapital,
  fetchPlans,
  upsertDispatchWork,
  fetchDispatchWorks
} from '../../../actions';
import DispatchWork from './DispatchWork';

import {
  getDispatchWorks,
  getLoginUserClientConfigTimezone,
  getWorkers,
  getEquipmentsList,
  getAssignPlanItems,
  getEditPlans, getLoginUserClientConfigPlanHierarchy
} from '../../../selectors';
import { getDispatchTreeData } from '../../../lib/getDispatchTreeData';
import { getDispatchAssignAndCompleteData } from '../../../lib/getDispatchAssignAndCompleteData';

const stateToProps = (state, ownProps) => {
  const timezone = getLoginUserClientConfigTimezone(state);
  const workers = getWorkers(state);
  const plans = getEditPlans(state);
  const equipments = getEquipmentsList(state);
  const dispatchWorks = getDispatchWorks(state);
  const dispatchId = ownProps.dispatchId;
  const dispatches = ownProps.dispatches;

  const selectedDispatch = (dispatches || []).find(item => item.dispatchId === dispatchId) || {};
  const assignPlanItems = getAssignPlanItems(state);
  const dispatchWorkType = (selectedDispatch || {}).dispatchWorkType;
  const isContractAssign = dispatchWorkType === 'contract';
  const dispatchWorkRows = dispatchWorks.filter(item => item.isMain);
  const dispatchWorkOtherRows = dispatchWorks.filter(item => !item.isMain);
  const data = getDispatchAssignAndCompleteData(selectedDispatch, assignPlanItems, dispatchWorkOtherRows);
  const planItemsPlainData = getDispatchTreeData({ data, plainData: true, plans });
  const planItemsTreeData = getDispatchTreeData({ data, plainData: false, plans }) || [];

  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];

  const dispatchWorkMainRows = dispatchWorkRows.map(item => {
    const { level1Id: l1, level2Id: l2, level3Id: l3 } = item || {};
    const fId = l3 || l2 || l1;
    const level = ((fId || '').split('-') || []).length;
    const firstItem = ((plans || []).find(plan => plan.key === l1) || {}).title;
    const secondItem = ((plans || []).find(plan => plan.key === l2) || {}).title;
    const thirdItem = ((plans || []).find(plan => plan.key === l3) || {}).title;

    const assignItem = `${firstItem ? firstItem : ''} ${secondItem ? ` / ${secondItem}` : ''} ${thirdItem ?
      ` / ${thirdItem}` : ''}`;
    return { ...item,
      assignItem,
      level: ((planHierarchy || []).find(planHie => planHie.level === level) || {}).name };
  });

  return ({
    isContractAssign,
    dispatchWorkMainRows,
    dispatchWorkOtherRows,
    selectedDispatch,
    dispatchWorks,
    workers,
    timezone,
    equipments,
    planItemsTreeData,
    planItemsPlainData
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertCapital,
  fetchPlans,
  upsertDispatchWork,
  fetchDispatchWorks
}, dispatch);

export default connect(stateToProps, dispatchToProps)(DispatchWork);
