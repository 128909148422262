import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWarehouseHistories, upsertWarehouseHistory } from '../../../actions';
import History from './History';
import { getWarehouseHistories, getLoginUserClientConfigTimezone } from '../../../selectors';

const stateToProps = state => {
  const warehouseHistories = getWarehouseHistories(state);
  const timezone = getLoginUserClientConfigTimezone(state);
  const totalInAndOut = (warehouseHistories || []).reduce((accum, item) => {
    const { type, number = 0 } = item || {};
    if (type === 'in') {
      // eslint-disable-next-line no-param-reassign
      accum.totalIn = accum.totalIn + number || 0;
    } else {
      // eslint-disable-next-line no-param-reassign
      accum.totalOut = accum.totalOut + number || 0;
    }
    return accum;
  }, {
    totalIn: 0,
    totalOut: 0
  });

  return ({
    warehouseHistories: warehouseHistories.sort((alpha, bate) => bate.createDate - alpha.createDate),
    timezone,
    totalInAndOut
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchWarehouseHistories,
  upsertWarehouseHistory
}, dispatch);

export default connect(stateToProps, dispatchToProps)(History);
