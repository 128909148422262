/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment-timezone';
import DispatchWorkIcon from '@material-ui/icons/DeviceHub';

export default function DispatchLists(props) {
  const {
    dispatchLists,
    planHierarchy,
    timezone,
    onEditDispatch,
    history,
    upsertDispatch,
    onDispatchWork
  } = props;

  useEffect(() => {

  }, []);

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'dispatchId',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'startDate',
      label: translate('dispatchDetailForm&Table.startDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'endDate',
      label: translate('dispatchDetailForm&Table.endDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'contract',
      label: translate('dispatchDetailForm&Table.contract'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'assignPlan',
      label: translate('dispatchDetailForm&Table.plan'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level1',
      label: ((planHierarchy || []).find(item => item.level === 1) || {}).name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level2',
      label: ((planHierarchy || []).find(item => item.level === 2) || {}).name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level3',
      label: ((planHierarchy || []).find(item => item.level === 3) || {}).name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'contractCompleteWorkerName',
      label: '员工',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'model',
      label: '型号',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'dispatchWorkType',
      label: translate('dispatchWorkType'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: dispatchWorkType =>
          (dispatchWorkType === 'contract' ? '包工' : '派工')
      }
    },
    {
      name: 'planNumber',
      label: translate('dispatchDetailForm&Table.planNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'completeNumber',
      label: translate('dispatchDetailForm&Table.completeNumber'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: translate('dispatchDetailForm&Table.note'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'dispatchId',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => (
          <GridContainer wrap={'nowrap'}>
            {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
            <GridItem>
              <Tooltip title={translate('dispatchHint.edit')}>
                <IconButton
                  onClick={() => onEditDispatch(value)}
                  color={'primary'}>
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            {/*}*/}

            {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
            <GridItem>
              <Tooltip title={translate('dispatchHint.dispatchWork')}>
                <IconButton
                  onClick={() => onDispatchWork(value)}
                  color={'primary'}>
                  <DispatchWorkIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            {/*}*/}

            {/*{isTrue((permissions.find(permission => permission.id === 'manageContractAddEdit') || {}).value) &&*/}
            <GridItem>
              <Tooltip title={translate('dispatchHint.delete')}>
                <IconButton
                  onClick={() => {
                    upsertDispatch({
                      isDelete: true,
                      formValues: { dispatchId: value },
                      history,
                      upsertDispatchMessage:
                        { success: translate('upsertDispatch.success'),
                          error: translate('upsertDispatch.error') },
                      generalAlertText: {
                        title: translate('generalAlertText.title'),
                        message: translate('generalAlertText.message')
                      }
                    });
                  }}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </GridItem>
            {/*}*/}

          </GridContainer>
        )
      }
    }
  ];

  return (
    <MUIDataTable
      title={(
        <GridContainer>
        </GridContainer>
      )}
      data={dispatchLists || []}
      columns={renderColumns()}
      options={{
        setTableProps: () => ({
          size: 'small'
        }),
        textLabels: MUI_DATA_TABLE_LABELS,
        responsive: 'stacked',
        elevation: 0,
        selectableRows: 'none',
        rowsPerPage: 50,
        rowsPerPageOptions: [25, 50, 75, 100],
        download: false,
        print: false
      }}
    />
  );
}

DispatchLists.propTypes = {
  history: PropTypes.any
};
