import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CapitalsSearch from './DispatchesSearch';
import {
  getFormValues,
  reduxForm,
  change
} from 'redux-form/immutable';
import { SEARCH_DISPATCHES as form } from '../../../lib/formNames';
import {
  fetchCapitals,
  fetchDispatches,
  fetchSearchPlans,
  fetchAssignPlans,
  saveDispatchesSearchData
} from '../../../actions';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Map } from 'immutable';
import {
  getLoginUserClientConfigTimezone,
  getContracts,
  getSearchPlans,
  getLoginUserClientConfigPlanHierarchy,
  getAssignPlans,
  getContractEntrustCompanyOptions,
  getDispatchesSearchData
} from '../../../selectors';

const renderOptions = (plans, key) => {
  const filterHierarchy = (key || '').split('-').length + 1;
  if (key === 'root') {
    return (plans || []).filter(plan =>
      ((plan.key || '').split('-').length === 1) && !plan.deleted)
      .map(item => ({
        value: item.key,
        label: item.title
      }));
  }
  return (plans || []).filter(plan =>
    ((plan.key || '').startsWith(key))
    && !plan.deleted && (plan.key !== key)
    && (plan.key || '').split('-').length === filterHierarchy)
    .map(item => ({
      value: item.key,
      label: item.title
    }));
};

const stateToProps = state => {
  const timezone = getLoginUserClientConfigTimezone(state);
  const contracts = getContracts(state);
  const entrustCompany = getContractEntrustCompanyOptions(state);
  const dispatchesSearchData = getDispatchesSearchData(state);
  const plans = getSearchPlans(state);
  const planHierarchy = getLoginUserClientConfigPlanHierarchy(state) || [];
  const startDate = moment().tz(timezone)
    .startOf('month')
    .unix();
  const endDate = moment().tz(timezone)
    .endOf('month')
    .unix();

  const initialValues = {
    ...dispatchesSearchData,
    startDate: dispatchesSearchData.startDate ? dispatchesSearchData.startDate : startDate,
    endDate: dispatchesSearchData.endDate ? dispatchesSearchData.endDate : endDate
  };

  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const searchType = formValues.searchType;
  const formStartDate = formValues.startDate;
  const formEndDate = formValues.endDate;
  const formContract = formValues.contract;
  const contractStatus = formValues.contractStatus;
  const contractEntrustCompany = formValues.contractEntrustCompany;
  const level1Options = formContract ? renderOptions(plans, 'root') : [];
  const level1 = formValues.level1;
  const level2Options = level1 ? renderOptions(plans, level1) : [];
  const level2 = formValues.level2;
  const level3Options = level2 ? renderOptions(plans, level2) : [];
  const contractsFilter = contracts.filter(contract => {
    const { contractStatus: cs } = contract || {};
    const finalCs = cs === 'active' ? 'active' : 'inactive';
    if (!contractStatus) {
      return true;
    }
    if (contractStatus && contractStatus === finalCs) {
      return true;
    }
    return false;
  }).filter(contract => {
    const { entrustCompany: ec } = contract || {};
    if (!contractEntrustCompany) {
      return true;
    }
    if (contractEntrustCompany && contractEntrustCompany === ec) {
      return true;
    }
    return false;
  });

  const contractsOptions = (contractsFilter || []).map(item => ({
    ...item,
    label: item.name,
    value: item.contractId
  }));

  const entrustCompanyOptions = (entrustCompany || []).map(item => ({
    ...item,
    label: item.name,
    value: item.id
  }));

  const assignPlans = getAssignPlans(state);

  const assignPlanOptions = (assignPlans || []).map(item => ({
    ...item,
    label: item.assignPlanName,
    value: item.assignPlanId
  }));

  return {
    formStartDate,
    formEndDate,
    formValues,
    searchType,
    initialValues,
    timezone,
    contractsOptions,
    formContract,
    planHierarchy,
    level1Options,
    level2Options,
    level3Options,
    entrustCompanyOptions,
    assignPlanOptions
  };
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchCapitals,
  fetchDispatches,
  fetchSearchPlans,
  fetchAssignPlans,
  saveDispatchesSearchData,
  onStartBiggerThanEnd: startDate => change(form, 'endDate', startDate),
  onLevel1Clear: () => change(form, 'level1', null),
  onLevel2Clear: () => change(form, 'level2', null),
  onLevel3Clear: () => change(form, 'level3', null)
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(CapitalsSearch));
