import gql from 'graphql-tag';

export default gql`
    query getEquipmentMaintainHistories(
        $equipmentId: String!
        $correlationId: String!
        $token: String!
    ){
        getEquipmentMaintainHistories(
          equipmentId: $equipmentId
          correlationId: $correlationId
          token: $token
        ){
            error
            message
            equipmentMaintainHistories {
                equipmentId
                maintainDate
                price
                note
            }
        }
    }`;
