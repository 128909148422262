/* eslint-disable max-lines */
import React, { useEffect, Fragment } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import TextField from '../../../components/redux-form/TextField';
import Dropdown from '../../../components/redux-form/Dropdown';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import useLocales from '../../../lib/i18n/useLocales';
import PropTypes from 'prop-types';
import Modal from '../../../components/ui/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { normalizeToFloat } from '../../../lib/validation/formatting';

const WarehouseAddEdit = props => {
  const {
    history,
    upsertWarehouse,
    formValues,
    warehouseId,
    onClose,
    pristine,
    invalid,
    warehouseMaterialTypeOptions,
    warehouseMaterialNameOptions,
    warehouseMaterialMaterialOptions,
    warehouseStandardPartsNameOptions,
    warehouseStandardPartsMaterialOptions,
    warehouseCuttingToolTypeOptions,
    warehouseCuttingToolNameOptions,
    warehouseCuttingToolMaterialOptions
  } = props || {};

  const {
    warehouseType: formWarehouseType
  } = formValues || {};

  const styles = () => ({
    modalAction: {
      marginRight: '12px'
    }
  });

  const useStyles = makeStyles(styles);

  const classes = useStyles();

  useEffect(() => {
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();
    upsertWarehouse({
      formValues,
      history,
      upsertWarehouseMessage:
        { success: '添加/修改仓库件成功！', error: '添加/修改仓库件失败！' },
      generalAlertText: {
        title: translate('generalAlertText.title'),
        message: translate('generalAlertText.message')
      }
    });
  };

  const actionButtons = () => (
    <Fragment>
      <Button
        variant="contained"
        color="default"
        onClick={onClose}
      >
        {translate('cancel')}
      </Button>
      <Button
        variant="contained"
        className={classes.modalAction}
        color="linkedin"
        disabled={pristine || invalid}
        type="submit"
        onClick={evt => {
          onSubmit(evt);
          onClose();
        }}
      >
        {translate('continue')}
      </Button>
    </Fragment>
  );

  const { translate } = useLocales();

  const WAREHOUSE_TYPE = [
    { id: 'product', name: '成品/半成品' },
    { id: 'material', name: '材料' },
    { id: 'standardParts', name: '标准件' },
    { id: 'accessories', name: '辅料/工具' },
    { id: 'cutter', name: '刃具' },
    { id: 'lowValue', name: '低值易耗/办公用品' }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>

        <Modal
          fixedTop
          maxWidth={'md'}
          isOpen={true}
          onClose={onClose}
          id="warehouseAddEdit-dialog"
          title= {warehouseId ? '编辑仓库件' : '创建仓库件'}
          dialogActions={actionButtons()}
        >
          <Form onSubmit={onSubmit}>
            <GridContainer>

              <GridItem xs={12}>
                <Field
                  required
                  fullWidth
                  name="warehouseType"
                  options={WAREHOUSE_TYPE}
                  component={Dropdown}
                  label={'仓库类别'}
                  validate={[required]}
                />
              </GridItem>

              {formWarehouseType === 'product' &&
                  <Fragment>
                    {/*<GridItem xs={12}>*/}
                    {/*  <Field*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*    name="company"*/}
                    {/*    component={TextField}*/}
                    {/*    label={'公司'}*/}
                    {/*    validate={[required]}*/}
                    {/*  />*/}
                    {/*</GridItem>*/}
                    {/*<GridItem xs={12}>*/}
                    {/*  <Field*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*    name="subject"*/}
                    {/*    component={TextField}*/}
                    {/*    label={'项目'}*/}
                    {/*    validate={[required]}*/}
                    {/*  />*/}
                    {/*</GridItem>*/}
                    {/*<GridItem xs={6}>*/}
                    {/*  <Field*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*    name="level1"*/}
                    {/*    component={TextField}*/}
                    {/*    label={'部件'}*/}
                    {/*    validate={[required]}*/}
                    {/*  />*/}
                    {/*</GridItem>*/}
                    {/*<GridItem xs={6}>*/}
                    {/*  <Field*/}
                    {/*    required*/}
                    {/*    fullWidth*/}
                    {/*    name="level1Number"*/}
                    {/*    component={TextField}*/}
                    {/*    label={'部件图号'}*/}
                    {/*    validate={[required]}*/}
                    {/*  />*/}
                    {/*</GridItem>*/}
                    <GridItem xs={6}>
                      <Field
                        required
                        fullWidth
                        name="level2"
                        component={TextField}
                        label={'零件'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <Field
                        required
                        fullWidth
                        name="level2Number"
                        component={TextField}
                        label={'零件图号'}
                        validate={[required]}
                      />
                    </GridItem>
                  </Fragment>
              }
              {formWarehouseType === 'material' &&
                  <Fragment>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="type"
                        showDefaultNullOption
                        options={warehouseMaterialTypeOptions}
                        component={Dropdown}
                        label={'类别'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="name"
                        showDefaultNullOption
                        options={warehouseMaterialNameOptions}
                        component={Dropdown}
                        label={'名称'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="material"
                        showDefaultNullOption
                        options={warehouseMaterialMaterialOptions}
                        component={Dropdown}
                        label={'材质'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="standard"
                        component={TextField}
                        label={'规格'}
                        validate={[required]}
                      />
                    </GridItem>
                  </Fragment>
              }
              {formWarehouseType === 'standardParts' &&
                  <Fragment>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="name"
                        showDefaultNullOption
                        options={warehouseStandardPartsNameOptions}
                        component={Dropdown}
                        label={'名称'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="material"
                        showDefaultNullOption
                        options={warehouseStandardPartsMaterialOptions}
                        component={Dropdown}
                        label={'材质'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="model"
                        component={TextField}
                        label={'型号'}
                        validate={[required]}
                      />
                    </GridItem>
                  </Fragment>
              }
              {formWarehouseType === 'accessories' &&
                  <Fragment>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="name"
                        component={TextField}
                        label={'名称'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="standard"
                        component={TextField}
                        label={'规格'}
                        validate={[required]}
                      />
                    </GridItem>
                  </Fragment>
              }
              {formWarehouseType === 'cutter' &&
                  <Fragment>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="type"
                        showDefaultNullOption
                        options={warehouseCuttingToolTypeOptions}
                        component={Dropdown}
                        label={'类别'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="name"
                        showDefaultNullOption
                        options={warehouseCuttingToolNameOptions}
                        component={Dropdown}
                        label={'名称'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="material"
                        showDefaultNullOption
                        options={warehouseCuttingToolMaterialOptions}
                        component={Dropdown}
                        label={'材质'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="standard"
                        component={TextField}
                        label={'规格'}
                        validate={[required]}
                      />
                    </GridItem>
                  </Fragment>
              }
              {formWarehouseType === 'lowValue' &&
                  <Fragment>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="name"
                        component={TextField}
                        label={'名称'}
                        validate={[required]}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        required
                        fullWidth
                        name="standard"
                        component={TextField}
                        label={'规格'}
                        validate={[required]}
                      />
                    </GridItem>
                  </Fragment>
              }

              <GridItem xs={12}>
                <Field
                  required
                  fullWidth
                  name="unit"
                  component={TextField}
                  label={'单位'}
                  validate={[required]}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  normalize={normalizeToFloat}
                  name="warningQuantity"
                  component={TextField}
                  label={'预警数量'}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  normalize={normalizeToFloat}
                  name="price"
                  component={TextField}
                  label={'总价'}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  fullWidth
                  name="note"
                  component={TextField}
                  label={'备注'}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  required
                  fullWidth
                  name="position"
                  component={TextField}
                  label={'位置'}
                  validate={[required]}
                />
              </GridItem>

            </GridContainer>
          </Form>
        </Modal>

      </GridItem>
    </GridContainer>
  );
};

WarehouseAddEdit.propTypes = {
  history: PropTypes.any
};

export default WarehouseAddEdit;
