import { takeLatest, put, race, take } from 'redux-saga/effects';
import {
  setAppAlert,
  setIsLoading,
  popupAlertDialog,
  upsertAssignPlanItem,
  fetchAssignPlanItems,
  fetchPlans,
  fetchDispatchesByContract
} from '../actions';
import { upsertAssignPlanItem as upsertAssignPlanItemApi } from '../api/graphql';

export function* upsertAssignPlanItemSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertAssignPlanItemMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertAssignPlanItem.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const {
      contractId,
      assignPlanId,
      assignPlanItemId,
      level1,
      level2,
      level3,
      planItemDispatchNumber
    } = formValues;

    const request = {
      contractId,
      assignPlanId,
      assignPlanItemId,
      level1,
      level2,
      level3,
      planItemDispatchNumber
    };

    const res =
      yield upsertAssignPlanItemApi({ token, request, isDelete });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertAssignPlanItemMessage.success,
        variant: 'success'
      }));
      yield put(upsertAssignPlanItem.success());
      yield put(fetchAssignPlanItems({ history, contractId, assignPlanId }));
      yield put(fetchPlans({ history, contractId }));
      yield put(fetchDispatchesByContract({ history, contractId }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertAssignPlanItemMessage.error,
        variant: 'error'
      }));
    }
  } catch (err) {
    upsertAssignPlanItem.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertAssignPlanItem.type.PENDING, upsertAssignPlanItemSaga);
}
