/* eslint-disable max-lines */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import DatePicker from '../../../components/redux-form/DatePicker';
import { Field, Form } from 'redux-form/immutable';
import { required } from '../../../lib/validation/validator';
import Button from '../../../components/CustomButtons/Button';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../components/Grid/GridItem';
import moment from 'moment-timezone';
import Dropdown from '../../../components/redux-form/Dropdown';

const styles = () => ({
  root: {
    marginTop: 10,
    marginRight: 50
  },
  searchButton: {
    marginTop: 17
  },
  marginTop: {
    marginTop: 10
  }
});
const useStyles = makeStyles(styles);

export default function Capitals(props) {
  const {
    history,
    formStartDate,
    formEndDate,
    onStartBiggerThanEnd,
    fetchAttendees,
    workers
  } = props;

  const onSubmit = evt => {
    evt.preventDefault();
    fetchAttendees({
      history
    });
  };

  const classes = useStyles();
  const { translate } = useLocales();

  return (
    <Form>
      <Grid container justify={'center'} alignItems="center" spacing={24}>
        <Grid container justify={'center'} alignItems="center" spacing={24}>
          <GridItem xs={3}>
            <Field
              required
              fullWidth
              onChange={evt => {
                const changeStartDate = moment(evt).unix();
                const endDate = Number.isInteger(formEndDate) ? formEndDate : moment(formEndDate).unix();
                if (changeStartDate > endDate) {
                  onStartBiggerThanEnd(evt);
                }
              }}
              label={translate('startDate')}
              component={DatePicker}
              name="startDate"
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              required
              fullWidth
              minDate={formStartDate}
              label={translate('endDate')}
              component={DatePicker}
              name="endDate"
              validate={[required]}
            />
          </GridItem>

          <GridItem xs={3}>
            <Field
              fullWidth
              name="worker"
              component={Dropdown}
              showDefaultNullOption
              label={'员工'}
              options={workers}
            />
          </GridItem>

        </Grid>

        <Grid container justify={'center'} alignItems="center">
          <div className={classes.root}>
            <Button
              onClick={evt => onSubmit(evt)}
              className={classes.searchButton}
              type="button"
              color="info"
            >
              {translate('submit')}
            </Button>
          </div>
        </Grid>

      </Grid>
    </Form>

  );
}

Capitals.propTypes = {
  history: PropTypes.any
};
