import gql from 'graphql-tag';

export default gql`
    query getContracts(
        $contractId: String
        $correlationId: String!
        $token: String!
        $level2Number: String
        $type: String
    ){
        getContracts(
            contractId: $contractId
            correlationId: $correlationId
            token: $token
            level2Number: $level2Number
            type: $type
        ){
            error
            message
            contracts {
                company
                contractId
                contractNo
                contractNumber
                createTime
                entrustCompany
                name
                note
                workTime
                contractStatus
                contractAmount
                receiptOverAmount
                returnOverAmount
            }
            contractOptions {
                id
                name
                count
                type
            }
        }
    }`;
