import { takeLatest, put, race, take } from 'redux-saga/effects';
import { fetchUsers, upsertUser, setAppAlert, setIsLoading, fetchLoginUserInfo, popupAlertDialog } from '../actions';
import { upsertUser as upsertUserApi } from '../api/graphql';

export function* upsertUserSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      formValues,
      history,
      upsertUserMessage,
      generalAlertText,
      isDelete
    } = payload || {};

    if (isDelete) {
      yield put(popupAlertDialog({
        title: (generalAlertText || {}).title,
        message: (generalAlertText || {}).message
      }));
      const [cancelled] = yield race([
        take(popupAlertDialog.type.ERROR),
        take(popupAlertDialog.type.SUCCESS)
      ]);

      if (cancelled) {
        yield put(upsertUser.error());
        return;
      }
    }

    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    const { permissions } = formValues || {};
    const permissionsInput = Object.keys((permissions || [])).map(key => ({ id: key, value: `${permissions[key]}` }));
    const request = { ...formValues, permissions: permissionsInput };

    const res =
      yield upsertUserApi({ token, user: request, isDelete });
    const { error, message } = res || {};
    if (!error) {
      yield put(setAppAlert({
        message: upsertUserMessage.success,
        variant: 'success'
      }));
      yield put(upsertUser.success());
      yield put(fetchUsers({ history }));
      yield put(fetchLoginUserInfo({ history }));
    }

    if (error && message === 'auth') {
      history.push('/auth');
    }

    if (error && message !== 'auth') {
      yield put(setAppAlert({
        message: message || upsertUserMessage.error,
        variant: 'error'
      }));
    }
    history.push('/admin/users');
  } catch (err) {
    upsertUser.error(err);
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function*() {
  yield takeLatest(upsertUser.type.PENDING, upsertUserSaga);
}
