import { fromJS } from 'immutable';
import {
  setSalaries
} from '../selectors';
import {
  FETCH_SALARIES_ASYNC,
  CLEAR_SALARIES
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [FETCH_SALARIES_ASYNC.SUCCESS]: () => setSalaries(state, payload),
  [CLEAR_SALARIES]: () => setSalaries(state, null)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
