import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchSetups,
  fetchContracts,
  fetchUsers,
  fetchDispatches,
  fetchPlans,
  clearPlans,
  fetchEquipments,
  fetchAssignPlans,
  fetchAssignPlanItems
} from '../../actions';
import {
  getContracts,
  getLoginUserPermissions,
  getDispatches,
  getEditPlans,
  getDispatchWorkDisplay,
  getLoginUserRole
} from '../../selectors';
import DispatchDisplay from './DispatchDisplay';

const stateToProps = state => {
  const permissions = getLoginUserPermissions(state);
  const contracts = (getContracts(state) || [])
    .map(item => ({ ...item, id: item.contractId, value: item.contractId, label: item.name }));
  const dispatches = getDispatches(state);
  const plans = getEditPlans(state);
  const dispatchWorkDisplay = getDispatchWorkDisplay(state) || [];
  const isWorker = getLoginUserRole(state) === 'worker';

  return ({
    isWorker,
    contracts,
    permissions,
    dispatches,
    plans,
    dispatchWorkDisplay
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  fetchSetups,
  fetchContracts,
  fetchUsers,
  fetchDispatches,
  fetchPlans,
  clearPlans,
  fetchEquipments,
  fetchAssignPlans,
  fetchAssignPlanItems
}, dispatch);

export default connect(
  stateToProps,
  dispatchToProps
)(DispatchDisplay);
