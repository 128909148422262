import { fromJS } from 'immutable';
import {
  setAlertDialogContent,
  setIsLoading,
  setAppAlert,
  closeAppAlert
} from '../selectors';
import {
  POPUP_ALERT_DIALOG,
  SET_IS_LOADING,
  SET_APP_ALERT,
  CLOSE_APP_ALERT
} from '../actions/types';

// eslint-disable-next-line no-unused-vars
const noop = type => () => {
  // debug('no reducer for type %s', type);
};

const initialState = fromJS({});

const reducer = (state, { type, payload, error }) => ({  //eslint-disable-line
  [POPUP_ALERT_DIALOG.PENDING]: () => setAlertDialogContent(state, payload),
  [POPUP_ALERT_DIALOG.ERROR]: () => setAlertDialogContent(state, {}),
  [POPUP_ALERT_DIALOG.SUCCESS]: () => setAlertDialogContent(state, {}),
  [SET_IS_LOADING]: () => setIsLoading(state, payload),
  [SET_APP_ALERT]: () => setAppAlert(state, payload),
  [CLOSE_APP_ALERT]: () => closeAppAlert(state)
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
