/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import PropTypes from 'prop-types';
import useLocales from '../../../lib/i18n/useLocales';
import { MUI_DATA_TABLE_LABELS } from '../../../conf';
import MUIDataTable from 'mui-datatables';
import moment from 'moment-timezone';
import GridItem from '../../../components/Grid/GridItem';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DispatchWorkComplete from '../DispatchWorkComplete';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DispatchDisplayLists(props) {
  const {
    dispatchWorkDisplayData,
    planHierarchy,
    timezone,
    manageShowCompleteFunction,
    history,
    upsertDispatchWork,
    isWorker
  } = props;

  const [editCreateDate, setEditCreateDate] = useState(null);

  useEffect(() => {

  }, []);

  const { translate } = useLocales();

  const renderColumns = () => [
    {
      name: 'createDate',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'startDate',
      label: translate('dispatchDetailForm&Table.startDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'endDate',
      label: translate('dispatchDetailForm&Table.endDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: startDate => moment.unix(startDate).tz(timezone)
          .format('MM/DD/yyyy')
      }
    },
    {
      name: 'workType',
      label: '班次',
      options: {
        filter: true,
        sort: true,
        customBodyRender: workType =>
          (workType ? translate(`workType.${workType}`) : '')
      }
    },
    {
      name: 'dispatchWorkType',
      label: translate('dispatchWorkType'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: dispatchWorkType =>
          (dispatchWorkType === 'contract' ? '包工' : '派工')
      }
    },
    {
      name: 'workerName',
      label: '分配人',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'equipmentName',
      label: '设备',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'workTime',
      label: '工作时间',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'contractName',
      label: translate('dispatchDetailForm&Table.contract'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'assignPlanName',
      label: translate('dispatchDetailForm&Table.plan'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level1Name',
      label: ((planHierarchy || []).find(item => item.level === 1) || {}).name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level2Name',
      label: ((planHierarchy || []).find(item => item.level === 2) || {}).name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'level3Name',
      label: ((planHierarchy || []).find(item => item.level === 3) || {}).name,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'model',
      label: '型号',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'dispatchNumber',
      label: '分配数量',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'completeNumber',
      label: '完成数量',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'quantityTest',
      label: '质检',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'note',
      label: translate('dispatchDetailForm&Table.note'),
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'createDate',
      label: translate('action'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: createDate => {
          const entity = (dispatchWorkDisplayData || [])
            .find(item => item.createDate === createDate) || {};
          const dispatchId = (entity || {}).dispatchId;
          return (
            <GridContainer wrap={'nowrap'}>
              {(isWorker || manageShowCompleteFunction) &&
             <GridItem>
               <Tooltip title={'编辑完成数量'}>
                 <IconButton
                   onClick={() => setEditCreateDate(createDate)}
                   color={'primary'}>
                   <EditIcon/>
                 </IconButton>
               </Tooltip>
             </GridItem>
              }
              {manageShowCompleteFunction &&
             <GridItem>
               <Tooltip title={'删除'}>
                 <IconButton
                   onClick={() => {
                     upsertDispatchWork({
                       isDelete: true,
                       dispatchWorkComplete: true,
                       formValues: { dispatchId, createDate },
                       history,
                       upsertDispatchMessage:
                         { success: translate('upsertDispatch.success'),
                           error: translate('upsertDispatch.error') },
                       generalAlertText: {
                         title: translate('generalAlertText.title'),
                         message: translate('generalAlertText.message')
                       }
                     });
                   }}
                 >
                   <DeleteIcon/>
                 </IconButton>
               </Tooltip>
             </GridItem>
              }
            </GridContainer>
          );
        }
      }
    }

  ];

  return (
    <Fragment>
      <MUIDataTable
        title={(
          <GridContainer>
          </GridContainer>
        )}
        data={dispatchWorkDisplayData || []}
        columns={renderColumns()}
        options={{
          setTableProps: () => ({
            size: 'small'
          }),
          textLabels: MUI_DATA_TABLE_LABELS,
          responsive: 'stacked',
          elevation: 0,
          selectableRows: 'none',
          rowsPerPage: 50,
          rowsPerPageOptions: [25, 50, 75, 100],
          download: false,
          print: false
        }}
      />

      {editCreateDate &&
       <DispatchWorkComplete
         isWorker={isWorker}
         onClose={() => setEditCreateDate(null)}
         dispatchWorkList={dispatchWorkDisplayData}
         editCreateDate={editCreateDate}
       />
      }
    </Fragment>

  );
}

DispatchDisplayLists.propTypes = {
  history: PropTypes.any
};
