import gql from 'graphql-tag';

export default gql`
    mutation checkin(
        $token: String!
        $contractId: String!
        $correlationId: String!
        $checkinNumber: Float!
        $key: String!
    ){
        checkin(
            token: $token
            correlationId: $correlationId
            contractId: $contractId
            checkinNumber: $checkinNumber
            key: $key
        ) {
            error
            message
        }
    }
`;
