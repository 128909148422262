import { createSelector } from 'reselect';
import { fromJS, isMap, List, Map } from 'immutable';

export const setDispatches = (dispatchesState, payload) => dispatchesState.setIn(['dispatchLists'], fromJS(payload));
export const getDispatches = createSelector(
  state => state.getIn(['dispatches', 'dispatchLists'], List()),
  dispatchLists => ((dispatchLists || List()).toJS() || [])
    .map(item => {
      const { contract, level1, level2, level3, assignPlan, assignPlanItem } = item || {};

      const level1Name = (level1 || {}).name;
      const level2Name = (level2 || {}).name;
      const level3Name = (level3 || {}).name;

      let model = '';
      if (level1Name) {
        model = (level1 || {}).modelName;
      }
      if (level2Name) {
        model = (level2 || {}).modelName;
      }
      if (level3Name) {
        model = (level3 || {}).modelName;
      }

      return {
        ...item,
        contractId: (contract || {}).id,
        contract: (contract || {}).name,
        level1Id: (level1 || {}).id,
        level1: level1Name,
        level2Id: (level2 || {}).id,
        level2: level2Name,
        level3Id: (level3 || {}).id,
        level3: level3Name,
        assignPlanId: (assignPlan || {}).id,
        assignPlan: (assignPlan || {}).name,
        assignPlanItemId: (assignPlanItem || {}).id,
        assignPlanItem: (assignPlanItem || {}).name,
        model
      };
    })
    .sort(
      (
        { startDate: startDateA, endDate: endDateA, createDate: createDateA },
        { startDate: startDateB, endDate: endDateB, createDate: createDateB }
      ) => (`${startDateA}:${endDateA}:${createDateA}` < `${startDateB}:${endDateB}:${createDateB}` ? 1 : -1))
);

export const setDispatchWorks = (dispatchesState, payload) =>
  dispatchesState.setIn(['dispatchWorkLists'], fromJS(payload));
export const getDispatchWorks = createSelector(
  state => state.getIn(['dispatches', 'dispatchWorkLists'], List()),
  dispatchWorkLists => ((dispatchWorkLists || List()).toJS() || [])
    .sort(
      (
        { createDate: createDateA },
        { createDate: createDateB }
      ) => (`${createDateA}` < `${createDateB}` ? 1 : -1))
);

export const setDispatchWorkByContract = (dispatchesState, payload) =>
  dispatchesState.setIn(['dispatchWorkByContract'], fromJS(payload));
export const getDispatchWorkByContract = createSelector(
  state => state.getIn(['dispatches', 'dispatchWorkByContract'], List()),
  dispatchWorkByContract => ((dispatchWorkByContract || List()).toJS() || [])
);

export const setDispatchWorkDisplay = (dispatchesState, payload) =>
  dispatchesState.setIn(['dispatchWorkDisplay'], fromJS(payload));
export const getDispatchWorkDisplay = createSelector(
  state => state.getIn(['dispatches', 'dispatchWorkDisplay'], List()),
  dispatchWorkDisplay => ((dispatchWorkDisplay || List()).toJS() || [])
);

export const setDispatchesSearchData = (dispatchesState, payload) =>
  dispatchesState.setIn(['dispatchesSearchData'], fromJS(payload));
export const getDispatchesSearchData = createSelector(
  state => state.getIn(['dispatches', 'dispatchesSearchData'], Map()),
  dispatchesSearchData => (isMap(dispatchesSearchData) ?
    (dispatchesSearchData || Map()).toJS() : dispatchesSearchData)
);
