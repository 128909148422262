import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WarehouseAddEdit from './WarehouseAddEdit';
import WarehousesList from './WarehousesList';
import InAndOut from './InAndOut';
import History from './History';
import ExportExcel from './ExportExcel';

const WAREHOUSE_TYPE = {
  0: 'product',
  1: 'material',
  2: 'standardParts',
  3: 'accessories',
  4: 'cutter',
  5: 'lowValue'
};

export default function Warehouses(props) {
  const useStyles = makeStyles(() => ({
    ...styles,
    root: {
      flexGrow: 1,
      backgroundColor: '#f3f3f4'
    }
  }));
  const classes = useStyles();
  const {
    history,
    fetchSetups,
    fetchWarehouses,
    warehouses,
    clearWarehousesExcel,
    clearContractsForWarehouse
  } = props;
  const [modeOpen, setModeOpen] = useState(null);
  const [editWarehouseId, setEditWarehouseId] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);
  const selectedWarehouse = (warehouses || []).find(warehouse => `${warehouse.warehouseId}` === `${editWarehouseId}`);
  const level2Number = (selectedWarehouse || {}).level2Number;

  useEffect(() => {
    fetchWarehouses(
      {
        history
      });
    fetchSetups(
      {
        history
      });
  }, []);

  const handleChange = (event, newValue) => {
    setTabIdx(newValue);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              setEditWarehouseId(null);
              setModeOpen('warehouse');
            }}
            color="facebook">
            添加仓库件
          </Button>
          <Button
            onClick={() => {
              setEditWarehouseId(null);
              setModeOpen('exportExcel');
            }}
            color="warning">
            导出EXCEL
          </Button>
        </div>

      </GridItem>
      <GridItem xs={12}>

        <div className={classes.root}>
          <Tabs
            value={tabIdx}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="成品/半成品" />
            <Tab label="材料" />
            <Tab label="标准件" />
            <Tab label="辅料/工具" />
            <Tab label="刃具" />
            <Tab label="低值易耗/办公用品" />
          </Tabs>
        </div>

        {warehouses && warehouses.length > 0 &&
            <WarehousesList
              onEdit={ value => {
                setModeOpen('warehouse');
                setEditWarehouseId(value);
              }}
              onAdd={ value => {
                setModeOpen('in');
                setEditWarehouseId(value);
              }}
              onRemove={ value => {
                setModeOpen('out');
                setEditWarehouseId(value);
              }}
              onViewHistory={ value => {
                setModeOpen('history');
                setEditWarehouseId(value);
              }}
              tabIdx={tabIdx}
              history={history}
            />
        }

        {modeOpen === 'warehouse' &&
            <WarehouseAddEdit
              warehouseType={WAREHOUSE_TYPE[tabIdx]}
              warehouseId={editWarehouseId}
              onClose={() => setModeOpen(null)}
            />
        }

        {modeOpen === 'exportExcel' &&
            <ExportExcel
              onClose={() => {
                clearWarehousesExcel();
                setModeOpen(null);
              }}
            />
        }

        {modeOpen === 'in' &&
            <InAndOut
              type={'in'}
              warehouseId={editWarehouseId}
              onClose={() => {
                clearContractsForWarehouse();
                setModeOpen(null);
              }}
            />
        }

        {modeOpen === 'out' &&
            <InAndOut
              type={'out'}
              warehouseType={WAREHOUSE_TYPE[tabIdx]}
              warehouseId={editWarehouseId}
              level2Number={level2Number}
              onClose={() => {
                clearContractsForWarehouse();
                setModeOpen(null);
              }}
            />
        }

        {modeOpen === 'history' &&
            <History
              warehouseId={editWarehouseId}
              onClose={() => {
                setModeOpen(null);
                fetchWarehouses(
                  {
                    history
                  });
              } }
            />
        }

      </GridItem>
    </GridContainer>
  );
}

Warehouses.propTypes = {
  history: PropTypes.any
};
