import gql from 'graphql-tag';

export default gql`
    query getWarehouseHistories(
        $warehouseId: String!
        $correlationId: String!
        $token: String!
    ){
        getWarehouseHistories(
            warehouseId: $warehouseId
            correlationId: $correlationId
            token: $token
        ){
            error
            message
            warehouseHistories {
                warehouseId
                createDate
                inOutNote
                number
                type
            }
        }
    }`;
