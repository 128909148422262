/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useLocales from '../../lib/i18n/useLocales';
import CapitalsSearch from './CapitalsSearch';
import Grid from '@material-ui/core/Grid';
import ToggleSwitch from '../../components/redux-form/ToggleSwitch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { currency } from '../../lib/getCurrencyAmount';
import Button from '../../components/CustomButtons/Button';
import CapitalAddEdit from './CapitalAddEdit';
import CapitalLists from './CapitalLists';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';

const styles = () => ({
  searchArea: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  accountArea: {
    marginTop: '25px',
    marginLeft: '20px'
  },
  listArea: {
    marginTop: '25px'
  },
  root: {
    width: 150,
    marginRight: 20,
    marginTop: 20,
    backgroundColor: '#f3f3f4'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 17
  },
  headerArea: {
    marginLeft: '20px'
  }
});

const useStyles = makeStyles(styles);

export default function Capitals(props) {
  const {
    fetchSetups,
    fetchContracts,
    capitalSubjects,
    capitalAccounts,
    capitalUnits,
    contracts,
    history,
    fetchCapitals,
    capitals,
    capitalLists,
    startDate,
    endDate
  } = props;

  useEffect(() => {
    fetchSetups(
      {
        history
      });
    fetchContracts(
      {
        history
      });
    fetchCapitals(
      {
        history
      });
  }, []);

  const classes = useStyles();
  const { translate } = useLocales();

  const [modeOpen, setModeOpen] = useState(null);
  const [editCapital, setEditCapital] = useState(null);
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchCompanyAmount, setSearchCompanyAmount] = React.useState(false);

  const downloadCapitals = capitalLists.map(item => ({
    ...item,
    type: item.type === 'income' ? '收入' : '支出'
  }));

  return (

    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              onClick={() => setModeOpen('income')}
              color="facebook"
            >
              {translate('income')}
            </Button>
            <Button
              onClick={() => setModeOpen('expense')}
              color="twitter"
            >
              {translate('expense')}
            </Button>

            {!(downloadCapitals && downloadCapitals.length) ?
              <Button
                disabled={true}
                type="button"
                color="warning"
              >
                导出EXCEL
              </Button>
              :
              <ExcelFile
                filename={`资金详情 ${startDate} ~ ${endDate}`}
                element={<Button
                  type="button"
                  color="warning"
                >
                  导出EXCEL
                </Button>}>
                <ExcelSheet data={downloadCapitals || []} name="仓库出入表">
                  <ExcelColumn label="日期" value="dateDisplay"/>
                  <ExcelColumn label="类型" value="type"/>
                  <ExcelColumn label="科目" value="subjectDisplay"/>
                  <ExcelColumn label="摘要" value="unit"/>
                  <ExcelColumn label="金额" value="amount"/>
                  <ExcelColumn label="账户" value="accountDisplay"/>
                  <ExcelColumn label="状态" value="statusDisplay"/>
                  <ExcelColumn label="合同" value="contractDisplay"/>
                  <ExcelColumn label="备注" value="note"/>
                </ExcelSheet>
              </ExcelFile>
            }

            {/*<Button*/}
            {/*  onClick={() => setModeOpen('transfer')}*/}
            {/*  color="twitter"*/}
            {/*>*/}
            {/*  {translate('transfer')}*/}
            {/*</Button>*/}
          </Grid>
        </Grid>

        {showSearch &&
        <Grid item xs={12}>
          <CapitalsSearch/>
        </Grid>
        }
        <Grid item xs={12} className={classes.accountArea}>
          <Grid container justify={'left'} alignItems="center" spacing={24}>
            <div>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <ToggleSwitch
                  input={{ value: showSearch,
                    onChange: () => setShowSearch(!showSearch) }}
                  label={translate('showSearch')}/>
                <ToggleSwitch
                  input={{ value: searchCompanyAmount,
                    onChange: () => setSearchCompanyAmount(!searchCompanyAmount) }}
                  label={translate('searchAccountAmount')}/>
              </Grid>
            </div>
            {(capitalAccounts || []).map((item, idx) =>
              <Card className={classes.root} key={idx}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {item.name}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {searchCompanyAmount ? currency(item.selected) : currency(item.all)}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>

        </Grid>
      </Grid>

      <Grid container className={classes.listArea}>
        <Grid item xs={12}>
          <CapitalLists
            capitalSubjects={capitalSubjects}
            capitalAccounts={capitalAccounts}
            capitalUnits={capitalUnits}
            contracts={contracts}
            history={history}
            capitals={capitals}
            onEditCapital={value => {
              setModeOpen('edit');
              setEditCapital(value);
            }}
          />
        </Grid>
      </Grid>

      {modeOpen &&
      <CapitalAddEdit
        editCapital={editCapital}
        contracts={contracts}
        capitalSubjects={capitalSubjects}
        capitalAccounts={capitalAccounts}
        capitalUnits={capitalUnits}
        modeType={modeOpen}
        onClose={() => {
          setModeOpen(null);
          setEditCapital(null);
        }}
      />
      }

    </Fragment>

  );
}

Capitals.propTypes = {
  history: PropTypes.any
};
