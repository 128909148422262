import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  upsertAttendee
} from '../../../actions';
import AttendeeAddEdit from './AttendeeAddEdit';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

import {
  getAllEmployees,
  getLoginUserClientConfigTimezone
} from '../../../selectors';
import { getFormValues, reduxForm } from 'redux-form/immutable';
import { ATTENDEE_ADD_EDIT as form } from '../../../lib/formNames';
import { Map } from 'immutable';

const stateToProps = (state, ownProps) => {
  const timezone = getLoginUserClientConfigTimezone(state);
  const formValues = (getFormValues(form)(state) || Map()).toJS();
  const workers = getAllEmployees(state);
  const attendeesLists = ownProps.attendeesLists;
  const editAttendeeId = ownProps.editAttendeeId;
  const attendee = (attendeesLists || [])
    .find(item => item.attendeeId === editAttendeeId) || {};

  const {
    workTime,
    date,
    attendeeId,
    worker,
    workType
  } = attendee || {};

  const initialValues = {
    attendeeId,
    date: date ? date : moment().tz(timezone)
      .startOf('day')
      .unix(),
    workTime,
    worker,
    workType
  };

  return ({
    formValues,
    timezone,
    initialValues,
    attendeeId,
    workers
  });
};

const dispatchToProps = dispatch => bindActionCreators({
  upsertAttendee
}, dispatch);

export default connect(stateToProps, dispatchToProps)(reduxForm({
  form,
  enableReinitialize: true
})(AttendeeAddEdit));
